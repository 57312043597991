import React, { useState } from 'react';

import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { Controller } from 'react-hook-form';

const OutOfStockBox = ({
 t, outOfStock, setOutOfStock, control, register,
}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        control={control}
        name="product_nostock"
        defaultValue={false}
        render={({
 field: {
          onChange,
          value,
          ref,
          onBlur,
        },
}) => (
  <>
    <Flex>
      <Switch
            checked={value}
            onChange={onChange}
            ref={ref}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onBlur={onBlur}
      />
      <DisableTitle>
        {t('out of stock')}
      </DisableTitle>
    </Flex>
    {value === true
      ? (
        <Background style={{ marginTop: '10px' }}>
          <Title style={{ fontSize: '13pt' }}>
            {t('restocking date')}
          </Title>
          <SubTitle>
            {t('restocking date description')}
          </SubTitle>
          <DateContainer>
            <DateInput>
              <Controller
          control={control}
           name="product_nostock_reapro"
           defaultValue={new Date()}
           render={({
            field: { onChange, value, ref },
           }) => (

             <KeyboardDatePicker
              InputProps={{
                disableUnderline: true,
              }}
              // InputAdornmentProps={{ position: 'start' }}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={value}
              onChange={(date) => onChange(date)}
              inputRef={ref}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
             />
            )}
              />
            </DateInput>
          </DateContainer>
        </Background>
)
      : <DisableSubtitle>
          {t('add restocking date')}
        </DisableSubtitle>}
  </>

        )}
      />

    </MuiPickersUtilsProvider>
  );

export default withTheme(withTranslation()(OutOfStockBox));

const Flex = styled.div`
  display: flex;
`;
const Title = styled.div`
	font-size: 15pt;
	font-weight: bold;
  margin-top: -5px;
	margin-left: 7px;
	margin-left: 7px;
  margin-bottom: 5px;
`;
const SubTitle = styled.div`
	font-size: 11pt;
  opacity: 0.6;
	margin-left: 7px;
`;
const Background = styled.div`
  background-color: #E6EFFF;
  padding: 20px;
  border-radius: 10px;
`;
const DisableTitle = styled.div`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 2px;
`;
const DisableSubtitle = styled.div`
	font-size: 10pt;
    margin-left: 12px;
`;
const DateInput = styled.div`
  width: 60%;
  padding-left: 10px;
  background-color: white;
  border-radius: 7px;
  .MuiFormControl-marginNormal {
    margin-top: 7px;
    margin-bottom: 5px;
  }
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.primary};
  }
`;
const DateContainer = styled.div`
  text-align-last: center;
  text-align: -webkit-center;
  margin-top: 15px;
`;
