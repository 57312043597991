import React from 'react';
import useStripe from '../../../../../hooks/Stripe';
import AuthContainer from '../../../../../stores/AuthContainer';
import { FirestoreDb } from '../../../../../utils/firebase/FirebaseModules';

const QrCodes = (props) => {
const { createSubscription } = useStripe();
const { userDatas } = AuthContainer.useContainer();

const testCreateQrCode = async () => {
    const subscriptionDatas = {

        priceId: 'price_1J5AJVHHuxIwAklQsCmXmpz4',
        customerId: userDatas.stripeId,
        type: 'qr_code',
        placeId: 'EGaStaWsVW8NqHoZCyWu',
        quantity: 5,

    };

    try {
        const { data } = await createSubscription(subscriptionDatas);
		console.log(data);
    } catch (e) {
        alert('Error during creating subscription -- contact the admin ');
        return;
    }
};

  return (
    <div>

      <button onClick={testCreateQrCode}>TEST</button>
    </div>
  );
};

export default QrCodes;
