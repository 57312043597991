import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import styled, { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import _ from 'underscore'
import PropTypes from 'prop-types'

const LangSelector = ({
  t,
  placeDatas,
  lang,
  id,
  menu_id = null,
  product_id = null,
  formula_id = null,
  action = false,
  productDatabase,
}) => {
  const history = useHistory()
  const location = useLocation()

  if (
    placeDatas &&
    _.isArray(placeDatas.languages_array) &&
    placeDatas.languages_array.length > 0
  ) {
    return (
      <LangSelectorContainer>
        <LangSelectorStyled>
          <Flex>
            <Button
              isActive={lang === placeDatas.main_language || lang === undefined}
              // removed: || FR
              key="langswitcher_main"
              onClick={() => {
                if (action) {
                  action()
                }
                history.push({
                  pathname: location.path,
                  search: `${!productDatabase ? `?place_id=${id}` : ''}&lang=${
                    placeDatas.main_language || 'FR'
                  }${menu_id !== null ? `&menu_id=${menu_id}` : ''}${
                    product_id !== null ? `&product_id=${product_id}` : ''
                  }${formula_id !== null ? `&formula_id=${formula_id}` : ''}${
                    productDatabase ? `&toProductDb=1` : ''
                  }`,
                })
              }}
            >
              {placeDatas.main_language || 'FR'}
            </Button>
            {placeDatas.languages_array.map((l, i) => (
              <Button
                isActive={lang === l}
                key={`langswitcher_${i}`}
                onClick={() => {
                  if (action) {
                    action()
                  }
                  history.push({
                    pathname: location.path,
                    search: `${!productDatabase ? `?place_id=${id}` : ''}&lang=${l}${
                      menu_id !== null ? `&menu_id=${menu_id}` : ''
                    }${product_id !== null ? `&product_id=${product_id}` : ''}${
                      formula_id !== null ? `&formula_id=${formula_id}` : ''
                    }${productDatabase ? `&toProductDb=1` : ''}`,
                  })
                }}
              >
                {l}
              </Button>
            ))}
          </Flex>
        </LangSelectorStyled>
      </LangSelectorContainer>
    )
  } else return null
}

export default withTheme(withTranslation()(LangSelector))

const Flex = styled.div`
  display: flex;
`
const LangSelectorContainer = styled.div`
  width: 100%;
  text-align: -webkit-center;
`
const LangSelectorStyled = styled.div`
  width: fit-content;
  padding: 5px 0px 5px 0px;
  border-radius: 9px;
  background-color: #6f4fb3;
`
const Button = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? 'white' : 'transparent')};
  color: ${(props) => (props.isActive ? '#6F4FB3' : 'white')};
  border-radius: 7px;
  padding: 2px 5px 2px 5px;
  margin: 0px 5px 0px 5px;
`
