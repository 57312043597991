import React from 'react'
import { createContainer } from 'unstated-next'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useCollection, useDocument } from '@nandorojo/swr-firestore'
import { firestore } from 'firebase/firestore'
import moment from 'moment'

const queryString = require('query-string')

const useMenuClientContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const [selected_lang, setSelected_lang] = React.useState('FR')
	const [place, setPlace] = React.useState()
	const { place_slug, menu_id } = useParams()
	const { lang } = queryString.parse(location.search)
	// place datas

	const { data: places, error: placesError } = useCollection(
		place_slug ? `places` : null,
		{
			where: [['place_slug', '==', place_slug]],
		}
	)
	// menu datas
	const { data: menus, error: menusError } = useCollection(
		places && places.length > 0 ? `places/${places[0].id}/menus` : null
	)

	React.useEffect(() => {
		if (places) {
			if (places.length < 1 || places[0].subscriptionStatus !== 'active') {
				history.push('/404')
				return
			}

			if (places[0].trial === true) {
				const now = moment()
				const diff = now.diff(moment(places[0].trial_started_at.toDate()), 'days')
				if (diff > 15) {
					history.push('/404')
					return
				}
			}

			setPlace(places[0])
		}
	}, [places])

	React.useEffect(() => {
		// get lang
		if (places && places.length > 0) {
			setSelected_lang(lang || places[0]?.main_language || 'FR')
		}
	}, [places, lang])

	return {
		place,
		menus,
		menu_id,
		selected_lang,
	}
}

export default createContainer(useMenuClientContainer)
