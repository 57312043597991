import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Spinner, Button } from 'react-bootstrap'
import { Auth, Storage, FirestoreDb } from '../utils/firebase/FirebaseModules'
import { deleteImage } from '../utils/ImageHelpers'
import { useHistory } from 'react-router-dom'
import { DeleteForever, Clear } from '@material-ui/icons'

export const useStorageTools = () => {
  const [filesUploaded, setFilesUploaded] = useState([])
  const [uploading, setUploading] = useState(false)
  const [deletingImage, setDeletingImage] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const _uploadAfile = async (file, path, customName = null) => {
    const user = await Auth().currentUser
    const name =
      customName !== null ? `${customName}.${file.name.split('.').pop()}` : file.name
    const usersImagesRef = Storage().ref().child(`${path}/${name}`)

    return usersImagesRef
      .put(file, { customMetadata: { uid: user.uid } })
      .then((snapshot) => ({ file, ref: snapshot.ref }))
      .catch((e) => {
        console.log(e)
        return null
      })
  }

  const firebaseUploader = async (files, path, names = []) => {
    setUploading(true)
    let newImages = []

    await Promise.all(
      files.map(async (f, i) => {
        const customName = names[i] || null
        let uploaded = await _uploadAfile(f, path, customName)

        if (!uploaded) {
          alert(`Le fichier${f.name} n'a pas pu être envoyé`)
        } else {
          const urlRef = await uploaded.ref.getDownloadURL()
          newImages.push({ url: urlRef, ref: uploaded.ref })
        }
      })
    )

    setFilesUploaded(newImages)
    setUploading(false)

    return newImages
  }

  const storageListFiles = async (path) => {
    const listRef = Storage().ref().child(path)

    // Find all the prefixes and items.
    const items = await listRef.listAll()

    let itemsUrls = []
    for (let itemRef of items.items) {
      const url = itemRef.getDownloadURL()
      itemsUrls.push({ url, ref: itemRef })
    }

    const result = await Promise.all(itemsUrls)
    return result
  }

  const deleteDocument = async ({
    url,
    collection,
    doc,
    field,
    message,
    resetFunction,
    server,
  }) => {
    setDeletingImage(true)
    if (server) {
      const del = await deleteImage({ url, collection, doc, field })
      if (del.status === 'success') {
        if (message?.success)
          enqueueSnackbar(message.success, { variant: 'success' })
      }
    }
    if (resetFunction) {
      resetFunction()
    }
    setDeletingImage(false)
  }

  const documentDeletionButton = ({
    text,
    icon,
    url,
    collection,
    doc,
    field,
    message,
    resetFunction,
    server,
  }) => (
    <div
      style={{
        position: 'absolute',
        width: '30px',
        left: '10px',
        top: '10px',
        color: 'red',
        cursor: 'pointer',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        background: 'white',
      }}
    >
      {deletingImage ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <Clear
          onClick={() =>
            deleteDocument({
              url,
              collection,
              doc,
              field,
              message,
              resetFunction,
              server,
            })
          }
        />
      )}
    </div>
  )

  return {
    firebaseUploader,
    filesUploaded,
    uploading,
    storageListFiles,

    documentDeletionButton,
  }
}

export const useFirestoreApi = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [datas, setDatas] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const fetchDatas = ({ query, type, clause, listen }) => {
    setLoading(true)
    let datas
    let queryRef
    let call
    if (type === 'document') {
      const doc = query.split('/').pop()
      const collection = `${query.split('/').slice(0, -1).join('/')}/`
      queryRef = FirestoreDb().collection(collection).doc(doc)
    } else if (type === 'collection') {
      queryRef = FirestoreDb().collection(query)
    } else {
      throw new Error('Unknow type')
    }

    if (Object.keys(clause).length > 0) {
      Object.keys(clause).map((k) => {
        switch (k) {
          case 'where':
            clause[k].forEach((where) => {
              queryRef = queryRef.where(where[0], where[1], where[2])
            })
            break
          case 'orderBy':
            queryRef = queryRef.orderBy(clause[k][0], clause[k][1], clause[k][2])
            break
          default:
            break
        }
      })
    }

    const req = (snapshot) => {
      if (type === 'collection') {
        if (snapshot.docs.length < 1) {
          setError('no docs can be fetched')
        } else {
          datas = []
          snapshot.forEach((doc) => {
            if (doc.exists) datas.push({ ...doc.data(), id: doc.id })
          })
          setDatas(datas)
        }
      }
      if (type === 'document') {
        datas = {}
        if (snapshot.exists) {
          datas = { ...snapshot.data(), id: snapshot.id }
          setDatas(datas)
        } else {
          setError('cant get the doc ')
        }
      }

      var source = snapshot.metadata.fromCache ? 'local cache' : 'server'
      console.log('data source', source)
      setLoading(false)
    }

    call = queryRef.get().then((snapshot) => req(snapshot))
  }

  const updateDatas = async ({ query, datas, message, action }) => {
    let queryRef
    let res
    if (action === 'set' || action === 'delete') {
      const doc = query.split('/').pop()
      const collection = `${query.split('/').slice(0, -1).join('/')}/`
      queryRef = FirestoreDb().collection(collection).doc(doc)
    } else if (action === 'add') {
      queryRef = FirestoreDb().collection(query)
    } else {
      throw new Error('Unknow type')
    }

    try {
      if (action === 'set' || action === 'add') {
        res = await queryRef[action](datas, { merge: true })
      } else res = await queryRef[action]()

      if (message?.success) enqueueSnackbar(message.success, { variant: 'success' })
    } catch (e) {
      console.log(e.message)
      if (message?.error) enqueueSnackbar(message.error, { variant: 'error' })
    }
    return res
  }

  return { fetchDatas, updateDatas, datas, error, loading }
}
