import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { SnackbarProvider } from 'notistack'

import AuthContainer from './stores/AuthContainer'
import DisplayContainer from './stores/DisplayContainer'
import useMenuClientContainer from './stores/MenuClientContainer'

import Index from './pages/Index/Index'
import Login from './pages/Login/Login'
import SignUp from './pages/SignUp/SignUp'
import PlaceView from './pages/MenuClient/PlaceView'
import MenuView from './pages/MenuClient/MenuView'
import Page404 from './pages/Page404/Page404'
import DashboardClientContainer from './pages/DashboardClient/DashboardClientContainer'
import DashboardAdminContainer from './pages/DashboardAdmin/DashboardAdminContainer'
import FirebaseAuthActions from './pages/FirebaseAuthActions/FirebaseAuthActions'

const App = () => (
  <>
    <SnackbarProvider maxSnack={1} preventDuplicate>
      <AuthContainer.Provider>
        <DisplayContainer.Provider>
          <GlobalStyle />
          <Switch>
            <Route exact path="/" component={() => <Index />} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/dashboard" component={DashboardClientContainer} />
            <Route path="/admin" component={DashboardAdminContainer} />
            <Route path="/auth/action" component={FirebaseAuthActions} />
            <Route exact path="/place/:place_slug">
              <useMenuClientContainer.Provider>
                <PlaceView />
              </useMenuClientContainer.Provider>
            </Route>
            <Route exact path="/place/:place_slug/menu/:menu_id">
              <useMenuClientContainer.Provider>
                {' '}
                <MenuView />
              </useMenuClientContainer.Provider>
            </Route>
            <Route exact path="/_/:place_slug">
              <useMenuClientContainer.Provider>
                <PlaceView />
              </useMenuClientContainer.Provider>
            </Route>
            <Route exact path="/_/:place_slug/menu/:menu_id">
              <useMenuClientContainer.Provider>
                {' '}
                <MenuView />
              </useMenuClientContainer.Provider>
            </Route>
            <Route exact="/404">
              <Page404 />
            </Route>
            <Route>
              <Page404 />
            </Route>
          </Switch>
        </DisplayContainer.Provider>
      </AuthContainer.Provider>
    </SnackbarProvider>
  </>
)
export default App

const GlobalStyle = createGlobalStyle`
body{
  font-family : ${(props) => props.theme.font_family} ;
  overflow-x : hidden ; 
}
h1{
    font-size : ${(props) => props.theme.title} ;
    color : ${(props) => props.theme.grey_1} ;
}
p{
  font-size : ${(props) => props.theme.text} ;
}
.primaryText{
color : ${(props) => props.theme.primary} ; 
}

body,html,#root{
    height : 100% ; 
    min-height : 100% ; 
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${(props) => props.theme.primary};
    outline: 0;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary};
}

.btn-primary.focus, .btn-primary:focus,.btn-primary:active {
    color: #fff;
    background-color: ${(props) => props.theme.primary} !important;
    border-color: ${(props) => props.theme.primary} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.primary}50 !important;
}

.btn-primary:hover{
      background-color: ${(props) => props.theme.primary}99;
    border-color: ${(props) => props.theme.primary};

}

.btn-primary:disabled{
  background-color: ${(props) => props.theme.primary}99;
border-color: ${(props) => props.theme.primary};
}

.btn-secondary {
    color: #fff;
    background-color: ${(props) => props.theme.secondary};
    border-color: ${(props) => props.theme.secondary};
}

.btn-secondary.focus, .btn-secondary:focus,.btn-secondary:active {
    color: #fff;
    background-color: ${(props) => props.theme.secondary} !important;
    border-color: ${(props) => props.theme.secondary} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme.secondary}50 !important;
}

.btn-secondary:hover{
      background-color: ${(props) => props.theme.secondary}99;
    border-color: ${(props) => props.theme.secondary};

}

.btn-disabled {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.5);
}

.btn-disabled.focus, .btn-disabled:focus,.btn-disabled:active {
  color: #fff;
  background-color: rgba(108, 117, 125, 0.5) !important;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5) !important;
}

.btn-disabled:hover{
    background-color: rgba(108, 117, 125, 0.5);
  color: #fff;
}

.btn-primaryoutline {
  color: ${(props) => props.theme.primary};
  background-color: rgba(111, 79, 179, 0.2);
  border-color: rgba(111, 79, 179, 0.2);
}

.btn-primaryoutline.focus, .btn-primaryoutline:focus,.btn-primaryoutline:active {
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(111, 79, 179, 0.2)50 !important;
}

.navbar-expand-bottom-fixed{
  position: fixed;
    width: 100%;
    left: 0;
    background: white;
    bottom: 0;
}
.card.white-card {
    padding: 14px;
    border-radius: 22px;
    box-shadow: 0 0 7px #00000029;
    margin: 17px 0;

    .card-title{
       font-size : ${(props) => props.theme.title_md} ;
    color : ${(props) => props.theme.grey_1} ;
    font-weight : bold ; 
    }
    .card-subtitle{
       font-size : ${(props) => props.theme.text} ;
    color : ${(props) => props.theme.grey_3} ;
    }
}
.modal-backdrop.show {
    z-index: 20000;
}
.modal-open .modal {
  
    z-index: 50000;
}
#dropdown-custom-components.dropdown-toggle::after{
  display : none ; 
}

.dropdown-item {
  text-align : center ; 
  padding : 5px 0px ; 
  > a{
    padding: 0px 18px;
    height: 100%;
    text-align: left;
    display : block ; 
    width: 100% ; 
    height: 100% ; 
      color : ${(props) => props.theme.grey_1} ;
      &:hover{
        text-decoration:none ; 
      }

}
}

.dropdown-item.active, .dropdown-item:active{
  a{

    color:white; 
  
  }
      color:white; 

    background : ${(props) => props.theme.primary} ;
     color : white ;

}

`
