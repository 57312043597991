import React, { useState, useEffect } from 'react'
import firestore from 'firebase/firestore'
import firebase from 'firebase/app'
import _, { isObject } from 'underscore'
import { Button, Modal, Col, Row, Spinner } from 'react-bootstrap'
import { useCollection } from '@nandorojo/swr-firestore'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  RadioButtonUnchecked,
  CheckCircle,
  Check,
  Search,
  Fastfood,
  LocalBar,
  Category,
} from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import ProductIcon from '../../../../assets/menu/products.svg'
import { FirestoreDb } from '../../../../utils/firebase/FirebaseModules'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import AuthContainer from '../../../../stores/AuthContainer'
import { useFirestoreApi } from '../../../../hooks/FireTools'

const queryString = require('query-string')

const _products = [
  {
    id: 0,
    category_name: 'foods',
    groups: [],
    products: [],
  },
  {
    id: 1,
    category_name: 'drinks',
    groups: [],
    products: [],
  },
  {
    id: 2,
    category_name: 'others',
    groups: [],
    products: [],
  },
]

const category_select = ['all', 'foods', 'drinks', 'others']

const AddProductModal = ({
  t,
  modalOpen,
  toggleModal,
  sync: syncMenu,
  producstIn = [],
  setProducstIn,
  formulas,
  setFormulas,
  index,
  currentLang,
  subIndex,
  currentElLength,
  mode,
  formulaId,
  category,
  syncMenuWithProd,
  placeDatas,
}) => {
  const location = useLocation()
  const { lang } = queryString.parse(location.search)
  const [searchInput, setSearchInput] = useState('')
  const [catSelect, setCatSelect] = useState('all')
  const [searchIndex, setSearchIndex] = useState(0)
  const history = useHistory()
  const [items, setItems] = useState(_products)
  const { user } = AuthContainer.useContainer()
  const { enqueueSnackbar } = useSnackbar()
  const [updating, setUpdating] = useState(false)
  const { updateDatas } = useFirestoreApi()
  const { data: products } = useCollection('products', {
    where: [
      ['place_id', '==', placeDatas.id],
      ['uid', '==', user?.uid],
    ],

    orderBy: ['order', 'asc'],
  })

  useEffect(() => {
    if (products) {
      formatItems(products)
    }
  }, [products, lang])

  const updateProductList = async (prods) => {
    // update cat to database

    await updateDatas({
      query: `places/${placeDatas.id}/formulas/${formulaId}/sections/${category.id}`,
      datas: { products: prods },
      message: {
        success: t('product_added_succeed'),
        error: t('product_added_failed'),
      },
      action: 'set',
    })
  }

  const formatItems = (_docs) => {
    const _products = [...items]
    let fItems = _docs.map((_i, index) => ({
      ..._i,
      cat: _i.tab,
      __snapshot: null,
    }))
    _products.map((p, i) => {
      _products[i].products = fItems.filter((_p) => _p.cat === p.category_name)
    })

    setItems(_products)
  }

  const isChecked = (id) => {
    for (const product of producstIn) if (product.id === id) return true

    return false
  }

  const setChecked = (id, item) => {
    if (isChecked(id)) setProducstIn(producstIn.filter((i) => i.id !== id))
    else setProducstIn([...producstIn, item])
  }
  const handleSuccess = async () => {
    setUpdating(true)
    var newMenus = formulas
    const oldp = [...category.products] || []

    await updateProductList(
      producstIn.map((p, i) => ({ ...p, menu_order: currentElLength + i }))
    )

    const sync = await syncMenuWithProd(category.id, placeDatas.id, formulaId, oldp)
    syncMenu()
    setUpdating(false)
    toggleModal(false)
  }

  const handleCancel = () => {
    setProducstIn([])
    toggleModal(false)
  }

  const itemRenderer = (product) => (
    <Flex>
      <IconContainer onClick={() => setChecked(product.id, product)}>
        {isChecked(product.id) ? <CircleCheck /> : <RadioButton />}
      </IconContainer>
      <Content
        isChecked={isChecked(product.id)}
        onClick={() => setChecked(product.id, product)}
      >
        <ContentLeftContainer>
          <ContentTitle>
            {getMultilangContent(product, 'product_name_ml', currentLang)}
          </ContentTitle>
          <ContentDescription>
            {getMultilangContent(product, 'product_description_ml', currentLang)}
          </ContentDescription>
        </ContentLeftContainer>
        <ContentAction>
          <Flex style={{ marginRight: '20px' }}>
            <PriceContainer>
              {product.product_promo ? (
                product.product_prices.map((item, index) => {
                  if (index < 2) {
                    return (
                      <PromoPriceBox>
                        <PromoPrice>{`${item.price} €`}</PromoPrice>
                        &nbsp;&nbsp;
                        <PromoNewPrice>
                          {`${(
                            item.price -
                            item.price * (product.product_promo_reduction / 100)
                          ).toFixed(2)} €`}
                        </PromoNewPrice>
                      </PromoPriceBox>
                    )
                  } else return null
                })
              ) : (
                <div style={{ width: '100%', display: 'flex' }}>
                  {product.product_prices.map((product, index) => {
                    if (index < 3) {
                      return <PriceBox>{`${product.price} €`}</PriceBox>
                    } else return null
                  })}
                </div>
              )}
            </PriceContainer>
          </Flex>
        </ContentAction>
      </Content>
    </Flex>
  )

  return (
    <CustomModal show={modalOpen} onHide={toggleModal}>
      <ButtonContainer>
        <Button
          variant="secondary"
          onClick={() =>
            history.push(
              `/dashboard/edit/new/product?place_id=${
                placeDatas.id
              }&from=${encodeURIComponent(location.pathname + location.search)}`
            )
          }
          style={{
            width: '30%',
            margin: '20px 0px 20px 0px',
            height: '50px',
            boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
          }}
        >
          <img
            src={ProductIcon}
            alt="logo"
            style={{
              width: '23px',
              marginRight: '20px',
              marginTop: '-3px',
            }}
          />
          {t('create new product')}
        </Button>
      </ButtonContainer>
      <Background>
        <Row>
          <Col lg="10">
            <SearchInputContainer>
              <SearchIcon />
              <SearchInput
                placeholder={t('search product')}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </SearchInputContainer>
          </Col>
          <Col lg="2">
            <CatSelect
              defaultValue="all"
              onChange={(e) => {
                if (e.target.value !== '0')
                  setSearchIndex(parseInt(e.target.value, 10) - 1)
                setCatSelect(category_select[e.target.value])
              }}
              catSelect
            >
              {category_select.map((cat, i) => (
                <option key={`cat${i}`} value={i}>
                  {t(cat)}
                </option>
              ))}
            </CatSelect>
          </Col>
        </Row>
      </Background>
      {items.map((item, i) => {
        let cpt = 0
        item.products.map((product) => {
          if (
            getMultilangContent(product, 'product_name_ml', currentLang)?.includes(
              searchInput
            )
          )
            cpt++
        })
        return (
          (searchIndex === i || catSelect === 'all') && (
            <>
              {cpt > 0 ? (
                <>
                  <Background>
                    <Title>{t(item.category_name)}</Title>
                    {item.products.map(
                      (product, index) =>
                        getMultilangContent(
                          product,
                          'product_name_ml',
                          currentLang
                        )?.includes(searchInput) &&
                        itemRenderer(product, searchInput)
                    )}
                  </Background>
                </>
              ) : item.products.length === 0 && catSelect !== 'all' ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '200',
                    padding: '40px',
                  }}
                >
                  Rien à afficher
                </div>
              ) : null}
            </>
          )
        )
      })}
      <br />
      <BottomBar>
        <CancelButton
          variant="primaryoutline"
          href="#"
          onClick={() => handleCancel()}
        >
          {t('cancel')}
        </CancelButton>
        <TextBottomBar>
          <CustomCheck />
          {`${producstIn.length} ${t('product selected')}`}
        </TextBottomBar>
        <SaveButton
          disabled={updating}
          variant="primary"
          href="#"
          onClick={() => handleSuccess()}
        >
          {updating ? <Spinner animation="border" size="sm" /> : 'Ajouter'}
        </SaveButton>
      </BottomBar>
    </CustomModal>
  )
}

export default withTheme(withTranslation()(AddProductModal))

const Flex = styled.div`
  display: flex;
`
const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f8f7ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`
const CircleCheck = styled(CheckCircle)`
  color: ${(props) => props.theme.primary};
`
const RadioButton = styled(RadioButtonUnchecked)`
  color: #d1d1d1;
`
const CustomModal = styled(Modal)`
  width: 100%;
  .modal-dialog {
    max-width: 80%;
  }
  .modal-content {
    background-color: #eceaff;
    border-radius: 20px;
  }
`
const CustomCheck = styled(Check)`
  margin-right: 5px;
`
const SearchIcon = styled(Search)`
  color: rgba(0, 0, 0, 0.5);
  width: 20px !important;
  height: 20px !important;
  margin-left: 20px;
`
const SearchInputContainer = styled.div`
  border-style: solid;
  border-radius: 20px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
`
const SearchInput = styled.input`
  margin-left: 10px;
  outline: none;
  width: 85%;
  background: transparent;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  ::placeholder {
    opacity: 0.5;
  }
`
const CatSelect = styled.select`
  cursor: pointer;
  width: 80%;
  height: 35px;
  text-align: center;
  background: #e6efff;
  padding: 5px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  margin-top: 2px;
  ::placeholder {
    opacity: 0.5;
  }
`
const IconContainer = styled.div`
  cursor: pointer;
  margin: 10px 10px 0px 0px;
`
const PriceContainer = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  margin-right: 20px;
`
const PriceBox = styled.div`
  margin-right: 20px;
`
const PromoPriceBox = styled.div`
  display: flex;
  margin-right: 15px;
`
const PromoPrice = styled.div`
  text-decoration: line-through;
`
const PromoNewPrice = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  @media screen and (max-width: 1150px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) {
    width: 60%;
  }
`
const ContentAction = styled.div`
  float: right;
  @media screen and (max-width: 1150px) {
    width: 60%;
  }
  @media screen and (min-width: 1150px) {
    width: 40%;
  }
`
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 40px;
`
const Background = styled.div`
  margin-top: 40px;
  background-color: white;
  margin: 20px 20px 0px 20px;
  padding: 20px 40px 20px 40px;
  text-align: center;
  border-radius: 25px;
`
const Content = styled.div`
  display: flex;
  padding: 10px 0px 10px 0px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${(props) =>
    props.isChecked ? (props) => props.theme.primary : '#D1D1D1'};
  width: 100%;
  margin-bottom: 20px;
`
const ContentTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  cursor: pointer;
  width: 140px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ContentDescription = styled.div`
  font-size: 12pt;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 200px;
`
const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 10px 40px 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
const TextBottomBar = styled.div`
  display: flex;
  margin-top: 10px;
  color: ${(props) => props.theme.primary};
`
const CancelButton = styled(Button)`
  width: 150px;
  height: 45px;
  padding-top: 10px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SaveButton = styled(Button)`
  width: 200px;
  height: 45px;
  padding-top: 10px;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
