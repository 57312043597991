import styled from 'styled-components';
import EventIcon from '@material-ui/icons/Event';
import ContactThemeLogo from './ContactThemeLogo';

const HeaderThemeCard = ({ datas }) => {
    const img = 'https://www.cafedelapaix.fr/assets/uploads/2021/06/cafe-de-la-paix-jerome-galland-2-960x640.jpg';
    return (
      <CardStyled>
        <HeadingReservation style={{display: !datas.topBanner && 'none'}}>
          <HeadingReservationAnimationContainer>
            <HeadingReservationIconButton datas={datas}>
              <EventIcon style={{ color: 'white', marginRight: '9px', fontSize: '20px' }} />
              Réserver
            </HeadingReservationIconButton>
          </HeadingReservationAnimationContainer>
        </HeadingReservation>
        <ImgCardStyled src={img} alt="restaurant" />
        <InformationHandler>
          <InformationTitle datas={datas}>Restaurant</InformationTitle>
          <InformationDescription datas={datas}>
            Adresse mythique des Grands Boulevards, le Café de la Paix renoue après une
            complète restauration avec le panache de son fameux décor Napoléon III.
          </InformationDescription>
          <ContactThemeLogo fontColor={datas.color} isWhite={datas.darkmode} />
        </InformationHandler>
      </CardStyled>
    );
};

export default HeaderThemeCard;
const HeadingReservationAnimationContainer = styled.div`
display:flex;
transform: translate3d(0, calc(32px - 100%), 0);
transition: transform .25s ease-in-out;
position: relative;
width: 100%;
height:100%;
align-items: center;
flex-direction: column;
`;

const HeadingReservationIconButton = styled.div`
display: flex;
justify-content: center;
align-items: end;
padding-top: 4px;
padding-bottom: 8px;
padding-left: 13px;
padding-right: 13px;
font: normal normal normal 16px ${(props) => props.datas.font};
width: 113px;
background-color: ${(props) => props.datas.color};
border-radius: 0px 0px 15px 15px;
`;

const HeadingReservation = styled.div`
position: absolute;
top: 0;
z-index: 10;
text-align: center;
overflow: hidden;
width: 100%;
`;

const ImgCardStyled = styled.img`
position: absolute;
width: 100%;
height: 100%;
object-fit: cover;
`;

const InformationTitle = styled.div`
font: bold 25px ${(props) => props.datas.font};
margin-bottom: 25px;
`;

const InformationHandler = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
z-index: 1;
background-color: rgba(0, 0, 0, 0.5);
width:100%;
height: 100%;
display:flex;
justify-content: center;
align-items:center;
`;

const InformationDescription = styled.p`
text-align: center;
width: 80%;
font-weight: normal;
font: normal 15px ${(props) => props.datas.font};
margin-bottom: 40px;
line-height: 1.2em;
`;
const CardStyled = styled.div`
overflow: hidden;
position: relative;
width: 100%;
height: 390px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-size: 26px;
font-weight: bold;
background-color: #276347;
`;
