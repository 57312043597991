import React, { useEffect, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Dropdown,
} from 'react-bootstrap'

import {
  TableContainer,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'

import { Link, useHistory } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import Navbar from '../../../components/UI/navbar/Navbar'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../stores/AuthContainer'
import UseDashboardClientStore from '../DashboardClientStore'

const useStyles = makeStyles({
  container: {
    height: '70vh',
  },
  headValues: {
    color: '#888',
    backgroundColor: 'white',
  },
})

const headValues = [
  { name: 'place_name', label: 'place' },
  { name: 'menu_number', label: 'menu number' },
  { name: 'place_address', label: 'place address' },
]

const Places = ({ setTitle, t }) => {
  const { user } = AuthContainer.useContainer()
  const { setPlaceItemsId } = UseDashboardClientStore.useContainer()
  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState(null)
  const [orderProperty, setOrderBy] = useState('place_name')
  const [orderDirection, setOrderDirection] = useState('asc')
  const table = useRef(null)
  const classes = useStyles()
  const history = useHistory()

  const actions = [
    {
      func: (id) => {
        setPlaceItemsId(id)
        history.push({
          pathname: '/dashboard/edit/infos/',
          search: `?place_id=${id}`,
        })
      },
      name: 'edit',
      params: 'id',
    },
    {
      link: '/dashboard/subscriptions',
      link_id: 'id',
      name: 'manage subscription',
    },
    {
      link: '/dashboard/invoices',
      link_id: 'id',
      name: 'show invoices',
    },
  ]

  const fetchDatas = async () => {
    let placesRef = FirestoreDb()
      .collection('places')
      .where('uid', '==', user.uid)
      .orderBy('created_at', 'asc')
    const { docs } = await placesRef.get()
    return { datas: docs }
  }

  useEffect(async () => {
    setTitle(`${t('place')} > <b>${t('places list')}</b>`)
    setLoading(true)
    const _datas = await fetchDatas()
    const datas_array = []
    _datas.datas.forEach(async (doc) => {
      if (doc.exists) {
        datas_array.push({
          ...doc.data(),
          id: doc.id,
          menu_number: doc.data()?.menus_list ? doc.data().menus_list.length : 0,
        })
      }
    })
    setDatas(datas_array)
    setLoading(false)
  }, [])

  const handleSort = (headValue) => {
    setOrderBy(headValue.name)
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) =>
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  return (
    <CustomContainer fluid>
      <Row>
        <Col lg="9">
          <Navbar
            items={[`${t('all')} (${datas ? datas.length : 0})`]}
            tab={`${t('all')} (${datas ? datas.length : 0})`}
            setTab={null}
            t={t}
          />
        </Col>
        <Col lg="3" style={{ marginTop: '20px' }}>
          <Button
            style={{ width: '100%' }}
            variant="primary"
            onClick={() => history.push(`/dashboard/create-place`)}
          >
            {t('Create a place')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card bsPrefix="card white-card">
            <TableContainer className={classes.container} ref={table}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headValues.map((val, i) => (
                      val.name === 'place_name' ? 
                      <TableCell
                        style={{ borderBottom: 'none' }}
                        className={classes.headValues}
                      >
                        <TableSortLabel
                          active={val.name === orderProperty}
                          onClick={() => handleSort(val)}
                          direction={
                            val.name === orderProperty ? orderDirection : 'asc'
                          }
                        >
                          {t(val.label)}
                        </TableSortLabel>
                      </TableCell> : 
                      <CustomTableCell
                        style={{ borderBottom: 'none' }}
                        className={classes.headValues}
                      >
                       <TableSortLabel
                          active={val.name === orderProperty}
                          onClick={() => handleSort(val)}
                          direction={
                            val.name === orderProperty ? orderDirection : 'asc'
                          }
                        >
                          {t(val.label)}
                        </TableSortLabel>
                      </CustomTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <Spinner animation="border" variant="dark" size="lg" />
                  ) : (
                    datas !== null &&
                    stableSort(
                      datas,
                      getComparator(orderDirection, orderProperty)
                    ).map((data, i) => (
                      <TableRow key={data.id}>
                        {headValues.map((d, i) => (
                          d.name === 'place_name' ?
                          <TableCell
                            style={{ borderBottom: 'none', fontWeight: 'bold' }}
                            key={`datatype-${i}`}
                          >
                            {data[d.name]}
                          </TableCell> :
                          <CustomTableCell
                            style={{ borderBottom: 'none', fontWeight: 'bold' }}
                            key={`datatype-${i}`}
                          >
                            {data[d.name]}
                          </CustomTableCell> 
                        ))}
                        <TableCell style={{ borderBottom: 'none', width: '10px' }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              as="div"
                              id="dropdown-custom-components"
                            >
                              <IconButton>
                                <SettingsIcon />
                              </IconButton>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {actions.map((action, i) => (
                                <Dropdown.Item as="div" key={`action-item-${i}`}>
                                  {action.func !== undefined ? (
                                    <CustomButton
                                      onClick={() =>
                                        action.func(data[action.params])
                                      }
                                      variant="default"
                                    >
                                      {t(action.name)}
                                    </CustomButton>
                                  ) : (
                                    <Link
                                      to={`${action.link}/${data[action.link_id]}`}
                                    >
                                      {t(action.name)}
                                    </Link>
                                  )}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Col>
      </Row>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(Places))

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media (min-width: 992px) {
    padding-left: 250px;
  }
`

const CustomButton = styled(Button)`
  width: 100%;
  text-align: initial;
  padding: 0px 18px;
`

const CustomTableCell = styled(TableCell)`
  @media (max-width: 602px) {
    display: none !important;
  }
`

