import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled, { withTheme, keyframes } from 'styled-components'
import {
  Row,
  Col,
  OverlayTrigger,
  Container,
  Dropdown,
  DropdownButton,
  Card,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap'
import { useSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import {
  PermMedia,
  CloudUploadOutlined,
  DescriptionOutlined,
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
  AssignmentReturnedRounded,
  SubdirectoryArrowRight,
  Close,
} from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'underscore'
import { useDropzone } from 'react-dropzone'
import { Functions } from '../../../../utils/firebase/FirebaseModules'
import Moveicon from '../../../../assets/menu/move.svg'
import { SetLogoModal, AddProductModal, EditCategory, EditFormula } from '../common'
import useImportFormula from '../common/importFormula'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'
import {
  getMultilangContent,
  createMultilangArray,
  updateMultilangArray,
} from '../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'
import { useFirestoreApi, useStorageTools } from '../../../../hooks/FireTools'
import ProductCard from '../../../../components/Dashboard/Menus/Cards/ProductCard'

const Menu = ({
  t,
  modePDF,
  register,
  setValue,
  background_image_url,
  pdf_url,
  menuDatas,
  placeDatas,
  sections,
  errors,
  currentLang,
  addSection,
  sectionId,
  productId,
}) => {
  const [picModalOpen, setPicModalOpen] = useState(false)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const [editCategoryOpen, setEditCategoryOpen] = useState(false)
  const [currentElLength, setCurrentElLength] = useState(0)
  const [editFormulaOpen, setEditFormulaOpen] = useState(false)
  const { documentDeletionButton } = useStorageTools()
  const [menus, setMenus] = useState([])
  const [productsNbMenus, setProductsNbMenus] = useState(0)
  const location = useLocation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()
  const [producstIn, setProducstIn] = useState([])
  const [indexEdit, setIndexEdit] = useState(0)
  const [subIndexEdit, setSubIndexEdit] = useState(0)
  const [typeEdit, setTypeEdit] = useState(0)
  const [editOrCreate, setEditOrCreate] = useState(true)
  const [duplicating, setDuplicating] = useState({})
  const {
    importFormulaModal,
    toggleModal: showImportFormula,
    config,
    deleteFormulaFunction,
  } = useImportFormula()
  const [category, setCategory] = useState(null)

  const [PicUrl, setPicUrl] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState(null)
  const myRefname = useRef(null)
  const menuContainerRef = useRef()
  const { updateDatas } = useFirestoreApi()

  const onDrop = useCallback((file) => {
    if (file[0].name && file[0].name.split('.')[1] === 'pdf') {
      setValue('pdf_file', file[0])
      setFileName(file[0].name)
      setFileSize(file[0].size)
    } else enqueueSnackbar(t('pdf_not_found'), { variant: 'error' })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (menuContainerRef.current)
      window.scroll({ top: menuContainerRef.current.offsetTop, behavior: 'smooth' })

    if (sectionId && productId) {
      console.log('EDIT PRODUCT!')
    }
  }, [])

  useEffect(() => {
    if (modePDF) {
      if (getMultilangContent(menuDatas, 'pdf_url_ml', currentLang)) {
        setFileName(getMultilangContent(menuDatas, 'pdf_file_name_ml', currentLang))
        setFileSize(getMultilangContent(menuDatas, 'pdf_file_size_ml', currentLang))
      } else {
        setFileName(false)
      }
    }
  }, [currentLang])

  useEffect(() => {
    let tmp = 0

    if (menus)
      menus.map((menu) => {
        formatCategoryElements(menu).map((section) => {
          if (section._element_type !== 'cat') tmp += 1
          else if (section.products) tmp += section.products.length
        })
      })
    setProductsNbMenus(tmp)
  }, [menus])

  useEffect(() => {
    setMenus(sections || [])
  }, [sections])

  const displayGroupTitle = (id) => {
    if (id === 2) {
      return t('featured category')
    } else if (id === 1) {
      return t('formule category')
    } else {
      return t('simple category')
    }
  }

  const productCount = (group) => {
    let tmp = 0

    formatCategoryElements(group).map((section) => {
      if (section._element_type !== 'cat') tmp += 1
      else if (section.products) tmp += section.products.length
    })
    return tmp
  }

  const syncMenuWithProd = async (sectionId, placeId, menuId, oldProds) => {
    const _syncFunction = Functions().httpsCallable('products-updateProductLocation')

    return _syncFunction({
      data: {
        sectionId,
        placeId,
        menuId,
        oldProds,
        type: 'menus',
      },
    })
      .then((data) => data)
      .catch((e) => e)
  }

  const displaySize = (size) => {
    if (size / 1000000 > 1)
      return `${(Math.trunc(fileSize / 100000) / 10).toString()} Mo`
    else return `${(Math.trunc(fileSize / 100) / 10).toString()} Ko`
  }

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const deleteCategory = async (group) => {
    const subCats = formatCategoryElements(group).filter(
      (c) => c._element_type === 'cat'
    )
    if (window.confirm(t('remove_category'))) {
      await Promise.all([
        updateDatas({
          query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${group.id}`,
          action: 'delete',
        }),
        subCats.map((s) => {
          updateDatas({
            query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${s.id}`,
            action: 'delete',
          })
        }),
      ])
      enqueueSnackbar('category_deleted_succeed', { variant: 'success' })
    } else return false
  }

  const editSubCat = async (id, field, content, old) => {
    const datas = {}
    datas[field] = updateMultilangArray(content, old, currentLang)
    await updateDatas({
      query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${id}`,
      action: 'set',
      datas,
      message: {
        error: t('sub_cat_added_failed'),
        success: t('sub_cat_added_succeed'),
      },
    })
  }

  const addSubCat = async (id) => {
    const elements = formatCategoryElements(menus.find((d) => d.id === id))
    const r = await updateDatas({
      query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections`,
      action: 'add',
      datas: {
        group_parent: id,
        group_name_ml: createMultilangArray(
          [placeDatas.main_language, ...placeDatas.languages_array],
          currentLang,
          ''
        ),
        group_description_ml: createMultilangArray(
          [placeDatas.main_language, ...placeDatas.languages_array],
          currentLang,
          ''
        ),
        uid: menuDatas.uid,
        group_visible: true,
        menu_order: elements.length + 1,
        order: elements.length + 1,
        products: [],
      },
      message: {
        success: t('sub_cat_added_succeed'),
        error: t('sub_cat_added_failed'),
      },
    })
  }

  const duplicateCat = async (id) => {
    const duplicate = Functions().httpsCallable('sections-duplicateSection')
    setDuplicating({ id, loading: true })
    try {
      const duplicateResult = await duplicate({
        data: {
          sectionId: id,
          menuId: menuDatas.id,
          placeId: placeDatas.id,
        },
      })
      enqueueSnackbar(t('group_duplicate_succeed'), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(t('group_duplicate_failed'), { variant: 'error' })
    }
    setDuplicating({ id, loading: false })
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }
    const _menus = [...menus]
    const catId = result.source.droppableId.split('_')[1]
    const elements =
      result.type === 'CATEGORY'
        ? formatCategoryElements(menus)
        : formatCategoryElements(menus.find((d) => d.id === catId))
    const reorderedElements = Reorder(
      elements,
      result.source.index,
      result.destination.index
    )

    reorderedElements.map((reorderedElement, index) => {
      if (reorderedElement._element_type === 'cat') {
        updateDatas({
          query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${reorderedElement.id}`,
          datas: {
            menu_order: index,
            order: index,
          },
          action: 'set',
        })
        _menus[_.findIndex(menus, { id: reorderedElement.id })] = {
          ...reorderedElement,
          menu_order: index,
          order: index,
        }
      }
    })

    const products = reorderedElements
      .map((d, i) => ({ ...d, menu_order: i }))
      .filter((p) => p._element_type === 'product')
    const formulas = reorderedElements
      .map((d, i) => ({ ...d, menu_order: i }))
      .filter((p) => p._element_type === 'formula')

    if (products && products.length > 0) {
      _menus[_.findIndex(menus, { id: catId })] = {
        ..._menus[_.findIndex(menus, { id: catId })],
        products,
      }
      updateDatas({
        query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${catId}`,
        datas: {
          products,
        },
        action: 'set',
      })
    }
    if (formulas && formulas.length > 0) {
      _menus[_.findIndex(menus, { id: catId })] = {
        ..._menus[_.findIndex(menus, { id: catId })],
        formulas,
      }
      updateDatas({
        query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${catId}`,
        datas: {
          formulas,
        },
        action: 'set',
      })
    }
    setMenus(_menus)
  }

  const formatCategoryElements = (category) => {
    const prods = category?.products
      ? category.products.map((p) => ({ ...p, _element_type: 'product' }))
      : []
    const formulas = category?.formulas
      ? category.formulas.map((p) => ({ ...p, _element_type: 'formula' }))
      : []
    const cats = menus
      .filter((c) => category.id === c.group_parent)
      .map((c) => ({ ...c, _element_type: 'cat' }))

    const elements = [...prods, ...cats, ...formulas]

    return _.sortBy(elements, 'menu_order')
  }

  const categoryRender = (group, index, provided) => (
    <CategorieItem visible={group.group_visible}>
      <Flex {...provided.dragHandleProps} style={{ padding: '10px 0px 0px 20px' }}>
        <ResponsiveHandler>
          <img
            src={Moveicon}
            alt="logo"
            style={{
              width: '15px',
              marginRight: '20px',
              marginTop: '-3px',
              cursor: 'move',
            }}
          />
        </ResponsiveHandler>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentLeftContainer>
            <ContentTitle>
              {getMultilangContent(group, 'group_name_ml', currentLang)}
            </ContentTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => duplicateCat(group.id)}>
                  {duplicating?.id === group.id && duplicating?.loading ? (
                    <Spinner animation="border" size="sm" variant="dark" />
                  ) : (
                    <CopyIcon />
                  )}
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('edit')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() =>
                    handleEditCategory(false, group.group_type, index, group)
                  }
                >
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => deleteCategory(group)}>
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
        </div>
      </Flex>
      <ResponsiveDiv>
        <Flex style={{ margin: '0px 0px 5px 5px' }}>
          <GroupTitle>{displayGroupTitle(group.group_type)}</GroupTitle>
          <ResponsiveHandler>
            <ProductNb top={false}>
              {productCount(group)} {t('product')}
            </ProductNb>
          </ResponsiveHandler>
        </Flex>
        <ResponsiveHandler2>
          <ProductNb top={false}>
            {productCount(group)} {t('product')}
          </ProductNb>
        </ResponsiveHandler2>
        <GroupDescription>
          {getMultilangContent(group, 'group_description_ml', currentLang)}
        </GroupDescription>
      </ResponsiveDiv>
    </CategorieItem>
  )

  const subCategoryRender = (group, index) => (
    <SubCategorieItem id={`subcat_${group.id}`}>
      <Flex>
        <Space />
        <SubTopContent>
          <ContentLeftContainer>
            <ContentTitle>
              <CustomInput
                onBlur={(e) =>
                  editSubCat(
                    group.id,
                    'group_name_ml',
                    e.target.value,
                    group.group_name_ml
                  )
                }
                placeholder="Titre"
                defaultValue={getMultilangContent(
                  group,
                  'group_name_ml',
                  currentLang
                )}
              />
            </ContentTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => duplicateCat(group.id)}>
                  {duplicating?.id === group.id && duplicating?.loading ? (
                    <Spinner animation="border" size="sm" variant="dark" />
                  ) : (
                    <CopyIcon />
                  )}
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => deleteCategory(group)}>
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
          <div style={{ margin: '0px 0px 0px 20px' }}>
            <GroupDescription>
              <CustomInputDescription
                placeholder={t('description')}
                onBlur={(e) =>
                  editSubCat(
                    group.id,
                    'group_description_ml',
                    e.target.value,
                    group.group_description_ml
                  )
                }
                defaultValue={getMultilangContent(
                  group,
                  'group_description_ml',
                  currentLang
                )}
              />
            </GroupDescription>
          </div>
        </SubTopContent>
      </Flex>
    </SubCategorieItem>
  )

  const productRender = (product, item, index) => (
    <ProductCard
      {...product}
      product_name={getMultilangContent(product, 'product_name_ml', 'FR')}
      product_description={getMultilangContent(
        product,
        'product_description_ml',
        'FR'
      )}
      promoPercentage={product.product_promo_reduction}
      imgUrl={product.product_main_image_url}
      prices={product.product_prices}
      index={index}
      props={product}
      currentLang={currentLang}
      actions={[
        {
          function: () => console.log(product.pid),
          icon: 'duplicate',
        },
        {
          function: () => {
            const from_url = encodeURIComponent(location.pathname + location.search)
            history.push(
              `/dashboard/edit/new/product?product_id=${product.id}&place_id=${placeDatas.id}&from=${from_url}`
            )
          },
          icon: 'edit',
        },
        {
          function: () => {
            updateDatas({
              query: `places/${placeDatas.id}/menus/${menuDatas.id}/sections/${item.id}`,
              action: 'set',
              datas: {
                products: item.products.filter((p) => p.id !== product.id),
              },
              message: {
                success: t('remove_cat_product_succeed'),
                error: t('remove_cat_product_failed'),
              },
            })
            syncMenuWithProd(item.id, placeDatas.id, menuDatas.id, item.products)
          },
          icon: 'trash',
        },
      ]}
    />
  )

  const subCatDroppable = (el) => (
    <Droppable droppableId={`droppable_${el.id}`} type="SUBCATEGORY">
      {(provided, snapshot) => (
        <Row
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="droppableSubCatArea"
        >
          {el.products.map((p, productIndex) => (
            <Draggable
              key={`${el.id}_${p.id}`}
              draggableId={`${el.id}_${p.id}`}
              index={productIndex}
            >
              {(provided, snapshot) => (
                <CustomCol
                  sm="12"
                  md="12"
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                >
                  {productRender(p, el, productIndex)}
                </CustomCol>
              )}
            </Draggable>
          ))}
        </Row>
      )}
    </Droppable>
  )

  const formulaRender = (el, category, indexEl) => (
    <Flex style={{ padding: ' 10px 0 ' }} className="productItem">
      <img
        src={Moveicon}
        alt="logo"
        style={{
          width: '15px',
          marginRight: '20px',
          marginTop: '-3px',
          cursor: 'move',
        }}
      />
      <Content>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentFormuleLeftContainer>
            <ContentFormuleInTitle>
              {getMultilangContent(el, 'name_ml', currentLang)}
            </ContentFormuleInTitle>
          </ContentFormuleLeftContainer>
          <ContentFormuleAction>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    Éditer
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() =>
                    history.push(
                      `/dashboard/edit/formula?place_id=${placeDatas.id}&formula_id=${el.id}`
                    )
                  }
                >
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() => {
                    deleteFormulaFunction(
                      el.id,
                      placeDatas.id,
                      menuDatas.id,
                      category.id
                    )
                  }}
                >
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </ContentFormuleAction>
          <div style={{ width: '10%' }}>
            <ContentFormuleDescription>
              {getMultilangContent(el, 'description_ml', currentLang)}{' '}
            </ContentFormuleDescription>
            <PriceFormuleContainer>{`${el?.price || 0} €`}</PriceFormuleContainer>
          </div>
        </div>
      </Content>
    </Flex>
  )

  const categoryDroppableContent = (category) => (
    <Droppable droppableId={`droppable_${category.id}`} type="CATEGORY_CONTENT">
      {(provided, snapshot) => (
        <Row
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ padding: '20px ' }}
        >
          {formatCategoryElements(category).map((el, indexEl) => {
            if (el._element_type === 'product') {
              return (
                <Draggable
                  key={`${category.id}_${el.id}`}
                  draggableId={`${category.id}_${el.id}`}
                  index={indexEl}
                >
                  {(provided, snapshot) => (
                    <CustomCol
                      sm="12"
                      md="12"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {productRender(el, category, indexEl)}
                    </CustomCol>
                  )}
                </Draggable>
              )
            }
            if (el._element_type === 'formula') {
              return (
                <Draggable
                  key={`${category.id}_${el.id}`}
                  draggableId={`${category.id}_${el.id}`}
                  index={indexEl}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {formulaRender(el, category, indexEl)}
                    </div>
                  )}
                </Draggable>
              )
            }
            if (el._element_type === 'cat') {
              return (
                <Draggable
                  key={`${category.id}_${el.id}`}
                  draggableId={`${category.id}_${el.id}`}
                  index={indexEl}
                >
                  {(provided, snapshot) => (
                    <SubCatContainer
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {subCategoryRender(el, indexEl)}
                      <DraggableFlex>{subCatDroppable(el)}</DraggableFlex>
                      <Flex style={{ minWidth: '300px' }}>
                        <Space />
                        <SubBottomContent>
                          <AddButton
                            type="button"
                            onClick={() => handleAddProduct(el, indexEl)}
                            variant="primary"
                          >
                            {t('add product')}
                          </AddButton>
                        </SubBottomContent>
                      </Flex>
                    </SubCatContainer>
                  )}
                </Draggable>
              )
            }
          })}
        </Row>
      )}
    </Droppable>
  )

  const handleClick = () => {
    myRefname.current.click()
  }

  const handleAddProduct = (item, index, l) => {
    setProducstIn(item.products)
    setCurrentElLength(l)
    setIndexEdit(index)
    setCategory(item)
    setAddProductOpen(true)
  }

  const handleEditCategory = (bol, type, index, category = null) => {
    setEditOrCreate(bol)
    setCategory(category)
    setTypeEdit(type)
    setEditCategoryOpen(true)
  }

  const handleEditFormula = (bol, index, subIndex) => {
    setEditOrCreate(bol)
    setIndexEdit(index)
    setSubIndexEdit(subIndex)
    setEditFormulaOpen(true)
  }

  const handleAddImage = (img) => {
    if (typeof img === 'object' && img.blob.size > 0) {
      setValue(
        'background_image_file',
        getFileFromBlob(img.blob, 'background_image')
      )
    }
  }

  return (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={picModalOpen}
        toggleModal={setPicModalOpen}
        handleAddImage={handleAddImage}
      />
      <AddProductModal
        index={indexEdit}
        producstIn={producstIn}
        setProducstIn={setProducstIn}
        modalOpen={addProductOpen}
        toggleModal={setAddProductOpen}
        menus={menus}
        setMenus={setMenus}
        placeDatas={placeDatas}
        menuId={menuDatas.id}
        selectedLang={currentLang}
        category={category}
        currentElLength={currentElLength}
        currentLang={currentLang}
        syncMenuWithProd={syncMenuWithProd}
      />
      <EditCategory
        create={editOrCreate}
        addSection={addSection}
        placeDatas={placeDatas}
        menuId={menuDatas.id}
        index={indexEdit}
        type={typeEdit}
        modalOpen={editCategoryOpen}
        toggleModal={setEditCategoryOpen}
        menus={menus}
        category={category}
        setMenus={setMenus}
        currentLang={currentLang}
      />
      {importFormulaModal}
      <EditFormula
        create={editOrCreate}
        index={indexEdit}
        subIndex={subIndexEdit}
        modalOpen={editFormulaOpen}
        toggleModal={setEditFormulaOpen}
        menus={menus}
        setMenus={setMenus}
      />
      <CustomContainer style={{ scrollBehavior: 'unset !important' }} fluid>
        <CustomCard bsPrefix="card white-card">
          <Title>{modePDF ? t('digital menu') : t('menu header')}</Title>
          {modePDF && (
            <>
              {fileName ? (
                <PDFContainer>
                  <DescriptionOutlined
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'red',
                      marginTop: '2px',
                      marginRight: '10px',
                    }}
                  />{' '}
                  <PDFName>{fileName}</PDFName>
                  <PDFSize>{displaySize(fileSize)}</PDFSize>
                  <div style={{ width: '70%' }}>
                    <Close
                      onClick={() => setFileName(null)}
                      style={{
                        cursor: 'pointer',
                        color: '#6F4FB3',
                        width: '25px',
                        height: '25px',
                        float: 'right',
                      }}
                    />{' '}
                  </div>
                </PDFContainer>
              ) : (
                <DashedBackground
                  isDragActive={isDragActive}
                  onClick={() => handleClick()}
                  {...getRootProps()}
                >
                  <CloudUploadOutlined
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '20px',
                      color: 'lightgrey',
                      margin: '30px',
                    }}
                  />
                  <ImportPDFContainer>
                    <input
                      style={{ display: 'none' }}
                      accept="application/pdf"
                      id={fileName}
                      type="file"
                      ref={myRefname}
                      {...getInputProps()}
                    />
                    <ImportPDFTitle>
                      {pdf_url && pdf_url !== '' ? (
                        <>
                          <div>{t('drag a file')}</div>
                          &nbsp;
                          <div
                            style={{ color: '#6F4FB3', textDecoration: 'underline' }}
                          >
                            {t('or import and replace')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div>{t('drag a file')}</div>
                          &nbsp;
                          <div
                            style={{ color: '#6F4FB3', textDecoration: 'underline' }}
                          >
                            {t('or import')}
                          </div>
                        </>
                      )}
                    </ImportPDFTitle>
                    <ImportPDFSubTitle>{t('file size')}</ImportPDFSubTitle>
                  </ImportPDFContainer>
                  <div style={{ width: '60px', height: '60px', margin: '30px' }} />
                </DashedBackground>
              )}
            </>
          )}

          <ProductBackground>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3}>
                {!PicUrl && !background_image_url ? (
                  <Logo
                    onClick={() => {
                      setPicModalOpen(!picModalOpen)
                    }}
                  >
                    <InsertLogo>
                      <PermMedia
                        style={{
                          width: '50px',
                          height: '50px',
                          marginBottom: '20px',
                          opacity: 0.5,
                          color: 'lightgrey',
                        }}
                      />
                      {t('add background pic')}
                      <SizeText>{t('pic size')}</SizeText>
                    </InsertLogo>
                  </Logo>
                ) : (
                  <div style={{ position: 'relative' }}>
                    {documentDeletionButton({
                      text: t('remove'),
                      icon: '',
                      server: true,
                      url: background_image_url,
                      collection: `places/${placeDatas.id}/menus/`,
                      doc: menuDatas.id,
                      field: 'background_image_url',
                      message: {
                        success: 'Image supprimé',
                        error: 'Erreur',
                      },
                      resetFunction: () => {
                        setPicUrl(null)
                        setValue('background_image_file', undefined)
                      },
                    })}
                    <Img
                      src={PicUrl !== null ? PicUrl.url : background_image_url}
                      alt="logo"
                      onClick={() => {
                        setPicModalOpen(!picModalOpen)
                      }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md>
                <div>
                  <Form.Control
                    as={NameInput}
                    isInvalid={errors.name}
                    {...register('name', { required: true })}
                    placeholder={t('menu name')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('menu_name_required')}
                  </Form.Control.Feedback>

                  <Form.Control
                    as={AreaInput}
                    isInvalid={errors.description}
                    {...register('description', { required: true })}
                    placeholder={t('menu description')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t('menu_description_required')}
                  </Form.Control.Feedback>
                </div>
              </Grid>
            </Grid>
          </ProductBackground>
        </CustomCard>

        {!modePDF && (
          <>
            <CustomCard ref={menuContainerRef} bsPrefix="card white-card">
              <Flex>
                <Title>Menu</Title>
                <ProductNb top>
                  {productsNbMenus} {t('product')}
                </ProductNb>
              </Flex>
              {sections && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" type="CATEGORY">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {menus
                          .filter(
                            (cat) => !cat?.group_parent || cat?.group_parent === null
                          )
                          .map((category, index) => (
                            <Draggable
                              key={category.id}
                              draggableId={category.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <CategoryBackground
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {categoryRender(category, index, provided)}
                                  {categoryDroppableContent(category)}
                                  <br />
                                  {category.group_type === 0 ? (
                                    <AddCatButton
                                      onClick={() => addSubCat(category.id)}
                                      variant="secondary"
                                    >
                                      {t('add subcat')}
                                    </AddCatButton>
                                  ) : null}
                                  {category.group_type === 0 ? (
                                    <AddCatButton
                                      onClick={() =>
                                        config({
                                          sectionId: category.id,
                                          menuId: menuDatas.id,
                                          formulas: category?.formulas || [],
                                          placeId: placeDatas.id,
                                          currentLang,
                                          menuOrder:
                                            formatCategoryElements(
                                              menus.find((d) => d.id === category.id)
                                            ).length + 1 || 0,
                                        })
                                      }
                                      variant="secondary"
                                    >
                                      {t('import formula')}
                                    </AddCatButton>
                                  ) : null}
                                  <AddButton
                                    type="button"
                                    onClick={() =>
                                      category.group_type === 1
                                        ? handleEditFormula(true, index)
                                        : handleAddProduct(
                                            category,
                                            index,
                                            formatCategoryElements(category).length >
                                              0
                                              ? formatCategoryElements(
                                                  category
                                                ).pop().menu_order
                                              : 0
                                          )
                                    }
                                    variant={
                                      category.group_type === 1
                                        ? 'secondary'
                                        : 'primary'
                                    }
                                  >
                                    {category.group_type === 1
                                      ? t('add formula')
                                      : t('add product')}
                                  </AddButton>
                                </CategoryBackground>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              <ButtonDropdown
                variant="secondary"
                id="section-dropdown"
                title={t('add section')}
              >
                <DropDownItem onClick={() => handleEditCategory(true, 2, 0)}>
                  <Flex>
                    <div
                      style={{
                        width: '90%',
                        textAlign: 'initial',
                      }}
                    >
                      {t('highlight')}
                    </div>
                    <RoundedInput />
                  </Flex>
                </DropDownItem>
                {/* <DropDownItem onClick={() => handleEditCategory(true, 1, 0)}>
                  Formule
                  </DropDownItem> */}
                <DropDownItem onClick={() => handleEditCategory(true, 0, 0, null)}>
                  {t('categorie')}
                </DropDownItem>
              </ButtonDropdown>
            </CustomCard>
          </>
        )}
        <CustomCard bsPrefix="card white-card">
          <Title>{t('page footer')}</Title>
          <Background>
            <TextArea
              {...register('menu_footer')}
              placeholder={t('footer placeholder')}
            />
          </Background>
        </CustomCard>
      </CustomContainer>
    </>
  )
}

export default withTheme(withTranslation()(Menu))

const Flex = styled.div`
  display: flex;
`
const CustomCard = styled(Card)`
  min-width: 300px;
`
const CustomContainer = styled(Container)`
  @media screen and (max-width: 400px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`
const CustomCol = styled(Col)`
  margin-bottom: 20px !important;
`
const RestaurantCardBox = styled.div`
  min-width: 200px;
  padding: 9px 15px 11px 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #d1d1d1;
  background-color: white;
  width: 100%;
  color: #969696 !important;
  &:hover {
    cursor: pointer;
  }
`
const RestaurantCardTitle = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal bold 16px Lato;
  color: black;
`
const RestaurantCardDesc = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal normal 14px Lato;
  color: ${(props) =>
    props.noStock ? '#969696' : props.darkmode ? '#969696' : '#606060'};
  margin-bottom: 11px;
`
const BadgeHandler = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`
const RestaurantCardSlideUpContentItemTitle = styled.div`
  margin-left: 15px;
  overflow: hidden;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const RestaurantCardSlideUpContentItem = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
`

const RestaurantCardSlideUpContent = styled.div`
  background-color: white;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 33px;
  transition: transform 0.2s ease-in-out;
  transform: scale(1, 0);
`

const RestaurantCardSlideUpRight = styled.div`
  display: flex;
  align-items: center;
  font: normal normal bold 16px Lato;
  color: #1d1d1d;
  > svg {
    transition: transform 0.2s ease-in-out;
  }
`

const RestaurantCardSlideUp = styled.div`
  margin-bottom: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: 1px solid #1d1d1d;
  width: 100%;
  color: #1d1d1d;
  font: normal normal normal 15px Lato;
`
const SlideUpTrigger = styled.div`
  display: flex;
  margin-left: 15px;
  font-weight: bold;
  display: flex;
  max-width: 42%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 15px;
  color: ${(props) => props.darkmode && 'white'};
`
const RestaurantCardImageHandler = styled.div`
  border-radius: 5px;
  height: 90px;
  width: 90px;
  position: relative;
  margin: 15px 0px 0px 10px;
`
const RestaurantCardImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`
const RestaurantCardOfferTitle = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 3px 9px 5px 9px;
  background-color: ${(props) => (props.noStock ? '#BA400A' : props.color)};
  text-align: center;
  font: normal normal bold 15px Lato;
  color: white;
  border-radius: 2px;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
`
const DragImg = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: white;
  width: 70%;
  height: 30px;
  border-radius: 10px;
  font-size: 11pt;
`
const CustomImg = styled.img`
  width: 15px;
  margin: 2px 5px 0px 5px;
  cursor: move;
`
const ResponsiveHandler = styled.div`
  @media screen and (max-width: 650px) {
    display: none !important;
  }
`
const ResponsiveHandler2 = styled.div`
  @media screen and (min-width: 651px) {
    display: none !important;
  }
`
const ResponsiveDiv = styled.div`
  margin: -10px 0px 0px 70px;
  @media screen and (max-width: 651px) {
    margin: -10px 0px 0px 20px;
  }
`
const AddCatButton = styled(Button)`
  margin: -10px -30px 20px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const AddButton = styled(Button)`
  margin: -10px 0px 20px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ButtonDropdown = styled(DropdownButton)`
  margin-bottom: 10px;
  margin-left: 10px;
  width: 23% !important;
  .dropdown-menu {
    width: 100% !important;
    min-width: 200px;
  }
  .dropdown-toggle {
    width: 100% !important;
    text-align: initial;
    min-width: 200px;
  }
  .dropdown-menu.show {
    margin: -20px 0px -20px 0px !important;
  }
  .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }
`
const DropDownItem = styled(Dropdown.Item)`
  text-align: initial !important;
  font-weight: bold;
  color: #e76b56;
  :hover {
    color: #e76b56;
  }
  padding-left: 20px !important;
  padding-right: 40px !important;
`
const PDFContainer = styled.div`
  display: flex;
  border-style: solid;
  border-color: ${(props) => props.theme.primary};
  border-width: 1px;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;
  margin-top: 10px;
`
const PDFName = styled.div`
  width: 40%;
  margin-right: 20px;
`
const PDFSize = styled.div`
  flex: none;
  opacity: 0.5;
`
const ImportPDFTitle = styled.div`
  display: inline-flex;
  font-weight: bold;
`
const ImportPDFContainer = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
`
const ImportPDFSubTitle = styled.div`
  opacity: 0.5;
`
const CategoryBackground = styled.div`
  min-width: 400px;
  background-color: #e6efff;
  border-radius: 10px;
  margin-bottom: 20px;
`
const ProductBackground = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const Background = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const TextArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 60px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const DraggableFlex = styled.div`
  min-width: 200px;
  padding: 20px;
  background-color: white;
  border-style: solid;
  border-top-style: none !important;
  border-bottom-style: none !important;
  border-width: 1px;
  border-color: #d1d1d1;
  margin-left: 16px;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const RoundedInput = styled(AssignmentReturnedRounded)`
  color: ${(props) => props.theme.primary};
  font-size: 13pt !important;
  margin-top: 4px;
`
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`
const ProductNb = styled.div`
  font-size: 13pt;
  font-weight: 400;
  color: #6f4fb3;
  margin-top: ${(props) => props.top && '10px'};
  margin-left: 15px;
  margin-bottom: 10px;
`
const DashedBackground = styled.div`
  display: flex;
  cursor: pointer;
  background: ${(props) => props.isDragActive && 'rgba(0, 0, 0, 0.05)'};
  border-style: dashed;
  border-width: 2px;
  border-radius: 7px;
  border-color: rgba(111, 79, 179, 0.5);
  margin-bottom: 20px;
`
const Logo = styled.div`
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 400px;
  height: 260px;
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 130px;
  }
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 204px !important;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const InsertLogo = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
`
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  color: ${(props) => props.theme.primary};
`
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  @media screen and (max-width: 500px) {
    width: 200px;
  }
`
const Content = styled.div`
  display: flex;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
`
const ContentFormuleLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  width: 50%;
`
const ContentFormuleAction = styled.div`
  float: right;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  @media screen and (max-width: 1150px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) {
    width: 80%;
  }
`
const ContentAction = styled.div`
  display: flex;
  justify-content: right;
  width: 30%;
`
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin-right: 20px;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`
const ContentFormuleInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  margin: 0px 40px 0px 40px;
  text-align: initial;
`
const ContentFormuleDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin: -5px 40px 0px 40px;
`
const PriceFormuleContainer = styled.div`
  display: flex;
  font-weight: bold;
  align-self: center;
  width: 70%;
  margin: 5px 40px 20px 40px;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const PriceContainer = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  margin-right: 20px;
`
const PromoPriceBox = styled.div`
  display: flex;
  margin-right: 15px;
`
const PromoPrice = styled.div`
  text-decoration: line-through;
`
const PromoNewPrice = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`
const PriceBox = styled.div`
  margin-right: 20px;
`
const CategorieItem = styled.div`
  opacity: ${(props) => props.visible && '0.5'};
`
const animationSubcat = keyframes`
   0% {
    transform: scale(0.5);
    opacity:0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const SubCategorieItem = styled.div`
  min-width: 200px;
`
const SubTopContent = styled.div`
  width: 100%;
  border-bottom-style: none !important;
  text-align: center;
  background-color: white;
  border-style: solid;
  border-radius: 10px 10px 0px 0px;
  border-width: 1px;
  border-color: #d1d1d1;
`
const SubBottomContent = styled.div`
  width: 100%;
  @media screen and (max-width: 400px) {
    text-align: center;
  }
  border-top-style: none !important;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: white;
  border-style: solid;
  border-radius: 0px 0px 10px 10px;
  border-width: 1px;
  border-color: #d1d1d1;
`
const Space = styled.div`
  width: 16px;
`
const CustomInput = styled.input`
  outline: none;
  font-weight: bold;
  font-size: 13pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 1px;
`
const CustomInputDescription = styled.input`
  outline: none;
  width: 100%;
  font-size: 12pt;
  font-weight: 300;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 1px;
`

const SubCatContainer = styled.div`
  padding: 10px 0;
  width: 99%;
  .animate {
    animation: ${animationSubcat} 600ms ease 0s 1 normal forwards;
  }
  .productItem {
  }
`
const RestaurantCardNoSlideUp = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`
const RestaurantCardPrice = styled.div`
  display: contents;
  font: normal normal bold 16px Lato;
  margin-bottom: 11px;
  color: ${(props) =>
    props.noStock ? '#969696' : props.darkmode ? '#969696' : '#1D1D1D'};
`
const RestaurantCardNewPrice = styled.div`
  margin-bottom: 11px;
  color: ${(props) => props.color};
  font: normal normal bold 16px Lato;
`
