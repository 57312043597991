import React from 'react';

import {
 Container, Form, Row, Col, Button, Alert, Spinner,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DefaultSeparator } from '../../../components/UI/Separators/Separators';
import LoginHeader from '../LoginHeader/LoginHeader';
import { useLogin } from '../../../hooks/Facebook';
import useGoogle from '../../../hooks/GoogleAuth';

const LoginForm = ({ t, theme }) => {
	const { facebookButton } = useLogin();
	const { googleLoginButton, signInWithEmail, signInWithEmailError } = useGoogle();
	const { register, handleSubmit, formState: { errors } } = useForm();
	const [loading, setLoading] = React.useState(false);

	const handleLoginForm = async (datas) => {
		setLoading(true);
		await signInWithEmail(datas.email, datas.password, datas.remember);
		setLoading(false);
	};
	return (
  <>
    <Container>
      <LoginHeader
				title={t('login')}
				subtitle={t('access to personal space')}
      />

      {signInWithEmailError !== null

					&& (
<Alert>
  {signInWithEmailError}
</Alert>
)}

      <Form onSubmit={handleSubmit(handleLoginForm)}>
        <Form.Group>
          <Form.Control
					isInvalid={errors.email}
					{...register('email', { required: true })}
					placeholder={t('Email address')}
					type="email"
					autoComplete="username"
          />
          <Form.Control.Feedback type="invalid">{t('valid email required')}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
					isInvalid={errors.password}
					{...register('password', { required: true })}
					placeholder={t('Password')}
					autoComplete="current-password"
					type="password"
          />
          <Form.Control.Feedback type="invalid">{t('password required')}</Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col>
            <Form.Group>
              <Form.Check
					color={theme.grey_1}
					{...register('remember', { required: false })}

					type="checkbox"
					label={t('remember me')}
              />
            </Form.Group>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Link style={{ color: '#6F4FB3', fontWeight: 'bold' }} to="/login/recover">{t('Forget my password')}</Link>
          </Col>

        </Row>
        <div style={{ textAlign: 'center' }}>
          <Button style={{ paddingLeft: '40px', paddingRight: '40px' }} disabled={loading} type="submit">
            {loading ? (
              <>
                <Spinner animation="border" variant="light" size="sm" />
                {' '}
                {t('login')}
              </>
) : ' Connexion' }
          </Button>

        </div>
      </Form>

      <DefaultSeparator>{t('or')}</DefaultSeparator>
      <Row>
        <Col>{facebookButton(t('Connect whith fb'))}</Col>
        <Col>{googleLoginButton(t('Connect whith google'))}</Col>
      </Row>

    </Container>

    <Row>
      <Col style={{ marginTop: '40px', fontSize: '12pt', textAlign: 'center' }}>
        {t('not register yet')} &nbsp;
        <Link
						style={{ color: '#6F4FB3', fontWeight: 'bold' }}
						to="/signup"
        >
          {t('create account')}
        </Link>
      </Col>
    </Row>
  </>
	);
};

export default withTheme(withTranslation()(LoginForm));
