import React from 'react'
import {
	Modal,
	ListGroup,
	Card,
	Row,
	Col,
	Button,
	Alert,
	Spinner,
} from 'react-bootstrap'
import { FirestoreDb } from '../../../../utils/firebase/FirebaseModules'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import ConfirmPayment from '../ConfirmPayment/ConfirmPayment'

const ErrorPaymentHandler = ({ t, invoice, currentMethod, canceled, placeId }) => {
	const [paymentModal, setPaymentModal] = React.useState(false)
	const history = useHistory()
	const [removingPlace, setRemovingPlace] = React.useState(false)
	const deletePlace = async () => {
		setRemovingPlace(true)
		try {
			await FirestoreDb().collection('places').doc(placeId).delete()
			history.push('/dashboard/places')
		} catch (e) {
			alert(
				"Erreur durant la suppression de votre établissment . Veuillez contacter l'admin du site"
			)
		}

		setRemovingPlace(false)
	}
	if (invoice) {
		if (invoice.payment_intent === null) return null
		const { status, next_action, last_payment_error, client_secret } =
			invoice.payment_intent

		if (status !== 'succeeded' && !canceled) {
			return (
				<>
					<Modal size="lg" show={paymentModal} onHide={() => setPaymentModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>{t('retry_paiement')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ConfirmPayment
								t={t}
								invoice={invoice}
								setShow={setPaymentModal}
								pm={currentMethod}
								client_secret={client_secret}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setPaymentModal(false)}>
								Fermer
							</Button>
						</Modal.Footer>
					</Modal>

					<ErrorPaymentHandlerStyled bsPrefix="card white-card">
						<Card.Header>
							<Card.Title>{t('something_wrong_with_payment')}</Card.Title>
						</Card.Header>
						<Card.Body>
							<ListGroup>
								<ListGroup.Item>
									<b>Statut </b> : {t(status)}
								</ListGroup.Item>
								<ListGroup.Item>
									<b>{t('error_payment_reason')}</b>:{' '}
									{last_payment_error?.message || 'unknow'}
								</ListGroup.Item>
							</ListGroup>
							<hr />
							<Alert variant="info">{t('last_payment_error_action_message')}</Alert>
							<div style={{ textAlign: 'center' }}>
								{status === 'requires_action' ? (
									<Button
										variant="secondary"
										onClick={() => {
											window.open(next_action.use_stripe_sdk.stripe_js, '_blank')
										}}
									>
										{t('reauth_3D_secure')}
									</Button>
								) : (
									<Button
										variant="secondary"
										onClick={() => {
											setPaymentModal(true)
										}}
									>
										{t('retry_paiement')}
									</Button>
								)}
							</div>
						</Card.Body>
					</ErrorPaymentHandlerStyled>
				</>
			)
		} else if (canceled) {
			return (
				<>
					<ErrorPaymentHandlerStyled bsPrefix="card white-card">
						<Card.Header>
							<Card.Title>{t('subscription_canceled')}</Card.Title>
						</Card.Header>
						<Card.Body>
							<p style={{ textAlign: 'center' }}>{t('subscription_was_canceled')}</p>
						</Card.Body>
						<Card.Footer>
							<Button
								disabled={removingPlace}
								style={{ margin: '10px auto', display: 'block' }}
								onClick={deletePlace}
							>
								{removingPlace ? (
									<Spinner animation="border" size="sm" />
								) : (
									t('delete_place')
								)}
							</Button>
						</Card.Footer>
					</ErrorPaymentHandlerStyled>
				</>
			)
		} else return null
	} else return null
}

export default withTranslation()(ErrorPaymentHandler)

const ErrorPaymentHandlerStyled = styled(Card)`
	.card-title {
		font-size: 19px !important;
		text-align: center;
	}
`
