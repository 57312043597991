import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import {
 Spinner, Row, Col, Button,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import ClassicList from './ClassicList';

const useStyles = makeStyles({

  container: {
    maxHeight: '80vh',
    overflowX: 'hidden',
  },
  headValues: {
    color: '#888',
    backgroundColor: 'white',
  },

});

const ClassicTableAlgolia = ({
 hasMore, refineNext, hits, format, headValues, dataTypes, t, actions,
}) => {
const classes = useStyles();
const table = React.useRef(null);
const [loading, setLoading] = React.useState(false);
const [datas, setDatas] = React.useState(null);
const [orderProperty, setOrderBy] = React.useState(null);
const [orderDirection, setOrderDirection] = React.useState('asc');

React.useEffect(() => {
  setLoading(true);
  setDatas(hits);
  if (datas != null) setLoading(false);
}, [hits, setDatas, datas]);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const handleSort = (headValue) => {
setOrderBy(headValue.name);
setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
};
  return (
    <TableContainer className={classes.container} ref={table}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headValues.map((val, i) => (
              <TableCell className={classes.headValues}>
                <TableSortLabel
                                      active={val.name === orderProperty}
                                      onClick={() => handleSort(val)}
                                      direction={val.name === orderProperty ? orderDirection : 'asc'}
                />
                {' '}
                {t(val.label)}
              </TableCell>
                              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? (
            <>
              {!hits.length > 0 ? ''
                          : <ClassicList sort={(datas) => stableSort(datas, getComparator(orderDirection, orderProperty))} t={t} format={format !== undefined ? format : null} datas={datas} dataTypes={dataTypes} actions={actions} />}
            </>
)
                         : <Spinner animation="border" variant="dark" size="lg" /> }

        </TableBody>
      </Table>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          {hasMore && (
          <ButtonShowMore
                            onClick={() => {
                              refineNext();
                              table.current.scrollTo(0, table.current.scrollHeight);
                            }}
                            variant="secondary"
          >
            {t('load more')}
          </ButtonShowMore>
) }
        </Col>
      </Row>
    </TableContainer>
  );
};

export default connectInfiniteHits(withTranslation()(withTheme(ClassicTableAlgolia)));

const ButtonShowMore = styled(Button)`
  
  margin-top : 20px ; 
  margin-bottom : 20px ; 

`;
