import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import ClassicTable from '../../../components/UI/tables/ClassicTable'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import { getMultilangContentFromObj } from '../../../utils/MultilangHelpers'
import { withTranslation } from 'react-i18next'
import { useUserSwitch } from '../../../hooks/Admin'

const DashboardAdminPlaces = ({t, props, setTitle}) => {
	const { connectAsUser, loading: connectingAsUser } = useUserSwitch()
	const [where, setWhere] = useState(null)
	const fetchDatas = async (lastDoc = false) => {
		let ref = await FirestoreDb().collection('places')
		if (lastDoc) {
			ref = ref.orderBy('created_at', 'desc').startAfter(lastDoc).limit(10)
		} else {
			ref = ref.orderBy('created_at', 'desc').limit(10)
		}

		const users = await ref.get()

		return { datas: users.docs }
	}

  useEffect(() => {
    setTitle(`${t('admin')} > <b>${t('admin places')}</b>`);
  }, [setTitle, t])

	return (
		<Card bsPrefix="card white-card">
			<ClassicTable
				fetchDatasFunction={fetchDatas}
				headValues={[
					{ name: 'place_name', label: 'Nom' },
					{ name: 'place_email', label: "Email de l'établissement" },
					{ name: 'created_at', label: 'Date de création' },
					{ name: 'subscriptionStatus', label: "Etat de l'abonnement" },
					{ name: 'trial', label: 'Version essais' },

					{ name: '', label: '' },
				]}
				dataTypes={[
					'place_name',
					'place_email',
					'created_at',
					'subscriptionStatus',
					'trial',
				]}
				actions={[
					{
						func: (uid) => connectAsUser(uid),
						name: `${
							connectingAsUser
								? 'connexion en cours...'
								: 'Se connecter en tant que cet utilisateur'
						}`,
						params: 'uid',
					},
				]}
				format={{
					created_at: (date) => moment(date * 1000).format('DD/MM à HH:mm'),
					trial: (trial) => (
						<Trial trial={trial === true}>
							{' '}
							{trial ? 'En mode test' : 'En mode payant'}
						</Trial>
					),
					subscriptionStatus: (status) => (
						<Status active={status === 'active'}>
							{' '}
							{status === 'active' ? 'Actif' : 'Inactif'}
						</Status>
					),
				}}
			/>
		</Card>
	)
}

export default withTranslation()(DashboardAdminPlaces)

const Status = styled.div`
	padding: 5px;
	border-radius: 5px;
	color: white;
	text-align: center;
	display: inline-block;

	background: ${(props) => (props.active ? 'green' : 'red')};
`

const Trial = styled.div`
	padding: 5px;
	border-radius: 5px;
	color: white;
	text-align: center;
	display: inline-block;

	background: ${(props) => (props.trial ? 'orange' : 'green')};
`
