import React from 'react'
import Plans from '../Plans/Plans'

const Trial = ({ subscriptionMethodChoosed, setSubscriptionMethodChoosed }) => {
	return (
		<div>
			<Plans
				selectMode
				subscriptionMethodChoosed={subscriptionMethodChoosed}
				setSubscriptionMethodChoosed={setSubscriptionMethodChoosed}
			/>
		</div>
	)
}

export default Trial
