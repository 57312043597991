import React from 'react'
import styled from 'styled-components'
import { Card, Col, Button, Spinner } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const config = require('../../../../config')

const Plan = ({
	selectMode,
	isActive,
	price,
	switchPlan,
	t,
	updatingSubscription,
	setSubscriptionMethodChoosed,
}) => {
	let interval = ''
	let perInterval = ''

	interval = t(`${price.recurring.interval}_${price.recurring.interval_count}`)
	perInterval = t(
		`per_${price.recurring.interval}_${price.recurring.interval_count}`
	)

	return (
		<Col>
			<PlanStyled active={isActive}>
				<div style={{height: '280px'}}>
					<Title>{interval}</Title>
					<div style={{ textAlign: '-webkit-center' }}>
						<SubTitle>{price.nickname}</SubTitle>
					</div>
					<br/>
					<PlanAmount>
						{parseFloat(price.unit_amount / 100 / config.taxes.tax_20).toFixed(2)} €
						HT/ {perInterval}
						<hr />
					</PlanAmount>
				</div>
				<Card.Body>
					<div
						style={{
							textAlign: 'center',
							bottom: '20px',
							marginLeft: isActive && '15px',
						}}
					>
						{isActive ? (
							<Button disabled>{t('current_plan')}</Button>
						) : (
							<Button
								disabled={updatingSubscription}
								onClick={() => {
									if (selectMode) {
										setSubscriptionMethodChoosed(price)
									} else {
										switchPlan(price.id)
									}
								}}
							>
								{updatingSubscription ? (
									<Spinner animation="border" variant="light" size="sm" />
								) : (
									t('select_this_plan')
								)}
							</Button>
						)}
						<div
							style={{
								marginTop: '5px',
								fontSize: '12px',
								fontStyle: 'italic',
							}}
						>
							{`( ${parseFloat(price.unit_amount / 100).toFixed(
								2
							)} € TTC/ ${perInterval} )`}
						</div>
					</div>
				</Card.Body>
			</PlanStyled>
		</Col>
	)
}

export default withTranslation()(Plan)

const PlanStyled = styled(Card)`
	border: ${(props) => props.active && `${props.theme.primary} 2px solid`};
	text-align: center;
	background-color: #e6efff;
	height: 400px;

	.card-title {
		font-size: 18px !important;
	}
`

const PlanAmount = styled.div``
const Title = styled.div`
	margin-top: 30px;
	font-size: 20px;
	font-weight: bold;
`
const SubTitle = styled.div`
	margin-top: 20px;
	font-size: 15px;
	width: 80%;
`
