import {
	updateMultilangArray,
	getMultilangContent,
	createMultilangArray,
} from '../../../utils/MultilangHelpers'

class ProductFieldsInterface {
	constructor(
		datas,
		multilang_fields = [],
		multilang_fields_map = {},
		action = 'create',
		oldDatas,
		langs = [],
		currentLang = 'FR',
		specialDatas = {}
	) {
		this.multilang_fields = multilang_fields
		this.currentLang = currentLang
		this.langs = langs
		this.oldDatas = oldDatas
		this.action = action
		this.fields = {}

		this.fields.product_labels = datas?.product_labels || []
		this.fields.product_alergens = datas?.product_alergens || []
		this.fields.product_prices = datas?.product_prices || []
		this.fields.product_nostock = datas?.product_nostock || false
		this.fields.product_additional_infos_type =
			datas?.product_additional_infos_type || null
		this.fields.product_nostock_reapro = datas?.product_nostock_reapro || new Date()
		this.fields.product_promo = datas?.product_promo || false
		this.fields.product_promo_reduction = datas?.product_promo_reduction || 5
		this.fields.product_promo_type = datas?.product_promo_type || null
		this.fields.drinks_datas = datas?.drinks_datas || {}
		this.fields.product_type = datas?.product_type || 'Entrée'

		if (multilang_fields.length > 0) {
			// adding multilanguage fields
			multilang_fields.map((f) => {
				if (typeof datas[f] === 'object') {
					this.fields[f] = this._mappingCheckMultilang(
						datas[f] || {},
						f,
						multilang_fields_map[f]
					)
				} else {
					this.fields[`${f}_ml`] =
						action === 'edit'
							? updateMultilangArray(datas[f], oldDatas[`${f}_ml`], currentLang)
							: createMultilangArray(langs, currentLang, datas[f])
				}
			})
		}
		this.fields = { ...specialDatas, ...this.fields }
	}

	_mappingCheckMultilang(datas, field, multilang_fields) {
		let _newFields = {}
		const _multilang_fields = new Set(multilang_fields)
		Object.keys(datas).map((key) => {
			if (_multilang_fields.has(key)) {
				_newFields[`${key}_ml`] =
					this.action === 'edit'
						? updateMultilangArray(
								datas[key],
								this.oldDatas[field][`${key}_ml`],
								this.currentLang
						  )
						: createMultilangArray(this.langs, this.currentLang, datas[key])
			} else {
				_newFields[key] = datas[key] || ''
			}
		})
		return _newFields
	}

	getFields() {
		return this.fields
	}
}
export default ProductFieldsInterface
