import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import { StepConnector } from '@material-ui/core';
import { ArrowForwardIos, Check} from '@material-ui/icons';
import styled from 'styled-components';

const Connector = () => <StepConnector />;

const TopStepper = (props) => {
  const { steps } = props;
  const { step } = props;

  return (
    <Stepper style={{padding: '0px'}} activeStep={props.step} connector={<Connector />}>
      {steps.map((label, index) => (
        <Step key={label} step={step} index={index}>
          {index > 0 && 
            <ArrowContainer>
              <ArrowIcon />
            </ArrowContainer>}
          <StepHandler step={step} index={index}>
            {step >= index + 1 && <CustomCheck/>}
            {label}
          </StepHandler>
        </Step>
          ))}
    </Stepper>
  );
};

export default TopStepper;

const ArrowIcon = styled(ArrowForwardIos)`
  width: 15px !important;
  heigth: 15px !important;
  opacity: 0.4;
`;
const CustomCheck = styled(Check)`
  width: 20px !important;
  height: 20px !important;
	color: ${(props) => props.primary};
  margin: 0px 10px 0px 0px;
`;
const ArrowContainer = styled.div`
  position: absolute;
  margin-top: 10px;
`
const Step = styled.div`
  flex: 1 1 auto;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-bottom-color: ${(props) => props.step >= props.index ? "#6F4FB3" : 'rgba(111, 79, 179, 0.3)'};
`;
const StepHandler = styled.div`
  text-align: center;
  margin: 10px 0px;
  font-weight: bold;
  color: ${(props) => props.step >= props.index ? (props) => props.theme.primary : 'black'};
  opacity:  ${(props) => props.step >= props.index ? '1' : '0.5'};
`;
