import React, { useState, useEffect } from 'react'

import { Tune } from '@material-ui/icons'

import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import { Controller, useController } from 'react-hook-form'

let Food_labels = [
  { name: 'Petit déjeuner' },
  { name: 'Amuse bouche' },
  { name: 'Entrée' },
  { name: 'Entrée - A partager' },
  { name: 'Plat' },
  { name: 'Plat - A partager' },
  { name: 'Fromage' },
  { name: 'Dessert' },
  { name: 'Pièce cocktail' },
  { name: 'Autre' },
]

let Allergène_foods = [
  { icon: <Tune />, name: 'Arachide' },
  { icon: <Tune />, name: 'Céleri' },
  { icon: <Tune />, name: 'Crustacé' },
  { icon: <Tune />, name: 'Fruit à coque' },
  { icon: <Tune />, name: 'Gluten' },
  { icon: <Tune />, name: 'Lactose' },
  { icon: <Tune />, name: 'Lupin' },
  { icon: <Tune />, name: 'Molusque' },
  { icon: <Tune />, name: 'Moutarde' },
  { icon: <Tune />, name: 'Oeuf' },
  { icon: <Tune />, name: 'Poisson' },
  { icon: <Tune />, name: 'Sésame' },
  { icon: <Tune />, name: 'Soja' },
  { icon: <Tune />, name: 'Sulfite' },
]

let Allergène_drinks = [
  { icon: <Tune />, name: 'Sulfite' },
  { icon: <Tune />, name: 'Gluten' },
]

let Labels_foods = [
  { icon: <Tune />, name: 'Bio' },
  { icon: <Tune />, name: 'Végan' },
  { icon: <Tune />, name: 'Fait maison' },
  { icon: <Tune />, name: 'Halal' },
  { icon: <Tune />, name: 'Casher' },
  { icon: <Tune />, name: 'Surgelé' },
  { icon: <Tune />, name: 'Produit frais' },
  { icon: <Tune />, name: 'Circuit court' },
  { icon: <Tune />, name: 'Végétarien' },
  { icon: <Tune />, name: 'Pêche du jour' },
  { icon: <Tune />, name: 'Végétalien' },
  { icon: <Tune />, name: 'Viande de France' },
]

let Labels_drinks = [
  { icon: <Tune />, name: 'Bio' },
  { icon: <Tune />, name: 'Fabriqué en France' },
  { icon: <Tune />, name: 'IGP' },
  { icon: <Tune />, name: 'AOC' },
  { icon: <Tune />, name: 'AOP' },
  { icon: <Tune />, name: 'AB' },
  { icon: <Tune />, name: 'Viticulture durable' },
  { icon: <Tune />, name: 'Bio-dynamie' },
  { icon: <Tune />, name: 'Sulfite' },
]

const TagsBox = ({
  t,
  type,
  from,
  productAlergens,
  onChange,
  control,
}) => {
  const [filter, setFilter] = useState([])

  useEffect(() => {
    if (productAlergens && productAlergens.length > 0) setFilter(productAlergens)
  }, [productAlergens])

  const handleFilter = (array) => {
    const { name } = array
    const _set = new Set(filter)
    const _filter = [...filter]
    let newFilter = []
    if (_set.has(name)) {
      newFilter = _filter.filter((f) => f !== name)
    } else {
      newFilter = [...filter, name]
    }
    setFilter(newFilter)
    onChange(newFilter)
  }

  if (type === 0) {
    return (
      <>
        <Title>
          {t('allergens')}
        </Title>
        <SubTitle>
          {t('allergens description')}
        </SubTitle>
        <BackgroundSelect color="#E6EFFF">
          <FilterContainer>
            {from === 'foods' &&
              Allergène_foods.map((allergene, i) => (
                <FilterModaleBadgeItem
                  type="button"
                  key={i}
                  onClick={() => handleFilter(allergene)}
                  isActive={filter.includes(allergene.name)}
                >
                  {allergene.icon}
                  <div>{allergene.name}</div>
                </FilterModaleBadgeItem>
              ))}
            {from === 'drinks' &&
              Allergène_drinks.map((allergene, i) => (
                <FilterModaleBadgeItem
                  type="button"
                  key={i}
                  onClick={() => handleFilter(allergene)}
                  isActive={filter.includes(allergene.name)}
                >
                  {allergene.icon}
                  <div>{allergene.name}</div>
                </FilterModaleBadgeItem>
              ))}
          </FilterContainer>
        </BackgroundSelect>
      </>
    )
  } else if (type === 1) {
    return (
      <>
        <Title>
          {t('labels')}
        </Title>
        <SubTitle>
          {t('labels description')}
        </SubTitle>
        <BackgroundSelect color="#E6EFFF">
          <FilterContainer>
            {from === 'foods' &&
              Labels_foods.map((label, i) => (
                <FilterModaleBadgeItem
                  type="button"
                  key={i}
                  onClick={() => handleFilter(label)}
                  isActive={filter.includes(label.name)}
                >
                  {label.icon}
                  <div>{label.name}</div>
                </FilterModaleBadgeItem>
              ))}
            {from === 'drinks' &&
              Labels_drinks.map((label, i) => (
                <FilterModaleBadgeItem
                  type="button"
                  key={i}
                  onClick={() => handleFilter(label)}
                  isActive={filter.includes(label.name)}
                >
                  {label.icon}
                  <div>{label.name}</div>
                </FilterModaleBadgeItem>
              ))}
          </FilterContainer>
        </BackgroundSelect>
      </>
    )
  } else if (type === 2) {
    return (
      <>
        <Title>
          {t('type')}
        </Title>
        <SubTitle>
          {t('product type')}
        </SubTitle>
        <BackgroundSelect
          color="#f8f7ff"
          style={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#6F4FB3',
          }}
        >
          <FilterContainer>
            <Controller
              control={control}
              name="product_type"
              defaultValue="Entrée"
              render={({ field: { onChange, onBlur, value, name, ref } }) =>
                Food_labels.map((label, i) => (
                  <>
                    <input
                      value={label.name}
                      checked={value === label.name}
                      type="radio"
                      name={name}
                      inputRef={ref}
                      onChange={onChange}
                      style={{ display: 'none' }}
                      id={`product_type_${i}`}
                    />
                    <FilterModaleBadgeItem
                      type="button"
                      key={i}
                      htmlFor={`product_type_${i}`}
                    >
                      {label.icon}
                      <div>{label.name}</div>
                    </FilterModaleBadgeItem>
                  </>
                ))
              }
            />
          </FilterContainer>
        </BackgroundSelect>
      </>
    )
  } else return null
}

export default withTheme(withTranslation()(TagsBox))

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  input[type='radio']:checked + label {
    background-color: ${(props) => props.theme.primary};
    color: white;
  }
`
const FilterModaleBadgeItem = styled.label`
  > svg {
    font-size: 12px;
    margin-right: 7px;
  }
  display: flex;
  align-items: center;
  border: none;
  background-color: ${(props) =>
    props.isActive ? (props) => props.theme.primary : 'white'};
  color: ${(props) => (props.isActive ? 'white' : '#969696')};
  font: normal normal normal 15px Lato;
  padding: 7px 15px 9px 14px;
  border-radius: 50px;
  margin-right: 20px;
  margin-bottom: 12px;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    margin-right: 0px;
  }
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
const BackgroundSelect = styled.div`
  background-color: ${(props) => props.color};
  padding: 20px 20px 7px 20px;
  border-radius: 10px;
`
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
  margin-left: 7px;
  margin-top: -20px;
`
