import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import RestaurantCard from '../../RestaurantCard/RestaurantCard'
import RestaurantFormulaCard from '../../RestaurantCard/RestaurantFormulaCard'
import SubCategorie from './SubCategorie/SubCategorie'

const Categorie = ({
  title,
  image,
  description,
  section,
  cardClicked,
  cardFormulaClicked,
  color,
  darkmode,
  cardFilter,
  formula
}) => (
  <CategorieHandler id={title} darkmode={darkmode}>
    <CategorieHeader style={{ height: image && '215px' }}>
      {image && <CategorieHeaderImage src={image} />}
      <CategorieHeaderTitle color={color} image={image}>
        {title}
      </CategorieHeaderTitle>
    </CategorieHeader>
    <CategorieDescription color={color} image={image}>
      {description}
    </CategorieDescription>
    <Row>
      {section.map((product, i) => {
        if (product._element_type === 'product')
          return (
            cardFilter(product) && (
              <Col key={i} sm={formula ? "12" : "6"} md={formula ? "12" : "6"} style={{ paddingBottom: '15px' }}>
                <RestaurantCard 
                  color={color} 
                  darkmode={darkmode} 
                  product={product} 
                  onClick={() => cardClicked(product)}
                  formula={formula}
                />
              </Col>
            )
          )
        else if (product._element_type === 'cat')
          return (
            <SubCategorie
              color={color}
              darkmode={darkmode}
              item={product}
              cardClicked={cardClicked}
              cardFilter={cardFilter}
              formula={formula}
            />
          )
        else if (product._element_type === 'formula')
          return (
            <Col key={i} sm={formula ? "12" : "6"} md={formula ? "12" : "6"} style={{ paddingBottom: '15px' }}>
              <RestaurantFormulaCard 
                color={color} 
                darkmode={darkmode} 
                product={product} 
                onClick={() => cardFormulaClicked(product)} 
                formula={formula}
              />
            </Col>
          )
        else
          return null
      })}
    </Row>
  </CategorieHandler>
)

export default Categorie

const CategorieDescription = styled.div`
  margin-top: ${(props) => (props.image ? '20px' : '0px')};
  margin-bottom: 19px;
  color: ${(props) => props.color};
  font: normal normal normal 15px Lato;
  width: 90%;
  margin-left: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-line;
`

const CategorieHeaderTitle = styled.div`
  border-radius: 5px;
  background-color: ${(props) => (props.image ? 'rgba(0, 0, 0, 0.5)' : '')};
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
  position: relative;
  padding-left: 19px;
  padding-right: 19px;
  font: normal normal bold 25px Lato;
  color: ${(props) => props.image ? 'white' : props.color};
  &:after {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 19px;
    width: calc(100% - 38px);
    height: 1px;
    background-color: ${(props) => props.image ? 'white' : props.color};
  }
`

const CategorieHeader = styled.div`
  position: relative;
`

const CategorieHeaderImage = styled.img`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
`

const CategorieHandler = styled.div`
  background-color: ${(props) => props.darkmode ? '#292C2B' : 'transparent'};
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
`
