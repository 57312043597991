import styled from 'styled-components';
import AcUnitIcon from '@material-ui/icons/AcUnit';

const Badge = ({
 text, icon, isActive, noStock, isBackgroundWhite, style, datas
 }) => (
   <BadgeItem 
      style={style} 
      datas={datas}
      active={isActive} 
      noStock={noStock} 
      isBackgroundWhite={isBackgroundWhite}>
     {icon && (
        <BadgeIcon>
          <AcUnitIcon style={{ width: '100%', height: '100%' }} />
          {' '}
          {/* remplace by the icon */}
        </BadgeIcon>
      )}
     {text}
   </BadgeItem>
);

export default Badge;

const BadgeIcon = styled.div`
color: #276347;
margin-right: 4px;
width: 15px;
`;

const BadgeItem = styled.div`
display: flex;
align-items: center;
background-color: ${(props) => (props.active === true ? '#276347' : '#E7F2EF')};
${(props) => ((!props.isBackgroundWhite && !props.active) ? 'background-color: white;' : '')}
border-radius: 13px;
padding: 1px 6px;
color: ${(props) => (props.active === true ? 'white' : '#276347')};
${(props) => (props.noStock ? 'background-color: #D1D1D1;color: white;' : '')}
margin-left: 12px;
font: ${(props) => `normal normal normal 13px ${props.datas.font}`};
font: normal normal normal 13px Lato;
&:first-child{
    margin-left: 0px;
}
`;
