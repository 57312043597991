import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { DeleteForeverOutlined, Add, Remove } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled, { withTheme } from 'styled-components';
import Switch from '@material-ui/core/Switch'
import _ from 'underscore'

const daysOrder = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

const Display = ({
 t, 
 unregister, 
 setValue, 
 formatHoursFromDb, 
 menuDatas,
 menu_hours,
}) => {
	const [disableHours, setDisableHours] = useState(menuDatas?.menu_hours_enable || false)
  const [displayHours, setDisplayHours] = useState(menuDatas?.menu_display_hours || []);
	const [hours, sethours] = useState([])
  const classes = useStyles();

  useEffect(() => {
    setDisplayHours(formatHoursFromDb(displayHours));
  }, []);

  useEffect(() => {
    unregister('menu_display_hours');
    setValue('menu_display_hours', displayHours);
  }, [displayHours]);

	useEffect(() => {
		unregister('menu_hours')
		setValue('menu_hours', hours)
	}, [hours])

	useEffect(() => {
		let _days = []
		const defaultHours = []

		for (let i = 0; i < 3; i++) {
			defaultHours.push({ start: '10:00', end: '22:00' })
		}

		for (let i = 0; i < 7; i++) {
			_days.push({ hours: defaultHours, status: false, thirdPeriod: false })
		}

		if (menu_hours) {
			menu_hours.map((day, index) => {
				let _hours = [...day.hours]
				_hours = _hours.map((e) => ({
					start: e.start,
					end: e.end,
				}))
				_days[index] = { ...day, hours: _hours }
				return null
			})
		}
		sethours(_days)
	}, [sethours, menu_hours])

  const displayLetter = (e) => {
    switch (e) {
      case 'monday':
        return 'L'
      case 'tuesday':
        return 'M'
      case 'wednesday':
        return 'M'
      case 'thursday':
        return 'J'
      case 'friday':
        return 'V'
      case 'saturday':
        return 'S'
      case 'sunday':
        return 'D'
      default:
        return '?'
    }
  };

	const updateHours = (id, index, hour, type) => {
		const _days = [...hours]
		const _hours = [..._days[id].hours]
		_hours[index] = !_.isUndefined(_hours[index]) ? { ..._hours[index] } : {}
		_hours[index][type] = hour
		_days[id].hours = _hours
		sethours(_days)
	}

  const updateDisplayDays = (item, index, day) => {
    let tmp = [...displayHours];
    tmp[index].openDays[day] = !item.openDays[day];
    setDisplayHours(tmp);
  };

  const updateDisplayHours = (value, index, t) => {
    let tmp = [...displayHours];
    if (t === 0) tmp[index].displayHours = value;
    else tmp[index].undisplayHours = value;
    setDisplayHours(tmp);
  };
  
	const setDay = (id, status, thirdPeriod = false) => {
		const _days = [...hours]
		_days[id] = {
			status: Boolean(status),
			hours: _days[id].hours || [],
			thirdPeriod,
		}
		sethours(_days)
	}

	function DayContainer(day, id) {
		const [thirdPeriod, setThirdPeriod] = useState(false)

		if (!_.isUndefined(hours[id]) && hours[id].status) {
			return (
				<Background>
					<Row>
						<Col lg="4">
							<SwitchContainer>
								<Flex>
									<Switch
										checked={hours[id].status}
										onChange={() => {
											setDay(id, !hours[id].status)
										}}
										name="daySwitch"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
									<Day>{day}</Day>
								</Flex>
							</SwitchContainer>
						</Col>
						<Col lg="4">
							<FlexTimeInput style={{ marginBottom: '10px' }}>
								<TimeInput>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={hours[id].hours[0].start}
										defaultValue="09:00"
										onChange={(e) => updateHours(id, 0, e.target.value, 'start')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInput>
								-
								<TimeInputBis>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={hours[id].hours[0].end}
										defaultValue="22:00"
										onChange={(e) => updateHours(id, 0, e.target.value, 'end')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInputBis>
							</FlexTimeInput>
							{/* <FlexTimeInput style={{ marginBottom: '10px' }}>
								<TimeInput>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={hours[id].hours[1].start}
										onChange={(e) => updateHours(id, 1, e.target.value, 'start')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInput>
								-
								<TimeInputBis>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={hours[id].hours[1].end}
										onChange={(e) => updateHours(id, 1, e.target.value, 'end')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInputBis>
							</FlexTimeInput> */}
							{hours[id].thirdPeriod ? (
								<FlexTimeInput>
									<TimeInput>
										<TextField
											id="time-1"
											type="time"
											InputProps={{ classes }}
											value={hours[id].hours[2].start}
											onChange={(e) => updateHours(id, 2, e.target.value, 'start')}
											InputLabelProps={{ shrink: true }}
											inputProps={{ step: 300 }}
										/>
									</TimeInput>
									-
									<TimeInputBis>
										<TextField
											id="time-1"
											type="time"
											InputProps={{ classes }}
											value={hours[id].hours[2].end}
											onChange={(e) => updateHours(id, 2, e.target.value, 'end')}
											InputLabelProps={{ shrink: true }}
											inputProps={{ step: 300 }}
										/>
									</TimeInputBis>
								</FlexTimeInput>
							) : null}
						</Col>
						<Col lg="4">
							<div
								style={{ textAlign: 'end' }}
								onClick={() => {
									setThirdPeriod(!thirdPeriod)
									setDay(id, true, !thirdPeriod)
								}}
							>
								{hours[id].thirdPeriod ? (
									<AddContainer>
										{t('remove hours')}
										<Remove
											style={{
												width: '20px',
												height: '17px',
												color: '#E76B56',
											}}
										/>
									</AddContainer>
								) : (
									<AddContainer>
										{t('add hours')}
										<Add
											style={{
												width: '20px',
												height: '17px',
												color: '#E76B56',
											}}
										/>
									</AddContainer>
								)}
							</div>
						</Col>
					</Row>
				</Background>
			)
		} else {
			return (
				<Unbackground>
					<Row>
						<Col lg="4">
							<SwitchContainer>
								<Flex>
									<Switch
										checked={!_.isUndefined(hours[id]) ? hours[id].status : false}
										onChange={() => {
											setDay(id, !_.isUndefined(hours[id]) ? !hours[id].status : true)
										}}
										name="daySwitch"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
									<Day>{day}</Day>
								</Flex>
							</SwitchContainer>
						</Col>
						<Col lg="4">
							<Closed>
                {t('closed')}
              </Closed>
						</Col>
					</Row>
				</Unbackground>
			)
		}
	}

  return (
    <Container fluid>
      <Card 
        bsPrefix="card white-card"
        style={{marginBottom: '30px'}}
      >
        <Flex>
          <Switch
            checked={disableHours}
            onChange={() => {
              unregister('menu_hours_enable')
              setValue('menu_hours_enable', !disableHours)
              setDisableHours(!disableHours)
            }}
            name="menuDisableHours"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <SwitchTitle>
            {t('menu specific hours')}
          </SwitchTitle>
        </Flex>
      </Card>
      <CustomCard bsPrefix="card white-card">
        <Title>
          {t('menu visibility')}
        </Title>
        <SubTitle>
          {t('menu visibility description')}
        </SubTitle>
        <div style={{ textAlign: 'center' }}>
          {displayHours.map((item, index) => (
            <SubBackground>
              <Flex>
                <DayPickerContainer>
                  {daysOrder.map((day, i) => (
                    <>
                      {item.openDays[day]
                      ? (
                        <DayPicker onClick={() => updateDisplayDays(item, index, day)}>
                          {displayLetter(day)}
                        </DayPicker>
                        )
                      : (
                        <DayUnPicked onClick={() => updateDisplayDays(item, index, day)}>
                          {displayLetter(day)}
                        </DayUnPicked>
                        )}
                    </>
                  ))}
                </DayPickerContainer>
                <ResponsiveHandler>
                  <FlexTimeInput>
                    <HoursInput>
                      <TextField
                          id="time-1"
                          type="time"
                          InputProps={{ classes }}
                          value={item.displayHours}
                          onChange={(e) => updateDisplayHours(e.target.value, index, 0)}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ step: 300 }}
                      />
                    </HoursInput>
                    <div style={{ marginTop: '11px' }}>-</div>
                    <HoursInput>
                      <TextField
                          id="time-1"
                          type="time"
                          InputProps={{ classes }}
                          value={item.undisplayHours}
                          onChange={(e) => updateDisplayHours(e.target.value, index, 1)}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ step: 300 }}
                      />
                    </HoursInput>
                  </FlexTimeInput>
                  <IconContainer
                    onClick={() => setDisplayHours(displayHours.filter((obj) => obj !== item))}
                  >
                    <DeleteIcon />
                  </IconContainer>
                </ResponsiveHandler>
              </Flex>
              <ResponsiveHandler2>
                <FlexTimeInput>
                  <HoursInput>
                    <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.displayHours}
                        onChange={(e) => updateDisplayHours(e.target.value, index, 0)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                    />
                  </HoursInput>
                  <div style={{ marginTop: '11px' }}>-</div>
                  <HoursInput>
                    <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.undisplayHours}
                        onChange={(e) => updateDisplayHours(e.target.value, index, 1)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                    />
                  </HoursInput>
                </FlexTimeInput>
                <IconContainer
                  onClick={() => setDisplayHours(displayHours.filter((obj) => obj !== item))}
                >
                  <DeleteIcon />
                </IconContainer>
              </ResponsiveHandler2>
            </SubBackground>
            ))}
          <CustomButton
            type="button"
              onClick={() => setDisplayHours(displayHours.concat({
                openDays: {
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
                },
                displayHours: '12:00',
                undisplayHours: '15:00',
              }))}
          >
           {t('add open hours')}
          </CustomButton>
        </div>
      </CustomCard>
      <HourList show={!disableHours} lg="10">
        <CustomCard bsPrefix="card white-card">
          <Title style={{marginBottom: '20px'}}>
            {t('menu hours')}
          </Title>
          {DayContainer('Lundi', 0)}
          <br />
          {DayContainer('Mardi', 1)}
          <br />
          {DayContainer('Mercredi', 2)}
          <br />
          {DayContainer('Jeudi', 3)}
          <br />
          {DayContainer('Vendredi', 4)}
          <br />
          {DayContainer('Samedi', 5)}
          <br />
          {DayContainer('Dimanche', 6)}
        </CustomCard>
      </HourList>
      <br/>
    </Container>
  );
};

export default withTheme(withTranslation()(Display));

const Flex = styled.div`
  display: flex;
`;
const SwitchTitle = styled.div`
	margin-top: 3px;
	font-size: 15pt;
	font-weight: bold;
`
const ResponsiveHandler = styled.div`
  display: flex;
  @media screen and (max-width: 650px) {
    display: none !important;
  }
`;
const ResponsiveHandler2 = styled.div`
  @media screen and (min-width: 651px) {
    display: none !important;
  }
`;
const HourList = styled(Col)`
  padding-left: 0px;
	opacity: ${(props) => props.show && '0.5'};
	* {
		cursor: ${(props) => props.show && 'not-allowed !important'};
		pointer-events: ${(props) => props.show && 'none !important'};
	}
`
const Background = styled.div`
	background-color: #E6EFFF;
	padding: 10px;
	text-align: center;
	border-radius: 7px;
`
const Unbackground = styled.div`
	background-color: white;
	padding: 10px;
	text-align: center;
	border-radius: 7px;
	border-color: rgba(55, 55, 55, 0.2);
	border-width: 1px;
	border-style: solid;
`
const SwitchContainer = styled.div`
	text-align: left;
	margin-top: -5px;
`
const Day = styled.div`
	font-weight: bold;
	font-size: 11pt;
	margin-top: 8px;
`
const AddContainer = styled.div`
	font-size: 11pt;
	margin-top: 8px;
	color: #e76b56;
	cursor: pointer;
`
const Closed = styled.div`
	display: flex;
	font-weight: bold;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`;
const DayPicker = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`;
const DayPickerContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 800px;
`;
const IconContainer = styled.div`
  background-color: #F8F7FF;
  cursor: pointer;
  margin: 10px;
  margin-right: 20px;
  padding: 4px;
  width: 30px;
  height: 35px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;
const DayUnPicked = styled.div`
  cursor: pointer;
  background-color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`;
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`;
const FlexTimeInput = styled.div`
	display: flex;
	justify-content: center;
`;
const HoursInput = styled.div`
  background-color: white;
  padding-left: 15px;
  border-radius: 5px;
  margin-right: 10px; 
  text-align: center;
  margin-right: 5px;
  margin: 10px;
  width: 100px;
  height: 30px;
`;
const TimeInput = styled.div`
	background-color: white;
	padding-left: 15px;
	border-radius: 5px;
	margin-right: 10px;
`
const TimeInputBis = styled.div`
	background-color: white;
	padding-left: 15px;
	border-radius: 5px;
	margin-left: 10px;
`
const CustomButton = styled.button`
	@media screen and (max-width: 991px) {
		width: 90%;
	} 
	@media screen and (min-width: 991px) {
		width: 30%;
	} 
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #F8F7FF;
	border-style: none;
	font-weight: bold;
	font-size: 11pt;
	color: ${(props) => props.theme.primary};
    padding: 5px;
	border-radius: 4px;
`;
const CustomCard = styled(Card)`
  padding: 30px !important;
`;
const SubBackground = styled.div`
  margin-top: 15px;
  background-color: #E6EFFF;
  padding: 5px 0px 5px 30px;
	@media screen and (max-width: 650px) {
    padding: 5px 0px 5px 5px;
	} 
  text-align: center;
  border-radius: 7px;
`;
