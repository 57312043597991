import React from 'react'
import { IconButton, Box, Badge } from '@material-ui/core'
import { LocalOffer } from '@material-ui/icons'
import useProductBaseStore from '../ProductBaseStore'
import styled, { withTheme, keyframes } from 'styled-components'

const ProductBaseCartToggle = (props) => {
	const { showCart, cart } = useProductBaseStore.useContainer()
	return cart.length ? (
		<Box
			sx={{
				position: 'fixed',
				right: '10px',
				bottom: '46%',
			}}
		>
			<IconButtonStyled
				style={{
					background: 'white',
					color: props.theme.primary,
					border: '1px solid #eee',
				}}
				onClick={() => showCart()}
				appear={cart.length === 1}
			>
				<Badge badgeContent={cart.length} color="secondary">
					<LocalOffer style={{ fontSize: 40 }} />
				</Badge>
			</IconButtonStyled>
		</Box>
	) : null
}

export default withTheme(ProductBaseCartToggle)

const appear = keyframes`

 0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
`

const IconButtonStyled = styled(IconButton)`
	animation: ${appear} 1s ease 0s 1 normal forwards;
`
