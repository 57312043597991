import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DashboardAdminIndex from './DashboardAdminIndex/DashboardAdminIndex'
import DashboardAdminUsers from './DashboardAdminUsers/DashboardAdminUsers'
import DashboardAdminPlaces from './DashboardAdminPlaces/DashboardAdminPlaces'
import DashboardAdminProductDatabase from './DashboardAdminProductDatabase/DashboardAdminProductDatabase'
import Page404 from '../Page404/Page404'

const Routes = ({ setTitle }) => (
	<Switch>
		<Route exact path="/admin">
			<DashboardAdminIndex setTitle={setTitle} />
		</Route>
		<Route path="/admin/users">
			<DashboardAdminUsers setTitle={setTitle} />
		</Route>
		<Route path="/admin/places/">
			<DashboardAdminPlaces setTitle={setTitle} />
		</Route>
		<Route path="/admin/product_base/">
			<DashboardAdminProductDatabase setTitle={setTitle} />
		</Route>
		<Route>
			<Page404 mode />
		</Route>
	</Switch>
)

export default Routes
