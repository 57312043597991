import styled from 'styled-components'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import './style.css'
import Modal from 'react-bootstrap/Modal'
import Categorie from '../../RestaurantInformation/Categorie/Categorie'
import { getMultilangContent } from '../../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../../stores/MenuClientContainer'
import { formatCategoryElements } from '../../../../../utils/MenuHelpers'

export const RestaurantFormulaCardModal = ({ isOpen, setIsOpen, currentCard, datas }) => {
  const { selected_lang } = useMenuClientContainer.useContainer()

  return (
    <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
      <Modal.Body scrollable style={{ padding: '0px' }}>
        <RestaurantFormulaCardModalHandler darkmode={datas.darkmode}>
          <div style={{ width: '100%', height: currentCard.background_image_url ? '301px' : '60px', position: 'relative' }}>
            <RestaurantFormulaCardModalCloseButton 
              darkmode={datas.darkmode}
              color={datas.color}
              onClick={() => setIsOpen(false)}>
              X
            </RestaurantFormulaCardModalCloseButton>
            {currentCard.background_image_url && 
              <AwesomeSlider fillParent mobileTouch buttons={false}>
                <div data-src={currentCard.background_image_url} />
              </AwesomeSlider>
            }
          </div>
          <div style={{ padding: '0 20px', paddingBottom: '1px', backgroundColor: datas.darkmode && '#292C2B' }}>
            <RestaurantFormulaCardModalHeading darkmode={datas.darkmode}>
              <div style={{width: '100%' , display: 'flex'}}>
                <RestaurantFormulaCardModalHeadingTitle darkmode={datas.darkmode}>
                  {currentCard.name}
                </RestaurantFormulaCardModalHeadingTitle>
                <RestaurantFormulaCardPrice>
                  {currentCard.price} €
                </RestaurantFormulaCardPrice>
              </div>
              <RestaurantFormulaCardModalHeadingDescription darkmode={datas.darkmode}>
                {currentCard.description}
              </RestaurantFormulaCardModalHeadingDescription>
              {currentCard._sections &&
                currentCard._sections.filter(
                  (cat) => !cat?.group_parent || cat?.group_parent === null
                ).sort((a, b) => a.order - b.order).map((section) => {
                  if (section.group_type === 0) {
                    return (
                      <Categorie
                        color={datas.color}
                        darkmode={datas.darkmode}
                        cardClicked={() => console.log('yes')}
                        cardFormulaClicked={() => console.log('yes')}
                        sectionInfo={section}
                        cardFilter={() => true}
                        section={formatCategoryElements(section, currentCard._sections) || []}
                        title={
                          getMultilangContent(section, 'group_name_ml', selected_lang) || ''
                        }
                        description={
                          getMultilangContent(
                            section,
                            'group_description_ml',
                            selected_lang
                          ) || ''
                        }
                        image={section.group_image_url && section.group_image_url}
                        formula
                      />
                    )
                  }
                })}
            </RestaurantFormulaCardModalHeading>
          </div>
        </RestaurantFormulaCardModalHandler>
      </Modal.Body>
    </Modal>
  )
}

export default RestaurantFormulaCardModal

const RestaurantFormulaCardPrice = styled.div`
  width: 15%;
  font-weight: bold;
`
const RestaurantFormulaCardModalHeading = styled.div`
  margin-top: 15px;
  background-color: ${(props) => props.darkmode ? 'black' : 'white'};
  padding: 16px 20px 25px 20px;
  border-radius: 5px;
  margin-bottom: 25px;
`
const RestaurantFormulaCardModalHeadingDescription = styled.div`
  font: normal normal normal 15px Lato;
  color: ${(props) => props.darkmode ? 'white' : '#606060'};
  margin-bottom: 16px;
`
const RestaurantFormulaCardModalHeadingTitle = styled.div`
  width: 85%;
  font: normal normal bold 20px Lato;
  color: ${(props) => props.darkmode ? 'white' : '#1d1d1d'};
  margin-bottom: 10px;
`
const RestaurantFormulaCardModalCloseButton = styled.button`
  z-index: 30;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: none;
  position: absolute;
  font-weight: bold;
  left: 20px;
  top: 18px;
  font-size: 13px;
  color: ${(props) => props.darkmode ? 'white' : props.color};
  background-color: ${(props) => props.darkmode ? 'black' : 'white'};
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`
const RestaurantFormulaCardModalHandler = styled.div`
  width: 524px;
  background-color: ${(props) => props.darkmode ? '#292C2B' : '#e7f2ef'};
  @media (max-width: 524px) {
    width: 100%;
  }
`