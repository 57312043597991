import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Auth } from '../../utils/firebase/FirebaseModules';
import useGoogle from '../../hooks/GoogleAuth';

const FirebaseAuthEmailNotVerified = (props) => {
	const {
		verificationMailSended,
		verifyEmailButton,
		sendVerificationEmail,
	} = useGoogle();
	const history = useHistory();

	useEffect(() => {
		if (verificationMailSended !== true) sendVerificationEmail();
	}, []);

  return (
    <div style={{ padding: '7%' }}>
      <h1 style={{ marginBottom: '30px' }}>{props.t('Activate account')}</h1>
      <p style={{ textAlign: 'center', fontSize: '13pt' }}>
        {props.t('Mail has been send to you')}
        <strong>{Auth().currentUser.email}</strong>
      </p>
      <p style={{ textAlign: 'center', fontSize: '13pt' }}>
        {props.t('Click on the link')}
      </p>
      <hr style={{ margin: '30px 0px 30px 0px' }} />
      <div style={{ textAlign: 'center' }}>
        <em>{props.t('Mail not received')}</em>
        <br />
        {verifyEmailButton(props.t('Send email again'))}
      </div>
      <div style={{ textAlign: 'center' }}>
        <em>ou</em>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button
    		variant="outline"
			style={{ color: '#6F4FB3' }}
    		onClick={() => Auth().signOut()}
        >
          {props.t('disconnect')}
        </Button>
      </div>
    </div>
  );
};

export default withTranslation()(FirebaseAuthEmailNotVerified);
