import _, { sortBy } from 'underscore'
/**
 * order and combine subcats  and products
 *
 * @param {Object} category , the category object you want to analize
 * @param {Array} sections , the array that contains all sections
 * @return {Array} subSections and products in same array sorted ASC .
 */
export const formatCategoryElements = (category, sections) => {
	const prods = category?.products
		? category.products.map((p) => ({ ...p, _element_type: 'product' }))
		: []
	const formulas = category?.formulas
		? category.formulas.map((p) => ({ ...p, _element_type: 'formula' }))
		: []
	const cats = sections
		.filter((c) => category.id === c.group_parent)
		.map((c) => ({ ...c, _element_type: 'cat' }))
	const elements = [...prods, ...cats, ...formulas]

	return _.sortBy(elements, 'menu_order')
}

/**
 * order and combine subcats  and products for product datase
 *
 * @param {Array} list array containing products and subcats
 * @return {Array} products and groups sort by product_database_order
 */
export const formatProductDatabaseOrder = (list) => {
	const prods = list?.products
		? list.products.map((p) => ({ ...p, _element_type: 'product' }))
		: []
	const groups = list?.groups
		? list.groups.map((p) => ({ ...p, _element_type: 'group' }))
		: []

	const elements = [
		{ products: [...prods], id: 'UNCLASSED', order: 0, _element_type: 'group' },
		...groups,
	]

	return _.sortBy(elements, 'order')
}
