import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import HeaderTheme from '../../components/Menus/Theme/HeaderTheme';
import HeaderThemeCard from '../../components/Menus/Theme/HeaderThemeCard';
import SubThemeMenu from '../../components/Menus/Theme/SubThemeMenu';
import RestaurantThemeFooter from '../../components/Menus/Theme/RestaurantThemeFooter';
import RestaurantThemeInformation from '../../components/Menus/Theme/RestaurantThemeInformation';

const MenuTheme = ({ t, datas }) => {
    let subMenuItems = [{ value: 'Offres', id: 'offre' },
    { value: 'Entrées', id: 'starter' },
    { value: 'Boissons', id: 'drinks' },
    { value: 'Fruits de mer', id: 'sea' },
    { value: 'Viandes', id: 'meat' },
    { value: 'Poissons', id: 'fish' }];

    return (
      <>
        <HeaderTheme datas={datas} />
        <HeaderThemeCard datas={datas}/>
        <SubThemeMenu datas={datas} items={subMenuItems} />
        <Box style={{ backgroundColor: datas.darkmode ? '#292C2B' : '#E7F2EF' }}>
          <RestaurantThemeInformation datas={datas} />
          <RestaurantThemeFooter datas={datas} text="Pied de page" />
        </Box>
      </>
    );
};

export default withTranslation()(MenuTheme);

const Box = styled.div`
    scroll-behavior: smooth;
`;
