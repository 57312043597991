import React from 'react'
import {
	useStripe as stripeHook,
	useElements,
	CardElement,
} from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'
import { Spinner, Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import withStripe from '../../../../hoc/withStripe'
import useStripe from '../../../../hooks/Stripe'

const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,

	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

const StripeForm = ({ t, setShow, paymenMethodsReload }) => {
	const stripe = stripeHook()
	const elements = useElements()
	const { getSetutIntent } = useStripe()
	const [intent, setIntent] = React.useState({})
	const [error, setError] = React.useState('')
	const { enqueueSnackbar } = useSnackbar()
	const [processing, setProcessing] = React.useState(false)
	const [loadingCardForm, setLoadingCardForm] = React.useState(false)
	React.useEffect(() => {

		;(async () => {
			setLoadingCardForm(true)
			const intent = await getSetutIntent()
			setIntent(intent.data)
			setLoadingCardForm(false); 
		})()
	}, [])

	const handleSubmit = async (event) => {
		setProcessing(true)
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}

		const result = await stripe.confirmCardSetup(intent.data.client_secret, {
			payment_method: {
				card: elements.getElement(CardElement),
			},
		})

		if (result.error) {
			setError(result.error.message)
		} else {
			enqueueSnackbar(t('add_new_payment_method_added_success'), {
				variant: 'success',
			})
			paymenMethodsReload()
			setShow(false)
		}
		setProcessing(false)
	}
	return (
		<form onSubmit={handleSubmit}>
			{error !== '' && <Alert variant="danger">{error}</Alert>}
			{intent?.status === 'success' && (
				<>
					{loadingCardForm ? (
							<div style={{textAlign:"center"}}><Spinner animation="border" size="sm" variant="dark" /></div>
						) : (
						
				<>
					<CardElement options={CARD_ELEMENT_OPTIONS} />

					<PayButton disabled={processing} type="submit">
						{processing ? (
							<Spinner animation="border" size="sm" variant="light" />
						) : (
							t('add_my_card')
						)}
					</PayButton>
					</>
						)}
					  <div style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            style={{ width: '80%' }}
            src="https://wannapay.fr/assets/img/secure_payment.png"
          />
        </div>
				</>
			)}
		</form>
	)
}

export default withStripe(StripeForm)

const PayButton = styled(Button)`
	display: block;
	margin: 10px auto;
`
