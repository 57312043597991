import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import RestaurantCard from '../../../RestaurantCard/RestaurantCard';
import { getMultilangContent } from '../../../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../../../stores/MenuClientContainer'

const SubCategorie = ({
  item, 
  color, 
  darkmode, 
  cardClicked, 
  cardFilter, 
  formula
}) => {
  const { selected_lang } = useMenuClientContainer.useContainer()

  return (
    <div 
      style={{width: '100%', paddingRight: '10px', paddingLeft: '10px'}}>
      <SubCategorieHandler darkmode={darkmode} formula={formula}>
        <SubCategorieTitle darkmode={darkmode} color={color}>
          {getMultilangContent(item, 'group_name_ml', selected_lang)}
        </SubCategorieTitle>
        {item.product_main_image_url && <SubCategorieImage src={item.product_main_image_url} />}
        <SubCategorieDescription darkmode={darkmode} color={color}>
          {getMultilangContent(item, 'group_description_ml', selected_lang)}
        </SubCategorieDescription>
        <Row>
          {item.products && item.products.map((product, i) => (
            cardFilter(product) && (
              <Col sm={formula ? "12" : "6"} md={formula ? "12" : "6"} style={{ paddingBottom: '15px' }}>
                <RestaurantCard 
                  key={i}
                  color={color} 
                  darkmode={darkmode}
                  product={product} 
                  onClick={cardClicked}
                  subProduct
                  formula={formula}
                />
              </Col>
            )
          ))}
        </Row>
        <br/>
      </SubCategorieHandler>
    </div>
  )
}

export default SubCategorie;

const SubCategorieDescription = styled.div`
  margin-bottom: 20px;
  color: ${(props) => props.darkmode ? 'white' : props.color};
  font: normal normal normal 15px Lato;
  width: 90%
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const SubCategorieTitle = styled.div`
  font: normal normal bold 20px Lato;
  color: ${(props) => props.darkmode ? 'white' : props.color};
  margin-bottom: 13px;
`;

const SubCategorieImage = styled.img`
  margin-bottom: 13px;
  border-radius: 5px;
  width: 100%;
  height: 146px;
  object-fit: cover;
`;

const SubCategorieHandler = styled.div`
  margin: 10px 0px 25px 0px;
  background-color: ${(props) => props.darkmode ? 'black' : props.formula ? '#E7F2EF' : 'white'};
  width: 100%;
  padding: 0px 20px;
  padding-top: 22px;
  border-radius: 5px;
`;
