import React, { useState, useRef, useCallback } from 'react'
import {
  OverlayTrigger,
  Container,
  Dropdown,
  DropdownButton,
  Card,
  Button,
  Form,
} from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import {
  PermMedia,
  CloudUploadOutlined,
  DescriptionOutlined,
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
  AssignmentReturnedRounded,
  SubdirectoryArrowRight,
  Close,
} from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled, { withTheme } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Moveicon from '../../../../assets/menu/move.svg'
import { SetLogoModal } from '../common'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'

const menus_test = [
  {
    id: 0,
    menu_name: 'Restaurant',
    groups: [],
  },
]

const Menu = ({ t, modePDF, register, setValue, errors }) => {
  const [picModalOpen, setPicModalOpen] = useState(false)
  const [menus, setMenus] = useState(menus_test[0].groups)

  const { enqueueSnackbar } = useSnackbar()

  const [PicUrl, setPicUrl] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState(null)
  const myRefname = useRef(null)

  const onDrop = useCallback((file) => {
    if (file[0].name && file[0].name.split('.')[1] === 'pdf') {
      setValue('pdf_file', file[0])
      setFileName(file[0].name)
      setFileSize(file[0].size)
    }
    else
      enqueueSnackbar(t('pdf_not_found'), { variant: 'error' })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const displayGroupTitle = (id) => {
    if (id === 2) {
      return t('featured category')
    } else if (id === 1) {
      return t('formule category')
    } else {
      return t('simple category')
    }
  }

  const displaySize = (size) => {
    if (size / 1000000 > 1) {
      return `${(Math.trunc(fileSize / 100000) / 10).toString()} Mo`
    } else {
      return `${(Math.trunc(fileSize / 100) / 10).toString()} Ko`
    }
  }

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    } else if (result.type === 'CATEGORY') {
      const newOrder = Reorder(menus, result.source.index, result.destination.index)
      setMenus(newOrder)
    } else {
      const answers = Reorder(
        menus[parseInt(result.type, 10)].products,
        result.source.index,
        result.destination.index
      )
      const newOrder = JSON.parse(JSON.stringify(menus))
      newOrder[result.type].products = answers
      setMenus(newOrder)
    }
  }

  const categoryRender = (group, index, provided) => (
    <CategorieItem visible={group.group_visible}>
      <Flex {...provided.dragHandleProps} style={{ padding: '10px 0px 0px 20px' }}>
        <img
          src={Moveicon}
          alt="logo"
          style={{
            width: '15px',
            marginRight: '20px',
            marginTop: '-3px',
            cursor: 'move',
          }}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentLeftContainer>
            <ContentTitle>{group.group_name}</ContentTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => console.log('yes')}>
                  <CopyIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('edit')}
                  </Toolbox>
                }
              >
                <IconContainer>
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() => setMenus(menus.filter((i) => i.id !== group.id))}
                >
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
        </div>
      </Flex>
      <div style={{ margin: '-10px 0px 0px 70px' }}>
        <Flex style={{ marginBottom: '5px' }}>
          <ArrowRight />
          <GroupTitle>{displayGroupTitle(group.group_type)}</GroupTitle>
        </Flex>
        <GroupDescription style={{ width: '450px' }}>
          {group.group_description}
        </GroupDescription>
      </div>
    </CategorieItem>
  )

  const productRender = (product, provided, item, index, subIndex) => (
    <Flex {...provided.dragHandleProps}>
      <img
        src={Moveicon}
        alt="logo"
        style={{
          width: '15px',
          marginRight: '20px',
          marginTop: '-3px',
          cursor: 'move',
        }}
      />
      <Content>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {item.group_type === 1 ? (
            <>
              <ContentFormuleLeftContainer>
                <ContentFormuleInTitle>{product.product_name}</ContentFormuleInTitle>
              </ContentFormuleLeftContainer>
              <ContentFormuleAction>
                <Flex style={{ marginRight: '20px' }}>
                  <OverlayTrigger
                    key="editToolbox"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Toolbox>
                        <Spike />
                        Éditer
                      </Toolbox>
                    }
                  >
                    <IconContainer>
                      <EditIcon />
                    </IconContainer>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="deleteToolbox"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Toolbox>
                        <Spike />
                        {t('remove')}
                      </Toolbox>
                    }
                  >
                    <IconContainer onClick={() => console.log('yes')}>
                      <DeleteIcon />
                    </IconContainer>
                  </OverlayTrigger>
                </Flex>
              </ContentFormuleAction>
              <div style={{ width: '10%' }}>
                <ContentFormuleDescription>
                  {product.product_description}
                </ContentFormuleDescription>
                <PriceFormuleContainer>{`${product.price} €`}</PriceFormuleContainer>
              </div>
            </>
          ) : (
            <>
              <ContentLeftContainer>
                <ContentInTitle>{product.product_name}</ContentInTitle>
                <ContentDescription>
                  {product.product_description}
                </ContentDescription>
              </ContentLeftContainer>
              <ContentAction>
                <Flex style={{ marginRight: '20px' }}>
                  <PriceContainer>
                    {product.promo ? (
                      product.prices.map((item, index) => {
                        if (index < 2) {
                          return (
                            <PromoPriceBox>
                              <PromoPrice>{`${item.price} €`}</PromoPrice>
                              &nbsp;&nbsp;
                              <PromoNewPrice>
                                {`${(
                                  item.price -
                                  item.price * (product.promo / 100)
                                ).toFixed(2)} €`}
                              </PromoNewPrice>
                            </PromoPriceBox>
                          )
                        } else return null
                      })
                    ) : (
                      <div style={{ width: '100%', display: 'flex' }}>
                        {product.prices.map((item, index) => {
                          if (index < 3) {
                            return <PriceBox>{`${item.price} €`}</PriceBox>
                          } else return null
                        })}
                      </div>
                    )}
                  </PriceContainer>
                  <OverlayTrigger
                    key="editToolbox"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Toolbox>
                        <Spike />
                        Éditer
                      </Toolbox>
                    }
                  >
                    <IconContainer onClick={() => console.log('yes')}>
                      <EditIcon />
                    </IconContainer>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="deleteToolbox"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Toolbox>
                        <Spike />
                        {t('remove')}
                      </Toolbox>
                    }
                  >
                    <IconContainer onClick={() => console.log('yes')}>
                      <DeleteIcon />
                    </IconContainer>
                  </OverlayTrigger>
                </Flex>
              </ContentAction>
            </>
          )}
        </div>
      </Content>
    </Flex>
  )

  const productDraggable = (category, i) => (
    <Droppable droppableId={`droppable${category.id}`} type={`${i}`}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={{ padding: '20px' }}>
          {category.products.map((product, index) => (
            <Draggable
              key={`${i}${index}`}
              draggableId={`${i}${index}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  {productRender(product, provided, category, i, index)}
                  <div style={{ marginBottom: '20px' }} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )

  const handleAddImage = (img) => {
    if (typeof img === 'object' && img.blob.size > 0) {
      setValue(
        'background_image_file',
        getFileFromBlob(img.blob, 'background_image')
      )
    }
  }

  return (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={picModalOpen}
        toggleModal={setPicModalOpen}
        handleAddImage={handleAddImage}
      />

      <Container fluid>
        <Card bsPrefix="card white-card">
          <Title>{modePDF ? t('digital menu') : t('menu header')}</Title>
          {modePDF && (
            <>
              {fileName ? (
                <PDFContainer>
                  <DescriptionOutlined
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'red',
                      marginTop: '2px',
                      marginRight: '10px',
                    }}
                  />
                  <PDFName>{fileName}</PDFName>
                  <PDFSize>{displaySize(fileSize)}</PDFSize>
                  <div style={{ width: '70%' }}>
                    <Close
                      onClick={() => setFileName(null)}
                      style={{
                        cursor: 'pointer',
                        color: '#6F4FB3',
                        width: '25px',
                        height: '25px',
                        float: 'right',
                      }}
                    />
                  </div>
                </PDFContainer>
              ) : (
                <DashedBackground isDragActive={isDragActive} {...getRootProps()}>
                  <CloudUploadOutlined
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '20px',
                      color: 'lightgrey',
                      margin: '30px',
                    }}
                  />
                  <ImportPDFContainer>
                    <input
                      style={{ display: 'none' }}
                      accept="application/pdf"
                      id={fileName}
                      type="file"
                      ref={myRefname}
                      {...getInputProps()}
                    />
                    <ImportPDFTitle>
                      <div>
                        {t('drag a file')}
                      </div>
                      &nbsp;
                      <div style={{ color: '#6F4FB3', textDecoration: 'underline' }}>
                          {t('or import')}
                      </div>
                    </ImportPDFTitle>
                    <ImportPDFSubTitle>
                      {t('file size')}
                    </ImportPDFSubTitle>
                  </ImportPDFContainer>
                  <div style={{ width: '60px', height: '60px', margin: '30px' }} />
                </DashedBackground>
              )}
            </>
          )}
          <ProductBackground>
            <Flex>
              {!PicUrl ? (
                <Logo
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                >
                  <InsertLogo>
                    <PermMedia
                      style={{
                        width: '50px',
                        height: '50px',
                        marginBottom: '20px',
                        opacity: 0.5,
                        color: 'lightgrey',
                      }}
                    />
                    {t('add background pic')}
                    <SizeText>{t('pic size')}</SizeText>
                  </InsertLogo>
                </Logo>
              ) : (
                <Img
                  src={PicUrl && PicUrl.url}
                  alt="logo"
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                />
              )}
              <div style={{ display: 'inline', width: '100%' }}>
                <Form.Control
                  as={NameInput}
                  isInvalid={errors.name}
                  {...register('name', { required: true })}
                  placeholder={t('menu name')}
                />
                <Form.Control.Feedback type="invalid">
                  {t('menu_name_required')}
                </Form.Control.Feedback>

                <Form.Control
                  as={AreaInput}
                  isInvalid={errors.description}
                  {...register('description', { required: true })}
                  placeholder={t('menu description')}
                />
                <Form.Control.Feedback type="invalid">
                  {t('menu_description_required')}
                </Form.Control.Feedback>
              </div>
            </Flex>
          </ProductBackground>
        </Card>

        <Card bsPrefix="card white-card">
          <Title>
            {t('page footer')}
          </Title>
          <Background>
            <TextArea
              {...register('menu_footer')}
              placeholder={t('footer placeholder')}
            />
          </Background>
        </Card>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(Menu))

const Flex = styled.div`
  display: flex;
`
const AddButton = styled(Button)`
  margin: -10px 0px 30px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ButtonDropdown = styled(DropdownButton)`
  margin-bottom: 10px;
  margin-left: 10px;
  width: 23% !important;
  .dropdown-menu {
    width: 100% !important;
    min-width: 200px;
  }
  .dropdown-toggle {
    width: 100% !important;
    text-align: initial;
    min-width: 200px;
  }
  .dropdown-menu.show {
    margin: -20px 0px -20px 0px !important;
  }
  .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }
`
const DropDownItem = styled(Dropdown.Item)`
  text-align: initial !important;
  font-weight: bold;
  color: #e76b56;
  :hover {
    color: #e76b56;
  }
  padding-left: 20px !important;
  padding-right: 40px !important;
`
const PDFContainer = styled.div`
  display: flex;
  border-style: solid;
  border-color: ${(props) => props.theme.primary};
  border-width: 1px;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;
  margin-top: 10px;
`
const PDFName = styled.div`
  width: 40%;
  margin-right: 20px;
`
const PDFSize = styled.div`
  flex: none;
  opacity: 0.5;
`
const ImportPDFTitle = styled.div`
  display: inline-flex;
  font-weight: bold;
`
const ImportPDFContainer = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
`
const ImportPDFSubTitle = styled.div`
  opacity: 0.5;
`
const CategoryBackground = styled.div`
  background-color: #e6efff;
  border-radius: 10px;
  margin-bottom: 20px;
`
const ProductBackground = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const Background = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const TextArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 60px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const RoundedInput = styled(AssignmentReturnedRounded)`
  color: ${(props) => props.theme.primary};
  font-size: 13pt !important;
  margin-top: 4px;
`
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`
const DashedBackground = styled.div`
  display: flex;
  cursor: pointer;
  background: ${(props) => props.isDragActive && 'rgba(0, 0, 0, 0.05)'};
  border-style: dashed;
  border-width: 2px;
  border-radius: 7px;
  border-color: rgba(111, 79, 179, 0.5);
  margin-bottom: 20px;
`
const Logo = styled.div`
  width: 600px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 204px !important;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const InsertLogo = styled.div`
  width: 200px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 70px;
  display: grid;
  justify-items: center;
`
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  color: ${(props) => props.theme.primary};
`
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
`
const Content = styled.div`
  display: flex;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
`
const ContentFormuleLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  width: 50%;
`
const ContentFormuleAction = styled.div`
  float: right;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  @media screen and (max-width: 1150px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) {
    width: 60%;
  }
`
const ContentAction = styled.div`
  float: right;
  @media screen and (max-width: 1150px) {
    width: 60%;
  }
  @media screen and (min-width: 1150px) {
    width: 40%;
  }
`
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin-right: 20px;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`
const ContentFormuleInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  margin: 0px 40px 0px 40px;
  text-align: initial;
`
const ContentFormuleDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin: -5px 40px 0px 40px;
`
const PriceFormuleContainer = styled.div`
  display: flex;
  font-weight: bold;
  align-self: center;
  width: 70%;
  margin: 5px 40px 20px 40px;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const PriceContainer = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  margin-right: 20px;
`
const PromoPriceBox = styled.div`
  display: flex;
  margin-right: 15px;
`
const PromoPrice = styled.div`
  text-decoration: line-through;
`
const PromoNewPrice = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`
const PriceBox = styled.div`
  margin-right: 20px;
`

const CategorieItem = styled.div`
  opacity: ${(props) => props.visible && '0.5'};
`
