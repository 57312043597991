import React, { useState } from 'react'
import { setOptions, Document, Page } from 'react-pdf'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import './style.css'

const pdfjsVersion = '2.0.305'
setOptions({
  workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.js`,
})

const FileViewer = ({ url, fontColor}) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  return (
    <>
      <Document
        className="PDFDocument"
        style={{ width: '100%' }}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page className="PDFPage" pageNumber={pageNumber} />
        {numPages > 1 &&
          <PageNumber>
            Page {pageNumber || (numPages ? 1 : '--')} sur {numPages || '--'}
          </PageNumber>
        }
      </Document>
      {numPages === 1 ? null : (
        <div style={{display: 'flex', zIndex: 9999999}}>
          <PreviousButton
            type="button"
            variant="none"
            fontColor={fontColor}
            disabled={pageNumber <= 1}
            onClick={() => changePage(-1)}
          >
            Précedent
          </PreviousButton>
          <NextButton
            type="button"
            variant="none"
            fontColor={fontColor}
            disabled={pageNumber >= numPages}
            onClick={() => changePage(1)}
          >
            Suivant
          </NextButton>
        </div>
      )}
    </>
  )
}

export default FileViewer

const PageNumber = styled.div`
  width: 100%;
  text-align: end;
  margin-right: 20px;
  font-size: 13pt;
`
const PreviousButton = styled(Button)`
  background-color: ${(props) => props.fontColor ? props.fontColor : '#266347'};
  border-style: none;
  color: white;
  &:hover {
    color: white;
  }
`
const NextButton = styled(Button)`
  background-color: ${(props) => props.fontColor ? props.fontColor : '#266347'};
  margin-left: 10px;
  border-style: none;
  color: white;
  &:hover {
    color: white;
  }
`
