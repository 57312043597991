import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import styled, { withTheme } from 'styled-components'
import { useDocument } from '@nandorojo/swr-firestore'

import { useSnackbar } from 'notistack'
import { General, Multilingual, Hours, Themes } from './view'
import Navbar from '../../../components/UI/navbar/Navbar'
import LangSelector from '../../../components/UI/form/LangSelector/LangSelector'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../stores/AuthContainer'
import { useStorageTools } from '../../../hooks/FireTools'
import PlaceContainer from '../../../stores/PlaceContainer'
import {
  updateMultilangArray,
  getMultilangContentMap,
  getMultilangContent,
} from '../../../utils/MultilangHelpers'
import PlaceFieldInterface from './placeFieldInterface'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const navItems = ['general', 'multilingual', 'hours', 'themes']
const EditAPlace = ({ t, setTitle }) => {
  const [tab, setTab] = useState('general')
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    unregister,
    control,
  } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const [delPicUrl, setDelPicUrl] = useState(false)
  const [saving, setSaving] = useState(false)
  const { user } = AuthContainer.useContainer()
  const { firebaseUploader } = useStorageTools()
  const [placeDatas, setPlaceDatas] = useState(null)
  const location = useLocation()
  const { lang, place_id } = queryString.parse(location.search)
  const submit = useRef(null)
  const { place: placeInfos, updatePlace } = PlaceContainer.useContainer()
  let currentLang = lang || placeInfos?.main_language || 'FR'
  const mlFields = ['place_description']
  useEffect(() => {
    if (placeInfos) {
      setPlaceDatas(placeInfos)
      populateForm(placeInfos)
      if (placeInfos) setTitle(`${placeInfos.place_name}`)
    }
  }, [placeInfos, currentLang])

  const populateForm = (datas) => {
    let mlFieldsDisplay = {}
    mlFields.map((f) => {
      mlFieldsDisplay[f] =
        typeof datas[f] === 'object'
          ? getMultilangContentMap(datas[f], currentLang)
          : getMultilangContent(datas, `${f}_ml`, currentLang)
    })

    reset({
      ...datas,
      ...mlFieldsDisplay,
    })
  }

  const handleSave = async (datas) => {
    setSaving(true)
    console.log('datas==>', datas)

    let place_logo = delPicUrl ? null : placeInfos?.place_logo || null
    if (datas.place_logo_file !== undefined && datas.place_logo_file.size > 0) {
      try {
        const newImages = await firebaseUploader(
          [datas.place_logo_file],
          `users/${user.uid}/places/${place_id}/logo`
        )
        place_logo = newImages[0].url
      } catch (e) {
        enqueueSnackbar('save_image_failed', { variant: 'error' })
      }
    }
    const fieldInterface = new PlaceFieldInterface(
      datas,
      mlFields,
      {},
      'edit',
      placeInfos,
      [...placeInfos.languages_array, placeInfos.main_language],
      currentLang,
      {
        place_logo,
      }
    )

    try {
      await Promise.all([updatePlace(fieldInterface.getFields())])
      enqueueSnackbar(t('place_info_saved_succeed'), { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(t('place_info_saved_failed'), { variant: 'error' })
      return
    }
    setSaving(false)
  }

  const displayView = () => {
    switch (tab) {
      case 'general':
        return (
          <General
            register={register}
            getValues={getValues}
            setValue={setValue}
            placeLogo={placeDatas.place_logo || ''}
            delPicUrl={delPicUrl}
            setDelPicUrl={setDelPicUrl}
            placeInfos={placeInfos}
          />
        )
      case 'multilingual':
        return (
          <Multilingual
            setValues={setValue}
            languagesArray={placeDatas.languages_array}
            main_language={placeDatas.main_language}
            register={register}
            unregister={unregister}
          />
        )
      case 'hours':
        return (
          <Hours
            setValue={setValue}
            unregister={unregister}
            register={register}
            daysFetched={placeDatas.place_hours}
            place_hours_enable={placeDatas.place_hours_enable}
          />
        )
      case 'themes':
        return (
          <Themes placeDatas={placeDatas} register={register} control={control} />
        )
      default:
        return <General />
    }
  }

  return (
    <CustomContainer fluid>
      <div style={{ marginTop: '70px' }}>
        {!placeInfos || !placeInfos.exists ? (
          <>Place does not exists</>
        ) : (
          <form onSubmit={handleSubmit(handleSave)}>
            <Row>
              <Col lg="8">
                <Navbar items={navItems} setTab={setTab} tab={tab} t={t} />
              </Col>
              <CustomCol lg="4" style={{ marginTop: '20px' }}>
                <ShowButton
                  variant="outline"
                  href={`/place/${placeDatas?.place_slug}`}
                  target="_blank"
                >
                  {t('visualize')}
                </ShowButton>
                <SaveButton
                  disabled={saving}
                  variant="secondary"
                  ref={submit}
                  type="submit"
                  style={{
                    marginLeft: '5%',
                    width: '45%',
                    boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  {saving ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    t('save')
                  )}
                </SaveButton>
              </CustomCol>
            </Row>
            <LangSelector placeDatas={placeDatas} lang={lang} id={place_id} />
            <ThemeProvider theme={theme}>
              {placeDatas !== null && displayView()}
            </ThemeProvider>
          </form>
        )}
      </div>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(EditAPlace))

const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const CustomCol = styled(Col)`
  @media (max-width: 991px) {
    margin: 20px;
  }
`
const ShowButton = styled(Button)`
  margin-right: 5%;
  padding-left: 18px;
  padding-right: 31px;
  width: 45%;
  border-style: solid;
  border: 1px;
  background-color: white;
  color: ${(props) => props.theme.primary};
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SaveButton = styled(Button)`
  margin-left: 5%;
  width: 45%;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
