import React, { useState } from 'react'
import styled from 'styled-components'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { OverlayTrigger, Row } from 'react-bootstrap'
import ProductDraggable from './ProductDraggable'
import {
	Folder,
	Remove,
	Add,
	FileCopyOutlined,
	EditOutlined,
	DeleteForeverOutlined
} from '@material-ui/icons'
import Moveicon from '../../../../../assets/menu/move.svg'
import { withTranslation } from 'react-i18next'

const GroupDraggable = ({
	t,
	group,
	unclassed,
	index,
	category_index,
	searchInput,
	functions,
	currentLang,
	register,
}) => {

	const [visible, setVisible] = useState(true)

	return (
		<Draggable draggableId={group.id} index={index} isDragDisabled={unclassed}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.dragHandleProps}
					{...provided.draggableProps}
				>
					{!unclassed && (
						<CustomFlex visible={visible}>
							<div>
								<Flex>
									<CustomImg src={Moveicon} alt="logo" />
									<div style={{ marginTop: '10px', marginLeft: '10px' }}>
										<FolderIcon />
									</div>
									<CustomInput
										placeholder={t('group name')}
										type="text"
										{...register(`name_cat_${group.id}`)}
										onBlur={(e) =>
											functions.editCatTitle(group.name_ml, e.target.value, group.id)
										}
									/>
									<OverlayTrigger
										key="deleteToolbox"
										placement="top"
										delay={{ show: 250, hide: 400 }}
										overlay={
											<Toolbox>
												<Spike />
                      {t('remove')}
											</Toolbox>
										}
									>
										<IconContainer onClick={() => functions.removeCat(group.id)}>
											<DeleteIcon />
										</IconContainer>
									</OverlayTrigger>
								</Flex>
								<ProductNb>
									{group.products?.length} {t('product')}
								</ProductNb>
							</div>
							{visible ?
								<RemoveIcon top onClick={() => setVisible(!visible)} /> :
								<AddIcon top onClick={() => setVisible(!visible)} />
							}
						</CustomFlex>
					)}
					{visible &&
						<Droppable
							droppableId={`CATEGORY-${group.id}`}
							type={`CATEGORY-${category_index}`}
						>
							{(provided, snapshot) => (
								<CustomRow ref={provided.innerRef} unclassed={unclassed}>
									{group.products.map(
										(prod, prod_index) =>
											prod.product_name && prod.product_name.includes(searchInput) && (
												<>
													<ProductDraggable
														key={prod.id}
														index={prod_index}
														category_index={category_index}
														prod={prod}
														group_index={index}
														currentLang={currentLang}
														functions={functions}
													/>
												</>
											)
									)}
									{provided.placeholder}
								</CustomRow>
							)}
						</Droppable>
					}
					{provided.placeholder}
				</div>
			)}
		</Draggable>
	)
}

export default withTranslation()(GroupDraggable)

const Flex = styled.div`
  display: flex;
`
const CustomFlex = styled.div`
	display: flex;
	background-color: #E6EFFF;
	justify-content: space-between;
	padding-left: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-left-radius: ${(props) => !props.visible && '10px'};
	border-bottom-right-radius: ${(props) => !props.visible && '10px'};
`
const CustomRow = styled(Row)`
  background: ${(props) => !props.unclassed && '#E6EFFF'};
	padding: 30px;
	margin: 0px 0px 0px 0px !important;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const ProductNb = styled.div`
  font-size: 13pt;
  font-weight: 400;
  color: #6F4FB3;
  margin-top: ${(props) => props.top && '10px'};
	margin -10px 0px 20px 35px;
`
const RemoveIcon = styled(Remove)`
  width: 25px !important;
  height: 25px !important;
	color: rgba(0, 0, 0, 0.5);
	margin: 10px 10px 0px 0px;
	cursor: pointer;
`
const AddIcon = styled(Add)`
  width: 25px !important;
  height: 25px !important;
	color: rgba(0, 0, 0, 0.5);
	margin: 10px 10px 0px 0px;
	cursor: pointer;
`
const FolderIcon = styled(Folder)`
  color: #6F4FB3;
  width: 25px !important;
  height: 25px !important;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const CustomInput = styled.input`
  background-color: transparent;
	margin: 5px 10px 5px 10px;
	width: 80%;
	height: 40px;
	border-style: none;
`
const CustomImg = styled.img`
  width: 15px;
  margin: 37px 5px 0px 5px;
  cursor: move;
`;

