const checkFilter = (filtersProps, item, search) => {
  let found = false
  filtersProps.map((filter) => {
    if (!item[filter]) return false
    found = item[filter].match(new RegExp(search, 'gi'))
  })
  return found
}

export const arraySearch = (array, keyword, filtersProps) => {
  const searchTerm = keyword.toLowerCase()
  return array.filter((value) => checkFilter(filtersProps, value, keyword))
}
