import React, { useEffect, useState } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { Row, Col } from 'react-bootstrap'
import {
	parseCats,
	retrieveWithMultilangProd,
	retrieveWithMultilangCats,
} from '../../../../../utils/ProductHelpers'
import { Search } from '@material-ui/icons'
import styled, { withTheme } from 'styled-components'
import { arraySearch } from '../../../../../utils/SearchHelpers'
import MainCategorie from './MainCategorie/MainCategorie'
import Product from './Product/Product'
import { useTranslation } from 'react-i18next'
import useProductBaseStore, { withProductBaseStore } from './ProductBaseStore'
import ProductBaseCart from './ProductBaseCart/ProductBaseCart'
import ProductBaseCartToggle from './ProductBaseCart/ProductBaseCartToggle'
import ProductFilters from './ProductFilters/ProductFilters'
import { useHistory, useLocation } from 'react-router-dom'
import { useFirestoreApi } from '../../../../../hooks/FireTools'
import LangSelector from '../../../../../components/UI/form/LangSelector/LangSelector'
import AuthContainer from '../../../../../stores/AuthContainer'
import { useSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'

const category_select = ['all', 'foods', 'drinks', 'others']

const queryString = require('query-string')

const mainCategories = [
	{
		id: 0,
		category_name: 'foods',
		products: [],
		groups: [],
	},
	{
		id: 1,
		category_name: 'drinks',
		products: [],
		groups: [],
	},
	{
		id: 2,
		category_name: 'others',
		products: [],
		groups: [],
	},
]
const availableLangs = ['ES', 'EN', 'CH']

const ProductBase = (props) => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const location = useLocation()
	const history = useHistory()
	const { place_id, lang } = queryString.parse(location.search)
	const { data: products } = useCollection('products-database')
	const { data: productCats } = useCollection('product-cats-database')
	const [categories, setCategories] = useState(mainCategories)
	const [parsedDatas, setParsedDatas] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [catSelect, setCatSelect] = useState('all')
  const [index, setIndex] = useState(0)
	const [search, setSearch] = useState(products)
	const [searchTerm, setSearchTerm] = useState('')
	const { cart, cleanCart, closeCart } = useProductBaseStore.useContainer()
	const [addingProducts, setAddingProducts] = useState(false)
	const { updateDatas } = useFirestoreApi()
	const { user } = AuthContainer.useContainer()
	let currentLang = lang || 'FR'

	const [filters, setFilters] = useState({
		lang: currentLang,
		category: 'all',
		productCat: 'all',
	})

	useEffect(() => {
		if (productCats && products) {
			const parsed = parseCats(
				search || products,
				productCats,
				false,
				categories,
				filters.lang,
				filters.productCat
			)
			setParsedDatas(parsed)
		}
	}, [products, productCats, filters, search])

	const handleOnChange = async (e) => {
		const { value } = e.target

		setSearchInput(value)
		if (value.length > 0) {
			let _search = await arraySearch(
				retrieveWithMultilangProd(products, filters.lang),
				value,
				['product_name']
			)
			setSearch(_search)
		} else {
			setSearch(products)
		}
	}

	const addProductToList = async (items) => {
		setAddingProducts(true)
		await Promise.all(
			items.map(async (item) => {
				const originalProduct = products.find((p) => p.id === item.id)
				const formatedOriginalProduct = {
					...originalProduct,
					place_id,
					uid: user.uid,
					subcat: '',
				}
				delete formatedOriginalProduct.__snapshot
				await updateDatas({
					query: 'products',
					action: 'add',
					datas: { ...formatedOriginalProduct },
				})
			})
		)
		enqueueSnackbar(t('product_copied_to_your_list'), { variant: 'success' })
		setAddingProducts(false)
		cleanCart()
		closeCart()
	}

	return (
		<div>
			<br/>
			<br/>
			{/* <ProductFilters
				retrieveWithMultilangCats={retrieveWithMultilangCats}
				productCats={productCats}
				changeSearchField={handleOnChange}
				setFilters={setFilters}
				filters={filters}
				mainCategories={mainCategories}
				setSearchTerm={setSearchTerm}
			/> */}
			<Background>
				<Row>
					<Col lg="10">
						<SearchInputContainer>
							<SearchIcon />
							<SearchInput
								placeholder="Rechercher un produit"
                onChange={(e) => handleOnChange(e)}
                value={searchInput}
							/>
						</SearchInputContainer>
					</Col>
					<Col lg="2">
						<CatSelect
							defaultValue="all"
							onChange={(e) => {
								if (e.target.value !== '0')
									setIndex(parseInt(e.target.value, 10) - 1)
								setCatSelect(category_select[e.target.value])
							}}
							catSelect
						>
							{category_select.map((cat, i) => (
								<option key={`cat${i}`} value={i}>
									{t(cat)}
								</option>
							))}
						</CatSelect>
					</Col>
				</Row>
			</Background>
			<br/>

			{searchTerm.length > 0 ? (
				<Grid container spacing={2} justifyContent="left" alignItems="center">
					{search &&
						search.map((p) => (
							<Grid key={p.id} item xs={12} md={4} lg={3}>
								<Product product={p} />
							</Grid>
						))}
				</Grid>
			) : (
				parsedDatas.length > 0 &&
				parsedDatas
					.filter(
						(i) => i.category_name === catSelect || catSelect === 'all'
					)
					.map((item) => (
						<MainCategorie products={products} item={item} key={item.id} />
					))
			)}
			<ProductBaseCart
				addProductToList={addProductToList}
				addingProducts={addingProducts}
			/>
			<ProductBaseCartToggle />
		</div>
	)
}

export default withTheme(withProductBaseStore(ProductBase))

const Background = styled.div`
  padding: 20px 40px 20px 40px;
  @media screen and (max-width: 526px) {
    padding: 20px 10px 20px 10px;
  }
  box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
`
const SearchInputContainer = styled.div`
  display: inline-flex;
  width: 100%;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
`
const SearchIcon = styled(Search)`
  color: rgba(0, 0, 0, 0.5);
  width: 20px !important;
  height: 20px !important;
  margin: 10px 0px 0px 20px;
`
const SearchInput = styled.input`
  margin-left: 10px;
  outline: none;
  font-weight: bold;
  width: 85%;
  background: transparent;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  ::placeholder {
    opacity: 0.5;
  }
`
const CatSelect = styled.select`
  cursor: pointer;
  height: 35px;
  width: 80%;
  max-width: 200px;
  text-align: center;
  background: #e6efff;
  padding: 5px;
  margin-right: 8px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  margin-top: 2px;
  ::placeholder {
    opacity: 0.5;
  }
`