import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import styled, { withTheme } from 'styled-components'
import { ArrowForward, FileCopy } from '@material-ui/icons'
import QRCodeStyling from 'qr-code-styling'
import { useSnackbar } from 'notistack'

import { ChromePicker } from 'react-color'
import QRcodeIcon from '../../../../assets/qrcode/qrcode-icon.svg'
import CheckIcon from '../../../../assets/qrcode/coche.svg'
import QrcodeImage from '../../../../assets/qrcode/qrcode-mm.jpg'

import { getFileFromBlob } from '../../../../utils/ImageHelpers'
import { useStorageTools } from '../../../../hooks/FireTools'

const customQrCode = new QRCodeStyling({
  width: 250,
  height: 250,
  margin: 20,
  // type: "canvas",
  image: '',
  dotsOptions: {
    color: '#000000',
    type: 'rounded',
  },
  cornersSquareOptions: {},
  imageOptions: {
    crossOrigin: 'anonymous',
    // saveAsBlob: true,
    // hideBackgroundDots: true,
    margin: 3,
    imageSize: 0.4,
  },
})

const downloadQrCode = new QRCodeStyling({
  width: 600,
  height: 600,
  margin: 0,
  image: '',
  dotsOptions: {
    color: '#000000',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 3,
    imageSize: 0.4,
  },
})

const qrCode = new QRCodeStyling({
  width: 250,
  height: 250,
  margin: 20,
  image: '',
  dotsOptions: {
    color: '#000000',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 3,
    imageSize: 0.4,
  },
})

const styles = ['square', 'dots', 'rounded', 'classy-rounded']

const openInNewTab = () => {
  const newWindow = window.open('https://monmenu360.fr/nos-supports-impression/')
  if (newWindow) newWindow.opener = null
}

const Pages = ({ t, place, menus, updatePlace, user }) => {
  const [menuSelected, setMenuSelected] = useState('main')
  const [url, setUrl] = useState(
    `https://app.monmenu360.fr/place/${place && place.place_slug}`
  )
  const { firebaseUploader } = useStorageTools()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const ref = useRef(null)
  const customRef = useRef(null)
  const downloadRef = useRef(null)

  const [imageFile, setImageFile] = useState(null)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [picUrl, setPicUrl] = useState(
    place?.place_qrcode ? place.place_qrcode.image : ''
  )
  const [themeStyle, setThemeStyle] = useState(
    place?.place_qrcode ? place.place_qrcode.style : 'square'
  )
  const [themeColor, setThemeColor] = useState(
    place?.place_qrcode ? place.place_qrcode.color : '#000000'
  )

  const handleSaveQrCode = async () => {
    let datas = {
      place_qrcode: {
        style: themeStyle,
        color: themeColor,
        image: place?.place_qrcode ? place.place_qrcode.image || '' : '',
      },
    }

    if (imageFile !== null && imageFile.size > 0) {
      try {
        const uploaded = await firebaseUploader(
          [imageFile],
          `users/${user.uid}/places/${place.id}/qrcodes`,
          ['qrcode_main_image']
        )
        datas.place_qrcode.image = uploaded[0].url
      } catch (e) {
        console.log(e)
      }
    }

    try {
      updatePlace(datas, { merge: true })

      enqueueSnackbar(t('qrCode_saved_success'), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(t('error_saving_qrcode'), { variant: 'error' })
    }
  }

  useEffect(() => {
    qrCode.update({
      image: picUrl,
      dotsOptions: {
        color: themeColor,
        type: themeStyle,
      },
      cornersSquareOptions: {
        type:
          themeStyle === 'rounded'
            ? 'extra-rounded'
            : themeStyle === 'dots'
            ? 'rounded'
            : null,
      },
    })
    downloadQrCode.update({
      image: picUrl,
      dotsOptions: {
        color: themeColor,
        type: themeStyle,
      },
      cornersSquareOptions: {
        type:
          themeStyle === 'rounded'
            ? 'extra-rounded'
            : themeStyle === 'dots'
            ? 'rounded'
            : null,
      },
    })
    qrCode.append(ref.current)
    customQrCode.append(customRef.current)
    downloadQrCode.append(downloadRef.current)
  }, [place])

  useEffect(() => {
    qrCode.update({ data: url })
    customQrCode.update({ data: url })
    downloadQrCode.update({ data: url })
  }, [url])

  useEffect(() => {
    customQrCode.update({
      image: picUrl,
      dotsOptions: {
        color: themeColor,
        type: themeStyle,
      },
      cornersSquareOptions: {
        type:
          themeStyle === 'rounded'
            ? 'extra-rounded'
            : themeStyle === 'dots'
            ? 'rounded'
            : null,
      },
    })
  }, [picUrl, themeStyle, themeColor])

  const onUrlChange = (value) => {
    setMenuSelected(value)
    if (value === 'main')
      setUrl(`https://app.monmenu360.fr/place/${place && place.place_slug}`)
    else
      setUrl(
        `https://app.monmenu360.fr/place/${place && place.place_slug}/menu/${value}`
      )
  }

  const onDownloadClick = () => {
    downloadQrCode.download({ extension: 'png' })
  }

  const displayQrcode = (title) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <>
        <Card bsPrefix="card white-card" style={{ display: !isOpen && 'none' }}>
          <Background>
            <BackgroundSub>
              <input
                style={{ width: '100%', margin: '15px 0px 20px 15px' }}
                className="hidden"
                onChange={(event) => {
                  const { target } = event
                  if (target.value.length > 0) {
                    setImageFile(event.currentTarget.files[0])
                    setPicUrl(URL.createObjectURL(event.currentTarget.files[0]))
                  } else setPicUrl('')
                }}
                accept="image/*"
                type="file"
              />
              <BackgroundTitle>
                {t('style')}
              </BackgroundTitle>
              <StyleSelect
                value={themeStyle}
                onChange={(e) => setThemeStyle(e.target.value)}
              >
                {styles.map((style, i) => (
                  <option key={`style-${i}`} value={style}>
                    {style}
                  </option>
                ))}
              </StyleSelect>
              <BackgroundTitle>
                {t('color')}
              </BackgroundTitle>
              {displayColorPicker ? (
                <div style={{ position: 'absolute', zIndex: '2' }}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setDisplayColorPicker(false)}
                  />
                  <ChromePicker
                    color={themeColor}
                    onChange={(e) => setThemeColor(e.hex)}
                  />
                </div>
              ) : (
                <ColorInput
                  onClick={() => setDisplayColorPicker(true)}
                  defaultValue="#00000"
                  value={themeColor}
                  placeholder={t('color')}
                />
              )}
            </BackgroundSub>
            <QrcodeContainer>
              <div ref={customRef} />
            </QrcodeContainer>
          </Background>
          <BottomBar>
            <Button
              variant="primaryoutline"
              style={{
                marginTop: '20px',
                marginLeft: '5px',
                width: '40%',
                boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
              }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              style={{
                marginTop: '20px',
                marginLeft: '5px',
                width: '40%',
                boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
              }}
              onClick={() => {
                handleSaveQrCode()
                setIsOpen(false)
              }}
            >
              {t('save')}
            </Button>
          </BottomBar>
        </Card>
        <Card
          bsPrefix="card white-card"
          style={{
            opacity: isOpen && '0.5',
            pointerEvents: isOpen && 'none',
            cursor: isOpen && 'default',
          }}
        >
          <Title>{title}</Title>
          <FlexPointer
            onClick={() => {
              navigator.clipboard.writeText(
                `https://app.monmenu360.fr/place/${place && place.place_slug}`
              )
              enqueueSnackbar('Lien copié dans votre presse-papier', {
                variant: 'success',
              })
            }}
          >
            <Link>
              {`https://app.monmenu360.fr/place/${place && place.place_slug}`}
              <CopyIcon/>
            </Link>
          </FlexPointer>
          <Background>
            <BackgroundSub>
              <BackgroundTitle>
                {t('display page')}
              </BackgroundTitle>
              <MenuSelect
                value={menuSelected}
                onChange={(e) => onUrlChange(e.target.value)}
              >
                <option key="menu-main" value="main">
                  {t('main page')}
                </option>
                {menus &&
                  menus.map((menu, i) => (
                    <option key={`menu-${i}`} value={menu.id}>
                      {
                        menu.name_ml.find((x) => x.lang === place.main_language)
                          .content
                      }
                    </option>
                  ))}
              </MenuSelect>
              <Button
                variant="outline"
                style={{
                  marginTop: '30px',
                  marginLeft: '5px',
                  width: '95%',
                  color: '#6F4FB3',
                  backgroundColor: 'white',
                  boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                }}
                onClick={() => setIsOpen(true)}
              >
                {t('qrcode personalize')}
              </Button>
              <Button
                variant="primary"
                style={{
                  marginTop: '20px',
                  marginLeft: '5px',
                  width: '95%',
                  boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                }}
                onClick={() => onDownloadClick()}
              >
                {t('qrcode download')}
              </Button>
            </BackgroundSub>
            <QrcodeContainer>
              <div ref={ref} />
            </QrcodeContainer>
          </Background>
        </Card>
      </>
    )
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="8">
            <Card bsPrefix="card white-card">
              <JustifyFlex>
                <QRTitle>1 {t('avalaible qr code')}</QRTitle>
                <QRSubTitle onClick={() => history.push('/dashboard/subscriptions')}>
                  {t('setup subscriptions')}
                  <ArrowForward
                    style={{
                      width: '20px',
                      height: '17px',
                      color: '#E76B56',
                      marginRight: '5px',
                      marginTop: '-2px',
                    }}
                  />
                </QRSubTitle>
              </JustifyFlex>
            </Card>
            {displayQrcode(t('main page'))}
            {/*
            <Button
            variant="secondary"
            style={{
              marginTop: '10px',
              width: '100%',
              borderRadius: '12px',
              marginBottom: '20px',
              boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
            }}
            onClick={() => console.log('yes')}
            >
              {t('qrcode buy')}
            </Button> */}
          </Col>
          <Col lg="4">
            {/* <CardDeskOnly 
              style={{
                background: 'linear-gradient(270deg, #E76B56 -50%, #6F4FB3 80%, #6F4FB3 20%)',
                margin: '17px 0',
                padding: '20px',
                alignItems: 'center',
              }}
            > 
              <CardTitle style={{ color: 'white' }}>Ajouter des QR codes</CardTitle>
              <FadeDiv>
                <Flex style={{ marginTop: '5%', justifyContent: 'center' }}>
                  <Img src={QRcodeIcon} style={{ marginRight: '20px' }} alt="logo" />
                  <FitContent>
                    <Flex>
                      <Price>
                        10
                      </Price>
                      <SubPrice>
                        <div style={{ fontSize: '15pt', color: 'white' }}>
                          €
                        </div>
                        <div style={{ fontSize: '11pt', color: 'white' }}>
                          HT / mois
                        </div>
                      </SubPrice>
                    </Flex>
                    <NoEngagment>
                      <img
                        src={CheckIcon}
                        alt="logo"
                        style={{
                          clear: 'both',
                          marginBottom: '5px',
                          marginRight: '5px',
                        }}
                      />
                      Sans engagement
                    </NoEngagment>
                  </FitContent>
                </Flex>
              </FadeDiv>
              <Button
                onClick={() => console.log('yes')}
                style={{
                  width: '100%',
                  color: '#6F4FB3',
                  borderStyle: 'none',
                  backgroundColor: 'white',
                  fontSize: '11pt',
                  boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                }}
              >
                Acheter des QR codes
              </Button>
            </CardDeskOnly> */}
            <CardDeskOnly
              style={{
                backgroundColor: '#EBEAFF',
                margin: '17px 0',
                padding: '20px',
                alignItems: 'center',
              }}
            >
              <CardTitle>
                {t('need to print qr')}
                <br />
                {t('need to print qr2')}
              </CardTitle>
              <img
                src={QrcodeImage}
                alt="logo"
                style={{
                  clear: 'both',
                  maxWidth: '250px',
                  maxHeight: '250px',
                }}
              />
              <Button
                variant="none"
                onClick={() => openInNewTab()}
                style={{
                  width: '100%',
                  color: '#6F4FB3',
                  borderStyle: 'none',
                  backgroundColor: 'white',
                  fontSize: '11pt',
                  boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                }}
              >
                {t('discover supports')}
              </Button>
            </CardDeskOnly>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(Pages))

const Flex = styled.div`
  display: flex;
`
const CustomModal = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #eceaff;
  border-radius: 20px;
`
const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 10px 40px 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
const ColorInput = styled.input`
  width: 90%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  margin: 0px 0px 0px 10px;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const FitContent = styled.div`
  height: fit-content;
`
const Price = styled.div`
  font-size: 39pt;
  height: fit-content;
  color: white;
  line-height: 1;
`
const SubPrice = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const NoEngagment = styled.div`
  color: white;
  font-size: 10pt;
  font-style: italic;
`
const FlexPointer = styled.div`
  display: flex;
  width: 100%;
`
const JustifyFlex = styled.div`
  display: flex;
  justify-content: space-between;
`
const CardDeskOnly = styled(Card)`
  @media screen and (max-width: 991px) {
    display: none;
  }
`
const CopyIcon = styled(FileCopy)`
  cursor: copy;
  width: 20px !important;
  height: 17px !important;
  color: black;
  opacity: 0.4;
  margin-left: 10px;
  margin-top: 5px;
`
const QrcodeContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7px;
  background-color: white;
  text-align: center;
  @media screen and (max-width: 646px) {
    display: none;
  }
`
const CardTitle = styled.div`
  font-size: 14pt;
  text-align: -webkit-center;
  width: 70%;
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`
const Background = styled.div`
  background-color: rgba(230, 239, 255);
  padding: 20px;
  border-radius: 10px;
  display: flex;
`
const BackgroundTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 12pt;
`
const BackgroundSub = styled.div`
  width: 200%;
  @media screen and (min-width: 646px) {
    margin-right: 20px;
  }
`
const Link = styled.div`
  text-decoration: underline;
  cursor: copy;
  width: 100%;
  color: ${(props) => props.theme.primary};
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 8px;
`
const MenuSelect = styled.select`
  background-color: white;
  cursor: pointer;
  width: 95%;
  padding: 5px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  margin-left: 6px;
  margin-right: 60px;
  margin-bottom: 15px;
  ::placeholder {
    opacity: 0.5;
  }
`
const StyleSelect = styled.select`
  background-color: white;
  cursor: pointer;
  width: 90%;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  margin-left: 6px;
  margin-right: 60px;
  margin-bottom: 15px;
  ::placeholder {
    opacity: 0.5;
  }
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`
const QRTitle = styled.div`
  font-size: 14pt;
  margin-left: 10px;
`
const QRSubTitle = styled.div`
  font-size: 11pt;
  color: #e76b56;
  margin-top: 4px;
  cursor: pointer;
`
