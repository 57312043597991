import React, { useState } from 'react'
import { ListGroup, Row, Col, Button, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { DeleteForeverOutlined } from '@material-ui/icons'
import useStripe from '../../../../hooks/Stripe'

const PaymentMethod = ({
	setUpdating,
	updating,
	type,
	card,
	created,
	current,
	placeId,
	t,
	isTrial,
	id,
	setPaymentMethodChoosed,
	removeMethod,
}) => {
	const { updatePaymentMethod, detachPaymentMethod } = useStripe()
	const { enqueueSnackbar } = useSnackbar()
	const [removingMethod, setRemovingMethod] = useState(false)
	const handleUpdatePaymentMethod = async (placeId, id) => {
		setUpdating(true)

		const update = await updatePaymentMethod(placeId, id)

		if (update.data.status === 'success') {
			enqueueSnackbar(t('update_payment_method_succeed'), { variant: 'success' })
		}
		if (update.data.status === 'error') {
			enqueueSnackbar(t('update_payment_method_failed'), { variant: 'error' })
		}
		setUpdating(false)
	}

	const _detachPaymentMethod = async (pm) => {
		setRemovingMethod(true)
		const _datas = await detachPaymentMethod({ pm })
		const { status, datas, error } = _datas.data
		if (status === 'success') {
			enqueueSnackbar(t('payment_method_removed_successfully'), {
				variant: 'success',
			})
			removeMethod(datas.id)
		}
		if (status === 'error') {
			enqueueSnackbar(t('payment_method_removed_failed'), { variant: 'error' })
		}
		setRemovingMethod(false)
	}

	let createdAt = new Date(created * 1000)
	createdAt = `${createdAt.getDate()}/${createdAt.getMonth()}/${createdAt.getFullYear()}`
	return (
		<ListPayment current={current} updating={updating}>
			<Row>
				<CardType>{card?.brand || ''}</CardType>
				<CardNum>
					{'**** **** **** '}
					{card?.last4 || ''}
				</CardNum>
				<Col>
					{current ? (
						<Button disabled>{t('current_payment_method')}</Button>
					) : (
						<>
							<Button
								style={{ margin: '4px' }}
								disabled={updating}
								onClick={() => {
									if (!isTrial) {
										handleUpdatePaymentMethod(placeId, id)
									} else {
										setPaymentMethodChoosed(id)
									}
								}}
							>
								{t('select_this_payment_method')}
							</Button>{' '}
							<Button
								disabled={removingMethod}
								onClick={() => _detachPaymentMethod(id)}
								style={{ margin: '4px' }}
							>
								{removingMethod ? (
									<Spinner animation="border" size="sm" variant="light" />
								) : (
									<DeleteForeverOutlined />
								)}
							</Button>
						</>
					)}
				</Col>
			</Row>
			<Row>
				<CardExpire>
					{'exp : '}
					{card?.exp_month || ''}/{card?.exp_year || ''}
				</CardExpire>
				<CreatedAt>Créé le :{createdAt}</CreatedAt>
				<Col />
			</Row>
		</ListPayment>
	)
}

export default withTranslation()(PaymentMethod)

const CardType = styled(Col)``
const CardExpire = styled(Col)``
const CardNum = styled(Col)``
const CreatedAt = styled(Col)``
const ListPayment = styled(ListGroup.Item)`
	border: ${(props) => props.current && `2px solid ${props.theme.primary}`};
	opacity: ${(props) => props.current && `0.6`};
`
