import {
	updateMultilangArray,
	getMultilangContent,
	createMultilangArray,
} from '../../../utils/MultilangHelpers'

class PlaceFieldsInterface {
	constructor(
		datas,
		multilang_fields = [],
		multilang_fields_map = {},
		action = 'create',
		oldDatas,
		langs = [],
		currentLang = 'FR',
		specialDatas = {}
	) {
		console.log('data---------------->s', datas)
		this.multilang_fields = multilang_fields
		this.currentLang = currentLang
		this.langs = langs
		this.oldDatas = oldDatas
		this.action = action
		this.fields = {}
		this.fields.place_name = datas?.place_name || ''
		this.fields.place_phone = datas?.place_phone || ''
		this.fields.place_email = datas?.place_email || ''
		this.fields.socials = datas?.socials
		this.fields.place_address = datas?.place_address || ''
		this.fields.main_language = datas?.main_language || 'FR'
		this.fields.languages_array = Object.values(datas?.languages_array || [])
		this.fields.place_hours = datas?.place_hours || []
		this.fields.place_hours_enable = datas?.place_hours_enable || false
		this.fields.place_theme = datas?.place_theme || 'light'
		this.fields.theme_font = datas?.theme_font || 'Arial'
		this.fields.theme_darkmode = datas?.theme_darkmode || false
		this.fields.theme_color = datas?.theme_color || '#356396'
		this.fields.theme_topbanner = datas?.theme_topbanner || false

		if (multilang_fields.length > 0) {
			// adding multilanguage fields
			multilang_fields.map((f) => {
				if (typeof datas[f] === 'object') {
					this.fields[f] = this._mappingCheckMultilang(
						datas[f] || {},
						f,
						multilang_fields_map[f]
					)
				} else {
					this.fields[`${f}_ml`] =
						action === 'edit'
							? updateMultilangArray(datas[f], oldDatas[`${f}_ml`], currentLang)
							: createMultilangArray(langs, currentLang, datas[f])
				}
			})
		}
		console.log('this.fields', this.fields)
		this.fields = { ...specialDatas, ...this.fields }
	}

	_mappingCheckMultilang(datas, field, multilang_fields) {
		let _newFields = {}
		const _multilang_fields = new Set(multilang_fields)
		Object.keys(datas).map((key) => {
			if (_multilang_fields.has(key)) {
				_newFields[`${key}_ml`] =
					this.action === 'edit'
						? updateMultilangArray(
								datas[key],
								this.oldDatas[field][`${key}_ml`],
								this.currentLang
						  )
						: createMultilangArray(this.langs, this.currentLang, datas[key])
			} else {
				_newFields[key] = datas[key] || ''
			}
		})
		return _newFields
	}

	getFields() {
		return this.fields
	}
}
export default PlaceFieldsInterface
