import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';

import {
  Close,
  SubdirectoryArrowRight,
} from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

const EditCategory = ({
 t, create, index, subIndex, modalOpen, toggleModal, menus, setMenus,
 }) => {
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');

  useEffect(() => {
    if (create) {
      setGroupName('');
      setGroupDescription('');
    } else {
      setGroupName(menus[index].included_products[subIndex].group_name);
      setGroupDescription(menus[index].included_products[subIndex].group_description);
    }
  }, [modalOpen]);

  const handleSubmit = () => {
    let tmp = menus;
    if (create) {
    tmp[index].included_products.push({
            id: `group-${menus[index].included_products.length + 1}`,
            group_name: groupName,
            group_description: groupDescription,
            products: [],
          });
    } else {
    tmp[index].included_products[subIndex] = {
            id: `group-${menus[index].included_products[subIndex].group_id}`,
            group_name: groupName,
            group_description: groupDescription,
            products: menus[index].included_products[subIndex].products,
          };
    }
    setMenus(tmp);
    toggleModal(false);
  };

  const HeaderRender = () => (
    <>
      <Flex style={{ padding: '10px 0px 0px 20px' }}>
        <ContentLeftContainer>
          <ContentTitle>
            {groupName === '' ? 'Nom de la catégorie' : groupName}
          </ContentTitle>
        </ContentLeftContainer>
        <div style={{ float: 'right' }}>
          <IconContainer
               onClick={() => toggleModal(false)}
          >
            <CloseIcon />
          </IconContainer>
        </div>
      </Flex>
      <div style={{ margin: '-20px 0px 0px 40px' }}>
        <Flex style={{ marginBottom: '10px' }}>
          <ArrowRight />
          <GroupTitle>
            Catégorie simple
          </GroupTitle>
        </Flex>
        <GroupDescription style={{ width: '450px' }}>
          {groupDescription}
        </GroupDescription>
      </div>
    </>
    );

  return (
    <CustomModal show={modalOpen} onHide={toggleModal}>
      <ButtonContainer>
        {HeaderRender()}
      </ButtonContainer>
      <BackgroundTransparent>
        <div style={{ display: 'inline', width: '100%' }}>
          <NameInput
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder={t('menu name')}
          />
          <AreaInput
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
            placeholder={t('menu description')}
          />
        </div>
      </BackgroundTransparent>
      <br />
      <BottomBar>
        <CancelButton
            variant="primaryoutline"
            href="#"
            onClick={() => toggleModal(false)}
        >
          {t('cancel')}
        </CancelButton>
        <SaveButton
            variant="primary"
            href="#"
            onClick={() => handleSubmit()}
        >
          {create ? t('validate') : t('save')}
        </SaveButton>
      </BottomBar>
    </CustomModal>
  );
};

export default withTheme(withTranslation()(EditCategory));

const Flex = styled.div`
  display: flex;
`;
const NameInput = styled.input`
	width: 100%;
	outline: none;
  padding: 8px;
	font-size: 11pt;
	border-style: none;
  padding-left: 10px;
	border-radius: 5px;
	::placeholder {
		opacity: 0.5;
	}
`;
const AreaInput = styled.textarea`
	resize: none;
	outline: none;
	width: 100%;
  height: 204px;
  padding: 5px;
  padding-left: 10px;
	font-size: 11pt;
	margin-top: 20px;
	border-style: none;
	border-radius: 5px;
	::placeholder {
		opacity: 0.5;
	}
`;
const ButtonContainer = styled.div`
  padding-bottom: 10px;
  background-color: #F8F7FF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const CustomModal = styled(Modal)`
  width: 100%;
  z-index: 10000;
  .modal-dialog {
    max-width: 80%;
  }
  .modal-content {
    background-color: #ECEAFF;
    border-radius: 20px;
  }
`;
const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0px 40px 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
const CancelButton = styled(Button)`
  width: 150px;
  height: 45px;
  padding-top: 10px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const SaveButton = styled(Button)`
  width: 200px;
  height: 45px;
  padding-top: 10px;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const CloseIcon = styled(Close)`
  color: ${(props) => props.theme.primary};
`;
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`;
const IconContainer = styled.div`
  background-color: #F8F7FF;
  float: right;
  position: relative;
  cursor: pointer;
  margin: 15px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;
const BackgroundTransparent = styled.div`
  margin: 20px 20px 10px 20px;
  background-color: #E6EFFF;
  text-align: center;
  border-radius: 7px;
`;
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
	width: 90%;
`;
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 250px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`;
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
	color: ${(props) => props.theme.primary};
`;
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
`;
