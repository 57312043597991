import styled from 'styled-components'
import EventIcon from '@material-ui/icons/Event'
import { useState } from 'react'
import PDFViewer from './pdfViewer'
import ContactLogo from '../../Contact/ContactLogo'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'

const HeaderPDFCard = ({ t, menu, menuInfo, place, lang, datas }) => {
  const [isOpen, setIsOpen] = useState(false)
  const img =
    'https://www.cafedelapaix.fr/assets/uploads/2021/06/cafe-de-la-paix-jerome-galland-2-960x640.jpg'

  return (
    <>
      <CardStyled color={datas.color}>
        <HeadingReservation
          style={{ display: !datas.topbanner && 'none' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <HeadingReservationAnimationContainer
            style={{ transform: isOpen && 'translate3d(0, 0, 0)' }}
          >
            <HeadingReservationInformation>
              <HeadingReservationInformationTitle>
                Réservation
              </HeadingReservationInformationTitle>
              <HeadingReservationInformationDesc>
                Réservation uniquement par téléphone au
              </HeadingReservationInformationDesc>
              <HeadingReservationInformationNumber>
                {place.place_phone && place.place_phone.match(/.{1,2}/g).join(' ')}
              </HeadingReservationInformationNumber>
            </HeadingReservationInformation>
            <HeadingReservationIconButton>
              <EventIcon
                style={{ color: 'white', marginRight: '9px', fontSize: '20px' }}
              />
              Réserver
            </HeadingReservationIconButton>
          </HeadingReservationAnimationContainer>
        </HeadingReservation>
        {menuInfo.background_image_url ? 
          <ImgCardStyled 
            src={menuInfo.background_image_url} 
            alt="restaurant"
          /> :
          <NoImgCardStyled 
            color={datas.color} 
            alt="restaurant"
          />
        }
        <InformationHandler>
          <PDFViewer
            url={getMultilangContent(menu, 'pdf_url_ml', lang)}
            fontColor={datas.color}
          />
          <InformationTitle>
            {getMultilangContent(menu, 'name_ml', lang)}
          </InformationTitle>
          <InformationDescription>
            {getMultilangContent(menu, 'description_ml', lang)}
          </InformationDescription>
          <ContactLogo
            place={place}
            fontColor={datas.color}
            isWhite={!!datas.darkmode}
          />
        </InformationHandler>
      </CardStyled>
    </>
  )
}

export default HeaderPDFCard
const HeadingReservationAnimationContainer = styled.div`
  display: flex;
  transform: translate3d(0, calc(32px - 100%), 0);
  transition: transform 0.25s ease-in-out;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
`

const HeadingReservationIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 13px;
  padding-right: 13px;
  font: normal normal normal 16px Lato;
  width: 113px;
  background-color: #276347;
  border-radius: 0px 0px 15px 15px;
`

const HeadingReservationInformationTitle = styled.div`
  font: normal normal bold 25px Lato;
  margin-top: 40px;
  margin-bottom: 10px;
`
const HeadingReservationInformationDesc = styled.div`
  font: normal normal normal 15px Lato;
  margin-bottom: 13px;
`
const HeadingReservationInformationNumber = styled.div`
  font: normal normal bold 20px Lato;
  margin-bottom: 30px;
`

const HeadingReservationInformation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #276347;
`

const HeadingReservation = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  text-align: center;
  overflow: hidden;
  width: 100%;
`

const ImgCardStyled = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const NoImgCardStyled = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: ${(props) => props.color && props.color};
`

const InformationTitle = styled.div`
font: bold 25px;
margin-top 30px;
margin-bottom: 20px;
`

const InformationHandler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  padding: 60px 0px 30px 0px;
  justify-content: center;
  align-items: center;
`

const InformationDescription = styled.p`
  text-align: center;
  font-size: 15px;
  width: 412px;
  font-weight: normal;
  margin-bottom: 40px;
  line-height: 1.2em;
`
const CardStyled = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  font-weight: bold;
  background-color: #276347;
  ${HeadingReservationInformation} {
    background: ${(props) => props.color && props.color};
  }
  ${HeadingReservationIconButton} {
    background: ${(props) => props.color && props.color};
  }
`
