import React from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import { OverlayTrigger } from 'react-bootstrap';

import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import {
  CloseOutlined,
  InfoOutlined,
  ExitToApp,
  RestaurantMenu,
  Assistant,
  Share,
} from '@material-ui/icons';

import LogoLongLight from '../../../assets/logo/logo_long_light.svg';
import LogoLight from '../../../assets/logo/logo_light.svg';

import DisplayContainer from '../../../stores/DisplayContainer';

const queryString = require('query-string')

const EditLeftMenu = ({ setMenuItems, items, t }) => {
  const location = useLocation()
  const { place_id } = queryString.parse(location.search)
  const { dashboardMenuShow, setDashboardMenuShow } = DisplayContainer.useContainer();
  const handleClick = (item) => {
    const _items = [...items];
    items.forEach((curr, i) => {
      if (item.id === curr.id && item.childs) {
      _items[i].showChild = true;
      } else {
      _items[i].showChild = false;
      }
    });
    setMenuItems(_items);
  };

  const displayImg = (icon) => {
    switch (icon) {
      case 'back':
        return (
          <ExitToApp style={{
                width: '2rem',
                height: '2rem',
                marginBottom: '20px',
                marginRight: '10px',
                transform: 'rotate(180deg)',
              }}
          />
);
      case 'share':
        return <Share style={{ width: '2rem', height: '2rem' }} />;
      case 'info':
        return <InfoOutlined style={{ width: '2rem', height: '2rem' }} />;
      case 'menu':
        return <RestaurantMenu style={{ width: '2rem', height: '2rem' }} />;
      case 'help':
        return <Assistant style={{ width: '2rem', height: '2rem' }} />;
      default:
        return <ExitToApp style={{ width: '2rem', height: '2rem' }} />;
    }
  };

  return (
    <LeftMenuStyled show={dashboardMenuShow}>
      <LeftMenuStyledNav show={dashboardMenuShow}>
        <HeaderStyled>
          <CloseMenu onClick={() => setDashboardMenuShow(false)} />
          <CustomImg src={LogoLongLight} alt="logo" style={{ clear: 'both', marginLeft: '10px' }} />
          <CustomImgLight src={LogoLight} alt="logo" style={{ width: '60px' }} />
        </HeaderStyled>
        <MenuItems>
          <br />
          {items.map((item, i) => (
            <OverlayTrigger
                key={`tab${i}`}
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(
                  <Toolbox>
                    <Spike />
                    {t(item.label)}
                  </Toolbox>
                )}
            >
              <MenuItem key={`menu_item${i}`} show={item.showChild}>
                <NavLink
                    onClick={() => {
                      setDashboardMenuShow(false);
                      handleClick(item);
                    }}
                    exact={!item.childs}
                    to={{ pathname: item.path, search: `?place_id=${item.place_id || ''}` }}
                >
                  {displayImg(item.icon)}
                </NavLink>
              </MenuItem>
            </OverlayTrigger>
          ))}
        </MenuItems>
        <BottomMenu>
          <BottomItem>
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(
                  <Toolbox style={{
                    marginLeft: '-15px',
                    color: '#E76B56',
                  }}
                  >
                    <Spike />
                    {t('share')}
                  </Toolbox>
                )}
            >
              <NavLinkStyled>
                <NavLink 
                  onClick={() => {
                    setDashboardMenuShow(false);
                  }}
                  to={`/dashboard/edit/qrcode?place_id=${place_id}`}
                >
                  {displayImg('share')}
                </NavLink>
              </NavLinkStyled>
            </OverlayTrigger>
          </BottomItem>
          <Arrow>
            <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={(
              <Toolbox style={{
                marginTop: '-10px',
                marginLeft: '-15px',
              }}
              >
                <Spike />
                {t('exit')}
              </Toolbox>
            )}
            >
              <BackLinkStyled>
                <NavLink
                  onClick={() => setDashboardMenuShow(false)}
                  to="/dashboard"
                >
                  {displayImg('back')}
                </NavLink>
              </BackLinkStyled>
            </OverlayTrigger>
          </Arrow>
        </BottomMenu>
      </LeftMenuStyledNav>
    </LeftMenuStyled>
  );
};

export default withTranslation()(withTheme(EditLeftMenu));

const CloseMenu = styled(CloseOutlined)`
  @media(min-width  : 991px){
      display : none !important; 
     }
    float: right;
    position: relative;
    right: 15px;
    font-size: 36px;
    color: white;
    cursor: pointer;
    &:hover{
      color : ${(props) => props.theme.secondary} ;
    }
`;
const MenuItem = styled.li`
.accordion {
  button {
    border: none;
    background: none;
  }
}
position : relative; 
margin-bottom : 15px; 
margin-left: 13px;
list-style : none;
text-align: center;
a{
  svg: * {
    fill: white !important;
  }
  transition : ease  400ms ;
  color : ${(props) => (props.show ? (props) => props.theme.primary : 'white')} ; 
   background : ${(props) => (props.show ? 'white' : 'transparent')}; 
  text-decoration  : none ; 
    display : inline-block ;
    height: 50px;
    border-radius: 12px 12px 12px 12px;
    line-height: 45px;
    padding: 0px 0px 0px 10px;
    margin: 0px 23px 0px 0px;
    svg {
      margin-right: 9px;
        color : ${(props) => (props.show ? (props) => props.theme.secondary : 'white')} ; 
    }
    &:hover{
      background-color: rgba(220, 220, 220, 0.2);
      color : ${(props) => props.theme.primary} ;
      svg {
      color : ${(props) => props.theme.secondary} ;
    }
  }
  &.active {
    svg: * {
      fill: white !important;
    }
    background : white ; 
    display : inline-block ; 
      color : ${(props) => props.theme.primary} ;
    svg {
      color : ${(props) => props.theme.secondary} ;
    }
  }
}
`;
const NavLinkStyled = styled.div`
.accordion {
  button {
    border: none;
    background: none;
  }
}
position : relative; 
margin-bottom : -15px; 
margin-left: 13px;
list-style : none;
text-align: center;
a{
  color: white;
  svg: * {
    fill: white !important;
  }
  transition : ease  400ms ;
  color : ${(props) => (props.show ? (props) => props.theme.primary : (props) => props.theme.primary)} ; 
   background : ${(props) => (props.show ? (props) => props.theme.primary : 'transparent')}; 
  text-decoration  : none ; 
    display : inline-block ;
    height: 50px;
    border-radius: 12px 12px 12px 12px;
    line-height: 45px;
    padding: 0px 0px 0px 10px;
    margin: 0px 23px 0px 0px;
    svg {
      margin-right: 11px;
        color : ${(props) => (props.show ? (props) => props.theme.secondary : (props) => props.theme.primary)} ; 
    }
    &:hover{

      color : ${(props) => props.theme.primary} ;
      svg {
      color : ${(props) => props.theme.secondary} ;
    }

  }
  background : white ; 
  &.active {
    background : #E76B56 ; 
    display : inline-block ; 
      color : ${(props) => props.theme.primary} ;

    svg {
      color : white ;
    }
  }
}
`;
const BackLinkStyled = styled.div`
a{
  svg {
    fill: white;
  }
    &:hover{
      svg {
      fill : ${(props) => props.theme.secondary} ;
    }
  }
}
`;
const MenuItems = styled.ul`
  position: absoltute;
  top: 0;
  margin : 0 ; 
  padding : 0px ; 
  margin-left : 0px ; 
  margin-bottom: 200px;
  overflow: auto;
`;
const Spike = styled.div`
  margin-top: 3px;
  margin-left: -25px;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent white transparent transparent;
  position: absolute;
`;
const Toolbox = styled.div`
  color: #6F4FB3;
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -8px;
  z-index: 999;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  @media(max-width  : 991px){
    display: none;
  }
`;

const Arrow = styled.div`
  text-align: center;
`;

const CustomImg = styled.img`
  @media(min-width  : 991px){
    display: none;
  }
`;
const CustomImgLight = styled.img`
  @media(max-width  : 991px){
    display: none;
  }
`;

const BottomMenu = styled.div`
    position: absolute;
    bottom: 0;
    width : 100%; 
    margin-bottom : 20px ; 
    text-align: center;
    ul{
      padding: 0  0 0 23px ; 
      li,button{
        margin : 12px 0 ; 
        list-style : none ; 
        a,button{
          padding : 0; 
          &:hover{
             svg {
      color : ${(props) => props.theme.primary} ;
    }}
        svg{
                margin-right: 9px;
        }
                display : block ; 

            color : white !important ; 
            text-decoration : none ; 
 &.active {
      color : ${(props) => props.theme.secondary} ;
    svg {
      color : ${(props) => props.theme.secondary} ;
    }
  }
        }
      }
    }
`;
const BottomItem = styled.div`
  margin-bottom: 40px;
`;
const LeftMenuStyled = styled.div`
  @media(max-width  : 991px){
    position : fixed ; 
    height : 100vh ; 
    overflow : auto ; 
    width : 100vw; 
    transition : ease 400ms; 
    transform : translateX(${(props) => (props.show ? '0' : '-100%')});
  }
`;
const HeaderStyled = styled.header`
text-align : center ; 
img {
	width : 113px ; 
}
padding : 40px 0 20px 0 ; 
`;
const LeftMenuStyledNav = styled.nav`
  position: relative;
	padding : 0px 0px 20px 8px  ; 
	min-height : 100vh ; 
  background: linear-gradient(0deg, #6F4FB3 10%, #879AE6 80%, #879AE6 81%);
  box-shadow: 4px -4px 20px 3px #00000045;
  @media(max-width  : 991px){
     height : 100vh ; 
     overflow : auto ; 
     width : 100vw; 
   }
   @media(min-width  : 991px){
      border-radius :0px  20px 20px 0 ; 
    }
`;
