import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import Geohash from 'latlon-geohash'
import styled, { withTheme } from 'styled-components'

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address: this.props.address,
      gmapsLoaded: false,
    }
  }

  componentDidMount() {
    window.initMap = this.initMap
    const gmapScriptEl = document.createElement(`script`)
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDbuz99PZvGW1fIpdDFvSzht-pLSGbtxcY&libraries=places&callback=initMap`
    document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
  }

  initMap = () => this.setState({ gmapsLoaded: true })

  getGeocodeByAddress = (address) => {
    return geocodeByAddress(address)
  }

  handleChange = async (value) => {
    this.setState({ address: value })
  }

  handleSelect = async (value) => {
    this.setState({ address: value })
    const geopoint = await this.getGeocodeByAddress(value)
    const lat = geopoint[0].geometry.location.lat()
    const lng = geopoint[0].geometry.location.lng()
    const location = {
      geohash: Geohash.encode(lat, lng),
      geopoint: { _latitude: lat, _longitude: lng },
    }

    this.props.setAddress(value)
    //this.props.setValue('place_address', value)
  }

  render() {
    return (
      <>
        {this.state.gmapsLoaded && (
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            {...this.props}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <>
                <CustomInput
                  //{...this.props.register('place_address', { required: true })}
                  {...getInputProps()}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="loading" />}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item'
                    const style =
                      suggestions.length === i + 1 && suggestion.active
                        ? styles.active_last
                        : i === 0 && suggestion.active
                        ? styles.active_first
                        : suggestion.active
                        ? styles.active
                        : suggestions.length === i + 1 && !suggestion.active
                        ? styles.unactive_last
                        : i === 0 && !suggestion.active
                        ? styles.unactive_first
                        : styles.unactive
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
        )}
      </>
    )
  }
}

export default withTheme(LocationSearchInput)

var styles = {
  active_last: {
    backgroundColor: '#E6EFFF',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '1px',
    borderTopColor: '#D7D7D7',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    borderBottomColor: '#E6EFFF',
    padding: '0.25rem',
  },

  active_first: {
    backgroundColor: '#E6EFFF',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '0px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '0px',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    padding: '0.25rem',
  },

  active: {
    backgroundColor: '#E6EFFF',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '0px',
    borderTopColor: '#D7D7D7',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    padding: '0.25rem',
  },
  unactive_last: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '1px',
    borderTopColor: '#D7D7D7',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    borderBottomColor: '#E6EFFF',
    padding: '0.25rem',
  },

  unactive_first: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '0px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '0px',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    padding: '0.25rem',
  },

  unactive: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderTopWidth: '1px',
    borderRightWidth: '1px',
    borderLeftWidth: '1px',
    borderBottomWidth: '0px',
    borderTopColor: '#D7D7D7',
    borderRightColor: '#E6EFFF',
    borderLeftColor: '#E6EFFF',
    padding: '0.25rem',
  },
}

const CustomInput = styled.input`
  width: 100%;
  outline: none;
  padding: 5px;
  font-size: 11pt;
  border-style: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
