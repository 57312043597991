import React from 'react'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import TopStepper from '../../../components/UI/TopStepper/TopStepper'
import UseCreatePlaceContainer from './CreateAPlaceStore'
import withAccountVerification from '../../../hoc/withAccountVerification'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'

// Steps
import InformationStep from './Steps/InformationStep/InformationStep'
import SubscriptionStep from './Steps/SubscriptionStep/SubscriptionStep'
import CheckoutStep from './Steps/CheckoutStep/CheckoutStep'
import PaymentSucceeded from './Steps/PaymentSucceeded/PaymentSucceeded'

const CreateAPlace = ({ t, trialStarted, trialExpired, setTitle }) => {
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()

  React.useEffect(() => {
    setTitle(t('Create a place'))
  }, [setTitle, t])

  let step = {
    0: <InformationStep />,
    1: <SubscriptionStep />,
    2: <CheckoutStep />,
    3: <PaymentSucceeded />,
  }

  return (
    <>
      <CreateAPlaceStyled>
        {trialStarted || trialExpired ? (
          <>
            <TopStepper
              steps={[
                t('information'),
                t('subscription'), 
                t('payment')]}
              step={CreatePlaceState.activeStep}
            />

            {step[CreatePlaceState.activeStep]}
          </>
        ) : (
          step[0]
        )}
      </CreateAPlaceStyled>
    </>
  )
}

export default withAccountVerification(withTranslation()(CreateAPlace))

const CreateAPlaceStyled = styled.div`
  overflow: auto;
  position: fixed;
  z-index: 5000;
  padding-bottom: 100px;
  top: 0;
  width: 100%;
  height: 103vh;
  left: 0;
  background: #eee;
`
