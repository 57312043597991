import React from 'react';
import UseCreatePlaceContainer from './CreateAPlaceStore';
import CreateAPlace from './CreateAPlace';

const CreateAPlaceContainer = ({ ...props }) => (
  <UseCreatePlaceContainer.Provider>
    <CreateAPlace {...props} />
  </UseCreatePlaceContainer.Provider>
  );

export default CreateAPlaceContainer;
