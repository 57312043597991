import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Container } from 'react-bootstrap';
import LoginHeader from '../pages/Login/LoginHeader/LoginHeader';
import BG from '../assets/login/carousel1.jpg';

const FirebaseAuthActionLayout = ({ subtitle, title, children }) => (
  <FirebaseAuthActionLayoutStyled bg={BG} fluid>

    <LoginHeader title="" subtitle="" />
    <Container>
      <h1>{title}</h1>
      <h3>{subtitle}</h3>
      {children}
    </Container>

  </FirebaseAuthActionLayoutStyled>
  );

export default withTheme(FirebaseAuthActionLayout);

const FirebaseAuthActionLayoutStyled = styled(Container)`

background  :url(${(props) => props.bg}) no-repeat center / cover rgba(255,255,255,80%) ;  
background-blend-mode : overlay ; 
height : 100vh ; 
box-shadow : 0px 8px 9px #00000029 ; 
.container{
		h1{
    font-weight  : bold ;

text-align : center ; 
		color : ${(props) => props.theme.grey_1};
		font-size : ${(props) => props.theme.title}; 
	}
	h3{
		text-align : center ; 
      color : ${(props) => props.theme.grey_3};
      font-size : ${(props) => props.theme.text}; 
	}
max-width  : 445px ; 
padding : 10px ; 
background : white ; 
border-radius : 15px ; 
}
`;
