import { Facebook, Instagram, LinkedIn, Link } from '@material-ui/icons'

const openInNewTab = (url) => {
  const newWindow = window.open(
    url.split(':')[0] === 'https' ? url : `https://${url}`,
    '_blank',
    'noopener,noreferrer'
  )
  if (newWindow) newWindow.opener = null
}

const ContactLogo = ({ place, styleContainer, isWhite, fontColor }) => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={styleContainer}
  >
    {place?.socials && place.socials.facebook !== '' && (
      <Facebook
        onClick={() => openInNewTab(place.socials.facebook)}
        style={{
          margin: '0 15px',
          borderRadius: '100%',
          color: isWhite ? 'white' : fontColor,
          backgroundColor: isWhite ? fontColor : 'white',
          padding: '3px',
          fontSize: '30px',
          cursor: 'pointer',
        }}
      />
    )}
    {place?.socials && place.socials.instagram !== '' && (
      <Instagram
        onClick={() => openInNewTab(place.socials.instagram)}
        style={{
          margin: '0 15px',
          borderRadius: '100%',
          color: isWhite ? 'white' : fontColor,
          backgroundColor: isWhite ? fontColor : 'white',
          padding: '3px',
          fontSize: '30px',
          cursor: 'pointer',
        }}
      />
    )}
    {place?.socials && place.socials.linkedin !== '' && (
      <LinkedIn
        onClick={() => openInNewTab(place.socials.linkedin)}
        style={{
          margin: '0 15px',
          borderRadius: '100%',
          color: isWhite ? 'white' : fontColor,
          backgroundColor: isWhite ? fontColor : 'white',
          padding: '3px',
          fontSize: '30px',
          cursor: 'pointer',
        }}
      />
    )}
    {place?.socials && place.socials.website !== '' && (
      <Link
        onClick={() => openInNewTab(place.socials.website)}
        style={{
          margin: '0 15px',
          borderRadius: '100%',
          color: isWhite ? 'white' : fontColor,
          backgroundColor: isWhite ? fontColor : 'white',
          padding: '3px',
          fontSize: '30px',
          cursor: 'pointer',
        }}
      />
    )}
    {/* <div
        onClick={() => console.log('open actualité')}
        style={{
        fontSize: '16px', fontWeight: 'bold', color: isWhite ? 'white' : '#276347', padding: '2px 11px', borderRadius: '15px', backgroundColor: isWhite ? '#276347' : 'white',
        }}
    >
      Actualités
    </div> */}
  </div>
)

export default ContactLogo
