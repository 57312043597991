import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { withTranslation } from 'react-i18next'

const ProfilePicModal = ({
  t,
  modalOpen,
  toggleModal,
  setPicUrl,
  handleAddImage,
}) => {
  const [url, setUrl] = useState(null)
  const [image, setImage] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 })

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          // blob.name = fileName;
          let fileUrl = window.URL.createObjectURL(blob)
          resolve({ url: fileUrl, blob })
        },
        'image/png ',
        1
      )
    })
  }

  const formSubmitted = async () => {
    if (url) {
      const croppedImg = await getCroppedImg(image, crop, fileName)
      console.log('IMG: ', croppedImg)
      setPicUrl(croppedImg)
      handleAddImage(croppedImg)
    }
    toggleModal()
  }
  const onCropComplete = (crop) => {}
  return (
    <Modal show={modalOpen} onHide={toggleModal}>
      <Modal.Header closeButton>{t('insert-logo')}</Modal.Header>
      <Modal.Body>
        <input
          className="hidden"
          onChange={(event) => {
            setUrl(URL.createObjectURL(event.currentTarget.files[0]))
            setFileName(event.currentTarget.files[0].name)
          }}
          accept="image/*"
          key={fileName}
          type="file"
          id={fileName}
        />
        <br />
        <br />
        {url && (
          <ReactCrop
            src={url}
            onImageLoaded={(c) => setImage(c)}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => onCropComplete(c)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline" onClick={() => toggleModal(false)}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={() => formSubmitted()}>
          {t('validate')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default withTranslation()(ProfilePicModal)
