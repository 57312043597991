import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled, { withTheme, keyframes } from 'styled-components'
import {
  OverlayTrigger,
  Container,
  Dropdown,
  DropdownButton,
  Card,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap'
import { useSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import {
  PermMedia,
  CloudUploadOutlined,
  DescriptionOutlined,
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
  AssignmentReturnedRounded,
  SubdirectoryArrowRight,
  Close,
} from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router-dom'
import _, { sortBy, findIndex } from 'underscore'
import { useDropzone } from 'react-dropzone'
import { Functions } from '../../../../utils/firebase/FirebaseModules'
import Moveicon from '../../../../assets/menu/move.svg'
import { SetLogoModal, AddProductModal, EditCategory, EditFormula } from '../common'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'
import {
  getMultilangContent,
  createMultilangArray,
  updateMultilangArray,
} from '../../../../utils/MultilangHelpers'
import { useFirestoreApi, useStorageTools } from '../../../../hooks/FireTools'
import ProductCard from '../../../../components/Dashboard/Menus/Cards/ProductCard'

const fileTypes = ['PDF']

const subCategory = {
  id: 'id-0',
  categoryName: 'testo',
  categoryDescription: 'testu',
  products: [],
}

const Formula = ({
  t,
  modePDF,
  register,
  setValue,
  background_image_url,
  pdf_url,
  formulaDatas,
  placeDatas,
  sections,
  errors,
  currentLang,
  addSection,
  sectionId,
  productId,
  sync,
}) => {
  const [picModalOpen, setPicModalOpen] = useState(false)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const [editCategoryOpen, setEditCategoryOpen] = useState(false)
  const [currentElLength, setCurrentElLength] = useState(0)
  const [editFormulaOpen, setEditFormulaOpen] = useState(false)
  const { documentDeletionButton } = useStorageTools()
  const [formulas, setFormulas] = useState([])
  const location = useLocation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()
  const [producstIn, setProducstIn] = useState([])
  const [indexEdit, setIndexEdit] = useState(0)
  const [subIndexEdit, setSubIndexEdit] = useState(0)
  const [typeEdit, setTypeEdit] = useState(0)
  const [editOrCreate, setEditOrCreate] = useState(true)
  const [duplicating, setDuplicating] = useState({})

  const [category, setCategory] = useState(null)

  const [PicUrl, setPicUrl] = useState(null)
  const [url, setUrl] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState(null)
  const myRefname = useRef(null)
  const menuContainerRef = useRef()
  const { updateDatas } = useFirestoreApi()

  const onDrop = useCallback((file) => {
    setValue('pdf_file', file[0])
    setUrl(URL.createObjectURL(file[0]))
    setFileName(file[0].name)
    setFileSize(file[0].size)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (menuContainerRef.current)
      window.scroll({ top: menuContainerRef.current.offsetTop, behavior: 'smooth' })

    if (sectionId && productId) {
      console.log('EDIT PRODUCT!')
    }
  }, [])

  useEffect(() => {
    if (modePDF) {
      if (getMultilangContent(formulaDatas, 'pdf_url_ml', currentLang)) {
        setFileName(
          getMultilangContent(formulaDatas, 'pdf_file_name_ml', currentLang)
        )
        setFileSize(
          getMultilangContent(formulaDatas, 'pdf_file_size_ml', currentLang)
        )
      } else {
        setFileName(false)
      }
    }
  }, [currentLang])
  useEffect(() => {
    setFormulas(sections || [])
  }, [sections])

  const displayGroupTitle = (id) => {
    if (id === 2) {
      return t('featured category')
    } else if (id === 1) {
      return t('formule category')
    } else {
      return t('simple category')
    }
  }

  const syncMenuWithProd = async (sectionId, placeId, menuId, oldProds) => {
    const _syncFunction = Functions().httpsCallable('products-updateProductLocation')

    return _syncFunction({
      data: {
        sectionId,
        placeId,
        menuId,
        oldProds,
        type: 'formulas',
      },
    })
      .then((data) => data)
      .catch((e) => e)
  }
  const displaySize = (size) => {
    if (size / 1000000 > 1)
      return `${(Math.trunc(fileSize / 100000) / 10).toString()} Mo`
    else return `${(Math.trunc(fileSize / 100) / 10).toString()} Ko`
  }

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const deleteCategory = async (group) => {
    const subCats = formatCategoryElements(group).filter(
      (c) => c._element_type === 'cat'
    )
    if (window.confirm(t('remove_category'))) {
      await Promise.all([
        updateDatas({
          query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${group.id}`,
          action: 'delete',
        }),
        subCats.map((s) => {
          updateDatas({
            query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${s.id}`,
            action: 'delete',
          })
        }),
      ])
      enqueueSnackbar('category_deleted_succeed', { variant: 'success' })
      sync()
    } else return false
  }

  const editSubCat = async (id, field, content, old) => {
    const datas = {}
    datas[field] = updateMultilangArray(content, old, currentLang)
    await updateDatas({
      query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${id}`,
      action: 'set',
      datas,
      message: {
        error: t('sub_cat_added_failed'),
        success: t('sub_cat_added_succeed'),
      },
    })
    sync()
  }
  const addSubCat = async (id) => {
    const elements = formatCategoryElements(formulas.find((d) => d.id === id))
    const r = await updateDatas({
      query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections`,
      action: 'add',
      datas: {
        group_parent: id,
        group_name_ml: createMultilangArray(
          [placeDatas.main_language, ...placeDatas.languages_array],
          currentLang,
          ''
        ),
        group_description_ml: createMultilangArray(
          [placeDatas.main_language, ...placeDatas.languages_array],
          currentLang,
          ''
        ),
        uid: formulaDatas.uid,
        group_visible: true,
        menu_order: elements.length + 1,
        order: elements.length + 1,
        products: [],
      },
      message: {
        success: t('sub_cat_added'),
        error: t('sub_cat_added_failed'),
      },
    })
    sync()
  }

  const duplicateCat = async (id) => {
    const duplicate = Functions().httpsCallable('sectionsFormulas-duplicateSection')
    setDuplicating({ id, loading: true })
    try {
      const duplicateResult = await duplicate({
        data: {
          sectionId: id,
          formulaId: formulaDatas.id,
          placeId: placeDatas.id,
        },
      })
      enqueueSnackbar(t('group_duplicate_succeed'), { variant: 'success' })
      sync()
    } catch (e) {
      enqueueSnackbar(t('group_duplicate_failed'), { variant: 'error' })
    }
    setDuplicating({ id, loading: false })
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }
    const _formulas = [...formulas]
    const catId = result.source.droppableId.split('_')[1]
    const elements =
      result.type === 'CATEGORY'
        ? formatCategoryElements(formulas)
        : formatCategoryElements(formulas.find((d) => d.id === catId))
    const reorderedElements = Reorder(
      elements,
      result.source.index,
      result.destination.index
    )

    reorderedElements.map((reorderedElement, index) => {
      if (reorderedElement._element_type === 'cat') {
        updateDatas({
          query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${reorderedElement.id}`,
          datas: {
            menu_order: index,
            order: index,
          },
          action: 'set',
        })
        _formulas[_.findIndex(formulas, { id: reorderedElement.id })] = {
          ...reorderedElement,
          menu_order: index,
          order: index,
        }
      }
    })

    const products = reorderedElements
      .map((d, i) => ({ ...d, menu_order: i }))
      .filter((p) => p._element_type === 'product')

    if (products && products.length > 0) {
      _formulas[_.findIndex(formulas, { id: catId })] = {
        ..._formulas[_.findIndex(formulas, { id: catId })],
        products,
      }
      updateDatas({
        query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${catId}`,
        datas: {
          products,
        },
        action: 'set',
      })
    }
    setFormulas(_formulas)
    sync()
  }

  const formatCategoryElements = (category) => {
    const prods = category?.products
      ? category.products.map((p) => ({ ...p, _element_type: 'product' }))
      : []
    const cats = formulas
      .filter((c) => category.id === c.group_parent)
      .map((c) => ({ ...c, _element_type: 'cat' }))
    const elements = [...prods, ...cats]

    return _.sortBy(elements, 'menu_order')
  }

  const categoryRender = (group, index, provided) => (
    <CategorieItem visible={group.group_visible}>
      <Flex {...provided.dragHandleProps} style={{ padding: '10px 0px 0px 20px' }}>
        <img
          src={Moveicon}
          alt="logo"
          style={{
            width: '15px',
            marginRight: '20px',
            marginTop: '-3px',
            cursor: 'move',
          }}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentLeftContainer>
            <ContentTitle>
              {getMultilangContent(group, 'group_name_ml', currentLang)}
            </ContentTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => duplicateCat(group.id)}>
                  {duplicating?.id === group.id && duplicating?.loading ? (
                    <Spinner animation="border" size="sm" variant="dark" />
                  ) : (
                    <CopyIcon />
                  )}
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('edit')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() =>
                    handleEditCategory(false, group.group_type, index, group)
                  }
                >
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                      {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => deleteCategory(group)}>
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
        </div>
      </Flex>
      <div style={{ margin: '-10px 0px 0px 70px' }}>
        <Flex style={{ marginBottom: '5px' }}>
          <ArrowRight />
          <GroupTitle>{displayGroupTitle(group.group_type)}</GroupTitle>
        </Flex>
        <GroupDescription style={{ width: '450px' }}>
          {getMultilangContent(group, 'group_description_ml', currentLang)}
        </GroupDescription>
      </div>
    </CategorieItem>
  )

  const subCategoryRender = (group, index) => (
    <SubCategorieItem id={`subcat_${group.id}`}>
      <Flex>
        <img
          src={Moveicon}
          alt="logo"
          style={{
            width: '15px',
            marginRight: '20px',
            marginTop: '-3px',
            cursor: 'move',
          }}
        />
        <SubTopContent>
          <ContentLeftContainer>
            <ContentTitle>
              <CustomInput
                onBlur={(e) =>
                  editSubCat(
                    group.id,
                    'group_name_ml',
                    e.target.value,
                    group.group_name_ml
                  )
                }
                placeholder="Titre"
                defaultValue={getMultilangContent(
                  group,
                  'group_name_ml',
                  currentLang
                )}
              />
            </ContentTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => duplicateCat(group.id)}>
                  {duplicating?.id === group.id && duplicating?.loading ? (
                    <Spinner animation="border" size="sm" variant="dark" />
                  ) : (
                    <CopyIcon />
                  )}
                </IconContainer>
              </OverlayTrigger>

              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                      {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => deleteCategory(group)}>
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
          <div style={{ margin: '0px 0px 0px 20px' }}>
            <GroupDescription style={{ width: '450px' }}>
              <CustomInputDescription
                placeholder={t('description')}
                onBlur={(e) =>
                  editSubCat(
                    group.id,
                    'group_description_ml',
                    e.target.value,
                    group.group_description_ml
                  )
                }
                defaultValue={getMultilangContent(
                  group,
                  'group_description_ml',
                  currentLang
                )}
              />
            </GroupDescription>
          </div>
        </SubTopContent>
      </Flex>
    </SubCategorieItem>
  )

  const productRender = (product, item, index, subIndex) => (
    <ProductCard
      {...product}
      product_name={getMultilangContent(
        product,
        'product_name_ml',
        'FR'
      )}
      product_description={getMultilangContent(
        product,
        'product_description_ml',
        'FR'
      )}
      promoPercentage={product.product_promo_reduction}
      imgUrl={product.product_main_image_url}
      prices={product.product_prices}
      index={index}
      props={product}
      currentLang={currentLang}
      actions={[
        {
          function: () => console.log(product.pid),
          icon: 'duplicate',
        },
        { function: () => {
          const from_url = encodeURIComponent(
            location.pathname + location.search
          )
          history.push(
            `/dashboard/edit/new/product?product_id=${product.id}&place_id=${placeDatas.id}&from=${from_url}`
          )
        }, icon: 'edit' },
        { function: () => {
          updateDatas({
            query: `places/${placeDatas.id}/formulas/${formulaDatas.id}/sections/${item.id}`,
            action: 'set',
            datas: {
              products: item.products.filter(
                (p) => p.id !== product.id
              ),
            },
            message: {
              success: t('remove_cat_product_succeed'),
              error: t('remove_cat_product_failed'),
            },
          })
          syncMenuWithProd(
            item.id,
            placeDatas.id,
            formulaDatas.id,
            item.products
          )
        }, icon: 'trash' },
      ]}
    />
  )

  const subCatDroppable = (el) => (
    <Droppable droppableId={`droppable_${el.id}`} type="SUBCATEGORY">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="droppableSubCatArea"
        >
          {el.products.map((p, productIndex) => (
            <Draggable
              key={`${el.id}_${p.id}`}
              draggableId={`${el.id}_${p.id}`}
              index={productIndex}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                >
                  {productRender(p, el, productIndex)}
                </div>
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  )

  const categoryDroppableContent = (category) => (
    <Droppable droppableId={`droppable_${category.id}`} type="CATEGORY_CONTENT">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ padding: '20px ' }}
        >
          {formatCategoryElements(category).map((el, indexEl) => {
            if (el._element_type === 'product') {
              return (
                <Draggable
                  key={`${category.id}_${el.id}`}
                  draggableId={`${category.id}_${el.id}`}
                  index={indexEl}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {productRender(el, category, indexEl)}
                    </div>
                  )}
                </Draggable>
              )
            }
            if (el._element_type === 'cat') {
              return (
                <Draggable
                  key={`${category.id}_${el.id}`}
                  draggableId={`${category.id}_${el.id}`}
                  index={indexEl}
                >
                  {(provided, snapshot) => (
                    <SubCatContainer
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {subCategoryRender(el, indexEl)}
                      <DraggableFlex>{subCatDroppable(el)}</DraggableFlex>
                      <Flex>
                        <Space />
                        <SubBottomContent>
                          <AddButton
                            type="button"
                            onClick={() => handleAddProduct(el, indexEl)}
                            variant="primary"
                          >
                          {t('add product')}
                          </AddButton>
                        </SubBottomContent>
                      </Flex>
                    </SubCatContainer>
                  )}
                </Draggable>
              )
            }
          })}
        </div>
      )}
    </Droppable>
  )

  const handleClick = () => {
    myRefname.current.click()
  }

  const handleAddProduct = (item, index, l) => {
    setProducstIn(item.products)
    setCurrentElLength(l)
    setIndexEdit(index)
    setCategory(item)
    setAddProductOpen(true)
  }

  const handleEditCategory = (bol, type, index, category = null) => {
    setEditOrCreate(bol)
    setCategory(category)
    setTypeEdit(type)
    setEditCategoryOpen(true)
  }

  const handleEditFormula = (bol, index, subIndex) => {
    setEditOrCreate(bol)
    setIndexEdit(index)
    setSubIndexEdit(subIndex)
    setEditFormulaOpen(true)
  }

  const handleAddImage = (img) => {
    if (typeof img === 'object' && img.blob.size > 0) {
      setValue(
        'background_image_file',
        getFileFromBlob(img.blob, 'background_image')
      )
    }
  }

  return (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={picModalOpen}
        toggleModal={setPicModalOpen}
        handleAddImage={handleAddImage}
      />
      <AddProductModal
        index={indexEdit}
        producstIn={producstIn}
        setProducstIn={setProducstIn}
        modalOpen={addProductOpen}
        toggleModal={setAddProductOpen}
        formulas={formulas}
        setFormulas={setFormulas}
        placeDatas={placeDatas}
        formulaId={formulaDatas.id}
        selectedLang={currentLang}
        category={category}
        currentElLength={currentElLength}
        currentLang={currentLang}
        syncMenuWithProd={syncMenuWithProd}
        sync={sync}
      />
      <EditCategory
        create={editOrCreate}
        addSection={addSection}
        placeDatas={placeDatas}
        formulaId={formulaDatas.id}
        index={indexEdit}
        type={typeEdit}
        modalOpen={editCategoryOpen}
        toggleModal={setEditCategoryOpen}
        formulas={formulas}
        category={category}
        setFormulas={setFormulas}
        sync={sync}
        currentLang={currentLang}
      />
      <EditFormula
        create={editOrCreate}
        index={indexEdit}
        subIndex={subIndexEdit}
        modalOpen={editFormulaOpen}
        toggleModal={setEditFormulaOpen}
        formulas={formulas}
        setFormulas={setFormulas}
      />
      <Container style={{ scrollBehavior: 'unset !important' }} fluid>
        <Card bsPrefix="card white-card">
          <Title>{t('header_formulas')}</Title>
          {modePDF && (
            <>
              {fileName ? (
                <PDFContainer>
                  <DescriptionOutlined
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'red',
                      marginTop: '2px',
                      marginRight: '10px',
                    }}
                  />{' '}
                  <PDFName>{fileName}</PDFName>
                  <PDFSize>{displaySize(fileSize)}</PDFSize>
                  <div style={{ width: '70%' }}>
                    <Close
                      onClick={() => setFileName(null)}
                      style={{
                        cursor: 'pointer',
                        color: '#6F4FB3',
                        width: '25px',
                        height: '25px',
                        float: 'right',
                      }}
                    />{' '}
                  </div>
                </PDFContainer>
              ) : (
                <DashedBackground
                  isDragActive={isDragActive}
                  onClick={() => handleClick()}
                  {...getRootProps()}
                >
                  <CloudUploadOutlined
                    style={{
                      width: '60px',
                      height: '60px',
                      marginBottom: '20px',
                      color: 'lightgrey',
                      margin: '30px',
                    }}
                  />
                  <ImportPDFContainer>
                    <input
                      style={{ display: 'none' }}
                      accept="application/pdf"
                      id={fileName}
                      type="file"
                      ref={myRefname}
                      {...getInputProps()}
                    />
                    <ImportPDFTitle>
                      {pdf_url && pdf_url !== '' ? (
                        <>
                          <div>
                        {t('drag a file')}
                        </div>
                          &nbsp;
                          <div
                            style={{ color: '#6F4FB3', textDecoration: 'underline' }}
                          >
                            {t('or import and replace')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                        {t('drag a file')}
                        </div>
                          &nbsp;
                          <div
                            style={{ color: '#6F4FB3', textDecoration: 'underline' }}
                          >
                          {t('or import')}
                          </div>
                        </>
                      )}
                    </ImportPDFTitle>
                    <ImportPDFSubTitle>
                      {t('file size')}
                    </ImportPDFSubTitle>
                  </ImportPDFContainer>
                  <div style={{ width: '60px', height: '60px', margin: '30px' }} />
                </DashedBackground>
              )}
            </>
          )}

          <ProductBackground>
            <Flex>
              {!PicUrl && !background_image_url ? (
                <Logo
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                >
                  <InsertLogo>
                    <PermMedia
                      style={{
                        width: '50px',
                        height: '50px',
                        marginBottom: '20px',
                        opacity: 0.5,
                        color: 'lightgrey',
                      }}
                    />
                    {t('add background pic')}
                    <SizeText>{t('pic size')}</SizeText>
                  </InsertLogo>
                </Logo>
              ) : (
                <div style={{ position: 'relative' }}>
                  {documentDeletionButton({
                    text: t('remove'),
                    icon: '',
                    server: true,
                    url: background_image_url,
                    collection: `places/${placeDatas.id}/formulas/`,
                    doc: formulaDatas.id,
                    field: 'background_image_url',
                    message: {
                      success: 'Image supprimé',
                      error: 'Erreur',
                    },
                    resetFunction: () => {
                      setPicUrl(null)
                      setValue('background_image_file', undefined)
                      sync()
                    },
                  })}
                  <Img
                    src={PicUrl !== null ? PicUrl.url : background_image_url}
                    alt="logo"
                    onClick={() => {
                      setPicModalOpen(!picModalOpen)
                    }}
                  />
                </div>
              )}
              <div style={{ display: 'inline', width: '100%' }}>
                <Form.Control
                  as={NameInput}
                  isInvalid={errors.name}
                  {...register('name', { required: true })}
                  placeholder="Nom de la formule"
                />
                <Form.Control.Feedback type="invalid">
                  {t('menu_name_required')}
                </Form.Control.Feedback>
                <br />
                <PriceInput>
                  <div style={{ margin: '8px 10px 0px 5px' }}>€</div>
                  <Form.Control
                    as={NameInput}
                    type="number"
                    isInvalid={errors.price}
                    {...register('price', { required: false })}
                    placeholder="Prix de la formule"
                  />
                </PriceInput>
                <Form.Control
                  as={AreaInput}
                  isInvalid={errors.description}
                  {...register('description', { required: true })}
                  placeholder="Description de la formule"
                />
                <Form.Control.Feedback type="invalid">
                  {t('menu_description_required')}
                </Form.Control.Feedback>
              </div>
            </Flex>
          </ProductBackground>
        </Card>

        {!modePDF && (
          <>
            <Card ref={menuContainerRef} bsPrefix="card white-card">
              <Title>{t('formula')}</Title>
              {sections && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" type="CATEGORY">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {formulas
                          .filter(
                            (cat) => !cat?.group_parent || cat?.group_parent === null
                          )
                          .map((category, index) => (
                            <Draggable
                              key={category.id}
                              draggableId={category.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <CategoryBackground
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  {categoryRender(category, index, provided)}
                                  {categoryDroppableContent(category)}
                                  <br />
                                  {category.group_type === 0 ? (
                                    <AddCatButton
                                      onClick={() => addSubCat(category.id)}
                                      variant="secondary"
                                    >
                                      {t('add subcat')}
                                    </AddCatButton>
                                  ) : null}
                                  <AddButton
                                    type="button"
                                    onClick={() =>
                                      category.group_type === 1
                                        ? handleEditFormula(true, index)
                                        : handleAddProduct(
                                            category,
                                            index,
                                            formatCategoryElements(category).length >
                                              0
                                              ? formatCategoryElements(
                                                  category
                                                ).pop().menu_order
                                              : 0
                                          )
                                    }
                                    variant={
                                      category.group_type === 1
                                        ? 'secondary'
                                        : 'primary'
                                    }
                                  >
                                    {category.group_type === 1
                                      ? t('add formula')
                                      : t('add product')}
                                  </AddButton>
                                </CategoryBackground>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              <ButtonDropdown
                variant="secondary"
                id="section-dropdown"
                title={t('add section')}
              >
                <DropDownItem onClick={() => handleEditCategory(true, 2, 0)}>
                  <Flex>
                    <div
                      style={{
                        width: '90%',
                        textAlign: 'initial',
                      }}
                    >
                      {t('highlight')}
                    </div>
                    <RoundedInput />
                  </Flex>
                </DropDownItem>
                {/* <DropDownItem onClick={() => handleEditCategory(true, 1, 0)}>
                  Formule
                  </DropDownItem> */}
                <DropDownItem onClick={() => handleEditCategory(true, 0, 0, null)}>
                  {t('categorie')}
                </DropDownItem>
              </ButtonDropdown>
            </Card>
          </>
        )}
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(Formula))

const Flex = styled.div`
  display: flex;
`
const AddCatButton = styled(Button)`
  margin: -10px -30px 20px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const AddButton = styled(Button)`
  margin: -10px 0px 20px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ButtonDropdown = styled(DropdownButton)`
  margin-bottom: 10px;
  margin-left: 10px;
  width: 23% !important;
  .dropdown-menu {
    width: 100% !important;
    min-width: 200px;
  }
  .dropdown-toggle {
    width: 100% !important;
    text-align: initial;
    min-width: 200px;
  }
  .dropdown-menu.show {
    margin: -20px 0px -20px 0px !important;
  }
  .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }
`
const DropDownItem = styled(Dropdown.Item)`
  text-align: initial !important;
  font-weight: bold;
  color: #e76b56;
  :hover {
    color: #e76b56;
  }
  padding-left: 20px !important;
  padding-right: 40px !important;
`
const PDFContainer = styled.div`
  display: flex;
  border-style: solid;
  border-color: ${(props) => props.theme.primary};
  border-width: 1px;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  margin-bottom: 20px;
  margin-top: 10px;
`
const PDFName = styled.div`
  width: 40%;
  margin-right: 20px;
`
const PDFSize = styled.div`
  flex: none;
  opacity: 0.5;
`
const ImportPDFTitle = styled.div`
  display: inline-flex;
  font-weight: bold;
`
const ImportPDFContainer = styled.div`
  width: 100%;
  text-align: center;
  align-self: center;
`
const ImportPDFSubTitle = styled.div`
  opacity: 0.5;
`
const CategoryBackground = styled.div`
  background-color: #e6efff;
  border-radius: 10px;
  margin-bottom: 20px;
`
const ProductBackground = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const DraggableFlex = styled.div`
  padding: 20px;
  background-color: white;
  border-style: solid;
  border-top-style: none !important;
  border-bottom-style: none !important;
  border-width: 1px;
  border-color: #d1d1d1;
  margin-left: 37px;
  @media screen and (min-width: 1060px) {
    margin-left: 35px;
  }
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const RoundedInput = styled(AssignmentReturnedRounded)`
  color: ${(props) => props.theme.primary};
  font-size: 13pt !important;
  margin-top: 4px;
`
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`
const DashedBackground = styled.div`
  display: flex;
  cursor: pointer;
  background: ${(props) => props.isDragActive && 'rgba(0, 0, 0, 0.05)'};
  border-style: dashed;
  border-width: 2px;
  border-radius: 7px;
  border-color: rgba(111, 79, 179, 0.5);
  margin-bottom: 20px;
`
const Logo = styled.div`
  width: 600px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const PriceInput = styled.div`
  display: flex;
  background: white;
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 129px !important;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const InsertLogo = styled.div`
  width: 200px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 70px;
  display: grid;
  justify-items: center;
`
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  color: ${(props) => props.theme.primary};
`
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
`
const Content = styled.div`
  display: flex;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
`
const ContentFormuleLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  width: 50%;
`
const ContentFormuleAction = styled.div`
  float: right;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  @media screen and (max-width: 1150px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) {
    width: 80%;
  }
`
const ContentAction = styled.div`
  float: right;
  @media screen and (max-width: 1150px) {
    width: 60%;
  }
  @media screen and (min-width: 1150px) {
    width: 40%;
  }
`
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin-right: 20px;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`
const ContentFormuleInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  margin: 0px 40px 0px 40px;
  text-align: initial;
`
const ContentFormuleDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin: -5px 40px 0px 40px;
`
const PriceFormuleContainer = styled.div`
  display: flex;
  font-weight: bold;
  align-self: center;
  width: 70%;
  margin: 5px 40px 20px 40px;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const PriceContainer = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  margin-right: 20px;
`
const PromoPriceBox = styled.div`
  display: flex;
  margin-right: 15px;
`
const PromoPrice = styled.div`
  text-decoration: line-through;
`
const PromoNewPrice = styled.div`
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`
const PriceBox = styled.div`
  margin-right: 20px;
`
const CategorieItem = styled.div`
  opacity: ${(props) => props.visible && '0.5'};
`
const animationSubcat = keyframes`
   0% {
    transform: scale(0.5);
    opacity:0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const SubCategorieItem = styled.div``
const SubTopContent = styled.div`
  width: 100%;
  border-bottom-style: none !important;
  text-align: center;
  background-color: white;
  border-style: solid;
  border-radius: 10px 10px 0px 0px;
  border-width: 1px;
  border-color: #d1d1d1;
`
const SubBottomContent = styled.div`
  width: 100%;
  border-top-style: none !important;
  padding-top: 15px;
  padding-bottom: 10px;
  background-color: white;
  border-style: solid;
  border-radius: 0px 0px 10px 10px;
  border-width: 1px;
  border-color: #d1d1d1;
`
const Space = styled.div`
  width: 37px;
  @media screen and (min-width: 1060px) {
    width: 36px;
  }
`
const CustomInput = styled.input`
  outline: none;
  font-weight: bold;
  font-size: 13pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 1px;
`
const CustomInputDescription = styled.input`
  outline: none;
  width: 100%;
  font-size: 12pt;
  font-weight: 300;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 1px;
`

const SubCatContainer = styled.div`
  padding: 10px 0;
  .animate {
    animation: ${animationSubcat} 600ms ease 0s 1 normal forwards;
  }
  .productItem {
  }
`
