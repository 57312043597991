import React from 'react'
import { Card, Row, Col, ListGroup ,Spinner} from 'react-bootstrap'
import moment from 'moment'

const UpcommingInvoice = ({ t, invoice }) => (
		<>
			<Card bsPrefix="card white-card">
				<Card.Header>
					<Card.Title>{t('next_invoice')}</Card.Title>
				</Card.Header>
				<Card.Body>
					{invoice !== null  ?
					<ListGroup>
						<ListGroup.Item>
							<b>{`${t('next_payment_amount')}:  `}</b>
							{(invoice.amount_due / 100).toFixed(2)} €
						</ListGroup.Item>
						<ListGroup.Item>
							<b>{`${t('next_payment_date')}:  `}</b>
							{moment(invoice.next_payment_attempt * 1000).format('DD/MM/YYYY')}
						</ListGroup.Item>
					</ListGroup>
					 :<Spinner animation='border'  size="lg" variant="" /> }
				</Card.Body>
			</Card>
		</>
	)

export default UpcommingInvoice
