import React, { useEffect } from 'react'
import { Functions } from '../../../utils/firebase/FirebaseModules'
import { withTranslation } from 'react-i18next'

const DashboardAdminIndex = ({t, props, setTitle}) => {
  useEffect(() => {
    setTitle(`${t('admin')} > <b>${t('dashboard')}</b>`);
  }, [setTitle, t])

	return <div>TEST</div>
}

export default withTranslation()(DashboardAdminIndex)
