import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { DeleteForeverOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled, { withTheme } from 'styled-components';

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

const Display = ({
 t, unregister, setValue, formatHoursFromDb,
}) => {
  const [hours, setHours] = useState([]);
  const classes = useStyles();

  const displayLetter = (e) => {
    switch (e) {
      case 'monday':
        return 'L'
      case 'tuesday':
        return 'M'
      case 'wednesday':
        return 'M'
      case 'thursday':
        return 'J'
      case 'friday':
        return 'V'
      case 'saturday':
        return 'S'
      case 'sunday':
        return 'D'
      default:
        return '?'
    }
  };

  const updateHours = (item, index, day) => {
    let tmp = [...hours];
    tmp[index].openDays[day] = !item.openDays[day];
    setHours(tmp);
  };

  const updateTime = (value, index, t) => {
    let tmp = [...hours];
    if (t === 0) tmp[index].displayHours = value;
    else tmp[index].undisplayHours = value;
    setHours(tmp);
  };

  useEffect(() => {
    unregister('menu_display_hours');
    setValue('menu_display_hours', hours);
  }, [hours]);

  return (
    <Container fluid>
      <Background>
        <Title>
          {t('menu visibility')}
        </Title>
        <SubTitle>
          {t('menu visibility description')}
        </SubTitle>
        <div style={{ textAlign: 'center' }}>
          {hours.map((item, index) => (
            <SubBackground>
              <FlexDiv>
                <DayPickerContainer>
                  {days.map((day, i) => (
                    <>
                      {item.openDays[day]
                      ? (
                        <DayPicker onClick={() => updateHours(item, index, day)}>
                          {displayLetter(day)}
                        </DayPicker>
)
                      : (
                        <DayUnPicked onClick={() => updateHours(item, index, day)}>
                          {displayLetter(day)}
                        </DayUnPicked>
)}
                    </>
                  ))}
                </DayPickerContainer>
                <FlexTimeInput>
                  <TimeInput>
                    <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.displayHours}
                        onChange={(e) => updateTime(e.target.value, index, 0)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                    />
                  </TimeInput>
                  <div style={{ marginTop: '11px' }}>-</div>
                  <TimeInput>
                    <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.undisplayHours}
                        onChange={(e) => updateTime(e.target.value, index, 1)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                    />
                  </TimeInput>
                </FlexTimeInput>
                <div style={{ width: '65%', float: 'right', marginRight: '10px' }}>
                  <IconContainer
                    onClick={() => setHours(hours.filter((obj) => obj !== item))}
                  >
                    <DeleteIcon />
                  </IconContainer>
                </div>
              </FlexDiv>
            </SubBackground>
            ))}
          <CustomButton
            type="button"
              onClick={() => setHours(hours.concat({
                openDays: {
                  monday: false,
                  tuesday: false,
                  wednesday: true,
                  thursday: false,
                  friday: false,
                  saturday: true,
                  sunday: false,
                },
                displayHours: new Date(),
                undisplayHours: new Date(),
              }))}
          >
            {t('add open hours')}
          </CustomButton>
        </div>
      </Background>
    </Container>
  );
};

export default withTheme(withTranslation()(Display));

const FlexDiv = styled.div`
  display: flex;
`;
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`;
const DayPicker = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`;
const DayPickerContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 800px;
`;
const IconContainer = styled.div`
  background-color: #F8F7FF;
  float: right;
  position: relative;
  cursor: pointer;
  margin: 15px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`;
const DayUnPicked = styled.div`
  cursor: pointer;
  background-color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`;
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`;
const FlexTimeInput = styled.div`
	display: flex;
	justify-content: center;
  width: 70%;
`;
const TimeInput = styled.div`
  background-color: white;
  padding-left: 15px;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
  margin: 10px;
  width: 100px;
  height: 30px;
`;
const CustomButton = styled.button`
	@media screen and (max-width: 991px) {
		width: 90%;
	} 
	@media screen and (min-width: 991px) {
		width: 30%;
	} 
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #F8F7FF;
	border-style: none;
	font-weight: bold;
	font-size: 11pt;
	color: ${(props) => props.theme.primary};
    padding: 5px;
	border-radius: 4px;
`;
const Background = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px 40px 20px 40px;
  text-align: center;
  border-radius: 7px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const SubBackground = styled.div`
  margin-top: 15px;
  background-color: #E6EFFF;
  padding: 5px 0px 5px 30px;
  text-align: center;
  border-radius: 7px;
`;
