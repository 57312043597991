import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useForm } from 'react-hook-form'
import { useDocument, useCollection } from '@nandorojo/swr-firestore'
import AuthContainer from '../../../stores/AuthContainer'
import Navbar from '../../../components/UI/navbar/Navbar'
import { Menu, Display, Theme } from './view'
import { useStorageTools } from '../../../hooks/FireTools'
import LangSelector from '../../../components/UI/form/LangSelector/LangSelector'
import { formatFromDb } from '../../../utils/DateHelpers'
import PlaceContainer from '../../../stores/PlaceContainer'
import {
  updateMultilangArray,
  getMultilangContent,
} from '../../../utils/MultilangHelpers'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const navItems = ['menu', 'display', 'theme']

const CreateMenu = ({ t, setTitle, modePDF }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState('menu')
  const location = useLocation()
  const { menu_id, lang, sectionId, productId } = queryString.parse(location.search)
  const { place: placeDatas } = PlaceContainer.useContainer()
  let currentLang = lang || placeDatas?.main_language || 'FR'

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    unregister,
    watch,
    formState: { errors },
    control,
  } = useForm()
  const { user } = AuthContainer.useContainer()
  const { firebaseUploader } = useStorageTools()
  const [saving, setSaving] = useState(false)
  const { data: menuDatas, set: updateMenu } = useDocument(
    placeDatas?.id && menu_id ? `places/${placeDatas.id}/menus/${menu_id}` : null,
    { listen: true }
  )
  const { data: sections, add: addSection } = useCollection(
    menuDatas?.id && user?.uid
      ? `places/${placeDatas.id}/menus/${menu_id}/sections`
      : null,

    { where: ['uid', '==', user.uid], orderBy: ['order', 'asc'], listen: true }
  )

  const formatHoursFromDb = (hours) => {
    const _hours = [...hours]
    hours.forEach((_h, i) => {
      _hours[i].displayHours = formatFromDb(_h.displayHours)
      _hours[i].undisplayHours = formatFromDb(_h.undisplayHours)
    })
    return _hours
  }

  useEffect(() => {
    if (placeDatas) {
      setTitle(`${placeDatas.place_name} > <b>${t('menus')}</b>`)
    }
  }, [t, setTitle, placeDatas])

  useEffect(() => {
    if (menuDatas) {
      reset({
        ...menuDatas,
        description: getMultilangContent(menuDatas, 'description_ml', currentLang),
        name: getMultilangContent(menuDatas, 'name_ml', currentLang),
        menu_footer: getMultilangContent(menuDatas, 'footer_ml', currentLang),
      })
    }
  }, [currentLang, menuDatas])

  const handleSubmitForm = async (datas) => {
    setSaving(true)
    const commonDatas = {
      uid: user.uid,
      modified_at: new Date(),
      place_id: placeDatas.id,
    }

    let pdf = {}

    if (datas.pdf_file !== undefined && datas.pdf_file.size > 0) {
      let pdfUploaded = await firebaseUploader(
        [datas.pdf_file],
        `users/${user.uid}/menus/${menuDatas.id}`,
        [`menu_pdf_${currentLang}`]
      )
      pdf.pdf_url_ml = updateMultilangArray(
        pdfUploaded[0].url,
        menuDatas?.pdf_url_ml,
        currentLang
      )
      pdf.pdf_file_size_ml = updateMultilangArray(
        datas.pdf_file.size,
        menuDatas?.pdf_file_size_ml,
        currentLang
      )
      pdf.pdf_file_name_ml = updateMultilangArray(
        datas.pdf_file.name,
        menuDatas?.pdf_file_name_ml,
        currentLang
      )
    }
    let background_image_url = menuDatas.background_image_url
      ? menuDatas.background_image_url
      : null
    if (
      datas.background_image_file !== undefined &&
      datas.background_image_file.size > 0
    ) {
      let bgImageUploaded = await firebaseUploader(
        [datas.background_image_file],
        `users/${user.uid}/menus/${menuDatas.id}`,
        ['background_image']
      )
      background_image_url = bgImageUploaded[0].url
    }

    const simpleDatas = {
      menu_hours_enable: datas.menu_hours_enable || false,
      menu_hours: datas.menu_hours || [],
      menu_display_hours: formatHoursFromDb(datas.menu_display_hours || []),
      ...pdf,
      ...commonDatas,
      background_image_url,
      description_ml: updateMultilangArray(
        datas.description,
        menuDatas?.description_ml,
        currentLang
      ),
      name_ml: updateMultilangArray(datas.name, menuDatas?.name_ml, currentLang),
      footer_ml: updateMultilangArray(
        datas.menu_footer,
        menuDatas?.footer_ml,
        currentLang
      ),
      menu_theme: datas.menu_theme || 'light',
      menu_specific_theme: datas.menu_specific_theme || false,
      theme_color: datas.theme_color || '#0F0F0F',
      theme_darkmode: datas.theme_darkmode || false,
      theme_font: datas.theme_font || 'Arial',
    }

    try {
      await updateMenu(simpleDatas, { merge: true })
      enqueueSnackbar(t('menu_saved_succeed'), { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(t('error_updating_data_menu'), { variant: 'error' })
    }

    setSaving(false)
  }

  const displayView = () => {
    const _props_menu = {
      register,
      modePDF,
      setValue,
      menuDatas,
      background_image_url: menuDatas.background_image_url,
      placeDatas,
      sections,
      errors,
      currentLang,
      addSection,
      sectionId,
      productId,
    }
    const props_display = {
      menuDatas,
      menu_hours: menuDatas.menu_hours || [],
      unregister,
      formatHoursFromDb,
      setValue,
    }
    const props_theme = {
      menuDatas,
      placeDatas,
      register,
      control,
      watch,
    }
    switch (tab) {
      case 'menu':
        return <Menu {..._props_menu} />
      case 'display':
        return <Display {...props_display} />
      case 'theme':
        return <Theme {...props_theme} />
      default:
        return <Menu {..._props_menu} />
    }
  }

  if (!placeDatas || !menuDatas) {
    return null
  } else {
    return (
      <CustomContainer fluid>
        <form>
          <div style={{ marginTop: '70px' }} />
          <Row>
            <Col lg="8">
              <Navbar items={navItems} setTab={setTab} tab={tab} t={t} />
            </Col>
            <Col lg="4" style={{ marginTop: '20px' }}>
              <ShowButton
                variant="outline"
                href={`/place/${placeDatas?.place_slug}/menu/${menu_id}`}
                target="_blank"
              >
                {t('visualize')}
              </ShowButton>
              <SaveButton
                disabled={saving}
                onClick={handleSubmit(handleSubmitForm)}
                type="submit"
                variant="secondary"
              >
                {saving ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  t('save')
                )}
              </SaveButton>
            </Col>
          </Row>
          <ThemeProvider theme={theme}>
            {tab === 'menu' && (
              <LangSelector
                placeDatas={placeDatas}
                lang={lang}
                menu_id={menu_id}
                id={placeDatas.id}
                action={handleSubmit(handleSubmitForm)}
              />
            )}
            {menuDatas !== null ? (
              displayView()
            ) : (
              <Spinner animation="border" size="lg" variant="dark" />
            )}
          </ThemeProvider>
        </form>
      </CustomContainer>
    )
  }
}

export default withTheme(withTranslation()(CreateMenu))
const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const SaveButton = styled(Button)`
  margin-left: 5%;
  width: 45%;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ShowButton = styled(Button)`
  margin-right: 5%;
  padding-left: 18px;
  padding-right: 31px;
  width: 45%;
  border-style: solid;
  border: 1px;
  background-color: white;
  color: ${(props) => props.theme.primary};
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
