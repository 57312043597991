import React from 'react';
import {
 Container, Form, Button, Spinner, Alert,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useGoogle from '../../hooks/GoogleAuth';

const queryString = require('query-string');

const LoginFormResetPassword = ({ t, location }) => {
const { register, handleSubmit, formState: { errors } } = useForm();
const { resetPassordErrors, confirmResetPassword } = useGoogle();
const [loading, setLoading] = React.useState(false);
const { search } = useLocation();

const handleResetForm = async (datas) => {
	setLoading(true);
    const parsed = queryString.parse(search);
    if (parsed.oobCode) {
	const reset = await confirmResetPassword(parsed.oobCode, datas.new_password);

    if (reset.success) {
        alert(reset.message);
    }
}
	setLoading(false);
};

  return (

    <Container>

      {resetPassordErrors !== null

    	 	&& (
    <Alert>
      {resetPassordErrors}
    </Alert>
)}
      <Form onSubmit={handleSubmit(handleResetForm)}>
        <Form.Group>
          <Form.Control
    	 	isInvalid={errors.new_password}
    	 	{...register('new_password', { required: true })}
    	 	placeholder={t('new password')}
    	 	type="password"
          />
          <Form.Control.Feedback type="invalid">{t('new password required')}</Form.Control.Feedback>

        </Form.Group>

        <div style={{ textAlign: 'center' }}>
          <Button disabled={loading} type="submit">
            {loading ? <><Spinner variant="light" size="sm" animation="border" /></> : t('validate')}
          </Button>
        </div>
      </Form>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Link to="/login">{t('login')}</Link>
      </div>

    </Container>
  );
};

export default withTranslation()(LoginFormResetPassword);
