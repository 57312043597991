import React, { useState, useEffect } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { Container, Button, Spinner, Row, Col, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import { Folder, Search, Remove, Add } from '@material-ui/icons'
import styled, { withTheme } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import ProductIcon from '../../../../assets/menu/products.svg'
import PlaceContainer from '../../../../stores/PlaceContainer'

import { Functions } from '../../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../../stores/AuthContainer'
import {
  getMultilangContent,
  updateMultilangArray,
} from '../../../../utils/MultilangHelpers'
import { formatProductDatabaseOrder } from '../../../../utils/MenuHelpers'
import { useFirestoreApi } from '../../../../hooks/FireTools'
import LangSelector from '../../../../components/UI/form/LangSelector/LangSelector'
import ProductsDnD from './ProductsDnD/ProductsDnD'
import _ from 'underscore'

const queryString = require('query-string')

const category_select = ['all', 'foods', 'drinks', 'others']

const products = [
  {
    id: 0,
    category_name: 'foods',
    products: [],
    groups: [],
  },
  {
    id: 1,
    category_name: 'drinks',
    products: [],
    groups: [],
  },
  {
    id: 2,
    category_name: 'others',
    products: [],
    groups: [],
  },
]

const Products = ({ t, productDatabase }) => {
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const { register, setValue } = useForm()
  const { place_id, lang } = queryString.parse(location.search)
  const [items, setItems] = useState(products)
  const history = useHistory()
  const { user } = AuthContainer.useContainer()
  const [savingDatas, setSavingDatas] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [index, setIndex] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [subCatSelect, setSubCatSelect] = useState('all')
  const [catSelect, setCatSelect] = useState('all')
  const { place } = productDatabase || PlaceContainer.useContainer()
  const { updateDatas } = useFirestoreApi()
  let currentLang = lang || place?.main_language || 'FR'
  const productWhere = productDatabase
    ? {
        listen: true,
        orderBy: ['order', 'asc'],
      }
    : {
        where: [
          ['place_id', '==', place?.id],
          ['uid', '==', user?.uid],
        ],
        orderBy: ['order', 'asc'],
        listen: true,
      }
  const catWhere = productDatabase
    ? {
        listen: true,
      }
    : {
        where: [
          ['place_id', '==', place?.id],
          ['uid', '==', user?.uid],
        ],
        listen: true,
      }
  const { data: productList } = useCollection(
    user?.uid && (place?.id || productDatabase)
      ? `products${productDatabase ? '-database' : ''}`
      : null,
    productWhere
  )
  const { data: productCats } = useCollection(
    user?.uid && (place?.id || productDatabase)
      ? `product-cats${productDatabase ? '-database' : ''}`
      : null,
    catWhere
  )

  const productCount = (item) => {
    let tmp = item.products?.length

    item.groups.map((group) => {
      tmp += group.products?.length
    })
    return tmp
  }

  const parseCats = (fItems, _cats, original = false) => {
    const types = [...products]
    const productUnCategorized = fItems.filter(
      (p) => !p?.subcat || p.subcat === '' || !_cats.find((c) => c.id === p.subcat)
    )
    const productCategorized = _cats.reduce((acc, cat) => {
      setValue(
        `name_cat_${cat.id}`,
        getMultilangContent(cat, 'name_ml', currentLang)
      )
      const updatedCat = { ...cat }
      const catProducts = _.where(fItems, { subcat: cat.id })
      updatedCat.products = catProducts.sort((a, b) => a.order - b.order) || []

      return [...acc, updatedCat]
    }, [])

    const parsed = types.map((t) => {
      const un =
        subCatSelect === 'uncategorized' || subCatSelect === 'all' || original
          ? productUnCategorized.filter((p) => p.cat === t.category_name)
          : []
      let cat =
        subCatSelect !== 'uncategorized' || original
          ? productCategorized
              .filter((p) => p.cat === t.category_name)
              .sort((a, b) => a.product_list_position - b.product_list_position)
          : []
      if (!original) {
        cat = cat.filter((p) => p.id === subCatSelect || subCatSelect === 'all')
      }

      return { ...t, products: un || [], groups: cat || [] }
    })
    return parsed
  }

  const formatItems = (_docs, _cats) => {
    const _products = [...items]
    const fItems = _docs.map((_i, index) => ({
      id: `product-${_i.id}`,
      pid: _i.id,
      product_name: getMultilangContent(_i, 'product_name_ml', currentLang),
      product_description: getMultilangContent(
        _i,
        'product_description_ml',
        currentLang
      ),
      order: _i.order,
      product_labels: _i?.product_labels,
      product_alergens: _i?.product_alergens,
      prices: _i.product_prices,
      promoPercentage: _i?.product_promo_reduction,
      imgUrl: _i?.product_main_image_url,
      cat: _i.tab,
      subcat: _i?.subcat || '',
    }))

    products.map((p, i) => {
      _products[i].products = fItems.filter((_p) => _p.cat === p.category_name)
    })

    setItems(parseCats(fItems, _cats))
  }

  useEffect(() => {
    if (productList && (place || productDatabase)) {
      formatItems(productList, productCats || [])
    }
  }, [productList, place, currentLang, productCats, subCatSelect])

  const addCat = ({ cat }) => {
    updateDatas({
      query: `product-cats${productDatabase ? '-database' : ''}`,
      message: {
        success: t('product_cat_created'),
        error: t('product_cat_created_failed'),
      },
      datas: {
        uid: user.uid,
        place_id: productDatabase || place.id,
        cat,
        order: 100000,
        name_ml: [],
        products: [],
      },
      action: 'add',
    })
  }

  const syncProdsWithMenus = async (product_id) => {
    const _syncFunction = Functions().httpsCallable(
      'products-updateProductsInMenusOnCall'
    )

    return _syncFunction({
      data: {
        productId: product_id,
        action: 'deleted',
      },
    })
      .then((data) => data)
      .catch((e) => e)
  }

  const handleRemoveProduct = async (product) => {
    setSavingDatas(true)
    await updateDatas({
      query: `products${productDatabase ? '-database' : ''}/${product.pid}`,
      message: {
        success: t('delete_succeed'),
        error: t('delete_failed'),
      },
      action: 'delete',
    })
    setSavingDatas(false)
    setIsOpen(false)
    await syncProdsWithMenus(product.pid)
  }

  const handleDuplicateProduct = async (productId) => {
    setSavingDatas(true)
    try {
      const duplicate = Functions().httpsCallable('products-duplicateProduct')

      duplicate({
        data: {
          productId,
        },
      })
        .then((result) => {
          setSavingDatas(false)

          if (result.data.id) {
            enqueueSnackbar(t('product_copy_succeed'), { variant: 'success' })
          }
        })
        .catch((e) => {
          enqueueSnackbar(t('product_copy_failed'), { variant: 'error' })
          setSavingDatas(false)
        })
    } catch (e) {
      setSavingDatas(false)
      enqueueSnackbar(t('product_copy_failed'), { variant: 'error' })
    }
  }

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const reorderInDb = async (list, type) =>
    Promise.all(
      list.map(async (p) => {
        await updateDatas({
          query: `${type}/${p?.pid || p.id}`,
          datas: {
            order: p.order,
          },
          type: 'document',
          action: 'set',
        })
      })
    )
  const onDragEnd = (result, category_index) => {
    if (!result.destination) {
      return
    }
    const _type = result.destination.droppableId.split('-')[0]
    const _id = result.destination.droppableId.split('-')[1]
    const parent_index = result.type.split('-')[1]
    const DraggableId =
      result.type !== 'ITEM' ? result.draggableId.split('-')[1] : result.draggableId

    const _items = [...formatProductDatabaseOrder(items[parent_index])]
    const itemState = [...items]
    if (_type === 'CATEGORY') {
      const reordered = Reorder(
        _items[result.draggableId.split('-')[2]].products,
        result.source.index,
        result.destination.index
      )

      if (_id !== 'UNCLASSED') {
        _items[result.draggableId.split('-')[2]].products = reordered
        if (
          _items[result.draggableId.split('-')[2]].products[result.source.index]
            .subcat !== _id
        ) {
          updateDatas({
            query: `products${productDatabase ? '-database' : ''}/${DraggableId}`,
            datas: {
              subcat: _id,
            },
            action: 'set',
          })
        }
      } else if (_id === 'UNCLASSED') {
        itemState[parent_index].products = reordered

        if (
          _items[result.draggableId.split('-')[2]].products[result.source.index]
            .subcat !== ''
        ) {
          updateDatas({
            query: `products${productDatabase ? '-database' : ''}/${DraggableId}`,
            datas: {
              subcat: '',
            },
            action: 'set',
          })
        }
      } else return
      setItems(itemState)
      reorderInDb(
        reordered.map((r, index) => ({ ...r, order: index })),
        `products${productDatabase ? '-database' : ''}`
      )
    } else if (_type === 'ITEM') {
      const reordered = Reorder(
        _items,
        result.source.index,
        result.destination.index
      )

      reorderInDb(
        reordered
          .filter((i) => i.id !== 'UNCLASSED')
          .map((r, index) => ({ ...r, order: index })),
        `product-cats${productDatabase ? '-database' : ''}`
      )
    }
  }

  const editSubCat = ({ id, datas, action }) => {
    updateDatas({
      query: `product-cats${productDatabase ? '-database' : ''}/${id}`,
      message: {
        success: t('product_cat_updated_succeed'),
        error: t('product_cat_updated_failed'),
      },
      datas,
      action,
    })

    if (action === 'delete') {
      _.where(productList, { subcat: id }).map((p) => {
        updateDatas({
          query: `products${productDatabase ? '-database' : ''}/${p.id}`,
          type: 'document',
          datas: {
            subcat: '',
          },
          action: 'set',
        })
      })
    }
  }
  const DragAndDropRender = (category, category_index, searchInput) => (
    <ProductsDnD
      onDragEnd={onDragEnd}
      category={category}
      category_index={category_index}
      searchInput={searchInput}
      currentLang={currentLang}
      register={register}
      functions={{
        editProd: (id) =>
          history.push(
            `/dashboard/edit/new/product?product_id=${id}${
              productDatabase ? '&toProductDb=1' : `&place_id=${place.id}`
            }`
          ),
        delete: (id) => {
          setIsOpen(true)
          setDeleteId(id)
        },
        editCatTitle: (arr, value, id) => {
          editSubCat({
            datas: {
              name_ml: updateMultilangArray(value, arr, currentLang),
            },
            id,
            action: 'set',
          })
        },
        removeCat: (id) => editSubCat({ action: 'delete', id }),
        duplicateProduct: handleDuplicateProduct,
      }}
    />
  )

  return (
    <Container fluid>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>{t('remove_product')}?</ModalTitle>
          <ModalSubTitle>{t('remove_product_subtitle')}</ModalSubTitle>
          <FlexModal>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => handleRemoveProduct(deleteId)}
            >
              {t('confirm')}
            </Button>
          </FlexModal>
        </Modal.Body>
      </Modal>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <NewProductButton
          variant="secondary"
          onClick={() =>
            history.push(
              `/dashboard/edit/new/product?place_id=${
                !productDatabase ? place_id : 'ADMIN'
              }${productDatabase ? '&toProductDb=1' : ''}`
            )
          }
        >
          <img
            src={ProductIcon}
            alt="logo"
            style={{
              width: '23px',
              marginRight: '20px',
              marginTop: '-3px',
            }}
          />
          {t('create new product')}
        </NewProductButton>
        <br />
        <br />
        <LangSelector placeDatas={place} lang={lang} id={place_id} />
      </div>
      <Row>
        <Col>
          {' '}
          {savingDatas && <Spinner animation="border" size="lg" variant="dark" />}
        </Col>
      </Row>
      <br />
      <Background>
        <Row>
          <Col lg="10">
            <SearchInputContainer>
              <SearchIcon />
              <SearchInput
                placeholder={t('search product')}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </SearchInputContainer>
          </Col>
          <Col lg="2">
            <CatSelect
              defaultValue="all"
              onChange={(e) => {
                if (e.target.value !== '0')
                  setIndex(parseInt(e.target.value, 10) - 1)
                setCatSelect(category_select[e.target.value])
              }}
              catSelect
            >
              {category_select.map((cat, i) => (
                <option key={`cat${i}`} value={i}>
                  {t(cat)}
                </option>
              ))}
            </CatSelect>
            {/* <CatSelect
              onChange={(e) => setSubCatSelect(e.target.value)}
              defaultValue="all"
            >
              <option value="all">Toutes les catégories</option>
              <option value="uncategorized">Produits non classés</option>
              {parseCats(productList || [], productCats || [], true).map((l) => (
                <optgroup label={t(l.category_name)}>
                  {l.groups.map((g) => (
                    <option value={g.id}>
                      {getMultilangContent(g, 'name_ml', currentLang)}
                    </option>
                  ))}
                </optgroup>
              ))}
            </CatSelect> */}
          </Col>
        </Row>
      </Background>
      <br />
      {items.map((item, i) => {
        let cpt = 0
        const [visible, setVisible] = useState(true)

        item.products.map((product) => {
          if (product.product_name?.includes(searchInput)) cpt++
        })
        item.groups.map((group) => {
          group.products.map((product) => {
            if (product.product_name?.includes(searchInput)) cpt++
          })
        })
        return (
          (index === i || catSelect === 'all') && (
            <>
              {cpt > 0 ? (
                <Background>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Flex>
                      <Title>{t(item.category_name)}</Title>
                      <ProductNb>
                        {productCount(item)} {t('product')}
                      </ProductNb>
                    </Flex>
                    {visible ? (
                      <RemoveIcon onClick={() => setVisible(!visible)} />
                    ) : (
                      <AddIcon onClick={() => setVisible(!visible)} />
                    )}
                  </div>
                  {visible && (
                    <>
                      {DragAndDropRender(item, i, searchInput)}
                      <div style={{ marginTop: '20px' }}>
                        <AddCatButton
                          onClick={() => addCat({ cat: item.category_name })}
                          variant="none"
                        >
                          <FolderIcon />
                          {t('create group')}
                        </AddCatButton>
                      </div>
                    </>
                  )}
                </Background>
              ) : catSelect !== 'all' ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '200',
                    padding: '40px',
                  }}
                >
                  Rien à afficher
                </div>
              ) : null}
              <br />
            </>
          )
        )
      })}
    </Container>
  )
}

export default withTheme(withTranslation()(Products))

const Flex = styled.div`
  display: flex;
`
const AddCatButton = styled(Button)`
  margin: 0px 0px 20px 40px;
  background-color: #e6efff;
  font-weight: bold;
  color: #6f4fb3;
  width: 220px;
  height: 40px;
  :hover {
    color: #6f4fb3;
  }
`
const NewProductButton = styled(Button)`
  width: 350px;
  @media screen and (min-width: 400px) {
    height: 50px;
  }
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SearchInputContainer = styled.div`
  display: inline-flex;
  width: 100%;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
`
const SearchInput = styled.input`
  margin-left: 10px;
  outline: none;
  font-weight: bold;
  width: 85%;
  background: transparent;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  ::placeholder {
    opacity: 0.5;
  }
`
const CatSelect = styled.select`
  cursor: pointer;
  height: 35px;
  width: 80%;
  max-width: 200px;
  text-align: center;
  background: #e6efff;
  padding: 5px;
  margin-right: 8px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  margin-top: 2px;
  ::placeholder {
    opacity: 0.5;
  }
`
const Title = styled.div`
  text-align: initial;
  font-size: 13pt;
  font-weight: bold;
  margin-left: 15px;
`
const Background = styled.div`
  padding: 20px 40px 20px 40px;
  @media screen and (max-width: 526px) {
    padding: 20px 10px 20px 10px;
  }
  box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
`
const ProductNb = styled.div`
  font-size: 13pt;
  font-weight: 400;
  color: #6f4fb3;
  margin-top: ${(props) => props.top && '10px'};
  margin-left: 15px;
  margin-bottom: 10px;
`
const FolderIcon = styled(Folder)`
  color: #6f4fb3;
  margin: 0px 10px 5px 0px;
  width: 20px !important;
  height: 20px !important;
`
const SearchIcon = styled(Search)`
  color: rgba(0, 0, 0, 0.5);
  width: 20px !important;
  height: 20px !important;
  margin: 10px 0px 0px 20px;
`
const AddIcon = styled(Add)`
  width: 25px !important;
  height: 25px !important;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`
const RemoveIcon = styled(Remove)`
  width: 25px !important;
  height: 25px !important;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`
const FlexModal = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`
