import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const config = require('../../../../config')

const UpdateTrialSummary = ({ selectedPlan, couponElements }) => {
	const { unit_amount } = selectedPlan
	const { t } = useTranslation()
	React.useEffect(() => {
		couponElements.reset()
	}, [selectedPlan])
	return (
		<UpdateTrialSummaryStyled loading={couponElements.loading}>
			<couponElements.element
				notValid={t('coupon_not_valid')}
				key="couponFields"
				placeholder={t('enter_your_coupon')}
				cancel={t('cancel_coupon')}
				apply={t('apply_coupon')}
				total={unit_amount}
				style={{
					textAlign: 'center',
					margin: '8px 0',
					background: 'white',
					padding: '8px',
					borderRadius: '9px',
				}}
			/>

			<ul>
				<li>
					<b>Total TTC</b> : {parseFloat(unit_amount / 100).toFixed(2)} €
				</li>
				<li>
					<b>Total HT</b> :
					{parseFloat(unit_amount / 100 / config.taxes.tax_20).toFixed(2)} €
				</li>
				{couponElements.datas?.reductedPrice && (
					<li>
						<b>
							Total après réduction : {couponElements.datas?.reductedPrice} € TTC{' '}
						</b>
					</li>
				)}
			</ul>
		</UpdateTrialSummaryStyled>
	)
}

export default UpdateTrialSummary

const UpdateTrialSummaryStyled = styled.div`
	ul {
		background: white;
		padding: 23px;
		list-style: none;
		text-align: center;
		border-radius: 9px;
		line-height: 30px;
	}

	opacity: ${(props) => props.loading && '0.5'};
	pointer-events: ${(props) => props.loading && 'none'};
`
