import React from 'react';
import { Redirect } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';

const Index = (props) => (
  <DefaultLayout>
    <Redirect to="/login" />
  </DefaultLayout>
  );

export default Index;
