import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Card, CardContent, Grid, CardActions } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import useProductBaseStore from '../ProductBaseStore'
import RestaurantCardModal from '../../../../../../components/Menus/Restaurant/RestaurantCard/RestaurantCardModal/RestaurantCardModal'

const Product = ({ product }) => {
	const { addToCart, cart, removeFromCart } = useProductBaseStore.useContainer()
	const [isOpen, setIsOpen] = useState(false)
	const inCart = cart.find((c) => c.id === product.id)

	return (
		<>
			<RestaurantCardModal
				datas={{
					color: '#6F4FB3',
					darkmode: false,
					topbanner: true,
				}}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				currentLang="fr"
				currentCard={product}
			/>
			<CustomCard inCart={inCart}>
				<Grid container>
					<div style={{ width: '60%', display: 'block' }}>
						<CardContent
							onClick={() => setIsOpen(true)}
							style={{ height: '75px', fontWeight: 'bold', overflow: 'hidden' }}
						>
							{product.product_name}
						</CardContent>
						<CardActions>
							{!inCart ? (
								<IconContainer onClick={() => addToCart(product)}>
									<AddContainer>
										<Add />
									</AddContainer>
									<div style={{ marginTop: '5px' }}>Ajouter</div>
								</IconContainer>
							) : (
								<IconContainer onClick={() => removeFromCart(product)}>
									<RemoveContainer>
										<Remove />
									</RemoveContainer>
									<div style={{ marginTop: '5px' }}>Retirer</div>
								</IconContainer>
							)}
						</CardActions>
					</div>
					<div style={{ width: '40%', textAlign: 'end', paddingRight: '30px' }}>
						{product.product_main_image_url && (
							<CustomImg
								alt="icon"
								onClick={() => setIsOpen(true)}
								src={product.product_main_image_url}
							/>
						)}
					</div>
				</Grid>
			</CustomCard>
		</>
	)
}

export default withTheme(Product)

const CustomCard = styled(Card)`
	cursor: pointer;
	min-width: 300px;
	min-height: 140px;
	border-style: ${(props) => (props.inCart ? 'solid' : 'none')};
	border-width: 1px;
	border-color: #6f4fb3;
`
const CustomImg = styled.img`
  width 100px;
	height: 100px;
	border-radius: 12px;
	margin-top: 20px;
`
const IconContainer = styled.div`
	cursor: pointer;
	display: flex;
	color: #6f4fb3;
	margin-left: 10px;
`
const AddContainer = styled.div`
	border-style: solid;
	border-width: 1px;
	border-color: #6f4fb3;
	margin-right: 10px;
	border-radius: 5px;
	width: 35px;
	height: 35px;
	text-align: center;
	padding-top: 4px;
`
const RemoveContainer = styled.div`
	background-color: #6f4fb3;
	color: white;
	margin-right: 10px;
	border-radius: 5px;
	width: 35px;
	height: 35px;
	text-align: center;
	padding-top: 4px;
`
