import {
  Facebook,
  Instagram,
} from '@material-ui/icons'

const ContactThemeLogo = ({ styleContainer, isWhite, fontColor }) => (
  <div className="d-flex justify-content-center align-items-center" style={styleContainer}>
    <Facebook
        style={{
          margin: '0 15px', 
          borderRadius: '100%', 
          color: isWhite ? 'white' : fontColor, 
          backgroundColor: isWhite ? fontColor : 'white', 
          fontSize: '30px',
          padding: '3px', 
        }}
    />
    <Instagram
      style={{
        margin: '0 15px', 
        borderRadius: '100%', 
        color: isWhite ? 'white' : fontColor, 
        backgroundColor: isWhite ? fontColor : 'white', 
        padding: '3px', 
        fontSize: '30px',
      }}
    />
    {/* <div
        onClick={() => console.log('open actualité')}
        style={{
        fontSize: '16px', fontWeight: 'bold', color: isWhite ? 'white' : '#276347', padding: '2px 11px', borderRadius: '15px', backgroundColor: isWhite ? '#276347' : 'white',
        }}
    >
      Actualités
    </div> */}
  </div>
)

export default ContactThemeLogo;
