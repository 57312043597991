import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import styled, { withTheme } from 'styled-components';

const AlSearchBox = (props) => (
  <SearchBoxStyled {...props} />
  );

export default withTheme(AlSearchBox);

const SearchBoxStyled = styled(SearchBox)`

.ais-SearchBox-input{
 border-radius : 50px ; 
 box-shadow : none; 

&:focus{
	border-color : ${(props) => props.theme.primary} ; 
}

}
.ais-SearchBox-input.focus-visible{}


`;
