import React, { useState } from 'react'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { BackspaceOutlined } from '@material-ui/icons';
import { withTranslation } from 'react-i18next'
import { Button, Row, Col } from 'react-bootstrap'
import styled, { withTheme } from 'styled-components'
import { Controller } from 'react-hook-form'

const type = [
  { value: 'Bière' },
  { value: 'Cidre' },
  { value: 'Boissons alcoolisées' },
]

const colors = [
  { value: 'Rouge' },
  { value: 'Blanc' },
  { value: 'Rosé' },
  { value: 'Moelleux' },
]

const with_alcohol = [
  { label: 'Avec alcool', value: 'yes' },
  { label: 'Sans alcool', value: 'no' },
]

const states = [{ value: 'Effervescent' }, { value: 'Non pétillant' }]

const family = [
  { value: 'Doux' },
  { value: 'Demi sec' },
  { value: 'Brut' },
  { value: 'Traditionnel' },
  { value: 'Rosé' },
]

const beer_color = [
  { value: 'Ambrée' },
  { value: 'Blanche' },
  { value: 'Blonde' },
  { value: 'Brune' },
  { value: 'Rousse' },
  { value: 'Inclassable' },
]

const wine_country = [
  { value: 'Alsace' },
  { value: 'Beaujolais' },
  { value: 'Bordeaux' },
  { value: 'Bourgogne' },
  { value: 'Gasgogne' },
  { value: 'Champagne' },
  { value: 'Charentes' },
  { value: 'Jura' },
  { value: 'Languedoc-roussillon' },
  { value: 'Loire' },
  { value: 'Lorraine' },
  { value: 'Provence' },
  { value: 'Rhône' },
  { value: 'Savoie bugey' },
  { value: 'Sud-ouest' },
  { value: 'Corse' },
  { value: 'Vins étrangers' },
  { value: 'Inclassable' },
]

const wine_year = [
  { value: '2021' },
  { value: '2020' },
  { value: '2019' },
  { value: '2018' },
  { value: '2017' },
  { value: '2016' },
  { value: '2015' },
  { value: '2014' },
  { value: '2013' },
  { value: '2012' },
  { value: '2011' },
  { value: '2010' },
  { value: '2009' },
  { value: '2008' },
  { value: '2007' },
  { value: '2006' },
  { value: '2005' },
  { value: '2004' },
  { value: '2003' },
  { value: '2002' },
  { value: '2001' },
  { value: '2000' },
  { value: '1999' },
  { value: '1998' },
  { value: '1997' },
  { value: '1996' },
  { value: '1995' },
  { value: '1994' },
  { value: '1993' },
  { value: '1992' },
  { value: '1991' },
  { value: '1990' },
  { value: '1989' },
  { value: '1988' },
  { value: '1987' },
  { value: '1986' },
  { value: '1985' },
  { value: '1984' },
  { value: '1983' },
  { value: '1982' },
  { value: '1981' },
  { value: '1980' },
  { value: '1979' },
  { value: '1978' },
  { value: '1977' },
  { value: '1976' },
  { value: '1975' },
  { value: '1974' },
  { value: '1973' },
  { value: '1972' },
  { value: '1971' },
  { value: '1970' },
  { value: '1969' },
  { value: '1968' },
  { value: '1967' },
  { value: '1966' },
  { value: '1965' },
  { value: '1964' },
  { value: '1963' },
  { value: '1962' },
  { value: '1961' },
  { value: '1960' },
  { value: '< 1960' },
]

const country = [
  { value: 'Allemagne' },
  { value: 'Autriche' },
  { value: 'Belgique' },
  { value: 'Bulgarie' },
  { value: 'Chypre' },
  { value: 'Danemark' },
  { value: 'Espagne' },
  { value: 'Estonie' },
  { value: 'Finlande' },
  { value: 'France' },
  { value: 'Grece' },
  { value: 'Irlande' },
  { value: 'Islande' },
  { value: 'Italie' },
  { value: 'Lettonie' },
  { value: 'Liechtenstein' },
  { value: 'Lituanie' },
  { value: 'Luxembourg' },
  { value: 'Malte' },
  { value: 'Monaco' },
  { value: 'Norvege' },
  { value: 'Pays Bas' },
  { value: 'Pologne' },
  { value: 'Portugal' },
  { value: 'Republique Tcheque' },
  { value: 'Roumanie' },
  { value: 'Royaume Uni' },
  { value: 'Russie' },
  { value: 'Slovaquie' },
  { value: 'Slovenie' },
  { value: 'Suede' },
  { value: 'Suisse' },
  { value: 'Ukraine' },
  { value: 'Vatican' },
]

const spirit_type = [
  { value: 'Whisky' },
  { value: 'Vodka' },
  { value: 'Rhum' },
  { value: 'Cognac' },
  { value: 'Gin' },
  { value: 'Tequila' },
  { value: 'Calvodos' },
  { value: 'Absinthe' },
  { value: 'Bourbon' },
  { value: 'Armagnac' },
  { value: 'Brandy' },
  { value: 'Anis' },
  { value: 'Pastis' },
  { value: 'Saké' },
  { value: 'Cachaça' },
  { value: 'Vermouth' },
  { value: 'Grappa' },
  { value: 'Eau de Vie' },
  { value: 'Liqueur et Crème' },
  { value: 'Autres' },
]

const coffee_type = [
  { value: 'Café' },
  { value: 'Thé' },
  { value: 'Maté' },
  { value: 'Chocolat' },
  { value: 'Infusions' },
]

const InfosBox = ({ t, control, register }) => (
  <div style={{ marginTop: '10px' }}>
    <Controller
      name="product_additional_infos_type"
      control={control}
      defaultValue=''
      render={({ field: { onChange, value, ref } }) => (
        <RadioGroup
          aria-label="gender"
          onChange={onChange}
          value={value}
          ref={ref}
        >
          {value === 'wine' ? (
            <BackgroundChecked>
              <Flex>
                <FormControlLabel value="wine" control={<Radio />} />
                <RadioTitle>
                  {t('wine')}
                </RadioTitle>
              </Flex>
              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('name *')}
                    {...register('product_name')}
                  />
                </Col>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('description')}
                    {...register('product_description')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[color]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {' '}
                          <option value='' selected hidden>
                            {t('color')}
                          </option>
                          {colors.map((color, i) => (
                            <option
                              selected={color.value === value}
                              key={`color${i}`}
                              value={color.value}
                            >
                              {color.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {value !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChange('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[country]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {' '}
                          {value === '' && (
                            <option value="none" selected hidden>
                              {t('region')}
                            </option>
                          )}
                          {wine_country.map((val, i) => (
                            <option
                              selected={val.value === value}
                              key={`country${i}`}
                              value={val.value}
                            >
                              {val.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {value !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChange('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder="Nom de cuvée"
                    {...register('drinks_datas[cuvee_name]')}
                  />
                </Col>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[states]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {' '}
                          {value === '' && (
                            <option value="none" selected hidden>
                              État
                            </option>
                          )}
                          {states.map((state, i) => (
                            <option
                              selected={state.value === value}
                              key={`state${i}`}
                              value={state.value}
                            >
                              {state.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {value !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChange('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[millesime]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {' '}
                          {value === '' && (
                            <option value="none" selected hidden>
                              {t('vintage')}
                            </option>
                          )}
                          {wine_year.map((promo, i) => (
                            <option
                              selected={promo.value === value}
                              key={`promo${i}`}
                              value={promo.value}
                            >
                              {promo.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {value !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChange('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[with_alcohol]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                      <InfoSelect
                        onBlur={onBlur}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      >
                        {value === '' && (
                          <option value="none" selected hidden>
                            {t('alcool')}
                          </option>
                        )}
                        {with_alcohol.map((_with_alcohol, i) => (
                          <option
                            selected={_with_alcohol.value === value}
                            key={`_with_alcohol${i}`}
                            value={_with_alcohol.value}
                          >
                            {_with_alcohol.label}
                          </option>
                        ))}
                      </InfoSelect>
                      {(value === 'yes') && 
                        <Row>
                          <Col lg="12">
                            <InputAlcool
                              {...register('drinks_datas[alcool_level]')}
                              defaultValue={0}
                              type="number"
                              min="0"
                              max="100"
                              step="0.1"
                            />
                            <Tricks>{`${t('% of alcool')} `}</Tricks>
                          </Col>
                        </Row>
                      }
                    </>
                    )}
                  />
                </Col>
              </Row>
              <ShiftTipsArea
                {...register('drinks_datas[advice]')}
                defaultValue=''
                placeholder="Conseils de service"
              />
            </BackgroundChecked>
          ) : (
            <BackgroundUnchecked onClick={() => onChange('wine')}>
              <Flex>
                <FormControlLabel value="wine" control={<Radio />} />
                <RadioTitle>
                  {t('wine')}
                </RadioTitle>
              </Flex>
            </BackgroundUnchecked>
          )}
          <br />
          {value === 'beer' ? (
            <BackgroundChecked>
              <Flex>
                <FormControlLabel value="beer" control={<Radio />} />
                <RadioTitle>
                  {t('beer & ciders & alcool')}
                </RadioTitle>
              </Flex>

              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('name *')}
                    {...register('product_name')}
                  />
                </Col>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('description')}
                    {...register('product_description')}
                  />
                </Col>
              </Row>
              <Row>
                <Controller
                  control={control}
                  name="drinks_datas[type]"
                  defaultValue=''
                  render={({
                    field: {
                      onChange: onChangeType,
                      onBlur: onBlurType,
                      value: valueType,
                      ref,
                    },
                  }) => (
                    <>
                      <Col lg="6">
                        <>
                          <InfoSelect
                            onBlur={onBlurType}
                            onChange={onChangeType}
                            inputRef={ref}
                            value={valueType}
                          >
                            {valueType === '' && (
                              <option value="none" selected hidden>
                                {t('type')}
                              </option>
                            )}
                            {type.map((promo, i) => (
                              <option
                                selected={(promo.value === valueType) && (valueType !== '')}
                                key={`promo${i}`}
                                value={promo.value}
                              >
                                {promo.value}
                              </option>
                            ))}
                          </InfoSelect>
                          {valueType !== '' && 
                            <DelButton
                              variant="primaryoutline"
                              onClick={() => onChangeType('')}
                            >
                              <DelIcon/>
                            </DelButton>
                          }
                      </>
                      </Col>
                      <Col lg="6">
                        {valueType !== '' &&
                          valueType !== 'Boissons alcoolisées' && (
                            <Controller
                              control={control}
                              name={`drinks_datas[${
                                valueType === 'Bière' ? 'beer_color' : 'family'
                              }]`}
                              defaultValue=''
                              render={({
                                field: { onChange, onBlur, value, name, ref },
                              }) => (
                                <>
                                  <InfoSelect
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    inputRef={ref}
                                    value={value}
                                  >
                                    {valueType === '' && (
                                      <option value="none" selected hidden>
                                        {valueType === 'Bière'
                                          ? `${t('color')} `
                                          : `${t('family')} `}
                                      </option>
                                    )}
                                    {valueType === 'Bière' &&
                                      beer_color.map((val, i) => (
                                        <option
                                          selected={val.value === value}
                                          key={`color${i}`}
                                          value={val.value}
                                        >
                                          {val.value}
                                        </option>
                                      ))}
                                    {valueType === 'Cidre' &&
                                      family.map((val, i) => (
                                        <option
                                          selected={val.value === value}
                                          key={`family${i}`}
                                          value={val.value}
                                        >
                                          {val.value}
                                        </option>
                                      ))}
                                  </InfoSelect>
                                  {value !== '' && 
                                    <DelButton
                                      variant="primaryoutline"
                                      onClick={() => onChange('')}
                                    >
                                      <DelIcon/>
                                    </DelButton>
                                  }
                                </>
                              )}
                            />
                          )}
                      </Col>
                    </>
                  )}
                />
              </Row>
              <Row>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[country]"
                    defaultValue=''
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {' '}
                          {value === '' && (
                            <option value="none" selected hidden>
                              {t('region')}
                            </option>
                          )}
                          {country.map((val, i) => (
                            <option
                              selected={val.value === value}
                              key={`country${i}`}
                              value={val.value}
                            >
                              {val.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {value !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChange('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col><Col lg="6">
                  <Controller
                    control={control}
                    defaultValue=''
                    name="drinks_datas[with_alcohol]"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {value === '' && (
                            <option value="none" selected hidden>
                            {t('alcool')}
                            </option>
                          )}
                          {with_alcohol.map((_with_alcohol, i) => (
                            <option
                              selected={_with_alcohol.value === value}
                              key={`_with_alcohol${i}`}
                              value={_with_alcohol.value}
                            >
                              {_with_alcohol.label}
                            </option>
                          ))}
                        </InfoSelect>
                        {(value === 'yes') && 
                          <Row>
                            <Col lg="12">
                              <InputAlcool
                                {...register('drinks_datas[alcool_level]')}
                                defaultValue={0}
                                type="number"
                                min="0"
                                max="100"
                                step="0.1"
                              />
                              <Tricks>{`${t('% of alcool')} `}</Tricks>
                            </Col>
                          </Row>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
            </BackgroundChecked>
          ) : (
            <BackgroundUnchecked onClick={() => onChange('beer')}>
              <Flex>
                <FormControlLabel value="beer" control={<Radio />} />
                <RadioTitle>
                  {t('beer & ciders & alcool')}
                </RadioTitle>
              </Flex>
            </BackgroundUnchecked>
          )}
          <br />
          {value === 'spirit' ? (
            <BackgroundChecked>
              <Flex>
                <FormControlLabel value="spirit" control={<Radio />} />
                <RadioTitle>
                  {t('spirits')}
                </RadioTitle>
              </Flex>

              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('name *')}
                    {...register('product_name')}
                  />
                </Col>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('description')}
                    {...register('product_description')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Controller
                    control={control}
                    name="drinks_datas[spirit_type]"
                    defaultValue=''
                    render={({
                      field: {
                        onChange: onChangeType,
                        onBlur: onBlurType,
                        value: valueType,
                        ref,
                      },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlurType}
                          onChange={onChangeType}
                          inputRef={ref}
                          value={valueType}
                        >
                          {valueType === '' && (
                            <option value="none" selected hidden>
                              Type
                            </option>
                          )}
                          {spirit_type.map((promo, i) => (
                            <option
                              selected={promo.value === valueType}
                              key={`promo${i}`}
                              value={promo.value}
                            >
                              {promo.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {valueType !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChangeType('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
                <Col lg="6">
                  <Controller
                    control={control}
                    defaultValue=''
                    name="drinks_datas[with_alcohol]"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {value === '' && (
                            <option value="none" selected hidden>
                              {t('alcool')}
                            </option>
                          )}
                          {with_alcohol.map((_with_alcohol, i) => (
                            <option
                              selected={_with_alcohol.value === value}
                              key={`_with_alcohol${i}`}
                              value={_with_alcohol.value}
                            >
                              {_with_alcohol.label}
                            </option>
                          ))}
                        </InfoSelect>
                        {(value === 'yes') && 
                          <Row>
                            <Col lg="12">
                              <InputAlcool
                                {...register('drinks_datas[alcool_level]')}
                                defaultValue={0}
                                type="number"
                                min="0"
                                max="100"
                                step="0.1"
                              />
                              <Tricks>{`${t('% of alcool')} `}</Tricks>
                            </Col>
                          </Row>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
            </BackgroundChecked>
          ) : (
            <BackgroundUnchecked onClick={() => onChange('spirit')}>
              <Flex>
                <FormControlLabel value="spirit" control={<Radio />} />
                <RadioTitle>
                  {t('spirits')}
                </RadioTitle>
              </Flex>
            </BackgroundUnchecked>
          )}
          <br />
          {value === 'cocktail' ? (
            <BackgroundChecked>
              <Flex>
                <FormControlLabel value="cocktail" control={<Radio />} />
                <RadioTitle>
                  {t('cocktail & mocktail')}
                </RadioTitle>
              </Flex>
                <Row>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('name *')}
                      {...register('product_name')}
                    />
                  </Col>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('description')}
                      {...register('product_description')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('composition')}
                      {...register('drinks_datas[composition]')}
                    />
                  </Col>
                  <Col lg="6">
                    <Controller
                      control={control}
                      defaultValue=''
                      name="drinks_datas[with_alcohol]"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <>
                          <InfoSelect
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                          >
                            {value === '' && (
                              <option value="none" selected hidden>
                              {t('alcool')}
                              </option>
                            )}
                            {with_alcohol.map((_with_alcohol, i) => (
                              <option
                                selected={_with_alcohol.value === value}
                                key={`_with_alcohol${i}`}
                                value={_with_alcohol.value}
                              >
                                {_with_alcohol.label}
                              </option>
                            ))}
                          </InfoSelect>
                          {(value === 'yes') && 
                            <Row>
                              <Col lg="12">
                                <InputAlcool
                                  {...register('drinks_datas[alcool_level]')}
                                  defaultValue={0}
                                  type="number"
                                  min="0"
                                  max="100"
                                  step="0.1"
                                />
                                <Tricks>{`${t('% of alcool')} `}</Tricks>
                              </Col>
                            </Row>
                          }
                        </>
                      )}
                    />
                  </Col>
                </Row>
              </BackgroundChecked>
            ) : (
              <BackgroundUnchecked onClick={() => onChange('cocktail')}>
                <Flex>
                  <FormControlLabel value="cocktail" control={<Radio />} />
                  <RadioTitle>
                    {t('cocktail & mocktail')}
                  </RadioTitle>
                </Flex>
              </BackgroundUnchecked>
            )}
            <br />
            {value === 'soft' ? (
              <BackgroundChecked>
                <Flex>
                  <FormControlLabel value="soft" control={<Radio />} />
                  <RadioTitle>
                    {t('soft')}
                  </RadioTitle>
                </Flex>
                <Row>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('name *')}
                      {...register('product_name')}
                    />
                  </Col>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('description')}
                      {...register('product_description')}
                    />
                  </Col>
                </Row>
              </BackgroundChecked>
            ) : (
              <BackgroundUnchecked onClick={() => onChange('soft')}>
                <Flex>
                  <FormControlLabel value="soft" control={<Radio />} />
                  <RadioTitle>
                    {t('soft')}
                  </RadioTitle>
                </Flex>
              </BackgroundUnchecked>
            )}
            <br />
            {value === 'cafeteria' ? (
              <BackgroundChecked>
                <Flex>
                  <FormControlLabel value="cafeteria" control={<Radio />} />
                  <RadioTitle>
                    {t('cafeteria')}
                  </RadioTitle>
                </Flex>
                <Row>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('name *')}
                      {...register('product_name')}
                    />
                  </Col>
                  <Col lg="6">
                    <OfferConditionArea
                    placeholder={t('description')}
                      {...register('product_description')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Controller
                      control={control}
                      name="drinks_datas[coffee_type]"
                      defaultValue="none"
                      render={({
                        field: {
                          onChange: onChangeType,
                          onBlur: onBlurType,
                          value: valueType,
                          ref,
                        },
                      }) => (
                        <>
                        <InfoSelect
                          onBlur={onBlurType}
                          onChange={onChangeType}
                          inputRef={ref}
                          value={valueType}
                        >
                          {valueType === '' && (
                            <option value="none" selected hidden>
                              Type
                            </option>
                          )}
                          {coffee_type.map((promo, i) => (
                            <option
                              selected={promo.value === valueType}
                              key={`promo${i}`}
                              value={promo.value}
                            >
                              {promo.value}
                            </option>
                          ))}
                        </InfoSelect>
                        {valueType !== '' && 
                          <DelButton
                            variant="primaryoutline"
                            onClick={() => onChangeType('')}
                          >
                            <DelIcon/>
                          </DelButton>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
            </BackgroundChecked>
          ) : (
            <BackgroundUnchecked onClick={() => onChange('cafeteria')}>
              <Flex>
                <FormControlLabel value="cafeteria" control={<Radio />} />
                <RadioTitle>
                  {t('cafeteria')}
                </RadioTitle>
              </Flex>
            </BackgroundUnchecked>
          )}
          <br />
          {value === 'other' ? (
            <BackgroundChecked>
              <Flex>
                <FormControlLabel value="other" control={<Radio />} />
                <RadioTitle>
                  {t('others')}
                </RadioTitle>
              </Flex>
              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('name *')}
                    {...register('product_name')}
                  />
                </Col>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('description')}
                    {...register('product_description')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <OfferConditionArea
                    placeholder={t('composition')}
                    {...register('drinks_datas[composition]')}
                  />
                </Col><Col lg="6">
                  <Controller
                    control={control}
                    defaultValue=''
                    name="drinks_datas[with_alcohol]"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <>
                        <InfoSelect
                          onBlur={onBlur}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        >
                          {value === '' && (
                            <option value="none" selected hidden>
                            {t('alcool')}
                            </option>
                          )}
                          {with_alcohol.map((_with_alcohol, i) => (
                            <option
                              selected={_with_alcohol.value === value}
                              key={`_with_alcohol${i}`}
                              value={_with_alcohol.value}
                            >
                              {_with_alcohol.label}
                            </option>
                          ))}
                        </InfoSelect>
                        {(value === 'yes') && 
                          <Row>
                            <Col lg="12">
                              <InputAlcool
                                {...register('drinks_datas[alcool_level]')}
                                defaultValue={0}
                                type="number"
                                min="0"
                                max="100"
                                step="0.1"
                              />
                              <Tricks>{`${t('% of alcool')} `}</Tricks>
                            </Col>
                          </Row>
                        }
                      </>
                    )}
                  />
                </Col>
              </Row>
            </BackgroundChecked>
          ) : (
            <BackgroundUnchecked onClick={() => onChange('other')}>
              <Flex>
                <FormControlLabel value="other" control={<Radio />} />
                <RadioTitle>
                  {t('others')}
                </RadioTitle>
              </Flex>
            </BackgroundUnchecked>
          )}
        </RadioGroup>
      )}
    />
  </div>
)

export default withTheme(withTranslation()(InfosBox))

const Flex = styled.div`
  display: flex;
`
const BackgroundUnchecked = styled.div`
  background-color: white;
  padding-top: 12px;
  padding-left: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
`
const RadioTitle = styled.p`
  font-size: 13pt;
  margin-top: 10px;
  margin-left: -15px;
  font-weight: bold;
`
const BackgroundChecked = styled.div`
  background-color: #f8f7ff;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.primary};
`
const DelButton = styled.div`
  position: absolute;
  right: 50px;
  width: 25px;
  height: 25px;
  top: 5px;
  cursor: pointer;
`
const DelIcon = styled(BackspaceOutlined)`
  width: 17px !important;
  height: 17px !important;
`
const Tricks = styled.div`
  top: 0;
  position: absolute;
  margin: 5px 0px 0px 40px;
`
const InfoSelect = styled.select`
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 11pt;
  color: ${(props) => props.value === '' && 'rgba(0, 0, 0, 0.3)'};
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const InputAlcool = styled.input`
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const OfferConditionArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 35px;
  margin-bottom: 15px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const ShiftTipsArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 55px;
  margin-bottom: 15px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
