// eslint-disable-next-line
import React, { useState, useRef } from 'react'
import { Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { FirestoreDb, Functions } from '../utils/firebase/FirebaseModules'

const useStripe = (props) => {
  const [couponDatas, setCouponDatas] = useState({})
  const [checkingCoupon, setCheckingCoupon] = useState(false)
  const couponField = useRef(null)

  const createSubscription = async (datas) => {
    const createSub = Functions().httpsCallable('stripe-createSubscription')
    return createSub({
      data: { ...datas },
    })
  }

  const getProducts = async () =>
    FirestoreDb()
      .collection('stripe_products')
      .where('active', '==', true)
      .get()
      .then((querySnapshot) => {
        let prods = []
        querySnapshot.forEach(async (doc) => {
          prods.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        return prods
      })
      .catch((e) => console.log(e))

  const getPrices = async (id) =>
    FirestoreDb()
      .collection('stripe_products')
      .doc(id)
      .collection('stripe_prices')
      .get()
      .then((querySnapshot) => {
        let prices = []
        querySnapshot.forEach((doc) => {
          prices.push({ ...doc.data(), id: doc.id })
        })

        return prices
      })
      .catch((e) => console.log(e))

  const checkCoupon = async (coupon, total, notValid) => {
    setCheckingCoupon(true)
    const checkCouponFunction = Functions().httpsCallable('stripe-checkCoupon')
    const couponChecked = await checkCouponFunction({
      data: {
        couponId: coupon,
      },
    })

    if (couponChecked.data.datas && !couponChecked.data.error) {
      if (couponChecked.data.datas.valid !== true) {
        alert(notValid)
      } else {
        if (couponChecked.data.datas.amount_off !== null)
          setCouponDatas({
            ...couponChecked.data.datas,
            reductedPrice: parseFloat(
              (total - couponChecked.data.datas.amount_off) / 100
            ).toFixed(2),
          })
        if (couponChecked.data.datas.percent_off !== null)
          setCouponDatas({
            ...couponChecked.data.datas,
            reductedPrice: parseFloat(
              (total - (total * couponChecked.data.datas.percent_off) / 100) / 100
            ).toFixed(2),
          })
      }
    } else {
      alert(notValid)
      setCouponDatas({})
    }

    setCheckingCoupon(false)
  }

  const Coupon = (props) => (
    <CouponStyled style={props.style || {}}>
      <Form.Group>
        <Form.Control
          ref={couponField}
          placeholder={props.placeholder}
          type="text"
          defaultValue={couponDatas?.id || ''}
        />
      </Form.Group>

      <Form.Group>
        <Button
          variant="primary"
          onClick={() =>
            checkCoupon(couponField.current.value, props.total, props.notValid)
          }
          type="button"
        >
          {props.apply}
        </Button>{' '}
        {couponDatas?.id && (
          <Button
            variant="default"
            onClick={() => {
              setCouponDatas({})
              couponField.current.value = ''
            }}
            type="button"
          >
            {props.cancel}
          </Button>
        )}
      </Form.Group>
    </CouponStyled>
  )
  const updateSubscription = async (placeId, priceId) => {
    const updateFunction = Functions().httpsCallable('stripe-updateSubscription')
    return updateFunction({
      data: {
        placeId,
        priceId,
      },
    })
  }
  const suspendSubscription = async (placeId, active) => {
    const updateFunction = Functions().httpsCallable('stripe-suspendSubscription')
    return updateFunction({
      data: {
        active,
        placeId,
      },
    })
  }
  const getPaymentMethods = async () => {
    const getMethodsPayment = Functions().httpsCallable('stripe-getPaymentMethods')
    return getMethodsPayment()
  }

  const getSetutIntent = async () => {
    const _getSetutIntent = Functions().httpsCallable('stripe-getSetutIntent')
    return _getSetutIntent()
  }
  const updatePaymentMethod = async (placeId, pm) => {
    const _updatePaymentMethod = Functions().httpsCallable(
      'stripe-updatePaymentMethod'
    )
    return _updatePaymentMethod({
      data: { placeId, pm },
    })
  }
  const updateTrialPlace = async ({ placeId, pm, coupon, priceId }) => {
    const _updateTrialPlace = Functions().httpsCallable('stripe-updateTrialPlace')
    return _updateTrialPlace({
      data: { placeId, pm, priceId, coupon },
    })
  }
  const detachPaymentMethod = async ({ pm }) => {
    const _detachPaymentMethod = Functions().httpsCallable(
      'stripe-detachPaymentMethod'
    )
    return _detachPaymentMethod({
      data: { pm },
    })
  }

  const upcomingInvoice = async ({ subscriptionId, priceId }) => {
    const _upcomingInvoice = Functions().httpsCallable('stripe-upcomingInvoice')
    return _upcomingInvoice({
      data: { subscriptionId, priceId },
    })
  }

  return {
    getProducts,
    getPrices,
    createSubscription,
    Coupon,
    couponDatas,
    setCouponDatas,
    checkingCoupon,
    updateSubscription,
    suspendSubscription,
    getPaymentMethods,
    updatePaymentMethod,
    getSetutIntent,
    updateTrialPlace,
    detachPaymentMethod,
    upcomingInvoice,
  }
}

export default useStripe

const CouponStyled = styled.div``
