import React from 'react'
import UseDashboardAdminStore from './DashboardAdminStore'
import DashboardAdmin from './DashboardAdmin'

const DashboardAdminContainer = ({ ...props }) => (
  <UseDashboardAdminStore.Provider>
    <DashboardAdmin {...props} />
  </UseDashboardAdminStore.Provider>
)

export default DashboardAdminContainer
