import React from 'react';
import styled from 'styled-components';

const GradientTextBanner = ({ children }) => 
  <div style={{textAlign: '-webkit-center', marginTop: '30px'}}>
    <GradientTextBannerStyled>
      {children}
    </GradientTextBannerStyled>
  </div>;

export default GradientTextBanner;

const GradientTextBannerStyled = styled.div`
  background: linear-gradient(90deg, #E76B56 0, #6F4FB3 56%, #6F4FB3 81%);
  padding :15px   40px ; 
  color : white  ; 
  text-align  : center ; 
  border-radius : 12px  ; 
  margin : 20px  0 20px  0; 
  width: 50%;
	@media screen and (max-width: 991px) {
	  width: 95%;
	} 
`;
