import styled from 'styled-components';
import AcUnitIcon from '@material-ui/icons/AcUnit';

const Badge = ({
 text, icon, isActive, noStock, isBackgroundWhite, style, type, color, modal, subProduct
}) => (
  <BadgeItem 
    style={style} 
    color={color}
    modal={modal}
    subProduct={subProduct}
    active={isActive} 
    noStock={noStock} 
    isBackgroundWhite={isBackgroundWhite}
    type={type}>
    {icon && (
      <BadgeIcon>
        <AcUnitIcon style={{ width: '100%', height: '100%' }} />
        {' '}
        {/* remplace by the icon */}
      </BadgeIcon>
    )}
    {text}
  </BadgeItem>
);

export default Badge;

const BadgeIcon = styled.div`
  color: #276347;
  margin-right: 4px;
  width: 15px;
`;

const BadgeItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.type === 'alergens' ? props.modal ? 'white' : props.subProduct ? 'white' : '#E7F2EF' : props.color)};
  border-radius: 13px;
  padding: 1px 6px;
  color: ${(props) => (props.type === 'alergens' ? props.color : 'white')};
  ${(props) => (props.noStock ? 'background-color: #D1D1D1;color: white;' : '')}
  margin-left: 12px;
  font: normal normal normal 13px Lato;
  &:first-child{
      margin-left: 0px;
  }
`;
