import styled from 'styled-components';

const RestaurantThemeFooter = ({ text, datas }) => (
  <div style={{ padding: '50px 15px', color: datas.darkmode ? 'white' : datas.color }}>
    <Line datas={datas} style={{ marginBottom: '5px' }} />
    <Line datas={datas} />
    <p style={{
        textAlign: 'center',
        font: `normal normal normal 16px ${datas.font}`,
        marginBottom: '0px',
        padding: '17px 0',
      }}
    >
      {text}
    </p>
    <Line datas={datas} style={{ marginBottom: '5px' }} />
    <Line datas={datas} />
  </div>
);

export default RestaurantThemeFooter;

const Line = styled.div`
width:100%;
height:1px;
background-color: ${(props) => (props.datas.darkmode ? 'white' : props.datas.color)}
`;
