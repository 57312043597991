import React from 'react';
import UseDashboardClientStore from './DashboardClientStore';
import DashboardClient from './DashboardClient';

const DashboardClientContainer = ({ ...props }) => (
  <UseDashboardClientStore.Provider>
    <DashboardClient {...props} />
  </UseDashboardClientStore.Provider>
  );

export default DashboardClientContainer;
