import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';

import { FormControlLabel, Radio } from '@material-ui/core'

export const BigRadioCheckbox = ({
  selected,
  change,
  name,
  type,
  key,
  quantitySelector,
  title,
  subtitle,
  price,
  priceMeta,
  subPriceInfo,
  currency,
  register,
}) => {
  const themeContext = useContext(ThemeContext);

  const [isSelected, setIsSelected] = useState(selected);
  return (
    <BigRadioCheckboxStyled
      onClick={change}
      selected={type === 'checkbox' ? isSelected : selected}
      theme={themeContext}
    >
      <div style={{display: 'flex', width: '100%'}}>
        <HalfContainer>
          <div style={{display: 'flex'}}>
            <div
              style={{
                textAlign: 'right',
                top: '8px',
                position: 'relative',
              }}
            >
              <FormControlLabel 
                value={key} 
                checked={selected} 
                selected 
                control={<Radio onChange={change} />} />
            </div>
            <div>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </div>
          </div>
        </HalfContainer>
        <OtherHalfContainer>
          <PriceContainer>
            <Price>{price}</Price>
            <Currency>{currency}</Currency>
            <PriceMeta>{priceMeta}</PriceMeta>
          </PriceContainer>
          {subPriceInfo &&
            <SubPriceInfo>
              {subPriceInfo}
            </SubPriceInfo>}
          {quantitySelector}
        </OtherHalfContainer>
      </div>
      <ROtherHalfContainer>
        <PriceContainer style={{placeContent: 'center'}}>
          <Price>{price}</Price>
          <Currency>{currency}</Currency>
          <PriceMeta>{priceMeta}</PriceMeta>
        </PriceContainer>
        {subPriceInfo &&
          <SubPriceInfo>
            {subPriceInfo}
          </SubPriceInfo>}
        {quantitySelector}
      </ROtherHalfContainer>
    </BigRadioCheckboxStyled>
  );
};

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
const Price = styled.div`
  font-size: 20pt;
  font-weight: bold;
`;
const Currency = styled.div`
  color: ${(props) => props.theme.grey_3};

  align-self: flex-start;
`;
const PriceMeta = styled.div`
  color: ${(props) => props.theme.grey_3};
`;
const SubPriceInfo = styled.div`
  color: white;
  font-size: 10pt;
  padding: 4px 15px !important;
  border-radius: 100px;
  text-align: center;
  padding: 0px 10px;
  background: ${(props) => props.theme.primary};
  display: inline-block;
`;
const Title = styled.div`
  font-size: 17pt;
  font-weight: bold;
`;
const Subtitle = styled.div`
  margin-top: 10px;
  font-size: 10pt;
`;
const HalfContainer = styled.div`
  width: 70%;
`;
const OtherHalfContainer = styled.div`
  width: 30%;
	@media screen and (max-width: 551px) {
	  display: none;
	} 
`;
const ROtherHalfContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
	@media screen and (min-width: 551px) {
	  display: none;
	} 
`;

const BigRadioCheckboxStyled = styled.div`
  padding: 15px 8px 0px 8px;
  border: 2px #eee solid;
  border-radius: 18px;
  cursor: pointer;
  margin-bottom: 10px;
  border: ${(props) => props.selected && `2px solid ${props.theme.primary}`};
  background: ${(props) => props.selected && props.theme.grey_4};

  ${Subtitle} {
    color: ${(props) => props.theme.grey_3};
  }
`;

BigRadioCheckbox.defaultProps = {
  type: 'checkbox',
};
