import React, { useState } from 'react';

import Switch from '@material-ui/core/Switch';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { Controller } from 'react-hook-form';

const promos = [
  {
    value: 5,
    label: '- 5%',
  },
  {
    value: 10,
    label: '- 10%',
  },
  {
    value: 20,
    label: '- 20%',
  },
  {
    value: 30,
    label: '- 30%',
  },
  {
    value: 40,
    label: '- 40%',
  },
  {
    value: 50,
    label: '- 50%',
  },
  {
    value: 60,
    label: '- 60%',
  },
  {
    value: 70,
    label: '- 70%',
  },
  {
    value: 80,
    label: '- 80%',
  },
  {
    value: 90,
    label: '- 90%',
  },
];

const PromoBox = ({
  control, t, prices,
}) => {
  const [promoChoice, setPromoChoice] = useState('percentage');

  return (
    <>
      <Controller
      control={control}
      name="product_promo"
      defaultValue={false}
      render={({
        field: {
          onChange,
          value,
          ref,
          onBlur,
        },
      }) => (
        <>
          <Flex>
            <Switch
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <DisableTitle>
              {t('promo')}
            </DisableTitle>
          </Flex>
          {value
            ? (
              <Background style={{ marginTop: '10px' }}>
                <Title style={{ fontSize: '13pt' }}>
                  {t('display in menu')}
                </Title>
                <Controller
        name="product_promo_type"
        control={control}
        defaultValue="percentage"
        render={({
          field: {
            onChange, value, ref,
          },
        }) => (
          <RadioGroup
            aria-label="gender"
            value={value}
            onChange={onChange}
            ref={ref}
          >
            {value === 'percentage'
              ? (
                <BackgroundChecked>
                  <Flex>
                    <FormControlLabel value="percentage" control={<Radio />} />
                    <RadioTitle>
                      {t('% de reduc')}
                    </RadioTitle>
                  </Flex>
                  <Controller
                control={control}
                name="product_promo_reduction"
                defaultValue={5}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <PromoSelect
                onChange={onChange}
                ref={ref}
                    >
                      {promos.map((promo, i) => (
                        <option
                      selected={value === promo.value}
                      key={`promo${i}`}
                      value={promo.value}
                        >
                          {promo.label}
                        </option>
                      ))}
                    </PromoSelect>
                    {prices.map((item, i) => (
                      <Flex style={{ margin: '10px 10px 0px 12px' }}>
                        <PromoDescription>{item.description}</PromoDescription>
                        <div style={{ width: '25%' }} />
                        <PromoPrice>{`${item.price} €`}</PromoPrice>
                        <PromoNewPrice>{`${(item.price - (item.price * (value / 100))).toFixed(2)} €`}</PromoNewPrice>
                      </Flex>
                    ))}
                    {prices.length === 0 && <div style={{ marginTop: '20px' }} />}
                  </>
                )}
                  />

                </BackgroundChecked>
              )
              : (
                <BackgroundUnchecked>
                  <Flex>
                    <FormControlLabel value="percentage" control={<Radio />} />
                    <RadioTitle>
                      {t('% de reduc')}
                    </RadioTitle>
                  </Flex>
                </BackgroundUnchecked>
              )}
            <br />
            {value === 'free'
              ? (
                <BackgroundChecked>
                  <Flex>
                    <FormControlLabel value="free" control={<Radio />} />
                    <RadioTitle>
                      {t('free')}
                    </RadioTitle>
                  </Flex>
                  <RadioSubtitle>
                    {t('indic conditions')}
                  </RadioSubtitle>
                  <Controller
                control={control}
                name="product_promo_free_conditions"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <OfferConditionArea
              placeholder={"Conditions de l'offre"}
              ref={ref}
              value={value}
              onChange={onChange}
                  />
                )}
                  />
                </BackgroundChecked>
              ) : (
                <BackgroundUnchecked>
                  <Flex>
                    <FormControlLabel value="free" control={<Radio />} />
                    <RadioTitle>
                      {t('free')}
                    </RadioTitle>
                  </Flex>
                </BackgroundUnchecked>
              )}
          </RadioGroup>
        )}
                />
              </Background>
            )
            : <DisableSubtitle>
                {t('apply red or offer')}
              </DisableSubtitle>}
        </>
      )}
      />

    </>
  );
};

export default withTheme(withTranslation()(PromoBox));

const Flex = styled.div`
  display: flex;
`;
const PromoDescription = styled.div`
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PromoPrice = styled.div`
  width: 25%;
  text-decoration:line-through;
`;
const PromoNewPrice = styled.div`
  width: 25%;
  color: ${(props) => props.theme.primary};
  font-weight: bold;
`;
const PromoSelect = styled.select`
  width: 95%;
  padding: 5px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`;
const BackgroundUnchecked = styled.div`
  background-color: #F8F7FF;
  padding-top: 12px;
  padding-left: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
`;
const RadioTitle = styled.p`
  font-size: 13pt;
  margin-top: 10px;
  margin-left: -15px;
  font-weight: bold;
`;
const RadioSubtitle = styled.div`
  font-size: 11pt;
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
`;
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: -5px;
  margin-left: 7px;
  margin-bottom: 20px;
`;
const Background = styled.div`
  background-color: #E6EFFF;
  padding: 20px;
  border-radius: 10px;
`;
const BackgroundChecked = styled.div`
  background-color: #F8F7FF;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.primary};
`;
const OfferConditionArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 110px;
  margin-bottom: 20px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`;
const DisableTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 2px;
`;
const DisableSubtitle = styled.div`
  font-size: 10pt;
  margin-left: 12px;
`;
