import React, { useEffect, useState } from 'react'
import { Card, Container, Badge, ListGroup, Button, Spinner } from 'react-bootstrap'
import ClassicTable from '../../../components/UI/tables/ClassicTable'
import { FirestoreDb, Functions } from '../../../utils/firebase/FirebaseModules'
import { useUserSwitch } from '../../../hooks/Admin'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import Drawer from '@material-ui/core/Drawer'

const DashboardAdminUsers = ({t, props, setTitle}) => {
	const [userDetails, setUserDetails] = useState(null)
	const [showDetails, setShowDetails] = useState(false)
	const { connectAsUser, loading: connectingAsUser } = useUserSwitch()
	const [userList, setUserList] = useState([])

  useEffect(() => {
    setTitle(`${t('admin')} > <b>${t('admin users')}</b>`);
  }, [setTitle, t])

	const fetchDatas = async (lastDoc = false) => {
		let ref = await FirestoreDb().collection('users')
		if (lastDoc) {
			ref = ref.orderBy('created_at', 'desc').startAfter(lastDoc).limit(100)
		} else {
			ref = ref.orderBy('created_at', 'desc').limit(100)
		}

		const users = await ref.get()

		return { datas: users.docs }
	}

	return (
		<>
			<Drawer
				anchor="right"
				open={showDetails}
				onClose={() => setShowDetails(false)}
			>
				{userDetails !== null && (
					<>
						<ListGroup>
							<ListGroup.Item>
								<b>Email : </b>
								{userDetails.email}
							</ListGroup.Item>
							<ListGroup.Item>
								<b>Stripe: </b>
								<a
									target="_blank"
									href={`https://dashboard.stripe.com/customers/${userDetails.stripeId}`}
									rel="noreferrer"
								>
									Voir compte stripe
								</a>
							</ListGroup.Item>

							<ListGroup.Item>
								<b>Prénom : </b>
								{userDetails?.firstname || 'non renseigné'}
							</ListGroup.Item>
							<ListGroup.Item>
								<b>Nom : </b>
								{userDetails?.lastname || 'non renseigné'}
							</ListGroup.Item>
							<ListGroup.Item>
								<b>Entreprise : </b>
								{userDetails?.compagny_name || 'non renseigné'}
							</ListGroup.Item>

							<ListGroup.Item>
								<b>Téléphone : </b>
								{userDetails?.phone || 'non renseigné'}
							</ListGroup.Item>
							<ListGroup.Item>
								<Button
									disabled={connectingAsUser}
									onClick={() => connectAsUser(userDetails.id)}
								>
									{connectingAsUser && <Spinner animation="border" size="sm" />} Se
									connecter en tant que {userDetails.email}
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</>
				)}
			</Drawer>
			<Card bsPrefix="card white-card">
				<ClassicTable
					fetchDatasFunction={fetchDatas}
					list={setUserList}
					headValues={[
						{ name: 'email', label: 'email' },
						{ name: 'created_at', label: 'Date de création' },

						{ name: '', label: '' },
					]}
					dataTypes={['email', 'created_at']}
					format={{
						created_at: (date) => moment(date.toDate()).format('DD/MM/YYYY'),
					}}
					actions={[
						{
							func: (id) => {
								setUserDetails(userList.find((u) => u.id === id))
								setShowDetails(true)
							},
							name: 'Details',
							params: 'id',
						},
					]}
				/>
			</Card>
		</>
	)
}

export default withTranslation()(DashboardAdminUsers)
