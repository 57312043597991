import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth } from '../utils/firebase/FirebaseModules'
import AuthContainer from '../stores/AuthContainer'

const moment = require('moment')

const trialPeriod = 15
const withAccountVerification =
	(Component) =>
	({ ...props }) => {
		const [user] = useAuthState(Auth())
		const AuthState = AuthContainer.useContainer()
		const [missingFields, setMissingFields] = useState(false)
		const [trialStarted, setTrialStarted] = useState(false)
		const [trialExpired, setTrialExpired] = useState(false)
		const [remaintTrialDay, setRemainTrialDays] = useState(0)

		useEffect(() => {
			if (AuthState.userDatas) {
				const fields = ['firstname', 'lastname', 'compagny_name', 'phone']
				let missing = 0
				fields.forEach((field, i) => {
					if (
						AuthState.userDatas[field] === undefined ||
						AuthState.userDatas[field] === ''
					) {
						missing++
					}
					if (missing > 0) {
						setMissingFields(true)
					} else {
						setMissingFields(false)
					}
				})

				if (
					typeof AuthState.userDatas.restrict === undefined ||
					Object.keys(AuthState.userDatas.restrict).length === 0
				) {
					setTrialStarted(false)
				} else {
					setTrialStarted(true)
					const now = moment()
					const started_at = moment(
						AuthState.userDatas.restrict.trial_started_at.toDate()
					)
					const diff = now.diff(started_at, 'days')
					setRemainTrialDays(trialPeriod - parseInt(diff))
					if (diff >= trialPeriod) {
						setTrialExpired(true)
					}
				}
			}
		}, [AuthState.userDatas])

		return (
			<>
				{user && !user.emailVerified && (
					<Redirect to="/auth/action?mode=verifyEmail" />
				)}

				<Component
					{...props}
					missingFields={missingFields}
					trialStarted={trialStarted}
					trialExpired={trialExpired}
					remaintTrialDay={remaintTrialDay}
				/>
			</>
		)
	}

export default withAccountVerification
