import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import AuthContainer from '../../stores/AuthContainer';
import FirebaseAuthEmailVerified from './FirebaseAuthEmailVerified';
import FirebaseAuthEmailNotVerified from './FirebaseAuthEmailNotVerified';
import { Auth } from '../../utils/firebase/FirebaseModules';

const queryString = require('query-string');

const FirebaseAuthValidateEmail = (props) => {
  const AuthState = AuthContainer.useContainer();
  const { search } = useLocation();
  const { oobCode } = queryString.parse(search);
  const [verificationSucceeded, setverificationSucceeded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (oobCode) {
      Auth().applyActionCode(oobCode).then((resp) => {
        setverificationSucceeded(true);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, []);

  if (!AuthState.user) history.push('/login');

  return (
    <div>
      {AuthState.user && (
      <>
        {(AuthState.user.emailVerified || verificationSucceeded) && <FirebaseAuthEmailVerified /> }
        {(!AuthState.user.emailVerified) && <FirebaseAuthEmailNotVerified /> }
      </>
)}
    </div>
  );
};

export default withTranslation()(FirebaseAuthValidateEmail);
