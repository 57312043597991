import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  OverlayTrigger,
  Container,
  Button,
  Spinner,
  Row,
  Col,
} from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Switch from '@material-ui/core/Switch'

import {
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import styled, { withTheme } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Modal from 'react-bootstrap/Modal'
import { Functions } from '../../../../utils/firebase/FirebaseModules'
import Digitalicon from '../../../../assets/menu/digital_menu.svg'
import PDFicon from '../../../../assets/menu/pdf_menu.svg'
import Moveicon from '../../../../assets/menu/move.svg'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import { useFirestoreApi } from '../../../../hooks/FireTools'
import LangSelector from '../../../../components/UI/form/LangSelector/LangSelector'
import PlaceContainer from '../../../../stores/PlaceContainer'

const queryString = require('query-string')

const CreateMenu = ({
  t,
  items,
  setItems,
  savingDatas,
  setSavingDatas,
  placeDatas,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deletedId, setDeletedId] = useState(0)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { updateDatas } = useFirestoreApi()
  const location = useLocation()
  const { place_id, lang } = queryString.parse(location.search)
  const { place } = PlaceContainer.useContainer()
  let currentLang = lang || place?.main_language || 'FR'

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const updateOrder = async (newOrder) => {
    setSavingDatas(true)

    await Promise.all(
      newOrder.map(async (item, index) => {
        await updateDatas({
          query: `places/${placeDatas.id}/menus/${item.id}`,
          datas: { order: index },
          action: 'set',
        })
      })
    )

    setItems(newOrder)
    setSavingDatas(false)
  }

  const setDisplay = async (id, visible) => {
    setSavingDatas(true)
    await updateDatas({
      query: `places/${placeDatas.id}/menus/${id}`,
      datas: { visible: !visible },
      message: {
        success: visible
          ? t('menu_visibility_modified_succeed2')
          : t('menu_visibility_modified_succeed'),
        error: t('menu_visibility_modified_failed'),
      },
      action: 'set',
    })

    setSavingDatas(false)
  }

  const copyMenus = async (id) => {
    setSavingDatas(true)

    const menusToCopy = items.filter((i) => i.id === id)[0]
    const duplicate = Functions().httpsCallable('menus-duplicateMenu')
    const maxValueOforder = Math.max(...items.map((o) => o.order), 1)
    let newOrder
    if (maxValueOforder > Object.keys(items).length) {
      newOrder = maxValueOforder + 1
    } else {
      newOrder = Object.keys(items).length + 1
    }

    duplicate({
      data: {
        menu_id: menusToCopy.id,
        place_id: placeDatas.id,
        order: newOrder,
      },
    }).then((result) => {
      console.log(result)
      enqueueSnackbar(t('menu_copy_succeed'), { variant: 'success' })
      setSavingDatas(false)
    })
  }

  const deleteMenus = (id) => {
    setDeletedId(id)
    setIsOpen(true)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    setSavingDatas(true)
    let newOrder = items
    newOrder = Reorder(items, result.source.index, result.destination.index)
    updateOrder(newOrder)
    setItems(newOrder)
    setSavingDatas(false)
  }

  const itemRenderer = (item, provided, index) => (
    <CardStyled {...provided.dragHandleProps}>
      <ContentDisplay
        color={item.theme_color || '#276347'}
        img={item.background_image_url}
      >
        {item.background_image_url && <ImgContentDisplay src={item.background_image_url} />}
        {item.type === 0 ? (
          <TypeBox>{t('digital menu')}</TypeBox>
        ) : (
          <TypeBox>{t('pdf menu')}</TypeBox>
        )}
        <ContentTitle
          onClick={() =>
            history.push(
              `/dashboard/edit/menu${item.type === 1 ? '/pdf' : ''}?place_id=${
                placeDatas.id
              }&menu_id=${item.menu_id}`
            )
          }
        >
          {getMultilangContent(item, 'name_ml', currentLang)}
        </ContentTitle>
      </ContentDisplay>
      <div style= {{textAlign: '-webkit-center'}}>
        <ContentAction>
          <DragImg>
            <CustomImg src={Moveicon} alt="logo"/>
            <div style={{margin: '5px 10px 0px 0px'}}>
              {index + 1}
            </div>
          </DragImg>
          <Flex>
            <Switch
              checked={item.visible}
              onChange={() => setDisplay(item.menu_id, item.visible)}
              name={`switch-${item.menu_id}`}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <div style={{marginTop: '10px', fontSize: '10pt', fontWeight: 'bold'}}>
              {t('visible menu')}
            </div>
          </Flex>
          <LeftFlex>
            <OverlayTrigger
              key="copyToolbox"
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Toolbox>
                  <Spike />
                  {t('duplicate')}
                </Toolbox>
              }
            >
              <IconContainer onClick={() => copyMenus(item.menu_id)}>
                <CopyIcon />
              </IconContainer>
            </OverlayTrigger>
            <OverlayTrigger
              key="editToolbox"
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Toolbox>
                  <Spike />
                  {t('edit')}
                </Toolbox>
              }
            >
              <IconContainer
                onClick={() =>
                  history.push(
                    `/dashboard/edit/menu${
                      item.type === 1 ? '/pdf' : ''
                    }?place_id=${placeDatas.id}&menu_id=${item.menu_id}`
                  )
                }
              >
                <EditIcon />
              </IconContainer>
            </OverlayTrigger>
            <OverlayTrigger
              key="deleteToolbox"
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Toolbox>
                  <Spike />
                  {t('remove')}
                </Toolbox>
              }
            >
              <IconContainer onClick={() => deleteMenus(item.menu_id)}>
                <DeleteIcon />
              </IconContainer>
            </OverlayTrigger>
          </LeftFlex>
        </ContentAction>
      </div>
    </CardStyled>
  )

  const formatItems = (items) => {
    const fItems = items.map((_i, index) => ({
      ..._i,
      id: `menu-${_i.id}`,
      menu_id: _i.id,
    }))
    return fItems
  }

  const DragAndDropRender = () => {
    const formatedItems = formatItems(items)

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="MENU">
          {(provided, snapshot) => (
            <Row ref={provided.innerRef}>
              {formatedItems.map((menu, index) => (
                <Draggable key={menu.id} draggableId={menu.id} index={index}>
                  {(provided, snapshot) => (
                    <CustomCol 
                      key={index}
                      sm="6"
                      md="6"
                      ref={provided.innerRef} 
                      {...provided.draggableProps}
                    >
                        {itemRenderer(menu, provided, index)}
                    </CustomCol>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>Supprimer le menu ?</ModalTitle>
          <ModalSubTitle>{t('cannot go back')}</ModalSubTitle>
          <FlexModal>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => {
                setSavingDatas(true)
                await updateDatas({
                  query: `places/${placeDatas.id}/menus/${deletedId}`,
                  message: {
                    success: t('menu_deleted_succeed'),
                    error: t('menu_deleted_failed'),
                  },
                  action: 'delete',
                })
                setIsOpen(false)
                setSavingDatas(false)
              }}
            >
              {t('confirm')}
            </Button>
          </FlexModal>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <NewMenuButton
            variant="secondary"
            onClick={() => {
              history.push(`/dashboard/edit/new/menu?place_id=${placeDatas.id}`)
            }}
          >
            <img
              src={Digitalicon}
              alt="logo"
              style={{
                width: '23px',
                marginRight: '20px',
                marginTop: '-3px',
              }}
            />
            {t('create new menu')}
          </NewMenuButton>
          <NewPDFButton
            variant="secondary"
            onClick={() =>
              history.push(`/dashboard/edit/new/pdf?place_id=${placeDatas.id}`)
            }
          >
            <img
              src={PDFicon}
              alt="logo"
              style={{
                width: '20px',
                marginRight: '20px',
                marginTop: '-3px',
              }}
            />
            {t('add pdf menu')}
          </NewPDFButton>
          <br />
          <br />
          <LangSelector placeDatas={place} lang={lang} id={place_id} />
        </div>
        <Background>
          <Title>
            {t('all menus')}{' '}
            {savingDatas && <Spinner animation="border" size="sm" variant="dark" />}
          </Title>
          <SubTitle>
            {t('all menus description')}
          </SubTitle>
          <DisplayRender>
            {Object.keys(items).length !== 0 && DragAndDropRender()}
          </DisplayRender>
        </Background>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(CreateMenu))

const Flex = styled.div`
  display: flex;
`
const CustomCol = styled(Col)`
  margin-bottom: 20px !important;
`
const LeftFlex = styled.div`
  display: flex;
  float: right;
`
const FlexModal = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const NewMenuButton = styled(Button)`
  width: 350px;
  margin: 0px 20px;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 400px) {
    height: 50px;
  }
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const NewPDFButton = styled(Button)`
  width: 350px;
  margin: 0px 20px;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 400px) {
    height: 50px;
  }
  @media screen and (max-width: 839px) {
    margin-top: 20px;
  }
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`
const DragImg = styled.div`
  justify-content:space-between;
  display: flex;
  background-color: white;
  width: 50px;
  height: 30px;
  border-radius: 10px;
  font-size: 11pt;
`;
const TypeBox = styled.div`
  position: absolute;
  background-color: #e6efff;
  padding: 5px 5px 5px 5px;
  width: 110px;
  height: 30px;
  left: 0px;
  top: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 11pt;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  margin: 5px 2px;
  cursor: pointer;
  padding: 5px 5px;
  height: 30px;
  align-self: center;
  svg {
    margin-top: -10px;
    width: 0.7em;
    height: 0.7em;
  }
`
const ContentTitle = styled.div`
  position: absolute;
  font-size: 17pt;
  top: 35%;
  width: fit-content;
  font-weight: bold;
  margin-top: 12px;
  color: white;
  width: 80%;
  text-overflow: ellipsis;
`
const ContentAction = styled.div`
  display: flex;
  text-align: initial;
  justify-content:space-between;
  background-color: white;
  margin-bottom: 5px;
  width: 90%;
  height: 40px;
  border-radius: 10px;
  @media screen and (max-width: 839px) {
    display: block
  }
`
const DisplayRender = styled.div`
  background-color: #E6EFFF;
  border-radius: 10px;
  padding: 30px 30px 10px 30px;
`
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;
`
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`
const Background = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px 40px 20px 40px;
  @media screen and (max-width: 500px) {
    padding: 20px 10px 20px 10px;
  }
  text-align: center;
  border-radius: 7px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const CardStyled = styled.div`
  background-color: white;
  height: 100%;
  padding-top: 2px;
  border-radius: 10px;
`
const ContentDisplay = styled.div`
  border: none;
  margin: 5%;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.22); 
  box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.22);
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  width: 90%;
  height: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 26px;
  border-radius: 5px;
  background-color: ${(props) => (props.color && props.color)};
  @media (max-width: 1139px) {
      height: 200px;
      margin-bottom: 28px;
  }
  @media (max-width: 576px) {
      height: 109px;
      margin-bottom: 15px;
  }
`;
const ImgContentDisplay = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const CustomImg = styled.img`
  width: 15px;
  margin-left: 5px;
  margin-top: 2px;
  cursor: move;
`;