import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useHistory } from 'react-router-dom'
import SlideUp from '../../SlideUp/SlideUp'
import './style.css'
import ContactInformation from '../../Contact/ContactInformation'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'

const Menu = ({ t, menus, place, place_id, isOpen, mode, setIsOpen }) => {
  const windowWidth = useMediaQuery('(max-width: 768px)')
  const history = useHistory()
  const {
    selected_lang,
    place: { theme_color },
  } = useMenuClientContainer.useContainer()

  const displaySlides = () =>
    menus
      .sort((a, b) => a.order - b.order)
      .map(
        (menu, i) =>
          menu.visible && (
            <Line
              theme_color={theme_color}
              key={i}
              onClick={() => {
                history.push(
                  `/place/${place.place_slug}/menu/${menu.id}?lang=${selected_lang}`
                )
                setIsOpen(false)
              }}
            >
              {getMultilangContent(menu, 'name_ml', selected_lang)}
            </Line>
          )
      )

  return (
    <section className={`menu ${isOpen && 'menu__open'}`}>
      <div className="menu__container">
        <SlideUp
          key={0}
          title="Menus"
          menus={menus}
          place_id={place_id}
          subComponent={menus && displaySlides()}
          isDefaultBold
          styleTitle={{ textAlign: 'center' }}
          styleContainer={{
            maxWidth: windowWidth ? '252px' : '630px',
            marginBottom: '35px',
          }}
        />
        {/* <SlideUp
            key={1}
            title="Actualités"
            isDefaultBold
            styleTitle={{ textAlign: 'center' }}
            styleContainer={{
                maxWidth: windowWidth ? '252px' : '630px',
                marginBottom: '35px',
            }}
          /> */}
        <div style={{ marginTop: '50px' }}>
          <div
            style={{
              fontSize: '20px',
              marginBottom: '26px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Contact
          </div>
          <ContactInformation items={place} isCentered />
        </div>
      </div>
    </section>
  )
}

export default Menu

const Line = styled.div`
  z-index: 1;
  cursor: pointer;
  color: #1d1d1d;
  font-size: 20px;
  font-family: 'Lato';
  position: relative;
  padding-bottom: 25px;
  text-align: center;
  color: #1d1d1d;
  &:after {
    top: 0;
    content: '';
    z-index: -1;
    position: absolute;
    height: 100%;
    left: -100vw;
    width: 200vw;
    background-color: ${(props) =>
      props.theme_color ? `${props.theme_color}30` : '#e7f2ef'};
  }
  &:nth-child(1) {
    padding-top: 20px;
  }
`
