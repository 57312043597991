import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Row, Col } from 'react-bootstrap';
import AuthContainer from '../../stores/AuthContainer';
import BG from '../../assets/login/carousel1.jpg';
import Arrow from '../../assets/menu/arrow_create_account.svg';
import Logo from '../../assets/logo/logo.svg';

import SignUpForm from './SignUpForm/SignUpForm';

  const SignUp = ({ t }) => {
  const AuthState = AuthContainer.useContainer();

  return (
    <SignUpStyled bg={BG}>
      {AuthState.user && <Redirect to="/dashboard" />}
      <Row>
        <ColStyled lg="12">
          <Switch>
            <Route exact path="/signup" component={SignUpForm} />
          </Switch>
        </ColStyled>
        <LeftColStyled lg="5">
          <Switch>
            <Route exact path="/signup" component={SignUpForm} />
          </Switch>
        </LeftColStyled>

        <RightColStyled lg="7">
          <LogoStyled src={Logo} />
          <div>
            <ArrowStyled src={Arrow} />
            <h1 className="primaryText">
              {t('Welcome')}
            </h1>
            <p>
              Mon Menu 360 a le plaisir de vous
              <br />
              proposer sa plateforme digitale pour créer
              <br />
              votre menu en toute simplicité.
              <br />
              <br />
              <b className="primaryText">Commencez par créer votre compte</b>
            </p>
          </div>
        </RightColStyled>

      </Row>
    </SignUpStyled>
  );
};

export default withTranslation()(withTheme(SignUp));

const ArrowStyled = styled.img`
  position: absolute;
  left :0px ; 
`;
const SignUpStyled = styled.div`
  background  :url(${(props) => props.bg}) no-repeat fixed center / cover rgba(255,255,255,80%) ;  
  background-blend-mode : overlay ; 
`;
const RightColStyled = styled(Col)`
  height : 100vh ; 
  @media screen and (max-width: 1200px) {
    display: none;
  }
  > div {
        display: flex;
      flex-direction: column;
      align-items: center;
      position : relative ; 
  }

  padding-top : 80px ; 
  height: 100vh;

  h1{
    font-size : 48pt ; 
  }
`;

const ColStyled = styled(Col)`
  padding: 91px 53px;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;
const LeftColStyled = styled(Col)`
  margin-bottom : 50px ;  
  padding: 91px 53px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const LogoStyled = styled.img`
  width: 130px;
  display: block;
  margin: 20px auto 145px;
`;
