import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Container, 
  Card, 
  Image, 
  Button,
} from 'react-bootstrap';
import Switch from '@material-ui/core/Switch';
import { useSnackbar } from 'notistack'
import { PermMedia } from '@material-ui/icons'
import styled, { withTheme } from 'styled-components';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import moment from 'moment';
import { Auth } from '../../../utils/firebase/FirebaseModules';
import AuthContainer from '../../../stores/AuthContainer';
import SetLogoModal from './setLogoModal'
import LocationSearchInput from './GoogleMap'

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: 'white',
      },
      colorSecondary: {
        '&$checked': {
          color: 'white',
        },
      },
      track: {
        opacity: 0.4,
        backgroundColor: '#6F4FB3',
        '$checked$checked + &': {
          opacity: 1,
          backgroundColor: '#6F4FB3',
        },
      },
    },
  },
})

const languages = [
	{
		id: 'fr',
		label: 'Français',
	},
	{
		id: 'en',
		label: 'English',
	},
];

const DashboardProfile = ({ setTitle, t , i18n }) => {
  const AuthState = AuthContainer.useContainer();
  const [modalOpen, setModalOpen] = useState(false)
  const [PicUrl, setPicUrl] = useState(null)
  const [currentLang, setCurrentLang] = useState(i18n.language)
	const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setTitle(`<b>${t('my profile')}</b>`);
  }, [setTitle, t]);

  const handleSubmit = () => {
    enqueueSnackbar(t('profile_edit_succeed'), { variant: 'success' })
  }

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang);
    setCurrentLang(lang)
  }

  const displayView = () => (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={modalOpen}
        toggleModal={setModalOpen}
      />
      <CustomContainer fluid>
        <br/>
        <Card bsPrefix="card white-card">
          <Title>
            {t('my infos')}
          </Title>
          <Background style={{ padding: '15px 0px 5px 0px'}}>
            <Row>
              <CustomCol lg="5">
                <div style={{width: '75px', marginTop: '2px'}}>
                  {t('role')}
                </div>
                <CustomSelect style={{pointerEvents: 'none'}}>
                  <option>
                    {t('admin')}
                  </option>
                </CustomSelect>
              </CustomCol>
              <CustomCol lg="5">
                <div style={{width: '75px', marginTop: '2px'}}>
                    {t('languages')}
                </div>
                <CustomSelect
                  value={currentLang}
                  onChange={(e) => changeLanguageHandler(e.target.value)}
                >
                  {languages.map((language, i) => (
                    <option
                      key={`language${i}`}
                      value={language.id}
                    >
                      {language.label}
                    </option>
                  ))}
                </CustomSelect>
              </CustomCol>
            </Row>
          </Background>
          <br/>
          <Background>
            <Flex 
              style={{
                padding: '15px', 
                width: '100%', 
                placeContent: 'center'
              }}
            >
              <HalfInputsContainer>
              {AuthState.user.providerData[0].providerId !== 'password' ? 
                <CustomImage src={AuthState.user.photoURL} alt="logo" width="100px" />
                : !PicUrl ? (
                  <Logo
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setModalOpen(!modalOpen)
                    }}
                  >
                    <InsertLogo>
                      <CustomIcon />
                      {t('add profil picture')}
                    </InsertLogo>
                  </Logo>
                ) : (
                  <CustomImage
                    style={{cursor: 'pointer'}}
                    src={PicUrl.url}
                    alt="logo"
                    onClick={() => {
                      setModalOpen(!modalOpen)
                    }}
                  />
                )}
              </HalfInputsContainer>
              <OtherHalf>
                <br/>
                <br/>
                <InputContainer>
                  {t('name')}
                  {AuthState.user.providerData[0].displayName ? 
                    <CustomNoInput>
                      {AuthState.user.providerData[0].displayName.split(' ')[0]}
                    </CustomNoInput> :
                    <CustomInput/>
                  }
                </InputContainer>
                <InputContainer>
                  {t('surname')}
                  {AuthState.user.providerData[0].displayName ? 
                    <CustomNoInput>
                      {AuthState.user.providerData[0].displayName.split(' ')[1]}
                    </CustomNoInput> :
                    <CustomInput/>
                  }
                </InputContainer>
              </OtherHalf>
            </Flex>
            <RInputContainer>
              {t('name')}
              {AuthState.user.providerData[0].displayName ? 
                <CustomNoInput>
                  {AuthState.user.providerData[0].displayName.split(' ')[0]}
                </CustomNoInput> :
                <CustomInput/>
              }
            </RInputContainer>
            <RInputContainer>
              {t('surname')}
              {AuthState.user.providerData[0].displayName ? 
                <CustomNoInput>
                  {AuthState.user.providerData[0].displayName.split(' ')[1]}
                </CustomNoInput> :
                <CustomInput/>
              }
            </RInputContainer>
            <div style={{marginRight: '20px'}}>
              <InputContainer>
                {t('email')}
                {AuthState.user.providerData[0].email ? 
                  <CustomNoInput>
                    {AuthState.user.providerData[0].email}
                  </CustomNoInput> :
                  <CustomInput/>
                }
              </InputContainer>
            </div>
            {/* <div style={{marginRight: '20px'}}>
              <Flex>
                <InputHalfContainer>
                  Entreprise:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].companyName ? 
                      <CustomNoInput>
                        {AuthState.user.providerData[0].companyName}
                      </CustomNoInput> :
                      <CustomInput/>
                    }
                  </CustomNoInput>
                </InputHalfContainer>
                <InputHalfContainer>
                  Téléphone:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].phoneNumber ? 
                      <CustomNoInput>
                        {AuthState.user.providerData[0].phoneNumber}
                      </CustomNoInput> :
                      <CustomInput/>
                    }
                  </CustomNoInput>
                </InputHalfContainer>
              </Flex>
              <Flex>
                <InputHalfContainer>
                  Poste:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].type ? 
                      <CustomNoInput>
                        {AuthState.user.providerData[0].type}
                      </CustomNoInput> :
                      <CustomInput/>
                    }
                  </CustomNoInput>
                </InputHalfContainer>
                <InputHalfContainer>
                  Email:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].email}
                  </CustomNoInput>
                </InputHalfContainer>
              </Flex>
            </div>
            <div style={{marginRight: '20px'}}>
              <InputContainer>
                Adresse:
                {AuthState.user.providerData[0].address ? 
                  <CustomNoInput>
                    {AuthState.user.providerData[0].address}
                  </CustomNoInput> :
                  <CustomInput/>
                }
              </InputContainer>
              <InputContainer>
                Complément d'adresse:
                {AuthState.user.providerData[0].addressComplement ? 
                  <CustomNoInput>
                    {AuthState.user.providerData[0].addressComplement}
                  </CustomNoInput> :
                  <CustomInput/>
                }
              </InputContainer>
              <Flex>
                <InputHalfContainer>
                  Code postal:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].code ? 
                      <CustomNoInput>
                        {AuthState.user.providerData[0].code}
                      </CustomNoInput> :
                      <CustomInput/>
                    }
                  </CustomNoInput>
                </InputHalfContainer>
                <InputHalfContainer>
                  Pays:
                  <CustomNoInput>
                    {AuthState.user.providerData[0].country ? 
                      <CustomNoInput>
                        {AuthState.user.providerData[0].country}
                      </CustomNoInput> :
                      <CustomInput/>
                    }
                  </CustomNoInput>
                </InputHalfContainer>
              </Flex>
            </div> */}
          </Background>
          <CustomButton 
            variant='secondary'
            onClick={() => handleSubmit()}>
            {t('save modification')}
          </CustomButton>
        </Card>
        {/* <CustomCard bsPrefix="card white-card">
          <Title>
            Actualités
          </Title>
          <SubTitle>
            Bénéficiez de toute l'actualité Mon Menu 360 par email: nouveautés, conseils...
          </SubTitle>
          <FlexBackground>
            <ContentLeftContainer>
              Newsletter conseils et astuces
            </ContentLeftContainer>
            <ContentRightContainer>
              <Switch
                checked={news1}
                onChange={() => setNews1(!news1)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </ContentRightContainer>
          </FlexBackground>
          <FlexBackground>
            <ContentLeftContainer>
              Newsletter actualités Mon Menu 360
            </ContentLeftContainer>
            <ContentRightContainer>
              <Switch
                checked={news2}
                onChange={() => setNews2(!news2)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </ContentRightContainer>
          </FlexBackground>
          <FlexBackground>
            <ContentLeftContainer>
              Newsletter offres promotionnelles
            </ContentLeftContainer>
            <ContentRightContainer>
              <Switch
                checked={news3}
                onChange={() => setNews3(!news3)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </ContentRightContainer>
          </FlexBackground>
          <CustomButton variant='secondary'>
            Enregistrer les modifications
          </CustomButton>
        </CustomCard> */}
      </CustomContainer>
    </>
  )

  return (
    <ThemeProvider theme={theme}>
      {displayView()}
    </ThemeProvider>
  )
};

export default withTheme(withTranslation()(DashboardProfile));

const Flex = styled.div`
  display: flex;
`;
const FlexBackground = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  background-color: #E6EFFF;
  padding: 3px;
  border-radius: 10px;
  margin-top: 15px;
`;
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  font-weight: bold;
  margin: 10px 0px 0px 25px;
  @media screen and (max-width: 1150px) {
    width: 80%;
  }
  @media screen and (min-width: 1150px) {
    width: 80%;
  }
`
const ContentRightContainer = styled.div`
  text-align: right;
  float: right;
  margin: 5px 14px 0px 0px;
  @media screen and (max-width: 1150px) {
    width: 20%;
  }
  @media screen and (min-width: 1150px) {
    width: 20%;
  }
`
const Logo = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 10px;
  background-color: white;
`
const InsertLogo = styled.div`
  width: 100px;
  height: 25px;
  font-size: 10pt;
  font-weight: bold;
  margin-left: 15px;
  display: grid;
  text-align: center;
  justify-items: center;
`
const InputContainer = styled.div`
  display: flex;
  text-align: left;
  background-color: white;
  border-radius: 7px;
  margin: 0px 0px 10px 15px;
  padding: 5px 0px 5px 20px;
`
const RInputContainer = styled.div`
  display: flex;
  text-align: left;
  background-color: white;
  border-radius: 7px;
  margin: 10px 0px 10px 15px;
  padding: 5px 0px 5px 20px;
  @media(min-width  : 551px) {
		display: none;
  }
`
const InputHalfContainer = styled.div`
  width: 50%;
  display: flex;
  text-align: left;
  background-color: white;
  border-radius: 7px;
  margin: 0px 0px 10px 15px;
  padding: 5px 0px 5px 20px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-left: 7px;
  margin-bottom: 20px;
`;
const CustomImage = styled(Image)`
  width: 130px;
  height: 130px;
  border-radius: 10px;
`
const CustomNoInput = styled.div`
  font-size: 11pt;
  border-style: none;
  font-weight: bold;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 2px;
`
const CustomInput = styled.input`
  outline: none;
  font-weight: bold;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 1px;
`
const CustomCol = styled(Col)`
  display: flex;
  place-content: center;
  margin-bottom: 10px;
`
const CustomIcon = styled(PermMedia)`
  width: 50px !important;
  height: 50px !important;
  margin-bottom: 5px;
  margin-top: 20px;
  opacity: 0.5;
  color: lightgrey;
`
const CustomButton = styled(Button)`
  width: 20%;
  @media(max-width  : 992px) {
    width: 40%;
  }
  @media(max-width  : 551px) {
    width: 90%;
  }
  margin: 20px 0px 10px 0px;
  align-self: center;
`
const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media(min-width  : 992px) {
		padding-left: 250px;
    }
`;
const LeftContainer = styled.div`
  display: flex;
  width: 40%;
  margin-left: 20px;
`;
const RightContainer = styled.div`
  display: flex;
  width: 60%;
`;
const CustomSelect = styled.select`
  width: 200px;
  margin-left: 20px;
  font-weight: bold;
  padding: 6px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`;
const CustomCard = styled(Card)`
  margin-top: 40px !important;
  margin-bottom: 60px !important;
`;
const HalfInputsContainer = styled.div`
    display: flex;
`;
const OtherHalf = styled.div`
    width: 100%;
    margin-right: 5px;
    @media(max-width  : 551px) {
      display: none;
    }
`;
const Background = styled.div`
  background-color: #E6EFFF;
  padding: 5px;
  border-radius: 10px;
`