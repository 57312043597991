import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import {
  Room,
  Facebook,
  Instagram,
  LinkedIn,
  Link,
  PermMedia,
  Check,
} from '@material-ui/icons'
import PopupPicture from '../../../../assets/help/it-help.jpg'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'
import { useStorageTools } from '../../../../hooks/FireTools'
import { Grid } from '@material-ui/core'
import { SetLogoModal } from '../common'
import LocationSearchInput from '../common/GoogleMap'

const General = ({
  t,
  register,
  setValue,
  getValues,
  placeLogo,
  delPicUrl,
  setDelPicUrl,
  placeInfos,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [PicUrl, setPicUrl] = useState(null)

  const [address, setAddress] = useState(getValues('place_address'))
  const { documentDeletionButton } = useStorageTools()

  useEffect(() => {
    if (PicUrl !== null) {
      setValue('place_logo_url', PicUrl.url)
      setValue('place_logo_file', getFileFromBlob(PicUrl.blob, 'place_logo'))
    } else if (delPicUrl) {
      setValue('place_logo', '')
      setValue('place_logo_file', '')
    }
  }, [PicUrl, setValue])

  return (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        setDelPicUrl={setDelPicUrl}
        setValue={setValue}
        modalOpen={modalOpen}
        toggleModal={setModalOpen}
      />
      <Container fluid>
        <Row>
          <Col lg="8">
            <Card bsPrefix="card white-card">
              <Title>{t('place info')}</Title>
              <Background>
                <Grid container justifyContent="center">
                  <Grid item>
                    {(!PicUrl && !placeLogo) || delPicUrl ? (
                      <Logo
                        onClick={() => {
                          setModalOpen(!modalOpen)
                        }}
                      >
                        <InsertLogo>
                          <PermMedia
                            style={{
                              width: '50px',
                              height: '50px',
                              marginBottom: '10px',
                              opacity: 0.5,
                              color: 'lightgrey',
                            }}
                          />
                          {t('add logo')}
                        </InsertLogo>
                      </Logo>
                    ) : (
                      <div style={{ position: 'relative' }}>
                        {documentDeletionButton({
                          text: '',
                          url: placeLogo,
                          server: true,
                          collection: `places/`,
                          doc: placeInfos.id,
                          field: 'place_logo',
                          message: {
                            success: 'Image supprimé',
                            error: 'Erreur',
                          },
                          resetFunction: () => {
                            setPicUrl(null)
                            setValue('place_logo_file', undefined)
                          },
                        })}

                        <Img
                          src={PicUrl?.url ? PicUrl.url : placeLogo}
                          alt="logo"
                          onClick={() => {
                            setModalOpen(!modalOpen)
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item sm>
                    <NameInput
                      placeholder={t('place name')}
                      {...register('place_name', { required: true })}
                    />
                    <AreaInput
                      placeholder={t('place description')}
                      {...register('place_description', { required: false })}
                    />
                  </Grid>
                </Grid>

                <FlexDiv style={{ marginBottom: '10px' }}>
                  <IconAddressBar>
                    <Room
                      style={{
                        width: '20px',
                        height: '30px',
                        color: '#6F4FB3',
                      }}
                    />
                  </IconAddressBar>
                  <AdressInput>
                    <LocationSearchInput
                      address={address}
                      setValue={setValue}
                      register={register}
                      setAddress={setAddress}
                    />
                  </AdressInput>
                </FlexDiv>
                <OtherInput
                  {...register('place_phone', { required: false })}
                  placeholder={t('place phone')}
                />
                <OtherInput
                  style={{ marginBottom: '0px' }}
                  {...register('place_email', { required: false })}
                  placeholder={t('place email')}
                />
                {/*
                    <NameInput
                      onClick={(e) => {
                        navigator.clipboard.writeText(e.target.value)
                        enqueueSnackbar('Lien copié dans votre presse-papier', {
                          variant: 'success',
                        })
                      }}
                      style={{
                        backgroundColor: 'white',
                        marginTop: '20px',
                        cursor: 'copy',
                      }}
                      defaultValue={`https://app.monmenu360.fr/place/${getValues(
                        'place_slug'
                      )}`}
                      value={`https://app.monmenu360.fr/place/${getValues('place_slug')}`}
                      placeholder={"Url de l'établissement"}
                    />
                  */}
              </Background>
            </Card>
            <Card bsPrefix="card white-card">
              <Title>{t('usefull links')}</Title>
              <Background>
                <RowDiv>
                  <FlexDiv>
                    <IconBar>
                      <Facebook
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#6F4FB3',
                        }}
                      />
                    </IconBar>
                    <LinkInput
                      placeholder="facebook.com"
                      {...register('socials[facebook]')}
                    />
                  </FlexDiv>
                  <FlexDivPlus>
                    <IconBar>
                      <Instagram
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#6F4FB3',
                        }}
                      />
                    </IconBar>
                    <LinkInput
                      placeholder="instagram.com"
                      {...register('socials[instagram]')}
                    />
                  </FlexDivPlus>
                  <FlexDivPlus>
                    <IconBar>
                      <LinkedIn
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#6F4FB3',
                        }}
                      />
                    </IconBar>
                    <LinkInput
                      placeholder="linkedin.com"
                      {...register('socials[linkedin]')}
                    />
                  </FlexDivPlus>
                  <FlexDivPlus>
                    <IconBar>
                      <Link
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#6F4FB3',
                        }}
                      />
                    </IconBar>
                    <LinkInput
                      placeholder={t('website url example')}
                      {...register('socials[website]')}
                    />
                  </FlexDivPlus>
                </RowDiv>
              </Background>
            </Card>
          </Col>
          <Col lg="4">
            <CardDeskOnly
              bsPrefix="card white-card"
              style={{
                margin: '17px 0',
                padding: '20px',
                alignItems: 'center',
              }}
            >
              <CustomImg src={PopupPicture} alt="logo" />
              <div style={{ zIndex: 2 }}>
                <PopupContent>
                  <PopupTitle>{t('help creation')}</PopupTitle>
                  <div style={{ fontStyle: 'oblique' }}>
                    {t('help creation description')}
                  </div>
                  <PopupList>
                    <div style={{ marginBottom: '5px' }}>
                      <Check
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#E76B56',
                          marginRight: '5px',
                        }}
                      />
                      {t('multi languages trad')}
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      <Check
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#E76B56',
                          marginRight: '5px',
                        }}
                      />
                      {t('menu entry')}
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      <Check
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#E76B56',
                          marginRight: '5px',
                        }}
                      />
                      {t('menu review')}
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                      <Check
                        style={{
                          width: '20px',
                          height: '17px',
                          color: '#E76B56',
                          marginRight: '5px',
                        }}
                      />
                      {t('shooting')}
                    </div>
                  </PopupList>
                </PopupContent>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => console.log('yes')}
                    style={{
                      width: '80%',
                      marginTop: '-25px',
                      backgroundColor: '#6F4FB3',
                      boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
                    }}
                  >
                    {t('discover')}
                  </Button>
                </div>
              </div>
            </CardDeskOnly>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(General))

const RowDiv = styled.div`
  display: row;
`
const FlexDiv = styled.div`
  display: flex;
`
const FlexDivPlus = styled.div`
  display: flex;
  margin-top: 10px;
`
const Background = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 10px;
`
const ResponsiveHandler = styled.div``
const ResponsiveHandler2 = styled.div`
  @media screen and (min-width: 501px) {
    display: none !important;
  }
`
const CardDeskOnly = styled(Card)`
  @media screen and (max-width: 991px) {
    display: none;
  }
`
const PopupTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 15pt;
  margin-bottom: 13px;
`
const PopupContent = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 7px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
`
const PopupList = styled.div`
  font-weight: 600;
  margin-top: 13px;
`
const InsertLogo = styled.div`
  width: 120px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 30px;
  display: grid;
  justify-items: center;
`
const Logo = styled.div`
  width: 230px;
  height: 150px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: white;
  cursor: pointer;
`
const IconBar = styled.div`
  background-color: #fbfbfb;
  width: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 7px;
  padding-top: 2px;
`
const IconAddressBar = styled.div`
  background-color: #fbfbfb;
  width: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 7px;
  padding-top: 2px;
  margin-right: -5px;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  margin-bottom: 5px;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 90px !important;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AdressInput = styled.div`
  width: 100%;
  padding: 0px 0px 0px 5px;
  font-size: 11pt;
  border-style: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const OtherInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  margin-bottom: 10px;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const LinkInput = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 11pt;
  padding-left: 10px;
  border-style: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
  ::placeholder {
    opacity: 0.5;
  }
`
const CustomImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  opacity: 0.2;
`
