import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useCollection } from '@nandorojo/swr-firestore'

import Navbar from '../../../components/UI/navbar/Navbar'
import AuthContainer from '../../../stores/AuthContainer'
import PlaceContainer from '../../../stores/PlaceContainer'
import { Menus, Products, Formulas, ProductBase } from './view'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: 'white',
      },
      colorSecondary: {
        '&$checked': {
          color: 'white',
        },
      },
      track: {
        opacity: 0.4,
        backgroundColor: '#6F4FB3',
        '$checked$checked + &': {
          opacity: 1,
          backgroundColor: '#6F4FB3',
        },
      },
    },
  },
})

const navItems = ['mymenus', 'products', 'formulas', 'product_base']

const EditMenu = ({ t, setTitle }) => {
  const location = useLocation()
  const { user } = AuthContainer.useContainer()
  const { place } = PlaceContainer.useContainer()
  const { tabs } = queryString.parse(location.search)
  const [items, setItems] = useState([])
  const [formulas, setFormulas] = useState([])
  const [savingDatas, setSavingDatas] = useState(false)
  const [tab, setTab] = useState(
    tabs === 'products' ? 'products' : tabs === 'formulas' ? 'formulas' : 'mymenus'
  )

  const {
    data: menus,
    error,
    isValidating,
  } = useCollection(place?.id ? `places/${place.id}/menus` : null, {
    where: ['uid', '==', user.uid],
    orderBy: ['order', 'asc'],
    listen: true,
  })
  const { data: formulasDatas, error: formulasErrors } = useCollection(
    place?.id ? `places/${place.id}/formulas` : null,
    {
      where: ['uid', '==', user.uid],
      orderBy: ['order', 'asc'],

      listen: true,
    }
  )

  useEffect(() => {
    if (menus) setItems(menus)
    if (formulasDatas) setFormulas(formulasDatas)
  }, [menus, formulasDatas])

  useEffect(() => {
    if (place) setTitle(`${place.place_name} > <b>${t('menus')}</b>`)
  }, [t, setTitle, place])

  const displayView = () => {
    switch (tab) {
      case 'mymenus':
        return (
          <Menus
            items={items}
            setItems={setItems}
            savingDatas={savingDatas}
            setSavingDatas={setSavingDatas}
            placeDatas={place}
          />
        )
      case 'products':
        return <Products />
      case 'product_base':
        return <ProductBase />
      case 'formulas':
        return (
          <Formulas
            items={formulas}
            setItems={setFormulas}
            placeDatas={place}
            savingDatas={savingDatas}
            setSavingDatas={setSavingDatas}
          />
        )
      default:
        return <Menus />
    }
  }

  return (
    <CustomContainer fluid>
      <div style={{ marginTop: '70px' }} />
      <Row>
        <Col lg="10">
          <Navbar items={navItems} setTab={setTab} tab={tab} t={t} />
        </Col>
        {tab === 'mymenus' && (
          <CustomCol lg="2" style={{ marginTop: '20px' }}>
            <ShowButton
              variant="outline"
              href={`/place/${place?.place_slug}`}
              target="_blank"
            >
              {t('visualize')}
            </ShowButton>
          </CustomCol>
        )}
      </Row>
      <ThemeProvider theme={theme}>{displayView()}</ThemeProvider>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(EditMenu))

const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const CustomCol = styled(Col)`
  @media (max-width: 991px) {
    margin: 20px;
  }
`
const ShowButton = styled(Button)`
  width: 100%;
  color: ${(props) => props.theme.primary};
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
