import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dashboard, FormatListBulleted, PieChartOutlined } from '@material-ui/icons'
import AuthContainer from '../../stores/AuthContainer'

const DashboardMenuItems = () => {
  const { t } = useTranslation()
  const { user } = AuthContainer.useContainer()

  const items = [
    {
      id: 'dashboard',
      path: '/dashboard',
      label: t('dashboard'),
      icon: <Dashboard />,
      childs: false,
      displayChilds: false,
    },
    {
      id: 'chr',
      path: '/dashboard/places',
      icon: <FormatListBulleted />,
      label: t('place'),
      displayChilds: false,
      childs: [
        {
          id: 'create-place',
          path: '/dashboard/create-place',
          label: t('Create'),
        },
        {
          id: 'places-childs',
          path: '/dashboard/places',
          label: t('places list'),
        },
      ],
    },
    {
      id: 'subscriptions',
      path: '/dashboard/subscriptions',
      icon: <PieChartOutlined />,
      label: t('subscriptions'),
      displayChilds: false,
      childs: [
        {
          id: 'invoices',
          path: '/dashboard/invoices',
          label: t('invoices'),
        },
        {
          id: 'subscriptions-list',
          path: '/dashboard/subscriptions',
          label: t('subscriptions'),
        },
      ],
    },
  ]

  return { items }
}

export default DashboardMenuItems
