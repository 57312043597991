import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const withStripe = (Component) => ({ ...props }) => (

  <Elements stripe={stripePromise}>
    <Component {...props} />
  </Elements>

		);

export default withStripe;
