import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// pages
import FirebaseAuthActionLayout from '../../layouts/FirebaseAuthActionLayout';
import LoginFormResetPassword from './LoginFormResetPassword';
import FirebaseAuthValidateEmail from './FirebaseAuthValidateEmail';

const queryString = require('query-string');

const FirebaseAuthActions = ({ t }) => {
const { search } = useLocation();
const { mode } = queryString.parse(search);

if (mode === 'resetPassword') {
   const headerDatas = {
   					title: t('new password'),
   					subtitle: t('enter new password'),
   				};
 	return (
   <FirebaseAuthActionLayout {...headerDatas}>
     <LoginFormResetPassword />
   </FirebaseAuthActionLayout>
	);
 } else if (mode === 'verifyEmail') {
 	return (
   <FirebaseAuthActionLayout>
     <FirebaseAuthValidateEmail />
   </FirebaseAuthActionLayout>
	);
 }	else {
 	return <><Redirect to="/login" /></>;
 }
};

export default withTranslation()(FirebaseAuthActions);
