import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import styled, { withTheme } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { Row, Col, Carousel } from 'react-bootstrap';
import LoginFormRecover from './LoginFormRecover/LoginFormRecover';
import LoginForm from './LoginForm/LoginForm';
import AuthContainer from '../../stores/AuthContainer';

import Carousel1 from '../../assets/login/carousel1.jpg';
import Carousel2 from '../../assets/login/carousel2.jpg';

const Login = ({ t }) => {
const AuthState = AuthContainer.useContainer();

  return (
    <>
      {AuthState.user && <Redirect to="/dashboard" />}
      <Row>
        <LeftColStyled lg="5">
          <Switch>
            <Route exact path="/login" component={LoginForm} />
            <Route exact path="/login/recover" component={LoginFormRecover} />
          </Switch>
        </LeftColStyled>
        <RightColStyled lg="7">
          <StyledCarousel fade style={{ height: '100%' }}>
            <Carousel.Item style={{ height: '100%' }}>
              <StyledImg
                src={Carousel1}
                alt="First slide"
              />
              <CarouselText>
                {t('carousel text 1')}
              </CarouselText>
            </Carousel.Item>
            <Carousel.Item style={{ height: '100%' }}>
              <StyledImg
                src={Carousel2}
                alt="Second slide"
              />
              <CarouselText>
                {t('carousel text 2')}
              </CarouselText>
            </Carousel.Item>
          </StyledCarousel>
        </RightColStyled>
      </Row>
    </>
  );
};

export default withTheme(withTranslation()(Login));

const RightColStyled = styled(Col)`
  height : 100vh ; 
  padding : 0px ; 
  background  : ${(props) => props.theme.grey_3} ; 
  @media screen and (max-width: 991px) {
    display: none;
  } 
`;
const LeftColStyled = styled(Col)`
  @media screen and (min-width: 991px) {
    padding: 4%;
  } 
`;
const CarouselText = styled(Carousel.Caption)`
  right: 0%;
  left: 10%;
  bottom: 12%;
  text-align: initial;
  font-weight: bold;
  font-size: 23pt;
  width: 50%;
  text-shadow: 1px 1px 2px black;
`;
const StyledCarousel = styled(Carousel)`
  .carousel-inner {
    height: 100%;
  }
  .carousel-control-prev {
    display: none;
  }
  .carousel-control-next {
    display: none;
  }
`;
const StyledImg = styled.img`
  height: auto;
  width: 100%;
  min-height: -webkit-fill-available;
`
