import styled from 'styled-components'
import { useState } from 'react'
import Badge from './Badge/Badge'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'

export const RestaurantFormulaCard = ({ product, onClick, color, darkmode, subProduct }) => {
  const { selected_lang } = useMenuClientContainer.useContainer()

  if (product) {
    const description = getMultilangContent(
      product,
      'description_ml',
      selected_lang
    )
    const name = getMultilangContent(product, 'name_ml', selected_lang)

    return (
      <RestaurantFormulaCardBox 
        darkmode={darkmode} 
        lineThrough={false} 
        subProduct={subProduct}
        onClick={() => onClick(product)}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: product?.background_image_url ? 'calc(100% - 90px)' : '100%',
            }}
          >
            <RestaurantFormulaCardTitle
              noStock={null}
              lineThrough={false}
              style={{ 
                marginBottom: !name && '19px',
                color: darkmode && 'white',
             }}
            >
              {name}
            </RestaurantFormulaCardTitle>
            {product?.product_labels && (
              <BadgeHandler 
                marginBottom="6px">
                {product?.product_labels.map((drink, i) => (
                  <Badge 
                    color={color} 
                    key={i} 
                    text={drink} 
                    isActive 
                    type="labels"
                    subProduct={subProduct}
                  />
                ))}
              </BadgeHandler>
            )}
            {description && (
              <RestaurantFormulaCardDesc 
                darkmode={darkmode} 
                noStock={null} 
                lineThrough={false}>
                {description}
              </RestaurantFormulaCardDesc>
            )}
            {product?.background_image_url && 
              <div style={{paddingTop: '10px', color: 'black', fontWeight: 'bold'}}>
                {`${product?.price} €`}
              </div>
            }
          </div>
          {!product?.background_image_url && 
            <div style={{paddingTop: '10px', width: '50px', color: 'black', fontWeight: 'bold'}}>
              {`${product?.price} €`}
            </div>
          }
          {product?.background_image_url && (
            <RestaurantFormulaCardImageHandler offerTitle={false}>
              <RestaurantFormulaCardImage src={product.background_image_url} />
              {false && (
                <RestaurantFormulaCardOfferTitle color={color} noStock={null}>
                  {false}
                </RestaurantFormulaCardOfferTitle>
              )}
            </RestaurantFormulaCardImageHandler>
          )}
        </div>
      </RestaurantFormulaCardBox>
    )
  } else return null
}

export default RestaurantFormulaCard

const RestaurantFormulaCardOfferTitle = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 3px 9px 5px 9px;
  background-color: ${(props) => (props.noStock ? '#BA400A' : props.color)};
  text-align: center;
  font: normal normal bold 15px Lato;
  color: white;
  border-radius: 2px;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
`
const BadgeHandler = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`
const RestaurantFormulaCardImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`
const RestaurantFormulaCardImageHandler = styled.div`
  border-radius: 5px;
  height: 90px;
  width: 90px;
  position: relative;
  margin: 15px 0px 0px 10px;
`
const RestaurantFormulaCardBox = styled.div`
  padding: 9px 15px 11px 20px;
  border-radius: 5px;
  background-color: ${(props) => (props.darkmode ? props.subProduct ? '#292C2B': 'black' : props.subProduct ? '#E7F2EF' : 'white')};
  width: 100%;
  color: #969696 !important;
  &:hover {
    cursor: pointer;
  }
`
const RestaurantFormulaCardTitle = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal bold 16px Lato;
  color: ${(props) => (props.noStock ? '#969696' : '#1D1D1D')};
`
const RestaurantFormulaCardDesc = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal normal 14px Lato;
  color: ${(props) => (props.noStock ? '#969696' : props.darkmode ? '#969696' : '#606060')};
  margin-bottom: 11px;
`