const days = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];

const Horaire = ({hours, darkmode}) => {
    const day = {
        fontSize: '15px',
        paddingTop: '15px',
        fontWeight: 'bold',
        color: darkmode && 'white',
    };

    return (
      <table style={{
            borderCollapse: 'collapse',
            width: '100%',
        }}
      >
        <tbody>
          {hours.map((horaire, i) => (
            <tr key={i}>
              <th style={day}>{days[i]}</th>
              {horaire.hours.map((horaireChild, i) => {
                if (i === 2 && !horaire.thirdPeriod) {
                  return null;
                } else if (i === 1) {
                  return null;
                } else {
                  return (
                    <th key={i} style={{ paddingTop: '15px', fontWeight: horaire.status && 'normal', color: darkmode && 'white', }}>
                      {horaire.status ? `${horaireChild.start.toString().replace(':', 'h')} - ${horaireChild.end.toString().replace(':', 'h')}` : i === 0 && 'Fermé'}
                    </th>
                    );
                }
            })}
            </tr>
          ))}
        </tbody>
      </table>
    );
};

export default Horaire;
