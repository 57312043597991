import styled from 'styled-components';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TelegramIcon from '@material-ui/icons/Telegram';

const ContactInformation = ({ items, isCentered, marginBottom, darkmode, fontColor }) => (
  <>
    {items.place_address && 
      <ContactLine isCentered={isCentered} marginBottom={marginBottom}>
          <LocationOnIcon style={{ color: fontColor, fontSize: '18px' }} />
          <div style={{color: darkmode && 'white'}}>
            {` ${items.place_address}`}
          </div>
      </ContactLine>
    }
    {items.place_email && 
      <ContactLine isCentered={isCentered} marginBottom={marginBottom}>
        <TelegramIcon style={{ color: fontColor, fontSize: '18px' }} />
        <div style={{color: darkmode && 'white'}}>
          {` ${items.place_email}`}
        </div>
      </ContactLine>
    }
    {items.place_phone && 
      <ContactLine isCentered={isCentered} marginBottom={marginBottom}>
        <PhoneIcon style={{ color: fontColor, fontSize: '18px' }} />
        <div style={{color: darkmode && 'white'}}>
          {` ${items.place_phone}`}
        </div>
      </ContactLine>
    }
  </>
)

export default ContactInformation;

const ContactLine = styled.div`
display: flex;
align-items: center;
justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-start')};
font-size: 15px;
margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '17px')} ;
> svg {
    margin-right: 6px;
}
`;
