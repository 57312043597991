import React, { useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Spinner, Alert } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import withStripe from '../../../../../hoc/withStripe'
import UseCreatePlaceContainer from '../../CreateAPlaceStore'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#444',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const PaymentForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const [processing, setProcessing] = React.useState(false)
  const [errorPayment, setErrorPayment] = React.useState('')
  const [formCompleted, setFormCompleted] = React.useState(false)
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()
  const {clientSecret} = CreatePlaceState.createdPlace; 
  useEffect(() => {
    if (elements) {
      elements.getElement(CardElement).on('change', (e) => {
        if (e.complete) {
          setFormCompleted(true)
        } else {
          setFormCompleted(false)
        }
      })
    }
  }, [elements])

  const handleSubmitPayment = async (e) => {
    setProcessing(true)

    e.preventDefault()

    if (!clientSecret || clientSecret == null) {
      setErrorPayment(
        "Votre paiement ne peux aboutir pour une raison inconnu --> Veuillez contacter l'admin du site "
      )
      setProcessing(false)
      return
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {...props.additionnalPaymentDatas}
      },


    })

    if (result.error) {
      setProcessing(false)
      setErrorPayment(result.error.message)
    } else if (result.paymentIntent.status === 'succeeded') {
      setProcessing(false)
      CreatePlaceState.setPaymentIntent(result.paymentIntent.id)
      CreatePlaceState.setActiveStep(3)
    }
  }

  return (
    <PaymentFormStyled>
      {errorPayment !== '' && <Alert>{errorPayment}</Alert>}
      <form onSubmit={handleSubmitPayment}>
        <div className="CardElement">
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <Button
          style={{
            margin: '15px auto',
            display: 'block',
          }}
          type="submit"
          variant="primary"
          disabled={!formCompleted || !stripe || processing}
        >
          {processing && <Spinner varian="light" size="sm" animation="border" />}{' '}
          {props.t('validate payment')}
        </Button>
        <div style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            style={{ width: '80%' }}
            src="https://wannapay.fr/assets/img/secure_payment.png"
          />
        </div>
      </form>
    </PaymentFormStyled>
  )
}

export default withTranslation()(withStripe(PaymentForm))

const PaymentFormStyled = styled.div`
  .StripeElement {
    background: white;
    padding: 8px;
    border-radius: 9px;
  }
  div.CardElement {
    border: 8px solid #eee;
    margin: 5px;
    border-radius: 4px;
  }
`
