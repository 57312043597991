import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Card = ({ img, title, url, color }) => {
    let history = useHistory();
    return (
      <CardStyled color={color} img={img} onClick={() => history.push(url)}>
        {img && <ImgCardStyled src={img} />}
        <div>{title}</div>
      </CardStyled>

    );
};

export default Card;

const ImgCardStyled = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const CardStyled = styled.button`
  border: none;
  padding: 0;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.22); 
  box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.22);
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  font-weight: bold;
  border-radius: 5px;
  background-color: ${(props) => (props.color && props.color)};
  &:hover {
  cursor:pointer;
  }
  >div {
      z-index: 1;
      background-color: ${(props) => (props.img ? 'rgba(0, 0, 0, 0.5)' : '')};
      width:100%;
      height: 100%;
      display:flex;
      justify-content: center;
      align-items:center;
  }
  @media (max-width: 1139px) {
      height: 200px;
      margin-bottom: 28px;
  }
  @media (max-width: 576px) {
      height: 109px;
      margin-bottom: 15px;
  }
`;
