import React from 'react';
import { MenuSelect } from 'react-instantsearch-dom';
import styled, { withTheme } from 'styled-components';

const AlSelect = ({ attribute, translations, transformItems }) => (
  <MenuSelectStyled
		transformItems={transformItems}
		translations={translations}
		attribute={attribute}
  />
);

export default withTheme(AlSelect);

const MenuSelectStyled = styled(MenuSelect)`
	.ais-MenuSelect-select {
		text-overflow: ellipsis;
		word-break: normal;
		border: none;
		background: ${(props) => props.theme.grey_4};

		color: black;
		border-radius: 12px;
		text-align: center;
	}
	margin: 0px 10px;
`;
