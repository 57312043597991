import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

import { DeleteForeverOutlined, GetApp } from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import AuthContainer from '../../../../stores/AuthContainer'
import { useFirestoreApi } from '../../../../hooks/FireTools'

import PriceItemBox from './priceItemBox'

const PriceBox = ({ pricesList, t, prices, setPrices, pricesGroup }) => {
  const [priceGroup, setpriceGroup] = useState(pricesGroup)
  const { user } = AuthContainer.useContainer()
  const { updateDatas } = useFirestoreApi()
  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  useEffect(() => {
    if (pricesList) {
      setpriceGroup(pricesList)
    }
  }, [pricesList])

  const saveProdutcPriceList = (name) => {
    if (prices && prices.length > 0) {
      updateDatas({
        query: `/users/${user.uid}/prices/`,
        datas: {
          name,
          prices,
        },
        message: {
          success: t('price_list_added'),
        },
        action: 'add',
      })
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    if (result.type === 'PRICES') {
      let newOrder = prices
      newOrder = Reorder(prices, result.source.index, result.destination.index)
      setPrices(newOrder)
    }
  }

  const DragAndDropRender = (prices) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="PRICES">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {prices.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <PriceItemBox
                      prices={prices}
                      setPrices={setPrices}
                      item={item}
                      index={index}
                      provided={provided}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  return (
    <>
      <Title>
        {t('price')}
      </Title>
      <div style={{ padding: '0 0 0 10px' }}>
        <Flex style={{ marginBottom: '20px' }}>
          <ButtonDropdown
            variant="outline"
            id="prices-dropdown"
            title={t('import price range')}
          >
            {priceGroup.length > 0 &&
              priceGroup.map((item, i) => (
                <DropDownItem>
                  <div style={{ width: '100%', display: 'flex' }}>
                    <div
                      style={{
                        float: 'left',
                        width: '80%',
                        textAlign: 'initial',
                        margin: '5px 10px 5px 15px',
                      }}
                      onClick={() => {
                        setPrices(item.prices)
                      }}
                    >
                      {item.prices.map((prices, i) => `${i !== 0 ? '  |  ' : ''}${prices.description}`)}
                    </div>
                    <div style={{ float: 'right' }}>
                      <Flex>
                        <IconContainerSelect
                          onClick={() => {
                            setpriceGroup(priceGroup.filter((e) => e.id !== item.id))
                            updateDatas({
                              query: `users/${user.uid}/prices/${item.id}`,
                              action: 'delete',
                              message: {
                                success: t('price_deleted_succeed'),
                              },
                            })
                          }}
                        >
                          <DeleteIcon />
                        </IconContainerSelect>
                      </Flex>
                    </div>
                  </div>
                </DropDownItem>
              ))}
          </ButtonDropdown>
          <SavePricesButton
            onClick={() => {
              pricesGroup.push({ id: `id-${pricesGroup.length + 1}`, group: prices })

              saveProdutcPriceList('TEST')
            }}
          >
            {t('save price range')}
            <GetApp
              style={{
                width: '25px',
                height: '25px',
                marginLeft: '5px',
                color: '#E76B56',
              }}
            />
          </SavePricesButton>
        </Flex>
        {DragAndDropRender(prices)}
        <div style={{ textAlign: 'center' }}>
          <CustomButton
            type="button"
            onClick={() => {
              setPrices([
                ...prices,
                {
                  id: `id-${prices.length + 1}`,
                  price: 0,
                  description: '',
                },
              ])
            }}
          >
            {t('add price')}
          </CustomButton>
        </div>
      </div>
    </>
  )
}

export default withTheme(withTranslation()(PriceBox))

const Flex = styled.div`
  display: flex;
`
const ButtonDropdown = styled(DropdownButton)`
  width: 70% !important;
  .dropdown-menu {
    width: 100% !important;
  }
  .dropdown-toggle {
    width: 100% !important;
    text-align: initial;
    font-weight: bold;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.2);
  }
  .dropdown-toggle::after {
    float: right;
    margin-top: 10px;
  }
`
const DropDownItem = styled(Dropdown.Item)`
  text-align: initial !important;
  color: black;
  :hover {
    color: #e76b56;
  }
  padding-left: 10px !important;
`
const CustomButton = styled.button`
  width: 40%;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: rgba(111, 79, 179, 0.05);
  border-style: none;
  font-weight: bold;
  font-size: 11pt;
  color: ${(props) => props.theme.primary};
  padding: 5px;
  border-radius: 4px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const IconContainerSelect = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 3px 5px 2px 0px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const SavePricesButton = styled.div`
  width: 30%;
  text-align: end;
  cursor: pointer;
  color: #e76b56;
  font-size: 11pt;
  margin-top: 7px;
  margin-right: 20px;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
