import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { withTheme } from 'styled-components';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';

const FirebaseAuthEmailVerified = (props) => {
const history = useHistory();
  return (
    <div>
      <h1>{props.t('Congrats')}</h1>
      <p style={{ textAlign: 'center' }}>
        {props.t('Congrats account activated')}
      </p>

      <hr />
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => history.push('/dashboard/create-place')}>
          {props.t('Create a place')}
        </Button>

        <br />
        <br />
        <div style={{ textAlign: 'center' }}>

          <Link
style={{
    			color: props.theme.secondary,
    		}}
to="/dashboard"
          >
            {props.t('skip this step')}
          </Link>
          <ArrowRightAltRoundedIcon style={{
    			color: props.theme.secondary,
    		}}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withTheme(FirebaseAuthEmailVerified));
