import styled from 'styled-components';

const SubMenu = ({ items, datas }) => (
  <SubMenuHandler style={{ backgroundColor: datas.darkmode ? 'black' : 'white' }}>
    {items.map((item, i) => (
      <SubMenuItem
          key={i}
          style={{ color: datas.darkmode ? 'white' : datas.color, font: `normal normal normal 18px  ${datas.font}`}}
      >
        {item.value}
      </SubMenuItem>
    ))}
  </SubMenuHandler>
  );

export default SubMenu;

const SubMenuItem = styled.a`
&:hover {
    text-decoration: none;
}
font: normal normal normal 18px Lato;
padding-bottom: 13px;
margin-right: 25px;
&:last-child {
    margin-right: 0px;
}

`;

const SubMenuHandler = styled.div`
padding-top: 9px;
display: flex;
align-items: center;
padding-left: 20px;
overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
-webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
top: 78px;
z-index: 10;
`;
