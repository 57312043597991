import React, { useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import Switch from '@material-ui/core/Switch'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import styled, { withTheme } from 'styled-components'
import { Controller } from 'react-hook-form'

import MenuTheme from '../../../MenuTheme/MenuTheme'

const polices = [
	'Arial',
	'Arial narrow',
	'Helvetica',
	'Verdana',
	'Lucida Sans',
	'Futara',
	'Trebuchet MS',
	'Segoe UI',
	'Times New Roman',
	'Big Caslon',
	'Bodoni MT',
]

const colors = [
	'#356396',
	'#10789B',
	'#952221',
	'#E6A821',
	'#B49F5E',
	'#276347',
	'#6F4FB3',
	'#0F0F0F',
]

const Themes = ({ t, placeDatas, register, control, menuDatas, watch }) => {
	const [font, setFont] = useState(menuDatas.theme_font)
	const [color, setColor] = useState(menuDatas.theme_color)
	const [darkmode, setDarkmode] = useState(menuDatas.theme_darkmode)
	const [changeTheme, setchangeTheme] = useState(false)
	const datas = {
		font,
		color,
		darkmode,
	}
	let menu_specific_theme = watch('menu_specific_theme')

	return (
		<>
			<Container fluid>
				<Card bsPrefix="card white-card">
					<Flex>
						<Controller
							control={control}
							name="menu_specific_theme"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<Switch
									onBlur={onBlur}
									onChange={(e) => {
										onChange(e)
									}}
									checked={value}
									inputRef={ref}
								/>
							)}
						/>
						<SwitchTitle>
							{t('menu specific hours')}
						</SwitchTitle>
					</Flex>
				</Card>
				<Row
					style={
						menu_specific_theme ? null : { pointerEvents: 'none', opacity: 0.3 }
					}
				>
					<Col lg="8">
						<Card bsPrefix="card white-card">
							<div className="radioButton3">
								<Title>
									{t('theme choice')}
								</Title>
								<Controller
									control={control}
									name="menu_theme"
									render={({ field: { onChange, onBlur, name, ref, value } }) => (
										<RadioGroup
											ref={ref}
											onChange={null /* onChange */}
											defaultValue="light"
											value={value}
										>
											{value === 'light' ? (
												<BackgroundChecked>
													<Flex>
														<FormControlLabel value="light" control={<Radio />} />
														<RadioTitleChecked>{t('minimalist')}</RadioTitleChecked>
														<RadioSubtitleChecked>
															{t('minimalist description')}
														</RadioSubtitleChecked>
													</Flex>
													<SubBackground>
														<SubBackgroundTitle>
															{t('theme font')}
														</SubBackgroundTitle>
														<ThemeSelect
															style={{ backgroundColor: '#F8F7FF', width: '98%' }}
															{...register('theme_font', { required: true })}
															defaultValue="Arial"
															onChange={(e) => {
																setFont(e.target.value)
															}}
														>
															{polices.map((police, i) => (
																<option key={`police${i}`} value={police}>
																	{police}
																</option>
															))}
														</ThemeSelect>
													</SubBackground>
													<SubBackground>
														<SubBackgroundTitle>{t('theme color')}</SubBackgroundTitle>
														<Controller
															control={control}
															name="theme_color"
															render={({
																field: { onChange, onBlur, value, name, ref },
															}) => (
																<RadioGroup
																	ref={ref}
																	onChange={(e) => {
																		onChange(e)
																		setColor(e.target.value)
																	}}
																	defaultValue="#356396"
																	value={value}
																>
																	<Flex style={{ flexFlow: 'wrap' }}>
																		{colors.map((color, i) => (
																			<ColorChoiceContainer
																				key={`colorChoice${i}`}
																				htmlFor={color}
																				active={value === color}
																			>
																				<Flex style={{ marginBottom: '10px' }}>
																					<ColorChoice
																						style={{ backgroundColor: color }}
																					/>
																					<ColorChoiceBisContainer>
																						<ColorChoiceBis
																							style={{ backgroundColor: color }}
																						/>
																					</ColorChoiceBisContainer>
																					<Radio
																						value={color}
																						style={{ display: 'none' }}
																						id={color}
																					/>
																				</Flex>
																			</ColorChoiceContainer>
																		))}
																	</Flex>
																</RadioGroup>
															)}
														/>
													</SubBackground>
													<SubBackground style={{ padding: '2px' }}>
														<Flex>
															<Controller
																control={control}
																name="theme_darkmode"
																render={({
																	field: { onChange, onBlur, value, ref },
																}) => (
																	<Switch
																		onBlur={onBlur}
																		onChange={(e) => {
																			onChange(e)
																			setDarkmode(e.target.checked)
																		}}
																		checked={value}
																		inputRef={ref}
																	/>
																)}
															/>
															<Darkmode>{t('dark mode')}</Darkmode>
														</Flex>
													</SubBackground>
												</BackgroundChecked>
											) : (
												<BackgroundUnchecked>
													<Flex>
														<FormControlLabel value="light" control={<Radio />} />
														<RadioTitle>{t('minimalist')}</RadioTitle>
														<RadioSubtitle>{t('minimalist description')}</RadioSubtitle>
													</Flex>
												</BackgroundUnchecked>
											)}
											<br />
											{value === 'custom' ? (
												<BackgroundChecked>
													<Flex>
														<FormControlLabel value="custom" control={<Radio />} />
														<RadioTitleChecked>{t('custom')}</RadioTitleChecked>
														<RadioSubtitleChecked>
															{t('custom description')}
														</RadioSubtitleChecked>
													</Flex>
													<SubBackground>{t('avalaible soon')}</SubBackground>
												</BackgroundChecked>
											) : (
												<BackgroundUnchecked>
													<Flex>
														<AvalaibleSoon>
															{t('avalaible soon')}
														</AvalaibleSoon>
														<FormControlLabel value="custom" control={<Radio />} />
														<RadioTitle>{t('custom')}</RadioTitle>
														<RadioSubtitle>{t('custom description')}</RadioSubtitle>
													</Flex>
												</BackgroundUnchecked>
											)}
										</RadioGroup>
									)}
								/>
							</div>
						</Card>
					</Col>
					<Col lg="4">
						<Card bsPrefix="card white-card">
							<Title>
								{t('theme preview')}
							</Title>
							<Background>
								<ScrollBackground>
									<MenuTheme datas={datas} />
								</ScrollBackground>
							</Background>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default withTheme(withTranslation()(Themes))

const Flex = styled.div`
	display: flex;
`
const SwitchTitle = styled.div`
	margin-top: 3px;
	font-size: 15pt;
	font-weight: bold;
`
const Darkmode = styled.div`
	font-size: 11pt;
	font-weight: bold;
	margin-top: 8px;
	margin-left: 10px;
`
const AvalaibleSoon = styled.div`
	width: 160px;
	height: 25px;
	padding-top: 2px;
	text-align-last: center;
	margin: 10px 50px 0px 0px;
	position: absolute;
	background-color: ${(props) => props.theme.primary};
	border-radius: 5px;
	font-size: 14px;
	color: white;
	right: 0;
  @media screen and (max-width: 500px) {
    margin-top: 30px;
  }
`
const ThemeSelect = styled.select`
	width: 95%;
	cursor: pointer;
	padding: 5px;
	font-size: 11pt;
	border-style: none;
	border-radius: 5px;
	margin-left: 6px;
	margin-right: 60px;
	::placeholder {
		opacity: 0.5;
	}
`
const Background = styled.div`
	background-color: #f8f7ff;
	padding: 15px 10px 15px 15px;
	border-radius: 10px;
`
const ScrollBackground = styled.div`
	padding-right: 10px;
	overflow-y: scroll;
	height: 500px;
	::-webkit-scrollbar {
		background-color: white;
		width: 10px;
		margin-left: 20px;
	}
	::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 7px;
	}
`
const BackgroundChecked = styled.div`
	background-color: #f8f7ff;
	padding-top: 12px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: ${(props) => props.theme.primary};
`
const BackgroundUnchecked = styled.div`
	background-color: #f8f7ff;
	padding-top: 12px;
	padding-left: 20px;
	border-radius: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: lightgrey;
`
const SubBackground = styled.div`
	padding: 10px;
	background-color: white;
	margin-bottom: 10px;
	border-radius: 7px;
`
const SubBackgroundTitle = styled.div`
	font-size: 11pt;
	font-weight: bold;
	margin-bottom: 10px;
`
const Title = styled.p`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 6px;
	margin-left: 7px;
	margin-bottom: 10px;
`
const RadioTitle = styled.p`
	font-size: 15pt;
	margin-top: 4px;
	font-weight: bold;
`
const RadioSubtitle = styled.div`
	font-size: 11pt;
	margin-top: 10px;
	margin-left: 8px;
	font-style: italic;
  @media screen and (max-width: 500px) {
    display: none;
  }
`
const RadioTitleChecked = styled.p`
	font-size: 15pt;
	margin-top: 4px;
	font-weight: bold;
	color: ${(props) => props.theme.primary};
`
const RadioSubtitleChecked = styled.div`
	font-size: 11pt;
	margin-top: 10px;
	margin-left: 8px;
	font-style: italic;
	color: ${(props) => props.theme.primary};
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const ColorChoice = styled.label`
	width: 35px;
	height: 30px;
	border-radius: 7px;
	margin-bottom: 0;
`
const ColorChoiceBis = styled.div`
	height: 30px;
	border-radius: 7px;
	opacity: 0.3;
`
const ColorChoiceBisContainer = styled.div`
	background-color: white;
	z-index: 900;
	margin-left: -20px;
	width: 35px;
	border-radius: 7px;
`

const ColorChoiceContainer = styled.label`
	opacity: ${(props) => !props.active && '0.4'};
	&:hover {
		opacity: 1;
	}
	margin-right: 10px;

	cursor: pointer;
`
