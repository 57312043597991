import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useForm } from 'react-hook-form'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../stores/AuthContainer'
import { Menu } from './view'
import { useStorageTools } from '../../../hooks/FireTools'
import { formatFromDb } from '../../../utils/DateHelpers'
import PlaceContainer from '../../../stores/PlaceContainer'

import {
  updateMultilangArray,
  createMultilangArray,
} from '../../../utils/MultilangHelpers'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const navItems = ['menu']

const CreateFormula = ({ t, setTitle, modePDF, newMenu }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState('menu')
  const location = useLocation()
  const { place: placeDatas } = PlaceContainer.useContainer()
  const { place_id } = queryString.parse(location.search)
  const history = useHistory()
  var formulaRef = []

  if (placeDatas?.id) {
    formulaRef = FirestoreDb()
      .collection(placeDatas?.id ? `places/${place_id}/formulas` : null)
      .doc()
  } else {
    history.push('/dashboard/places')
  }

  const {
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
    unregister,
    watch,
    formState: { errors },
    control,
  } = useForm()
  const { user } = AuthContainer.useContainer()
  const { firebaseUploader } = useStorageTools()
  const [saving, setSaving] = useState(false)
  let currentLang = placeDatas?.main_language || 'FR'

  useEffect(() => {
    if (placeDatas) {
      setTitle(`${placeDatas.place_name} > <b>${t('menus')}</b>`)
    }
  }, [t, setTitle, placeDatas])

  const formatHoursFromDb = (hours = []) => {
    const _hours = [...hours]
    hours.forEach((_h, i) => {
      _hours[i].displayHours = formatFromDb(_h.displayHours)
      _hours[i].undisplayHours = formatFromDb(_h.undisplayHours)
    })
    return _hours
  }

  const handleSubmitForm = async (datas) => {
    setSaving(true)
    const commonDatas = {
      uid: user.uid,
      created_at: new Date(),
      place_id: placeDatas.id,
    }

    let background_image_url = null
    if (datas.background_image_file && datas.background_image_file.size > 0) {
      try {
        let bgImageUploaded = await firebaseUploader(
          [datas.background_image_file],
          `users/${user.uid}/formulas/${formulaRef.id}`,
          ['background_image']
        )
        background_image_url = bgImageUploaded[0].url
      } catch (e) {
        enqueueSnackbar(t('error_saving_image'), { variant: 'error' })
      }
    }

    const simpleDatas = {
      ...commonDatas,
      background_image_url,
      name_ml: createMultilangArray(
        [placeDatas.main_language, ...placeDatas.languages_array],
        currentLang,
        datas.name
      ),
      description_ml: createMultilangArray(
        [placeDatas.main_language, ...placeDatas.languages_array],
        currentLang,
        datas.description
      ),
      price: datas?.price || 0,

      order: 1000000,
    }
    try {
      await formulaRef.set(simpleDatas, { merge: true })
    } catch (e) {
      enqueueSnackbar(t('error_saving_data_menu'), { variant: 'error' })
      return
    }

    enqueueSnackbar(t('menu_created_success'), { variant: 'success' })
    setSaving(false)
    history.push(
      `/dashboard/edit/formula?place_id=${placeDatas.id}&formula_id=${formulaRef.id}`
    )
  }

  const displayView = () => {
    const _props = {
      register,
      modePDF,
      setValue,
      unregister,
      formatHoursFromDb,
    }
    const _propsMenu = {
      errors,
    }
    switch (tab) {
      case 'menu':
        return <Menu {..._props} {..._propsMenu} />

      default:
        return <Menu />
    }
  }

  return (
    <CustomContainer fluid>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div style={{ marginTop: '70px' }} />
        <Row>
          <Col lg="10" />
          <Col lg="2" style={{ marginTop: '20px' }}>
            <SaveButton disabled={saving} type="submit" variant="secondary">
              {saving ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                t('save')
              )}
            </SaveButton>
          </Col>
        </Row>
        <ThemeProvider theme={theme}>{displayView()}</ThemeProvider>
      </form>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(CreateFormula))

const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const SaveButton = styled(Button)`
  margin-left: 5%;
  width: 85%;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
