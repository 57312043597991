import _, { where } from 'underscore'
import { getMultilangContent } from './MultilangHelpers'

export const parseCats = (
  fItems,
  _cats,
  original = false,
  products,
  currentLang,
  subCatSelect
) => {
  const types = [...products]
  fItems = retrieveWithMultilangProd(fItems, currentLang)
  _cats = retrieveWithMultilangCats(_cats, currentLang)

  const productUnCategorized = fItems.filter(
    (p) => !p?.subcat || p.subcat === '' || !_cats.find((c) => c.id === p.subcat)
  )

  const productCategorized = _cats.reduce((acc, cat) => {
    const updatedCat = { ...cat }
    const catProducts = _.where(fItems, { subcat: cat.id })
    updatedCat.products = catProducts.sort((a, b) => a.order - b.order) || []

    return [...acc, updatedCat]
  }, [])

  const parsed = types.map((t) => {
    const un =
      subCatSelect === 'uncategorized' || subCatSelect === 'all' || original
        ? productUnCategorized.filter((p) => p.tab === t.category_name)
        : []
    let cat =
      subCatSelect !== 'uncategorized' || original
        ? productCategorized
            .filter((p) => p.cat === t.category_name)
            .sort((a, b) => a.product_list_position - b.product_list_position)
        : []
    if (!original) {
      cat = cat.filter((p) => p.id === subCatSelect || subCatSelect === 'all')
    }

    return { ...t, products: un || [], groups: cat || [] }
  })
  return parsed
}

export const retrieveWithMultilangProd = (item, lang) => {
  return item.map((prod) => ({
    ...prod,
    product_name: getMultilangContent(prod, 'product_name_ml', lang),
    product_description: getMultilangContent(prod, 'product_description_ml', lang),
  }))
}

export const retrieveWithMultilangCats = (item, lang) => {
  return item.map((cat) => ({
    ...cat,
    name: getMultilangContent(cat, 'name_ml', lang),
  }))
}
