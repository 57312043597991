import React, { useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import 'firebase/auth'

import { FcGoogle } from 'react-icons/fc'
import styled from 'styled-components'
import { Auth } from '../utils/firebase/FirebaseModules'

const useGoogle = (props) => {
  const [signUpWithEmailError, setSignUpWithEmailError] = useState(null)
  const [signInWithEmailError, setSignInWithEmailError] = useState(null)
  const [sendResetMailError, setSendResetMailError] = useState(null)
  const [resetPassordErrors, setResetPassordErrors] = useState(null)
  const [signinIn, setSigninIn] = useState(false)
  const [sendingEmailVerification, setSendingEmailVerification] = useState(false)
  const [verificationMailSended, setVerificationMailSended] = useState(false)

  const sendVerificationEmail = async () => {
    setSendingEmailVerification(true)
    const user = await Auth().currentUser
    user
      .sendEmailVerification()
      .then(() => {
        setVerificationMailSended(true)
        setSendingEmailVerification(false)
      })
      .catch((e) => {
        console.log(e)
        setVerificationMailSended(false)
        setSendingEmailVerification(false)
      })
  }
  const verifyEmailButton = (text = "Vérifier l'email") => (
    <SendEmailVerificationButton
      variant="default"
      disabled={sendingEmailVerification}
      onClick={sendVerificationEmail}
    >
      {sendingEmailVerification ? (
        <Spinner animation="border" variant="dark" size="sm" />
      ) : (
        text
      )}
    </SendEmailVerificationButton>
  )

  const confirmResetPassword = async (code, password) => {
    let err = "Une erreur s'est produite"

    return Auth()
      .confirmPasswordReset(code, password)
      .then(() => ({
        success: true,
        message: 'Votre mot de passe à bien été modifié',
      }))
      .catch((error) => {
        switch (error.code) {
          case 'auth/expired-action-code':
            err = 'Le jeton de renouvellement de mot de passe a expiré'

            break
          case 'auth/invalid-action-code':
            err = "Votre jeton de renouvellement n'est pas valide"

            break
          case 'auth/user-disabled':
            err = "Cet utilisateur n'est pas actif"

            break
          case 'auth/user-not-found':
            err = 'Aucun compte pour cet utilisateur'

            break

          case 'auth/weak-password':
            err = 'Mot de passe trop faible (au moins 6 caractères)'

            break
          default:
            err = "Erreur d'authentification"

            break
        }

        setResetPassordErrors(<SignupError>{err}</SignupError>)

        return {
          success: false,
        }
      })
  }

  const sendResetEmail = async (email) => {
    let err = "Une erreur s'est produite"

    return Auth()
      .sendPasswordResetEmail(email, {
        url: `${window.location.origin}/login?action=renew_password`,
      })
      .then(() => ({
        success: true,
        message: 'Un email vous a été envoyé',
      }))
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            err = "Votre email n'est pas valide"

            break
          case 'auth/user-not-found':
            err =
              'Votre adresse email ne correspond à aucun compte sur notre plateforme'

            break
          default:
            err =
              "Une erreur imprévu est survenu , veuillez réessayer plus tard ou contacter l'admin de la plateforme"

            break
        }

        setSendResetMailError(<SignupError>{err}</SignupError>)

        return {
          success: false,
        }
      })
  }

  const signUpWithEmail = async (email, password) =>
    Auth()
      .createUserWithEmailAndPassword(email, password)

      .then((userCredential) => {
        setSignUpWithEmailError(null)
        return {
          success: true,
          userCredential,
        }
      })
      .catch((error) => {
        let error_message =
          "Erreur pendant l'authentification , contacter nous si le problème persiste"
        switch (error.code) {
          case 'auth/email-already-in-use':
            error_message = 'Cet email a déjà été utilisé'
            break
          case 'auth/invalid-email':
            error_message = "Votre email n'est pas valide"
            break

          case 'auth/weak-password':
            error_message = 'Mot de passe trop faible ( au moins 6 caractères ) '
            break

          default:
            error_message = 'Erreur durant la création du compte'
            break
        }

        setSignUpWithEmailError(<SignupError>{error_message}</SignupError>)
        return {
          success: false,
          message: error_message,
          code: error.code,
        }
      })
  const signInWithEmail = async (email, password, persistance = true) => {
    const persist = persistance
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION

    await Auth().setPersistence(persist)

    setSignUpWithEmailError(null)
    return Auth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        console.log(error)
        let error_message =
          "Erreur pendant l'authentification , contacter nous si le problème persiste"
        switch (error.code) {
          case 'auth/invalid-email':
            error_message = "Votre email n'est pas valide"

            break
          case 'auth/user-disabled':
            error_message = 'Ce compte est désactivé'

            break
          case 'auth/too-many-requests':
            error_message =
              'Trop de tentative , ce compte à été temporairement désactivé. Réinitialisez le mot de passe pour le réactiver immédiatement'

            break
          case 'auth/user-not-found':
            error_message = 'Aucun compte trouvé avec ces identifiants'

            break
          case 'auth/wrong-password':
            error_message = 'Erreur de mot de passe'

            break

          default:
            error_message = "Erreur durant l'authentification"

            break
        }

        setSignInWithEmailError(<SignupError>{error_message}</SignupError>)
        return {
          success: false,
          message: error_message,
          code: error.code,
        }
      })
  }

  const signInWithGoogle = () => {
    setSigninIn(true)
    let provider = new firebase.auth.GoogleAuthProvider()
    Auth()
      .signInWithPopup(provider)
      .then((result) => {
        setSigninIn(false)

        return
      })
      .catch((error) => {
        setSigninIn(false)

        // Handle Errors here.
        var errorMessage = error.message
        // The firebase.auth.AuthCredential type that was used.
        alert(errorMessage)
      })
  }

  const googleLoginButton = (title = 'login with google') => (
    <GoogleButtonStyled disabled={signinIn} onClick={signInWithGoogle}>
      <FcGoogle />{' '}
      {signinIn ? <Spinner animation="border" variant="dark" size="sm" /> : title}
    </GoogleButtonStyled>
  )
  return {
    googleLoginButton,
    signUpWithEmail,
    signInWithEmail,
    signUpWithEmailError,
    signInWithEmailError,
    setSignInWithEmailError,
    sendResetMailError,
    setSendResetMailError,
    sendResetEmail,
    resetPassordErrors,
    confirmResetPassword,
    sendingEmailVerification,
    verificationMailSended,
    verifyEmailButton,
    sendVerificationEmail,
  }
}

export default useGoogle

const SendEmailVerificationButton = styled(Button)`
  color: ${(props) => props.theme.primary};
`

const GoogleButtonStyled = styled.button`
  background: #ffffff;
  margin: 10px auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
  svg {
    margin-right: 20px;
  }
  border: 1px solid #eee;
  padding: 5px 20px;
`

const SignupError = styled.div`
  padding: 5px;
  text-align: center;
  margin: 5px;
  border-radius: 2px;
  background: #ffd2d2;
`
