import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container, Row, Col, Card } from 'react-bootstrap'
import moment from 'moment'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import styled, { withTheme } from 'styled-components'
import useAlgolia from '../../../hooks/Algolia'
import AuthContainer from '../../../stores/AuthContainer'
import ClassicTableAlgolia from '../../../components/UI/tables/ClassicTableAlgolia'
import AlSearchBox from '../../../components/UI/form/algolia/AlSearchBox/AlSearchBox'
import AlSelect from '../../../components/UI/form/algolia/AlSelect/AlSelect'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'

const Invoices = ({ setTitle, t }) => {
  const { user } = AuthContainer.useContainer()
  const { AlgoliaClient } = useAlgolia()

  React.useEffect(() => {
    setTitle(`${t('subscriptions')} > <b>${t('invoices')}</b>`)
  }, [setTitle, t])

  const showInvoice = async (id) => {
    const invoice = await FirestoreDb()
      .collection('users')
      .doc(user.uid)
      .collection('invoices')
      .doc(id)
      .get()

    if (invoice.exists) window.open(invoice.data().invoice_pdf)
  }

  return (
    <CustomContainer fluid>
      <Row>
        <InstantSearch indexName="invoices_index" searchClient={AlgoliaClient}>
          <Configure filters={`uid:${user.uid}`} hitsPerPage={15} />
          <Col>
            <Card bsPrefix="card white-card">
              <Row style={{ marginBottom: '15px ' }}>
                <Col lg="7">
                  <AlSearchBox
                    translations={{ placeholder: `${t('place')}, ${t('type')}...` }}
                    autoFocus
                  />
                </Col>
                <Col
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AlSelect
                    translations={{ seeAllOption: t('type') }}
                    transformItems={(items) =>
                      items.map((item) => ({
                        ...item,
                        label:
                          item.label === 'place_subscription'
                            ? t('place')
                            : 'QrCodes',
                      }))
                    }
                    attribute="type"
                  />
                  <AlSelect
                    translations={{ seeAllOption: t('place') }}
                    attribute="place_name"
                  />
                </Col>
              </Row>
              <ClassicTableAlgolia
                type="algolia"
                headValues={[
                  { name: 'created', label: 'created at' },
                  { name: 'place_name', label: 'place name' },
                  { name: 'type', label: 'type' },
                  // { name: 'objectID', label: 'id' },
                  { name: 'amount_paid', label: 'amount' },
                  { name: '', label: ' ' },
                ]}
                actions={[
                  {
                    func: (id) => showInvoice(id),
                    name: 'dowload your invoice',
                    params: 'objectID',
                  },
                ]}
                dataTypes={[
                  'created',
                  'place_name',
                  'type',
                  // 'objectID',
                  'amount_paid',
                ]}
                format={{
                  created: (date) =>
                    moment(date * 1000).format('DD/MM/YYYY à HH:mm'),
                  amount_paid: (amount) => `${parseFloat(amount / 100).toFixed(2)}€`,
                  type: (type) => (type === 'qr_code' ? 'Qr Codes' : 'Abonnement'),
                }}
              />
            </Card>
          </Col>
        </InstantSearch>
      </Row>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(Invoices))

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media (min-width: 992px) {
    padding-left: 250px;
  }
`
