import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ListGroup,Spinner } from 'react-bootstrap'
import PaymentMethod from './PaymentMethod'

const PaymentMethods = ({
	methods,
	currentMethod,
	placeId,
	isTrial,
	paymentMethodChoosed,
	setPaymentMethodChoosed,
	removeMethod,
}) => {
	const [updating, setUpdating] = useState(false)

	return (
		<ListGroup updating={updating}>
			{methods ?
				methods.map((method) => (
					<PaymentMethod
						methods={methods}
						updating={updating}
						setUpdating={setUpdating}
						placeId={placeId}
						current={
							isTrial
								? paymentMethodChoosed === method.id
								: currentMethod === method.id
						}
						{...method}
						key={method.id}
						isTrial={isTrial}
						paymentMethodChoosed={paymentMethodChoosed}
						setPaymentMethodChoosed={setPaymentMethodChoosed}
						removeMethod={removeMethod}
					/>
				)) : 
				<Spinner  animation='border'  size="lg" variant="dark" /> 

				}
		</ListGroup>
	)
}
export default PaymentMethods
