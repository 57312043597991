import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const config = require('../../../../../../config')

const SubscriptionCart = ({ t, formatedCart, cart, reduction }) => {
  const Total = () => {
    let total = parseFloat(
      cart.reduce((acc, curr) => curr.price.unit_amount + acc, 0) /
        config.taxes.tax_20 /
        100
    ).toFixed(2)
    let total_TTC = parseFloat(
      cart.reduce((acc, curr) => curr.price.unit_amount + acc, 0) / 100
    ).toFixed(2)
    let totalToday =
      cart
        .filter(
          (p) =>
            p.price.type !== 'recurring' ||
            (p.price.recurring !== null &&
              p.price.recurring.interval_count === 1 &&
              p.price.recurring.interval === 'month')
        )
        .reduce((acc, curr) => curr.price.unit_amount + acc, 0) /
      config.taxes.tax_20 /
      100

    return {
      total,
      totalToday,
      total_TTC,
    }
  }

  return (
    <div>
      <CartItemList>
        {formatedCart() &&
          formatedCart().map((item, i) => (
            <li key={item.id}>
              <Row>
                <Col lg="5">
                  {`${item.name}`}
                  {/* x${item.qty}` */}
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  {`${
                    (item.price.unit_amount / config.taxes.tax_20 / 100) * item.qty
                  } € `}{t('without tax')}
                </Col>
              </Row>
            </li>
          ))}
        <hr />
        <li>
          <Row>
            <Col lg="5">
              {t('')}
            </Col>
            <Col style={{ textAlign: 'right' }}>
              <PriceTTC reduc={reduction}>{`${Total().total_TTC} €`}</PriceTTC>
              {/* `${Total().total} €` */}
            </Col>
          </Row>
        </li>

        {reduction && (
          <li>
            <Row>
              <Col lg="5">Total après réduction</Col>
              <Col style={{ textAlign: 'right' }}>
                <PriceTTC>{`${reduction} €`}</PriceTTC>
                {/* `${Total().total} €` */}
              </Col>
            </Row>
          </li>
        )}
      </CartItemList>
    </div>
  )
}

export default withTranslation()(SubscriptionCart)

const PriceTTC = styled.span`
  text-decoration: ${(props) => props.reduc && 'line-through'};
`
const CartItemList = styled.ul`
  padding: 0;
  li {
    background: rgba(111, 79, 179, 0.1);
    padding: 16px 24px;
    list-style: none;
    border-radius: 17px;
    margin-bottom: 12px;

    font-weight: bold;
  }
`
