import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import styled, { withTheme } from 'styled-components'
import { Add, Remove } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import Switch from '@material-ui/core/Switch'
import { withTranslation } from 'react-i18next'
import _ from 'underscore'

const useStyles = makeStyles({
	underline: {
		'&&&:before': {
			borderBottom: 'none',
		},
		'&&:after': {
			borderBottom: 'none',
		},
	},
})

const Hours = ({ t, setValue, unregister, daysFetched, place_hours_enable }) => {
	const [disableHours, setDisableHours] = useState(place_hours_enable || false)
	const [days, setDays] = useState([])
	const classes = useStyles()

	const setDay = (id, status, thirdPeriod = false) => {
		const _days = [...days]
		_days[id] = {
			status: Boolean(status),
			hours: _days[id].hours || [],
			thirdPeriod,
		}
		setDays(_days)
	}

	const updateTime = (id, index, hour, type) => {
		const _days = [...days]
		const _hours = [..._days[id].hours]
		_hours[index] = !_.isUndefined(_hours[index]) ? { ..._hours[index] } : {}
		_hours[index][type] = hour
		_days[id].hours = _hours
		setDays(_days)
	}

	useEffect(() => {
		let _days = []
		const defaultHours = []

		for (let i = 0; i < 3; i++) {
			defaultHours.push({ start: '10:00', end: '22:00' })
		}

		for (let i = 0; i < 7; i++) {
			_days.push({ hours: defaultHours, status: false, thirdPeriod: false })
		}

		if (daysFetched) {
			daysFetched.map((day, index) => {
				let _hours = [...day.hours]
				_hours = _hours.map((e) => ({
					start: e.start,
					end: e.end,
				}))
				_days[index] = { ...day, hours: _hours }
				return null
			})
		}
		setDays(_days)
	}, [setDays, daysFetched])

	useEffect(() => {
		unregister('place_hours')
		setValue('place_hours', days)
	}, [days, setValue, unregister])

	function DayContainer(day, id, setDaySwitch) {
		const [thirdPeriod, setThirdPeriod] = useState(false)

		if (!_.isUndefined(days[id]) && days[id].status) {
			return (
				<Background>
					<Row>
						<Col lg="4">
							<SwitchContainer>
								<Flex>
									<Switch
										checked={days[id].status}
										onChange={() => {
											setDay(id, !days[id].status)
										}}
										name="daySwitch"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
									<Day>{day}</Day>
								</Flex>
							</SwitchContainer>
						</Col>
						<Col lg="4">
							<FlexTimeInput style={{ marginBottom: '10px' }}>
								<TimeInput>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={days[id].hours[0].start}
										defaultValue="09:00"
										onChange={(e) => updateTime(id, 0, e.target.value, 'start')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInput>
								-
								<TimeInputBis>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={days[id].hours[0].end}
										defaultValue="22:00"
										onChange={(e) => updateTime(id, 0, e.target.value, 'end')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInputBis>
							</FlexTimeInput>
							{/* <FlexTimeInput style={{ marginBottom: '10px' }}>
								<TimeInput>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={days[id].hours[1].start}
										onChange={(e) => updateTime(id, 1, e.target.value, 'start')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInput>
								-
								<TimeInputBis>
									<TextField
										id="time-1"
										type="time"
										InputProps={{ classes }}
										value={days[id].hours[1].end}
										onChange={(e) => updateTime(id, 1, e.target.value, 'end')}
										InputLabelProps={{ shrink: true }}
										inputProps={{ step: 300 }}
									/>
								</TimeInputBis>
							</FlexTimeInput> */}
							{days[id].thirdPeriod ? (
								<FlexTimeInput>
									<TimeInput>
										<TextField
											id="time-1"
											type="time"
											InputProps={{ classes }}
											value={days[id].hours[2].start}
											onChange={(e) => updateTime(id, 2, e.target.value, 'start')}
											InputLabelProps={{ shrink: true }}
											inputProps={{ step: 300 }}
										/>
									</TimeInput>
									-
									<TimeInputBis>
										<TextField
											id="time-1"
											type="time"
											InputProps={{ classes }}
											value={days[id].hours[2].end}
											onChange={(e) => updateTime(id, 2, e.target.value, 'end')}
											InputLabelProps={{ shrink: true }}
											inputProps={{ step: 300 }}
										/>
									</TimeInputBis>
								</FlexTimeInput>
							) : null}
						</Col>
						<Col lg="4">
							<div
								style={{ textAlign: 'end' }}
								onClick={() => {
									setThirdPeriod(!thirdPeriod)
									setDay(id, true, !thirdPeriod)
								}}
							>
								{days[id].thirdPeriod ? (
									<AddContainer>
										{t('remove hours')}
										<Remove
											style={{
												width: '20px',
												height: '17px',
												color: '#E76B56',
											}}
										/>
									</AddContainer>
								) : (
									<AddContainer>
										{t('add hours')}
										<Add
											style={{
												width: '20px',
												height: '17px',
												color: '#E76B56',
											}}
										/>
									</AddContainer>
								)}
							</div>
						</Col>
					</Row>
				</Background>
			)
		} else {
			return (
				<Unbackground>
					<Row>
						<Col lg="4">
							<SwitchContainer>
								<Flex>
									<Switch
										checked={!_.isUndefined(days[id]) ? days[id].status : false}
										onChange={() => {
											setDay(id, !_.isUndefined(days[id]) ? !days[id].status : true)
										}}
										name="daySwitch"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
									<Day>{day}</Day>
								</Flex>
							</SwitchContainer>
						</Col>
						<Col lg="4">
							<Closed>Fermé</Closed>
						</Col>
					</Row>
				</Unbackground>
			)
		}
	}

	return (
		<Container fluid>
			<Row style={{ flexWrap: 'wrap-reverse' }}>
				<HourList show={disableHours} lg="8">
					<Card bsPrefix="card white-card">
						<Title>
							{t('open hours')}
						</Title>
						{DayContainer(t('monday'), 0)}
						<br />
						{DayContainer(t('tuesday'), 1)}
						<br />
						{DayContainer(t('wednesday'), 2)}
						<br />
						{DayContainer(t('thursday'), 3)}
						<br />
						{DayContainer(t('friday'), 4)}
						<br />
						{DayContainer(t('saturday'), 5)}
						<br />
						{DayContainer(t('sunday'), 6)}
					</Card>
				</HourList>
				<Col lg="4">
					<Card bsPrefix="card white-card">
						<Flex>
							<Switch
								checked={disableHours}
								onChange={() => {
									unregister('place_hours_enable')
									setValue('place_hours_enable', !disableHours)
									setDisableHours(!disableHours)
								}}
								name="disableHours"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
							/>
							<DisableTitle>
								{t('hide')}
							</DisableTitle>
						</Flex>
						<DisableSubtitle>
							{t('hide hours')}
						</DisableSubtitle>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export default withTranslation()(withTheme(Hours))

const HourList = styled(Col)`
	opacity: ${(props) => props.show && '0.5'};
	* {
		cursor: ${(props) => props.show && 'not-allowed !important'};

		pointer-events: ${(props) => props.show && 'none !important'};
	}
`

const Flex = styled.div`
	display: flex;
`
const FlexTimeInput = styled.div`
	display: flex;
	justify-content: center;
`
const Day = styled.div`
	font-weight: bold;
	font-size: 11pt;
	margin-top: 8px;
`
const Closed = styled.div`
	display: flex;
	font-weight: bold;
`
const TimeInput = styled.div`
	background-color: white;
	padding-left: 15px;
	border-radius: 5px;
	margin-right: 10px;
`
const TimeInputBis = styled.div`
	background-color: white;
	padding-left: 15px;
	border-radius: 5px;
	margin-left: 10px;
`
const AddContainer = styled.div`
	font-size: 11pt;
	margin-top: 8px;
	color: #e76b56;
	cursor: pointer;
`
const Background = styled.div`
	background-color: #E6EFFF;
	padding: 10px;
	text-align: center;
	border-radius: 7px;
`
const Unbackground = styled.div`
	background-color: white;
	padding: 10px;
	text-align: center;
	border-radius: 7px;
	border-color: rgba(55, 55, 55, 0.2);
	border-width: 1px;
	border-style: solid;
`
const Title = styled.div`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 6px;
	margin-left: 7px;
	margin-bottom: 10px;
`
const DisableTitle = styled.div`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 2px;
`
const DisableSubtitle = styled.div`
	font-size: 10pt;
	margin-left: 12px;
`
const SwitchContainer = styled.div`
	text-align: left;
	margin-top: -5px;
`
