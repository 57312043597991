import React, { useState } from 'react';
import firebase from 'firebase/app';
import { FaFacebookF } from 'react-icons/fa';
import axios from 'axios';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { Auth } from '../utils/firebase/FirebaseModules';

export const useLogin = (props) => {
const [accessToken, setAccessToken] = useState(null);
const [accounts, setAccounts] = useState(null);
const [instagramAccountDatas, setInstagramAccountDatas] = useState(null);
const [error, setError] = useState(false);
const [signinIn, setSigninIn] = useState(false);

const signIn = async () => {
setSigninIn(true);
const provider = new firebase.auth.FacebookAuthProvider();
 Auth().signInWithPopup(provider).then((result) => {
// This gives you a Facebook Access Token.
  const token = result.credential.accessToken;
  setAccessToken(token);
setSigninIn(false);
});
};

const facebookButton = (title = 'login with facebook') => (
  <FacebookButtonStyled disabled={signinIn} onClick={signIn}>
    <FaFacebookF />
    {' '}
    {signinIn ? <Spinner animation="border" variant="light" size="sm" /> : title }
    {' '}
  </FacebookButtonStyled>
    );

const getAccounts = async () => {
setError(false);
const provider = new firebase.auth.FacebookAuthProvider();
provider.addScope('instagram_basic,pages_show_list');

const token = await Auth().signInWithPopup(provider).then((result) => {
// This gives you a Facebook Access Token.
 setAccessToken(result.credential.accessToken);
 return result.credential.accessToken;
}).catch((e) => {
  setError(true);
  switch (e.code) {
    case 'auth/popup-closed-by-user':
    alert("Vous avez fermé la popup avant vérification de vore compte , nous n'avons pas pu mettre à jour vos données instagram");
    break;
    default:
    alert('An error occured -- please retry or contact the admin ');
    break;
  }
});

try {
  axios.get(`https://graph.facebook.com/v10.0/me/accounts?fields=instagram_business_account{name,profile_picture_url},name&access_token=${token}`).then((d) => {
	setAccounts(d.data.data);
});
} catch (e) {
	console.log(e);
	alert(e);
}
};

const getInstagramAccountDatas = async (token, instagram_business_account_id) => {
try {
 axios.get(`https://graph.facebook.com/v10.0/${instagram_business_account_id}?fields=name,biography,username,followers_count,profile_picture_url&access_token=${token}`).then((d) => {
	setInstagramAccountDatas(d.data);
});
} catch (e) {
	console.log(e);
	alert(e);
}
};

  return {
  	signIn,
  	getAccounts,
  	accessToken,
  	accounts,
    error,
  	getInstagramAccountDatas,
  	instagramAccountDatas,
    facebookButton,

  };
};

const FacebookButtonStyled = styled.button`

    margin: 10px auto;
    display: block;
    width : 100% ;
    display : flex  ; 
    align-items:center ; 
    color : white ; 
    justify-content : center ;  
    font-size: 18px;
    background  : #2C4F8B ; 
    border : none ; 
    border-radius : 5px  ;     svg {

      margin-right : 20px ; 
      
       > * {

          fill : white ; 
       }
    }
    padding : 5px 20px ; 

`;
