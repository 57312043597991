import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import { Spinner, Row, Col, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ClassicList from './ClassicList'

const useStyles = makeStyles({
  container: {
    maxHeight: '80vh',
    overflowX: 'hidden',
  },
  headValues: {
    color: '#888',
    backgroundColor: 'white',
  },
})

const ClassicTable = ({
  format,
  headValues,
  fetchDatasFunction,
  dataTypes,
  list,
  t,
  actions,
  userList,
}) => {
  const classes = useStyles()
  const table = React.useRef(null)
  const [isMore, setIsMore] = React.useState(true)
  const [loadingMore, setLoadingMore] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [datas, setDatas] = React.useState(null)
  const [lastDoc, setLastDoc] = React.useState(null)
  const [hasMore, setHasMore] = React.useState(false)
  const [orderProperty, setOrderBy] = React.useState(null)
  const [orderDirection, setOrderDirection] = React.useState('asc')

  React.useEffect(() => {
    if (list) {
      list(datas)
    }
  }, [datas])

  React.useEffect(() => {
    ;(async () => {
      setLoading(true)
      const _datas = await fetchDatasFunction()
      let datas_array = []
      if (!userList) {
        setLastDoc(_datas.datas[_datas.datas.length - 1])
        _datas.datas.forEach((doc) => {
          if (doc.exists) {
            datas_array.push({ ...doc.data(), id: doc.id })
          }
        })
      } else {
        setLastDoc(_datas.pageToken)

        _datas.datas.forEach((doc) => {
          datas_array.push({ ...doc, id: doc.uid })
        })
      }

      setHasMore(_datas.datas?.hasMore)

      setDatas(datas_array)
      setLoading(false)
    })()
  }, [])

  const fetchMore = async () => {
    setLoadingMore(true)
    const _datas = await fetchDatasFunction(lastDoc)
    if (userList && !hasMore) {
      setIsMore(false)

      setLoadingMore(false)

      return
    }
    if (!userList) {
      setLastDoc(_datas.datas[_datas.datas.length - 1])
    } else {
      setLastDoc(_datas.pageToken)
    }

    const datas_array = [...datas]

    if (_datas.datas.length > 0) {
      _datas.datas.forEach((doc) => {
        datas_array.push(
          !userList ? { ...doc.data(), id: doc.id } : { ...doc, id: doc.uid }
        )
      })

      setDatas(datas_array)

      // scroll to bottom
      table.current.scrollTo(0, table.current.scrollHeight)
    } else {
      setIsMore(false)
    }

    setLoadingMore(false)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  const handleSort = (headValue) => {
    setOrderBy(headValue.name)
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
  }
  return (
    <TableContainer className={classes.container} ref={table}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headValues.map((val, i) => (
              <TableCell className={classes.headValues}>
                <TableSortLabel
                  active={val.name === orderProperty}
                  onClick={() => handleSort(val)}
                  direction={val.name === orderProperty ? orderDirection : 'asc'}
                />{' '}
                {t(val.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Spinner animation="border" variant="dark" size="lg" />
          ) : (
            <ClassicList
              sort={(datas) =>
                stableSort(datas, getComparator(orderDirection, orderProperty))
              }
              t={t}
              format={format !== undefined ? format : null}
              datas={datas}
              dataTypes={dataTypes}
              actions={actions}
            />
          )}
        </TableBody>
      </Table>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          {isMore && (
            <ButtonShowMore
              onClick={fetchMore}
              disabled={loadingMore}
              variant="secondary"
            >
              {loadingMore ? (
                <Spinner animation="border" variant="dark" size="sm" />
              ) : (
                t('load more')
              )}
            </ButtonShowMore>
          )}
        </Col>
      </Row>
    </TableContainer>
  )
}

export default withTranslation()(withTheme(ClassicTable))

const ButtonShowMore = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`
