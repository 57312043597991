import React from 'react'
import Product from '../Product/Product'
import { Card, CardHeader, Box, CardContent, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const SubCategorie = ({ group }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ mb: 2 }}>
      <Card>
        {' '}
        <CardHeader
          title={group.name}
          subheader={`${group.products.length} ${t('_products')}`}
        />
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              {group.products.map((product) => (
                <Grid key={product.id} item xs={12} md={4} lg={4}>
                  <Product product={product} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SubCategorie
