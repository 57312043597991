import React, { useEffect, useState } from 'react'
import { Card, Container, Row, Col, Button, Spinner, Navbar } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ArrowBack, AddCircleOutline } from '@material-ui/icons'
import UseCreatePlaceContainer from '../../CreateAPlaceStore'
import useStripe from '../../../../../hooks/Stripe'

import PlacesPlan from './PlacesPlan/PlacesPlan'
import SubscriptionCart from './SubscriptionCart/SubscriptionCart'

const SubscriptionStep = ({ t }) => {
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()
  const { formatedCart, cart, placeInformations } = CreatePlaceState
  const [products, setProducts] = useState(null)
  const { getProducts } = useStripe()
  let history = useHistory()
  const productFiltered = (id) => {
    const filtred = products.filter((product) => product.id === id)
    return filtred[0]
  }

  useEffect(() => {
    ;(async () => {
      try {
        const _prods = await getProducts()
        setProducts(_prods)
      } catch (e) {
        console.error('error retrieving products: ', e.message)
        return
      }
    })()
  }, [])

  return (
    <Container style={{ textAlign: '-webkit-center' }}>
      {products !== null ? (
        <>
          <Row>
            <Col>
              <CustomCard bsPrefix="card white-card">
                <Flex>
                  <TitleLeft>{t('Subscription')}</TitleLeft>
                  <TitleRight>
                    <CustomAddCircle />
                      {t('1 place + 1 qr code included')}
                  </TitleRight>
                </Flex>
                <RTitleRight>
                  <CustomAddCircle />
                    {t('1 place +')}
                    <div style={{marginLeft: '45px'}}>
                      {t('1 qr code included')}
                    </div>
                </RTitleRight>
                <PlacesPlan
                  product={productFiltered(process.env.REACT_APP_STRIPE_PROD_places)}
                />
              </CustomCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomCard bsPrefix="card white-card">
                <Title>{t('cart')}</Title>
                <SubscriptionCart formatedCart={formatedCart} cart={cart} />
              </CustomCard>
            </Col>
          </Row>
          <Navbar bsPrefix=" navbar navbar-expand navbar-expand-bottom-fixed">
            <Navbar.Collapse>
              <CustomOutlineButton
                onClick={() => history.push('/dashboard/create-place')}
              >
                {t('cancel')}
              </CustomOutlineButton>
            </Navbar.Collapse>
            <CustomButton2
              variant="default"
              onClick={() => {
                CreatePlaceState.cleanCart()
                CreatePlaceState.setActiveStep(0)
              }}
            >
              <ArrowIcon />
              {t('return')}
            </CustomButton2>
            {CreatePlaceState.cart.length > 0 && (
              <CustomButton
                onClick={() => CreatePlaceState.setActiveStep(2)}
                variant="primary"
              >
                {t('next')}
              </CustomButton>
            )}
          </Navbar>
        </>
      ) : (
        <Spinner animation="border" size="lg" variant="primary" />
      )}
    </Container>
  )
}

export default withTranslation()(SubscriptionStep)

const Flex = styled.div`
  display: flex;
  width: 100%;
`
const Title = styled.div`
  font-size: 16pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
const TitleLeft = styled.div`
  font-size: 16pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
  width: 50%;
	@media screen and (max-width: 551px) {
    width: 100%;
	} 
  text-align: left;
`
const TitleRight = styled.div`
  font-size: 11pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
  align-self: center;
  color: #f16a46;
  width: 50%;
  text-align: right;
	@media screen and (max-width: 551px) {
	  display: none;
	} 
`
const RTitleRight = styled.div`
  font-size: 11pt;
  font-weight: bold;
  margin-left: -15px;
  margin-bottom: 20px;
  color: #f16a46;
  width: 100%;
	@media screen and (min-width: 551px) {
	  display: none;
	} 
`
const CustomAddCircle = styled(AddCircleOutline)`
  width: 20px !important;
  height: 20px !important;
  color: #e76b56;
  margin: 0px 10px 2px 15px;
`
const CustomCard = styled(Card)`
  width: 70%;
	@media screen and (max-width: 991px) {
	  width: 95%;
	} 
  text-align: left;
  padding: 20px 40px 30px 40px !important;
`
const ArrowIcon = styled(ArrowBack)`
  width: 20px !important;
  height: 30px !important;
  margin-top: -2px;
  margin-right: 7px;
  color: black;
`
const CustomButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
`
const CustomButton2 = styled(Button)`
  width: 150px;
  height: 40px;
`
const CustomOutlineButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
  background-color: rgba(111, 79, 179, 0.2) !important;
  color: ${(props) => props.theme.primary} !important;
  border-style: none;
`
