export const theme = {
	primary: '#6F4FB3',
	secondary: '#E76B56',
	failed: '#f26363',
	success: '#11c13d',
	grey_1: '#1D1D1D',
	grey_2: '#404040',
	grey_3: '#B6B6B6',
	grey_4: '#FBFBFB',
	font_family: "'Lato', sans-serif",
	title: '20pt',
	title_md: '18pt',
	text: '15pt',
	small_text: '10pt',
}
