import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'

const SubMenu = ({ sections, lang, datas }) => {
  const [selected, setSelected] = useState('')

  return (
    <SubMenuHandler color={datas.color} darkmode={datas.darkmode}>
      {sections
        .filter((cat) => !cat?.group_parent || cat?.group_parent === null)
        .filter((c) => !c.group_visible)
        .map((item, i) => {
          const name = getMultilangContent(item, 'group_name_ml', lang)
          return (
            item && (
              <SubMenuItem
                color={datas.color}
                darkmode={datas.darkmode}
                href={`#${name}`}
                key={i}
                style={{
                  borderBottom: name === selected ? `2px solid ${datas.color}` : '',
                }}
                onClick={() => setSelected(name)}
              >
                {name}
              </SubMenuItem>
            )
          )
        })}
    </SubMenuHandler>
  )
}

export default SubMenu

const SubMenuItem = styled.a`
  &:link {
    text-decoration: none;
  }
  color: ${(props) => (props.darkmode ? 'white' : props.color)} !important;
  font: normal normal normal 18px Lato;
  padding-bottom: 13px;
  margin-right: 25px;
  &:last-child {
    margin-right: 0px;
  }
`

const SubMenuHandler = styled.div`
  padding-top: 9px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  position: sticky;
  top: 78px;
  background-color: ${(props) => (props.darkmode ? 'black' : 'white')};
  z-index: 10;
`
