import './style.css';
import styled from 'styled-components';
import { useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const SlideUp = ({
 title, 
 subComponent, 
 styleTitle, 
 styleContainer, 
 TitlefontSize, 
 isDefaultBold,
 darkmode,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="fadeIn" style={styleContainer && styleContainer}>
        <div 
          className="fadeIn__title" 
          style={styleTitle && styleTitle} 
          onClick={() => setIsOpen(!isOpen)}>
          <Title 
            darkmode={darkmode} 
            fontSize={TitlefontSize} 
            isDefaultBold={isDefaultBold} 
            className={`${isOpen ? 'bold' : ''}`}>
            {title}
          </Title>
          <span className={isOpen ? 'fadeIn__title__open' : ''}>
            <ArrowBackIosIcon style={{ color: '#969696', fontSize: '14px' }} />
          </span>
        </div>
        {isOpen && (
        <div className={`fadeIn__subComponent ${isOpen ? 'fadeIn__show': ''}`}>
          {subComponent}
        </div>
)}
      </div>
    );
};

export default SlideUp;

const Title = styled.div`
  color: ${(props) => (props.darkmode ? 'white' : '#1D1D1D')};
  font: ${(props) => (props.isDefaultBold ? 'bold' : '')} ${(props) => (props.fontSize ? props.fontSize : '20px')} Lato;
  width: 100%;
`;
