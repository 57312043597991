import React from 'react'
import { Row } from 'react-bootstrap'
import useStripe from '../../../../hooks/Stripe'
import Plan from './Plan'

const Plans = ({
	switchPlan,
	currentPriceId,
	updatingSubscription,
	selectMode,
	subscriptionMethodChoosed,
	setSubscriptionMethodChoosed,
}) => {
	const { getPrices } = useStripe()
	const [prices, setPrices] = React.useState(null)
	React.useEffect(() => {
		;(async () => {
			try {
				const _prices = await getPrices(process.env.REACT_APP_STRIPE_PROD_places)
				_prices.splice(1, 0, _prices.splice(2, 1)[0])
				setPrices(_prices)
			} catch (e) {
				console.error('can not retrieve prices', e.message)
				return
			}
		})()
	}, [])

	return (
		<Row>
			{prices !== null &&
				prices.map((price, i) => (
					<Plan
						selectMode={selectMode}
						isActive={
							selectMode
								? subscriptionMethodChoosed === price.id
								: currentPriceId === price.id
						}
						price={price}
						switchPlan={switchPlan}
						key={`${price.id}_price_plan`}
						updatingSubscription={updatingSubscription}
						setSubscriptionMethodChoosed={setSubscriptionMethodChoosed}
					/>
				))}
		</Row>
	)
}

export default Plans
