export const updateMultilangArray = (content, field, lang) => {
	let _product_name_ml = field ? [...field] : []

	if (_product_name_ml.find((z) => z.lang === lang)) {
		_product_name_ml = _product_name_ml.map((d) =>
			d.lang === lang ? { ...d, content: content || '' } : d
		)
	} else {
		_product_name_ml.push({ lang, content: content || '' })
	}

	return _product_name_ml
}

export const getMultilangContentFromObj = (array, currentLang) => {
	return array.find((d) => d.lang === currentLang)?.content || 'rien a afficher'
}
export const getMultilangContent = (datas, field, currentLang) => {
	const content =
		datas && datas[field] !== undefined
			? datas[field].find((d) => d.lang === currentLang)?.content
			: ''
	return content
}
export const getMultilangContentMap = (datas, currentLang) => {
	let _newFields = {}
	if (datas) {
		Object.keys(datas).map((key) => {
			if (Array.isArray(datas[key])) {
				_newFields[key.replace('_ml', '')] = getMultilangContent(
					datas,
					key,
					currentLang
				)
			} else {
				_newFields[key.replace('_ml', '')] = datas[key]
			}
		})
		return _newFields
	}
}

export const createMultilangArray = (langs, currentLang, content) => {
	if (!content) return []
	const arr = langs.map((l) =>
		l !== currentLang
			? { lang: l, content: content === '' ? content : `${content}` }
			: { lang: l, content }
	)
	return arr
}
