import algoliasearch from 'algoliasearch/lite';

const useAlgolia = (props) => {
  const AlgoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
  );

  return { AlgoliaClient };
};

export default useAlgolia;
