import React from 'react';
import {
 Container, Form, Button, Spinner, Alert,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useGoogle from '../../../hooks/GoogleAuth';
import LoginHeader from '../LoginHeader/LoginHeader';

const LoginFormRecover = ({ t }) => {
	const { register, handleSubmit, formState: { errors } } = useForm();
	const { sendResetMailError, sendResetEmail } = useGoogle();
	const [loading, setLoading] = React.useState(false);

	const handleResetForm = async (datas) => {
		setLoading(true);
		const sended = await sendResetEmail(datas.email);
		if (sended.success === true) {
			alert(sended.message);
			window.location = '/login';
		}
		setLoading(false);
	};

  return (
    <Container>
      <LoginHeader
			title={t('Forget my password')}
			subtitle={t('Recover password')}
      />
      {sendResetMailError !== null
    	 	&& (
    <Alert>
      {sendResetMailError}
    </Alert>
)}
      <Form onSubmit={handleSubmit(handleResetForm)}>
        <Form.Group>
          <Form.Control
				isInvalid={errors.email}
				{...register('email', { required: true })}
				placeholder={t('Email address')}
				type="email"
          />
          <Form.Control.Feedback type="invalid">
            {t('valid email required')}
          </Form.Control.Feedback>
        </Form.Group>
        <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
          <Link style={{ alignSelf: 'center', marginRight: '20px', color: 'black' }} to="/login">{t('cancel')}</Link>
          <Button disabled={loading} type="submit">
            {loading ? (
              <>
                <Spinner variant="light" size="sm" animation="border" />
                {' '}
                {t('in progress')}
              </>
) : t('send')}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default withTranslation()(LoginFormRecover);
