import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap'
import Background from '../../assets/dashboard/404-background.png'

const Page404 = ({ t, mode }) => {
  const history = useHistory();

  return (
    <CustomContainer fluid style={{margin: mode ? '5% ​0 0 10' : ''}}>
      <Title mode={mode}>
        {t('oops')}
      </Title>
      <FirstSubTitle mode={mode}>
        {t('oops description')}
      </FirstSubTitle>
      <FirstCustomButton
        mode={mode}
        variant="secondary"
        onClick={() => history.push('/')}
      >
        {t('home')}
      </FirstCustomButton>
      <CustomImg 
        src={Background} 
        alt="Logo" 
        style={{width: '100%'}}/>
      <SecondSubTitle>
        {t('oops description')}
      </SecondSubTitle>
      <SecondCustomButton
        variant="secondary"
        onClick={() => history.push('/')}
      >
        {t('home')}
      </SecondCustomButton>
    </CustomContainer>
  )
}

export default withTranslation()(Page404);

const Title = styled.div`
  position: absolute;
  top: 8%;
  left: 10%;
  color: #f16a46;
  font-weight: bolder;
  font-size: 50pt;
  margin-left: ${(props) => props.mode && '150px'};
`;
const FirstSubTitle = styled.div`
  font-weight: bold;
  font-size: 16pt;
  width: 330px;
  position: absolute;
  top: 22%;
  left: 10%;
  @media screen and (max-width: 900px) {
    display: none;
  }
  margin-left: ${(props) => props.mode && '150px'};
`;
const SecondSubTitle = styled.div`
  font-weight: bold;
  font-size: 16pt;
  width: 65%;
  position: absolute;
  left: 10%;
  bottom: 20%;
  @media screen and (min-width: 900px) {
    display: none;
  }
`;
const FirstCustomButton = styled(Button)`
  border-radius: 50px;
  position: absolute;
  top: 35%;
  left: 10%;
  width: 15%;
  @media screen and (max-width: 900px) {
    display: none;
  }
  margin-left: ${(props) => props.mode && '150px'};
`;
const SecondCustomButton = styled(Button)`
  border-radius: 50px;
  position: absolute;
  left: 10%;
  width: 20%;
  bottom: 10%;
  @media screen and (min-width: 900px) {
    display: none;
  }
  @media screen and (max-width: 550px) {
    width: 40%;
  }
`;
const CustomContainer = styled(Container)`
  padding-right: 0px !important;
  padding-left: 0px !important;
`;
const CustomImg = styled.img`
@media screen and (min-width: 1200px) {
  margin-top: -90px;
}
@media screen and (max-width: 720px) {
  margin-top: 100px
}
  @media screen and (max-width: 400px) {
    margin-top: 150px
  }
`;
