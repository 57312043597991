import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Alert, Button, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import withAccountVerification from '../../hoc/withAccountVerification'
import AuthContainer from '../../stores/AuthContainer'
import DashboardLayout from '../../layouts/DashboardLayout'
import DashboardMenuItems from './DashboardMenuItems'
import LeftMenu from '../../components/Dashboard/LeftMenu/LeftMenu'
import TopMenu from '../../components/Dashboard/TopMenu/TopMenu'
import Routes from './routes'

const DashboardAdmin = ({ t, i18n }) => {
  const [title, setTitle] = React.useState('title')
  const { user, userClaims } = AuthContainer.useContainer()
  const { items } = DashboardMenuItems()

  const [menuItems, setMenuItems] = React.useState(items)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (i18n.language) setMenuItems(items)
  }, [i18n.language])

  return (
    <>
      {!user || userClaims?.admin !== true ? (
        <>
          <Alert>
            {t('Need to be connected as admin to see this page')}
            <br />
            <Button onClick={() => history.push('/login')} variant="primary">
              {t('login')}
            </Button>
          </Alert>
        </>
      ) : (
        <DashboardLayout>
          <TopMenu title={title} />

          <Row>
            <ColStyledLeft lg="auto">
              <LeftMenu
                isAdmin={location.pathname.split('/')[1] === 'admin'}
                items={menuItems}
                setMenuItems={setMenuItems}
              />
            </ColStyledLeft>
            <ColStyledRight>
              <CustomContainer fluid>
                <Routes setTitle={setTitle} />
              </CustomContainer>
            </ColStyledRight>
          </Row>
        </DashboardLayout>
      )}
    </>
  )
}

export default withTranslation()(withAccountVerification(DashboardAdmin))

const ColStyledLeft = styled(Col)`
  z-index: 999;
  position: fixed;
`

const ColStyledRight = styled(Col)``

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media (min-width: 992px) {
    padding-left: 280px;
  }
`
