import React from 'react'
import useProductBaseStore from '../ProductBaseStore'
import { Spinner } from 'react-bootstrap'
import {
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Typography,
	ListItemSecondaryAction,
	IconButton,
	Drawer,
	Button,
	Grid,
	Box,
} from '@material-ui/core'

import { LocalOffer, Delete, ArrowForward } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const ProductBaseCart = ({ addProductToList, addingProducts }) => {
	const { t } = useTranslation()
	const { cart, removeFromCart, openCart, showCart, closeCart } =
		useProductBaseStore.useContainer()
	return (
		<Drawer anchor="right" open={openCart} onClose={() => closeCart()}>
			<Grid
				style={{ background: '#eee', textAlign: 'left', padding: 10 }}
				container
				alignItems="left"
				justifyContent="space-between"
			>
				<Grid item>
					<Typography variant="h5">{t('products')}</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={closeCart}>
						<ArrowForward />
					</IconButton>
				</Grid>
			</Grid>

			<List>
				{cart.map((item) => (
					<ListItem alignItems="flex-start">
						<ListItemAvatar>
							<Avatar src={item.product_main_image_url}>
								<LocalOffer />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={item.product_name}
							secondary={
								<>
									<Typography component="span" variant="body2" color="textPrimary">
										{item.product_description}
									</Typography>
								</>
							}
						/>
						<ListItemSecondaryAction>
							<IconButton
								onClick={() => removeFromCart(item)}
								edge="end"
								aria-label="delete"
							>
								<Delete />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
			{cart.length > 0 && (
				<Box sx={{ textAlign: 'center', p: 4 }}>
					<Button
						onClick={() => addProductToList(cart)}
						variant="contained"
						color="primary"
					>
						{addingProducts ? (
							<Spinner animation="border" size="sm" />
						) : (
							t('add_my_product_list')
						)}
					</Button>
				</Box>
			)}
		</Drawer>
	)
}

export default ProductBaseCart
