import React, { useState } from 'react';
import {
	Container,
	Row,
	Col,
	Card,
	Button,
} from 'react-bootstrap';
import styled, { withTheme } from 'styled-components';
import { Check, DeleteForever } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import PopupPicture from '../../../../assets/help/working.jpg';

const languages = [
	{
		id: 'FR',
		label: 'french',
	},
	{
		id: 'EN',
		label: 'english',
	},
	{
		id: 'ES',
		label: 'spanish',
	},
	{
		id: 'DE',
		label: 'deutch',
	},
	{
		id: 'CH',
		label: 'chinese',
	},
];

const Multilingual = ({
 t,
 languagesArray, 
 register, 
 setValues, 
 unregister, 
 main_language,
}) => {
	const [languagePrincipal, setLanguagePrincipal] = useState(main_language || 'FR');
	const [languageSecondary, setLanguageSecondary] = useState(languagesArray || []);

	const checkLanguages = (value, type, exvalue) => {
		if (!languagePrincipal.includes(value)
			&& !languageSecondary.includes(value)) {
    type === 0 ? setLanguagePrincipal(value)
      : setLanguageSecondary([...languageSecondary.filter((item) => item !== exvalue), value]);
    }
	};

	React.useEffect(() => {
		unregister('languages_array');
		setValues('languages_array', languageSecondary);
	}, [languageSecondary]);

	return (
  <Container fluid>
    <Row>
      <Col lg="8">
        <Card bsPrefix="card white-card">
          <Title>
            {t('languages choice')}
          </Title>
          <Background>
            <div style={{ display: 'row' }}>
              <div style={{ display: 'row' }}>
                <Subtitle>
                  {t('principal language')}
                </Subtitle>
                <div style={{ display: 'flex' }}>
                  <LanguageSelect
                    onChange={(e) => checkLanguages(e.target.value, 0)}
                    {...register('main_language')}
                  >
                    {languages.map((language, i) => (
                      <option
                        key={`language${i}`}
                        value={language.id}
                      >
                        {t(language.label)}
                      </option>
												))}
                  </LanguageSelect>
                </div>
              </div>
            </div>
          </Background>
          <Background style={{ marginTop: '10px' }}>
            <div style={{ display: 'row' }}>
              <div style={{ display: 'row' }}>
                <Subtitle>
                  {t('secondary languages')}
                </Subtitle>
                {languageSecondary.map((language, i) => (
                  <div
                    key={`languageselect${i}`}
                    style={{ display: 'flex' }}
                  >
                    <SubLanguageSelect
                      value={language}
                      onChange={(e) => {
                        checkLanguages(e.target.value, 1, language);
                      }}
                    >
                      {languages.map((language, i) => (
                        <option
                          key={`language${i}`}
                          value={language.id}
                        >
                          {t(language.label)}
                        </option>
											))}
                    </SubLanguageSelect>
                    <IconBar>
                      <DeleteForever
                        style={{ width: '20px', height: '24px', color: '#6F4FB3' }}
                        onClick={() => setLanguageSecondary(languageSecondary.filter((item) => 
                          item !== language
                        ))}
                      />
                    </IconBar>
                  </div>
										))}
                <div style={{ textAlign: 'center' }}>
                  <CustomButton
											type="button"
											onClick={() => {
												if (languageSecondary.length < 3) {
                          languages.map((language, i) => !languageSecondary.includes(language.id) &&
														setLanguageSecondary([...languageSecondary, language.id]));
                        }
										}}
                  >
                    {t('add language')}
                  </CustomButton>
                </div>
              </div>
            </div>
          </Background>
        </Card>
      </Col>
      <Col lg="4">
        <CardDeskOnly
          bsPrefix="card white-card"
          style={{
						margin: '17px 0',
						padding: '20px',
						alignItems: 'center',
					}}
        >
          <CustomImg src={PopupPicture} alt="logo" />
          <div style={{ zIndex: 2 }}>
            <PopupContent>
              <PopupTitle>
                {t('professional trad')}
              </PopupTitle>
              <div style={{ fontStyle: 'oblique' }}>
                {t('need help trad')}
                <div>
                  {t('need help trad description')}
                </div>
              </div>
              <PopupList>
                <div style={{ marginBottom: '5px' }}>
                  <Check style={{
                      width: '20px', height: '17px', color: '#E76B56', marginRight: '5px',
                    }}
                  />
                  {t('multi languages trad')}
                </div>
                <div style={{ marginBottom: '5px' }}>
                  <Check style={{
                    width: '20px', height: '17px', color: '#E76B56', marginRight: '5px',
                  }}
                  />
                  {t('72h response')}
                </div>
                <div style={{ marginBottom: '5px' }}>
                  <Check style={{
                    width: '20px', height: '17px', color: '#E76B56', marginRight: '5px',
                  }}
                  />
                  {t('no error certification')}
                </div>
              </PopupList>
            </PopupContent>
            <div style={{ textAlign: 'center' }}>
              <Button
									onClick={() => console.log('yes')}
									style={{
										width: '80%',
										marginTop: '-25px',
										backgroundColor: '#6F4FB3',
										boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
									}}
              >
                {t('discover')}
              </Button>
            </div>
          </div>
        </CardDeskOnly>
      </Col>
    </Row>
  </Container>
	);
};

export default withTranslation()(withTheme(Multilingual));

const Background = styled.div`
  background-color: #E6EFFF;
  padding: 10px;
  padding-bottom: 30px;
  border-radius: 10px;
`;
const Title = styled.div`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 6px;
	margin-left: 7px;
    margin-bottom: 10px;
`;
const Subtitle = styled.div`
	font-size: 12pt;
	font-weight: bold;
	margin-top: 6px;
  margin-left: 50px;
  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
  margin-bottom: 10px;
`;
const PopupTitle = styled.div`
	text-align: center;
    font-weight: bold;
    font-size: 15pt;
    margin-bottom: 13px;
`;
const PopupContent = styled.div`
	width: 100%;
	height: 300px;
	border-radius: 7px;
    padding: 20px;
	background-color: rgba(255, 255, 255, 0.7);
`;
const PopupList = styled.div`
    font-weight: 600;
    margin-top: 13px;
`;
const IconBar = styled.div`
	background-color: #FBFBFB;
    width: 30px;
	text-align: center;
    margin-right: 20px;
	margin-top: 10px;
	cursor: pointer;
`;
const LanguageSelect = styled.select`
	width: 95%;
	cursor: pointer;
	padding: 5px;
    font-size: 11pt;
	border-style: none;
	border-radius: 5px;
    margin-left: 50px;
    margin-right: 60px;
	::placeholder {
		opacity: 0.5;
	}
`;
const CardDeskOnly = styled(Card)`
	@media screen and (max-width: 991px) {
	display: none;
	} 
`;
const SubLanguageSelect = styled.select`
	width: 95%;
	padding: 5px;
    font-size: 11pt;
	border-style: none;
	border-radius: 5px;
    margin-left: 50px;
    margin-top: 10px;
    margin-right: 10px;
	::placeholder {
		opacity: 0.5;
	}
`;
const CustomButton = styled.button`
	width: 40%;
  @media screen and (max-width: 500px) {
    width: 80%;
  }
	margin-top: 10px;
	background-color: white;
	border-style: none;
	font-weight: bold;
	font-size: 11pt;
	color: ${(props) => props.theme.primary};
    padding: 5px;
	border-radius: 4px;
`;
const CustomImg = styled.img`
	position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
	border-radius: 20px;
    opacity: 0.2;
`;
