import React from 'react';
import styled, { withTheme } from 'styled-components';

const DashboardLayout = (props) => (
  <DashboardLayoutStyled>{props.children}</DashboardLayoutStyled>
  );

export default withTheme(DashboardLayout);

const DashboardLayoutStyled = styled.div`
  height: 100%;
`;
