import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import StripeForm from './StripeForm'

const CreatePaymentMethod = ({ t, show, setShow, paymenMethodsReload }) => {
	return (
		<Modal size="md" show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>{t('add_new_payment_method')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<StripeForm
					t={t}
					setShow={setShow}
					paymenMethodsReload={paymenMethodsReload}
				/>
			</Modal.Body>
		</Modal>
	)
}

export default withTranslation()(CreatePaymentMethod)
