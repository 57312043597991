import React, { useEffect, useState } from 'react'
import { OverlayTrigger, Container, Card, Button, Modal } from 'react-bootstrap'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
  PermMedia,
  DeleteForeverOutlined,
  FileCopyOutlined,
  EditOutlined,
  SubdirectoryArrowRight,
} from '@material-ui/icons'

import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import Moveicon from '../../../../assets/menu/move.svg'

import SetLogoModal from './setLogoModal'
import AddProductModal from './addProduct'
import EditSubCategory from './editSubCategory'

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
})

const openHours = [
  {
    openDays: {
      monday: false,
      tuesday: false,
      wednesday: true,
      thursday: false,
      friday: false,
      saturday: true,
      sunday: false,
    },
    displayHours: '12:00',
    undisplayHours: '15:00',
  },
  {
    openDays: {
      monday: false,
      tuesday: false,
      wednesday: true,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: true,
    },
    displayHours: '18:00',
    undisplayHours: '22:00',
  },
]

const EditFormula = ({
  t,
  create,
  index,
  subIndex,
  modalOpen,
  toggleModal,
  menus,
  setMenus,
}) => {
  const [picModalOpen, setPicModalOpen] = useState(false)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const [editCategoryOpen, setEditCategoryOpen] = useState(false)
  const [hours, setHours] = useState(openHours)
  const [PicUrl, setPicUrl] = useState(null)

  const [producstIn, setProducstIn] = useState([])
  const [indexAdd, setIndexAdd] = useState(0)
  const [editOrCreate, setEditOrCreate] = useState(true)

  const [productName, setProductName] = useState('')
  const [productPrice, setProductPrice] = useState(0)
  const [productDescription, setProductDescription] = useState('')
  const [includedProducts, setIncludedProducts] = useState([])
  const classes = useStyles()

  useEffect(() => {
    // setIncludedProducts(menus[index].included_products)
    if (create) {
      setProductName('')
      setProductPrice(0)
      setProductDescription('')
    } else {
      setProductName(menus[index].products[subIndex].product_name)
      setProductPrice(menus[index].products[subIndex].price)
      setProductDescription(menus[index].products[subIndex].product_description)
    }
  }, [modalOpen])

  const displayLetter = (e) => {
    switch (e) {
      case 'monday':
        return 'L'
      case 'tuesday':
        return 'M'
      case 'wednesday':
        return 'M'
      case 'thursday':
        return 'J'
      case 'friday':
        return 'V'
      case 'saturday':
        return 'S'
      case 'sunday':
        return 'D'
      default:
        return '?'
    }
  };
  
  const handleSubmit = () => {
    const tmp = menus
    if (create) {
      tmp[index].products.push({
        id: `product-${menus.length + 1}`,
        product_name: productName,
        product_description: productDescription,
        product_type: 1,
        price: productPrice,
      })
    } else {
      tmp[index].products[subIndex] = {
        id: `product-${menus[index].product_id}`,
        product_name: productName,
        product_description: productDescription,
        product_type: 1,
        price: productPrice,
      }
    }
    setMenus(tmp)
    toggleModal(false)
  }

  const handleEditCategory = (bol, i) => {
    setEditOrCreate(bol)
    setEditCategoryOpen(true)
  }

  const handleAddProduct = (item, i) => {
    setProducstIn(item.products)
    setIndexAdd(i)
    setAddProductOpen(true)
  }

  const updateTime = (value, i, t) => {
    const tmp = [...hours]
    if (t === 0) {
      tmp[i].displayHours = value
    } else {
      tmp[i].undisplayHours = value
    }
    setHours(tmp)
  }

  const updateHours = (item, i, day) => {
    const tmp = [...hours]
    tmp[i].openDays[day] = !item.openDays[day]
    setHours(tmp)
  }
  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    } else if (result.type === 'CATEGORY') {
      const answers = Reorder(
        menus[index].included_products,
        result.source.index,
        result.destination.index
      )
      const newOrder = JSON.parse(JSON.stringify(menus))
      newOrder[index].included_products = answers
      setMenus(newOrder)
    } else {
      const answers = Reorder(
        menus[index].included_products[parseInt(result.type, 10)].products,
        result.source.index,
        result.destination.index
      )
      const newOrder = JSON.parse(JSON.stringify(menus))
      newOrder[index].included_products[result.type].products = answers
      setMenus(newOrder)
    }
  }

  const categoryRender = (group, i, provided) => (
    <>
      <Flex {...provided.dragHandleProps} style={{ padding: '10px 0px 0px 20px' }}>
        <img
          src={Moveicon}
          alt="logo"
          style={{
            width: '15px',
            marginRight: '20px',
            marginTop: '-3px',
            cursor: 'move',
          }}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentLeftContainer>
            <ContentGroupTitle>{group.group_name}</ContentGroupTitle>
          </ContentLeftContainer>
          <div style={{ float: 'right' }}>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="copyToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('duplicate')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => console.log('yes')}>
                  <CopyIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                  {t('edit')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={() => handleEditCategory(false, i)}>
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() => setMenus(menus.filter((i) => i.id !== group.id))}
                >
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </div>
        </div>
      </Flex>
      <div style={{ margin: '-10px 0px 0px 70px' }}>
        <Flex style={{ marginBottom: '5px' }}>
          <ArrowRight />
          <GroupTitle>Catégorie simple</GroupTitle>
        </Flex>
        <GroupDescription style={{ width: '450px' }}>
          {group.group_description}
        </GroupDescription>
      </div>
    </>
  )

  const productRender = (product, provided) => (
    <Flex {...provided.dragHandleProps}>
      <img
        src={Moveicon}
        alt="logo"
        style={{
          width: '15px',
          marginRight: '20px',
          marginTop: '-3px',
          cursor: 'move',
        }}
      />
      <Content>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <>
            <ContentLeftContainer>
              <ContentInTitle>{product.product_name}</ContentInTitle>
              <ContentDescription>{product.product_description}</ContentDescription>
            </ContentLeftContainer>
            <ContentAction>
              <Flex style={{ marginRight: '20px' }}>
                <PriceContainer />
                <OverlayTrigger
                  key="editToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      Éditer
                    </Toolbox>
                  }
                >
                  <IconContainer onClick={() => console.log('yes')}>
                    <EditIcon />
                  </IconContainer>
                </OverlayTrigger>
                <OverlayTrigger
                  key="deleteToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      {t('remove')}
                    </Toolbox>
                  }
                >
                  <IconContainer onClick={() => console.log('yes')}>
                    <DeleteIcon />
                  </IconContainer>
                </OverlayTrigger>
              </Flex>
            </ContentAction>
          </>
        </div>
      </Content>
    </Flex>
  )

  const productDraggable = (question, questionNum) => (
    <Droppable droppableId={`droppable${question.id}`} type={`${questionNum}`}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={{ padding: '20px' }}>
          {question.products.map((product, i) => (
            <Draggable
              key={`${questionNum}${i}`}
              draggableId={`${questionNum}${i}`}
              index={i}
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  {productRender(product, provided, question, i, questionNum)}
                  <div style={{ marginBottom: '20px' }} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )

  return (
    <CustomModal show={modalOpen} onHide={toggleModal}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          zIndex: 1000,
          opacity: editCategoryOpen || addProductOpen || picModalOpen ? 0.5 : 0,
          pointerEvents: 'none',
        }}
      />
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={picModalOpen}
        toggleModal={setPicModalOpen}
      />
      <AddProductModal
        mode={1}
        index={index}
        subIndex={indexAdd}
        producstIn={producstIn}
        setProducstIn={setProducstIn}
        modalOpen={addProductOpen}
        toggleModal={setAddProductOpen}
        menus={menus}
        setMenus={setMenus}
      />
      <EditSubCategory
        create={editOrCreate}
        index={index}
        subIndex={subIndex}
        modalOpen={editCategoryOpen}
        toggleModal={setEditCategoryOpen}
        menus={menus}
        setMenus={setMenus}
      />
      <Container fluid>
        <ContentTitle>
          {productName === '' ? 'Nom de la formule' : productName}
        </ContentTitle>
        <CustomCard bsPrefix="card white-card">
          <Title>Informations de la formule</Title>
          <BackgroundTransparent>
            <Flex style={{ padding: '20px' }}>
              {!PicUrl ? (
                <Logo
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                >
                  <InsertLogo>
                    <PermMedia
                      style={{
                        width: '50px',
                        height: '50px',
                        marginBottom: '20px',
                        opacity: 0.5,
                        color: 'lightgrey',
                      }}
                    />
                    {t('add background pic')}
                    <SizeText>{t('pic size')}</SizeText>
                  </InsertLogo>
                </Logo>
              ) : (
                <Img
                  src={PicUrl && PicUrl.url}
                  alt="logo"
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                />
              )}
              <div style={{ display: 'inline', width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <NameInput
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    placeholder="Nom de la formule"
                  />
                  <PriceInput
                    type="number"
                    step={0.01}
                    value={productPrice === 0 ? null : productPrice}
                    onChange={(e) => setProductPrice(e.target.value)}
                    placeholder="Prix"
                  />
                </div>
                <AreaInput
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  placeholder="Description de la formule"
                />
              </div>
            </Flex>
          </BackgroundTransparent>
        </CustomCard>
        <CustomCard bsPrefix="card white-card">
          <Title>Contenu de la formule</Title>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="CATEGORY">
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {includedProducts &&
                    includedProducts.map((category, i) => (
                      <Draggable
                        key={category.id}
                        draggableId={category.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <CategoryBackground
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {categoryRender(category, i, provided)}
                            {productDraggable(category, i)}
                            <AddButton
                              type="submit"
                              onClick={() => handleAddProduct(category, i)}
                              variant="primary"
                            >
                              {t('add product')}
                            </AddButton>
                          </CategoryBackground>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            style={{
              margin: '10px 0px 10px 0px',
              width: '250px',
              boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
            }}
            onClick={() => handleEditCategory(true)}
            variant="secondary"
          >
            {t('add cat')}
          </Button>
        </CustomCard>
        <CustomCard bsPrefix="card white-card">
          <Title>Visibilité de la formule</Title>
          <SubTitle>Choisissez quand afficher votre formule</SubTitle>
          <div style={{ textAlign: 'center' }}>
            {hours.map((item, i) => (
              <Background>
                <FlexDiv>
                  <DayPickerContainer>
                    {Object.keys(item.openDays).map((day) => (
                      <>
                        {item.openDays[day] ? (
                          <DayPicker onClick={() => updateHours(item, i, day)}>
                            {displayLetter(day)}
                          </DayPicker>
                        ) : (
                          <DayUnPicked onClick={() => updateHours(item, i, day)}>
                            {displayLetter(day)}
                          </DayUnPicked>
                        )}
                      </>
                    ))}
                  </DayPickerContainer>
                  <FlexTimeInput>
                    <TimeInput>
                      <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.displayHours}
                        onChange={(e) => updateTime(e.target.value, i, 0)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                      />
                    </TimeInput>
                    <div style={{ marginTop: '11px' }}>-</div>
                    <TimeInput>
                      <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.displayHours}
                        onChange={(e) => updateTime(e.target.value, i, 1)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                      />
                    </TimeInput>
                  </FlexTimeInput>
                  <div style={{ width: '65%', float: 'right', marginRight: '10px' }}>
                    <IconTimeContainer
                      onClick={() => setHours(hours.filter((obj) => obj !== item))}
                    >
                      <DeleteIcon />
                    </IconTimeContainer>
                  </div>
                </FlexDiv>
              </Background>
            ))}
            <CustomButton
              onClick={() =>
                setHours(
                  hours.concat({
                    openDays: {
                      monday: false,
                      tuesday: false,
                      wednesday: true,
                      thursday: false,
                      friday: false,
                      saturday: true,
                      sunday: false,
                    },
                    displayHours: '12:00',
                    undisplayHours: '15:00',
                  })
                )
              }
            >
              {t('add open hours')}
            </CustomButton>
          </div>
        </CustomCard>
        <br />
        <BottomBar>
          <CancelButton
            variant="primaryoutline"
            href="#"
            onClick={() => toggleModal(false)}
          >
            {t('cancel')}
          </CancelButton>
          <SaveButton variant="primary" href="#" onClick={() => handleSubmit()}>
            {create ? t('validate') : t('save')}
          </SaveButton>
        </BottomBar>
      </Container>
    </CustomModal>
  )
}

export default withTheme(withTranslation()(EditFormula))

const Flex = styled.div`
  display: flex;
`
const CustomCard = styled(Card)`
  padding: 30px !important;
  margin: 20px !important;
`
const Logo = styled.div`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 75%;
  float: left;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const PriceInput = styled.input`
  width: 20%;
  float: right;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 204px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const InsertLogo = styled.div`
  width: 200px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 70px;
  display: grid;
  justify-items: center;
`
const CustomModal = styled(Modal)`
  width: 100%;
  .modal-dialog {
    margin: 0;
    max-width: 100%;
  }
  .modal-content {
    background-color: #eceaff;
    border-radius: 0;
  }
`
const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0px 40px 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
const CancelButton = styled(Button)`
  width: 150px;
  height: 45px;
  padding-top: 10px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SaveButton = styled(Button)`
  width: 200px;
  height: 45px;
  padding-top: 10px;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const FlexDiv = styled.div`
  display: flex;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const DayPicker = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`
const DayPickerContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 800px;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const IconTimeContainer = styled.div`
  background-color: #f8f7ff;
  float: right;
  position: relative;
  cursor: pointer;
  margin: 15px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const DayUnPicked = styled.div`
  cursor: pointer;
  background-color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;
`
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`
const FlexTimeInput = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
`
const TimeInput = styled.div`
  background-color: white;
  padding-left: 15px;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
  margin: 10px;
  width: 100px;
  height: 30px;
`
const CustomButton = styled.button`
  @media screen and (max-width: 991px) {
    width: 90%;
  }
  @media screen and (min-width: 991px) {
    width: 30%;
  }
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f8f7ff;
  border-style: none;
  font-weight: bold;
  font-size: 11pt;
  color: ${(props) => props.theme.primary};
  padding: 5px;
  border-radius: 4px;
`
const Background = styled.div`
  margin-top: 15px;
  background-color: #E6EFFF;
  padding: 5px 0px 5px 30px;
  text-align: center;
  border-radius: 7px;
`
const BackgroundTransparent = styled.div`
  background-color: #E6EFFF;
  text-align: center;
  border-radius: 7px;
`
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 100%;
  margin: 20px 0px 30px 0px;
  text-align: center;
`
const AddButton = styled(Button)`
  margin: -10px 0px 30px 60px;
  width: 220px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const CategoryBackground = styled.div`
  background-color: #E6EFFF;
  border-radius: 10px;
  margin-bottom: 20px;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  color: ${(props) => props.theme.primary};
`
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
`
const Content = styled.div`
  display: flex;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  @media screen and (max-width: 1150px) {
    width: 40%;
  }
  @media screen and (min-width: 1150px) {
    width: 60%;
  }
`
const ContentAction = styled.div`
  float: right;
  @media screen and (max-width: 1150px) {
    width: 60%;
  }
  @media screen and (min-width: 1150px) {
    width: 40%;
  }
`
const ContentInTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const ContentDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  margin-right: 20px;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`
const ContentGroupTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 160px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const PriceContainer = styled.div`
  display: flex;
  align-self: center;
  width: 70%;
  margin-right: 20px;
`
