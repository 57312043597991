import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

export class Fuego {
	constructor(config) {
		this.db = !firebase.default.apps.length
			? firebase.default.initializeApp(config).firestore()
			: firebase.default.app().firestore()
		this.auth = firebase.default.auth
		this.functions = firebase.default.functions
		this.storage = firebase.default.storage
	}
}
