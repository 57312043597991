import React from 'react';
import styled from 'styled-components';

const DefaultLayout = (props) => (
  <DefaultLayoutStyled>{props.children}</DefaultLayoutStyled>
  );

export default DefaultLayout;

const DefaultLayoutStyled = styled.div``;
