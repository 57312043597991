import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'

import { DeleteForeverOutlined } from '@material-ui/icons'

import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import Moveicon from '../../../../assets/menu/move.svg'

const PriceItemBox = ({ t, prices, setPrices, item, index, provided }) => {
  const updatePriceInfos = (type, index, value) => {
    let newArr = [...prices]
    if (type === 0) newArr[index].description = value
    else newArr[index].price = value

    setPrices(newArr)
  }

  return (
    <Flex>
      <img
        {...provided.dragHandleProps}
        src={Moveicon}
        alt="logo"
        style={{
          width: '15px',
          marginRight: '20px',
          marginTop: '-3px',
          cursor: 'move',
        }}
      />
      <Content>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentLeftContainer>
            <PriceInfo
              placeholder="Information du produit (optionel)"
              value={item.description}
              onChange={(e) => {
                updatePriceInfos(0, index, e.target.value)
              }}
            />
            <PriceInputContainer>
              <Price
                type="number"
                step="0.01"
                placeholder="Prix"
                value={item.price}
                onChange={(e) => {
                  updatePriceInfos(1, index, e.target.value)
                }}
              />
              €
            </PriceInputContainer>
          </ContentLeftContainer>
          <ContentAction>
            <Flex style={{ marginRight: '20px' }}>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                      {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer
                  onClick={() => {
                    setPrices(prices.filter((i) => i.id !== item.id))
                  }}
                >
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </ContentAction>
        </div>
      </Content>
    </Flex>
  )
}

export default withTheme(withTranslation()(PriceItemBox))

const Flex = styled.div`
  display: flex;
`
const IconContainer = styled.div`
background-color: #F8F7FF;
cursor: pointer;
margin: 15px 5px 10px 5px;3px -10px 2px 0px
padding: 2px 4px 4px 4px;
align-item: center;
svg {
  width: 0.8em;
  height: 0.8em;
}
`
const PriceInputContainer = styled.div`
  background-color: white;
  font-size: 11pt;
  margin: 4px;
  padding: 8px;
  border-style: none;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
`
const Price = styled.input`
  width: 70px;
  outline: none;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const PriceInfo = styled.input`
  margin: 5px 30px 5px 10px;
  width: 250px;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const Content = styled.div`
  display: flex;
  background-color: #e6efff;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
  margin-bottom: 20px;
`
const ContentLeftContainer = styled.div`
  display: flex;
  padding: 6px;
  float: left;
  width: 70%;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const ContentAction = styled.div`
  float: right;
`
