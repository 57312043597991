import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
 Container, 
 Col, 
 Row, 
 Card, 
 Button,
} from 'react-bootstrap';

import { 
  SaveAlt, 
  Help, 
  VideoLibrary, 
  Add, 
  Remove, 
  Check,
  Phone,
} from '@material-ui/icons';
import styled, { withTheme } from 'styled-components';
import HelpPic from '../../../assets/dashboard/help.jpg';

const DashboardHelp = ({ setTitle, t }) => {
  const [checked, setChecked] = useState(-1);
  const most_ask_questions = [
    { question: t('MA_question1'), answer: t('MA_answer1') },
    { question: t('MA_question2'), answer: t('MA_answer2') },
    { question: t('MA_question3'), answer: t('MA_answer3') },
    { question: t('MA_question6'), answer: t('MA_answer6') },
    { question: t('MA_question7'), answer: t('MA_answer7') },
    { question: t('MA_question8'), answer: t('MA_answer8') },
    { question: t('MA_question9'), answer: t('MA_answer9') },
    { question: t('MA_question10'), answer: t('MA_answer10') },
  ]

  React.useEffect(() => {
    setTitle(`<b>${t('help_')}</b>`);
  }, [setTitle, t]);

  return (
    <CustomContainer fluid>
      <MainTitle>
        {t('help welcome')}
      </MainTitle>
      {/* <Row>
        <Col lg="4">
          <CustomCard bsPrefix="card white-card">
            <IconContainer>
              <SettingsIcon />
            </IconContainer>
            <CardTitle>
              Ouvrir un ticket
            </CardTitle>
            <div style={{placeSelf: 'center'}}>
              <CardSubTitle>
                Le ticket vous permet de signaler un incident auprès de notre support technique
              </CardSubTitle>
            </div>
            <CheckContainer>
              <CustomCheck/>
              Réponse en 48h ouvrées
            </CheckContainer>
            <CardButton>
              Ouvrir un ticket
            </CardButton>
          </CustomCard>
        </Col>
        <Col lg="4">
          <CustomCard bsPrefix="card white-card">
            <IconContainer>
              <HelpIcon />
            </IconContainer>
            <CardTitle>
              F.A.Q
            </CardTitle>
            <div style={{placeSelf: 'center'}}>
              <CardSubTitle>
              Trouvez toutes les réponses à vos questions dans notre F.A.Q !
              </CardSubTitle>
            </div>
            <CheckContainer>
              <CustomCheck/>
              + 50 questions résolues
            </CheckContainer>
            <CardButton>
              Voir la F.A.Q
            </CardButton>
          </CustomCard>
        </Col>
        <Col lg="4">
          <CustomCard bsPrefix="card white-card">
            <IconContainer>
              <VideoIcon />
            </IconContainer>
            <CardTitle>
              Tutorials vidéos
            </CardTitle>
            <div style={{placeSelf: 'center'}}>
              <CardSubTitle>
              Apprenez à utiliser notre outil comme un expert grace à nos vidéos guidés pas à pas.
              </CardSubTitle>
            </div>
            <CheckContainer>
              <CustomCheck/>
              + 10 vidéos explicatives
            </CheckContainer>
            <CardButton>
              Commencer
            </CardButton>
          </CustomCard>
        </Col>
      </Row> */}
      <StyledCard bsPrefix="card white-card">
        <CustomImg src={HelpPic} alt="logo" />
        <div style={{ zIndex: 2 }}>
          <PopupShareContent>
            <PopupTitle>
              {t('available all time')}
            </PopupTitle>
            <SubContainer>
              <PopupSubTitle>
                {t('need help')}
              </PopupSubTitle>
              <PopupSubTitle>
                {t('response')}
              </PopupSubTitle>
              <PopupSubTitle>
                {t('response2')}
              </PopupSubTitle>
            </SubContainer>
            <div style={{ textAlign: 'center'}}>
              <div 
                style={{ display: 'flex', justifyContent: 'center'}} 
                onClick={() => console.log(true)}>
                <CustomPhone/>
                <CallNumber>
                  01 85 39 21 91
                </CallNumber>
              </div>
            </div>
          </PopupShareContent>
        </div>
      </StyledCard>
      <Title>
        {t('most asked questions')}
      </Title>
        {most_ask_questions.map((maq, id) => (
          checked === id ? 
          <FlexChecked
            onClick={() => setChecked(-1)}
          >
            <div style={{display: 'flex'}}>
              <ContentLeftContainer>
                {maq.question}
              </ContentLeftContainer>
              <ContentRightContainer>
                <RemoveIcon/>
              </ContentRightContainer>
            </div>
            <AnswerContainer>
              {maq.answer}
            </AnswerContainer>
          </FlexChecked> : 
          <FlexUnchecked
            onClick={() => setChecked(id)}
          >
            <ContentLeftContainer>
              {maq.question}
            </ContentLeftContainer>
            <ContentRightContainer>
              <AddIcon/>
            </ContentRightContainer>
          </FlexUnchecked>
        ))}
      <br/>
      <br/>
    </CustomContainer>
  );
};

export default withTheme(withTranslation()(DashboardHelp));

const CustomCard = styled(Card)`
  height: 340px;
`;
const CallNumber = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 12pt;
  margin-bottom: 30px;
	color: ${(props) => props.theme.primary};
`;
const FlexChecked = styled.div`
  display: block;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
	box-shadow: 4px -4px 10px -1px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  padding-bottom: 20px;
`;
const FlexUnchecked = styled.div`
  display: flex;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
	box-shadow: 4px -4px 10px -1px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  padding-bottom: 10px;
`;
const CustomContainer = styled(Container)`
  @media(min-width  : 992px) {
		padding-left: 250px;
  }
`;
const Title = styled.div`
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  padding: 30px 0px 30px 0px;
`;
const MainTitle = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding: 100px 0px 30px 0px;
`;
const AnswerContainer = styled.div`
  text-align: left;
  width: 80%;
  margin: 10px 0px 0px 40px;
  font-size: 14px;
  font-weight: normal;
`;
const CardTitle = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
`;
const CardSubTitle = styled.div`
  text-align: left;
  font-size: 14px;
  text-align: left;
  margin: 15px 10px 20px 20px;
`;
const IconContainer = styled.div`
text-align: center;
`;
const SettingsIcon = styled(SaveAlt)`
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px;
  opacity: 0.3;
`;
const HelpIcon = styled(Help)`
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px;
  opacity: 0.3;
`;
const VideoIcon = styled(VideoLibrary)`
  width: 50px !important;
  height: 50px !important;
  margin-top: 10px;
  opacity: 0.3;
`;
const AddIcon = styled(Add)`
  width: 30px !important;
  height: 30px !important;
  opacity: 0.5;
`;
const RemoveIcon = styled(Remove)`
  width: 30px !important;
  height: 30px !important;
  opacity: 0.5;
`;
const PopupTitle = styled.div`
	text-align: center;
	font-weight: bold;
	font-size: 15pt;
  @media(max-width  : 551px) {
    font-size: 12pt;
  }
	margin: 0 25px;
	margin-bottom: 15px;
`;
const PopupSubTitle = styled.div`
	text-align: center;
	font-size: 11pt;
  margin-bottom: -3px;
`;
const SubContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  @media screen and (max-width: 551px) {
    display: none;
  }
`;
const StyledCard = styled(Card)`
  width: 100%;
	margin: 17px 0;
  height: 300px;
  @media screen and (max-width: 551px) {
    display: none;
  }
	padding: 20px;
	align-items: center;
	overflow: hidden;
`;
const CustomImg = styled.img`
	position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  @media screen and (max-width: 1150px) {
    width: 185%;
  }
	border-radius: 20px;
  opacity: 0.2;
`;
const PopupShareContent = styled.div`
  height: 200px;
  @media screen and (max-width: 551px) {
    height: 100px;
  }
  width: 700px;
	border-radius: 7px;
	margin-top: 30px;
    padding: 20px;
    background: linear-gradient(320deg,white 28%,transparent 126%,transparent 118%);
`;
const CardButton = styled(Button)`
	width: 200px;
  position: absolute;
  bottom: 0;
  align-self: center;
	font-size: 11pt;
  margin-bottom: 20px;
	background-color: ${(props) => props.theme.primary};
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const CustomButton = styled(Button)`
	width: 240px;
	margin-top: -25px;
	font-size: 11pt;
	background-color: ${(props) => props.theme.primary};
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const CustomCheck = styled(Check)`
  width: 15px;
  height: 15px;
	color: #E76B56;
  margin: 17px 10px 20px 15px;
`;
const CustomPhone = styled(Phone)`
  width: 15px;
  height: 15px;
  @media(max-width  : 551px) {
    margin-top: 5px;
    width: 14px !important;
    height: 14px !important;
  }
	color: ${(props) => props.theme.primary};
  position: absolute;
  margin-right: 150px;
`;
const CheckContainer = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-style: oblique;
`;
const ContentLeftContainerChecked = styled.div`
  display: block;
  font-weight: bold;
  margin: 14px 0px 0px 25px;
  @media screen and (max-width: 1150px) {
    width: 60%;
  }
  @media screen and (min-width: 1150px) {
    width: 60%;
  }
`
const ContentLeftContainer = styled.div`
  display: flex;
  font-weight: bold;
  margin: 14px 0px 0px 25px;
  @media screen and (max-width: 1150px) {
    width: 80%;
  }
  @media screen and (min-width: 1150px) {
    width: 80%;
  }
`
const ContentRightContainer = styled.div`
  text-align: right;
  margin: 10px 14px 0px 0px;
  @media screen and (max-width: 1150px) {
    width: 20%;
  }
  @media screen and (min-width: 1150px) {
    width: 20%;
  }
`