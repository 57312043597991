import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';

import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'
import { Check } from '@material-ui/icons';
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'

import SettingsPic from '../../../assets/help/settings.jpg';
import ThanksPic from '../../../assets/help/thank-you.jpg';
import WorkingPic from '../../../assets/help/working.jpg';
import ShootingPic from '../../../assets/help/shooting.jpg';

const queryString = require('query-string')

const EditHelp = ({ t, setTitle }) => {
  const location = useLocation()
  const { place_id } = queryString.parse(location.search)

  useEffect(() => {
    FirestoreDb()
      .collection('places')
      .doc(place_id)
      .onSnapshot((docPlace) => {
        if (docPlace.exists) {
          setTitle(`${docPlace.data().place_name} > <b>${t('assistance')}</b>`);
        }
      })
  }, [t, setTitle, place_id])

  return (
    <CustomContainer fluid>
      <AllPopups>
        <Popup style={{ marginTop: '90px' }}>
          <ImgContainer>
            <CustomImg src={SettingsPic} alt="logo" />
          </ImgContainer>
          <div style={{ textAlign: '-webkit-center' }}>
            <PopupContent>
              <PopupTitle>{t('menu entry')}</PopupTitle>
              <PopupSubTitle>
                {t('menu entry description')}
              </PopupSubTitle>
              <PopupList>
                <MBottom>
                  <CustomCheck />
                  {t('create your menu')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('setup 24h')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('optimize')}
                </MBottom>
              </PopupList>
            </PopupContent>
            <PopupButton>
              {t('discover')}
            </PopupButton>
          </div>
        </Popup>
        <Popup>
          <ImgContainer>
            <CustomImg src={ThanksPic} alt="logo" />
          </ImgContainer>
          <div style={{ textAlign: '-webkit-center' }}>
            <PopupContent>
              <PopupTitle>
                {t('multi languages trad')}
              </PopupTitle>
              <PopupSubTitle>
                {t('multi languages trad description')}
              </PopupSubTitle>
              <PopupList>
                <MBottom>
                  <CustomCheck />
                  {t('professional trad')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('72h setup')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('no error certification')}
                </MBottom>
              </PopupList>
            </PopupContent>
            <PopupButton>
              {t('discover')}
            </PopupButton>
          </div>
        </Popup>
        <Popup>
          <ImgContainer>
            <CustomImg src={WorkingPic} alt="logo" />
          </ImgContainer>
          <div style={{ textAlign: '-webkit-center' }}>
            <PopupContent>
              <PopupTitle>
                {t('menu review')}
              </PopupTitle>
              <PopupSubTitle>
                {t('menu review description')}
              </PopupSubTitle>
              <PopupList>
                <MBottom>
                  <CustomCheck />
                  {t('saving time')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('setup 24h')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('no error certification')}
                </MBottom>
              </PopupList>
            </PopupContent>
            <PopupButton>
              {t('discover')}
            </PopupButton>
          </div>
        </Popup>
        <Popup>
          <ImgContainer>
            <CustomImg src={ShootingPic} alt="logo" />
          </ImgContainer>
          <div style={{ textAlign: '-webkit-center' }}>
            <PopupContent>
              <PopupTitle>
                {t('shooting')}
              </PopupTitle>
              <PopupSubTitle>
                {t('shooting description')}
              </PopupSubTitle>
              <PopupList>
                <MBottom>
                  <CustomCheck />
                  {t('date and hours')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('all around france')}
                </MBottom>
                <MBottom>
                  <CustomCheck />
                  {t('all products pictures')}
                </MBottom>
              </PopupList>
            </PopupContent>
            <PopupButton>
              {t('discover')}
            </PopupButton>
          </div>
        </Popup>
      </AllPopups>
    </CustomContainer>
  );
};

export default withTheme(withTranslation()(EditHelp));

const CustomContainer = styled(Container)`
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media(min-width  : 992px){
      padding-left: 100px;
  }
`;
const MBottom = styled.div`
  margin-bottom: 5px;
  display: flex;
`;
const AllPopups = styled.div`
  width: fit-content;
  margin-top: 40px;
  padding-bottom: 80px;
  margin-left: 5%;
  @media screen and (min-width: 550px) {
    display: flex;
  }
`;
const PopupList = styled.div`
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: initial;
  @media screen and (min-width: 550px) {
    padding: 5px;
  }
`;
const PopupContent = styled.div`
  width: 80%;
`;
const PopupTitle = styled.div`
  font-size: 15pt;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: initial;
`;
const PopupSubTitle = styled.div`
  font-size: 12pt;
  font-weight: 300;
  text-align: initial;
`;
const PopupButton = styled(Button)`
	width: 80%;
  margin-bottom: 20px;
	font-size: 11pt;
	background-color: ${(props) => props.theme.primary};
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const Popup = styled.div`
  background-color: white;
	border-radius: 20px;
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
  @media screen and (min-width: 550px) {
    width: 400px;
    margin-right: 50px;
    margin-top: 90px;
  }
  @media screen and (max-width: 550px) {
    margin-right: 20px;
    margin-top: 50px;
  }
`;
const CustomCheck = styled(Check)`
	width: 20px;
	height: 17px; 
	color: #E76B56;
	margin-right: 2px;
`;
const ImgContainer = styled.div`
  width: 100%;
  height: 300px;
`;
const CustomImg = styled.img`
  width: 100%;
  height: 100%;
	border-radius: 20px 20px 0px 0px;
`;
