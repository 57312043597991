import React, { useState } from 'react'
import {
  Modal,
  Card,
  Container,
  Row,
  Form,
  Col,
  Button,
  Spinner,
  Navbar,
} from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import _, { isEmpty } from 'underscore'
import styled from 'styled-components'

import { ArrowBack } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import AuthContainer from '../../../../../stores/AuthContainer'
import {
  FirestoreDb,
  Functions,
} from '../../../../../utils/firebase/FirebaseModules'
import useStripe from '../../../../../hooks/Stripe'
import UseCreatePlaceContainer from '../../CreateAPlaceStore'

import PaymentForm from './PaymentForm'
import SubscriptionCart from '../SubscriptionStep/SubscriptionCart/SubscriptionCart'

const queryString = require('query-string')

const CheckoutStep = ({ t }) => {
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const {
    register: register_2,
    handleSubmit: handleSubmit_2,
    formState: { errors: errors_2 },
    getValues,
    watch,
    reset: reset_2,
  } = useForm()
  const { userDatas } = AuthContainer.useContainer()
  const { createSubscription, Coupon, couponDatas, checkingCoupon } = useStripe()
  const [additionnalPaymentDatas, setAdditionnalPaymentDatas] = useState({})
  const [showPayment, setShowPayment] = useState(false)
  const { formatedCart, cart } = CreatePlaceState
  const { enqueueSnackbar } = useSnackbar()
  const [loadingPaymentForm, setLoadingPaymentForm] = useState(false)
  const [couponBlock, showCouponBlock] = useState(true)
  const location = useLocation()
  const { lang } = queryString.parse(location.search)
  const currentLang = lang || 'FR'

  const cancelPayment = () => {
    if (typeof CreatePlaceState.createdPlace.place_id !== undefined) {
      try {
        FirestoreDb()
          .collection('places')
          .doc(CreatePlaceState.createdPlace.place_id)
          .delete()
      } catch (e) {
        return
      }
    }

    setShowPayment(false)
  }

  const handlePaymentSubmit = async (datas) => {
    setLoadingPaymentForm(true)
    setAdditionnalPaymentDatas({
      name: datas.compagny_name,
      address: {
        line1: datas.invoice_address,
      },
    })

    const { price } = CreatePlaceState.cart.filter(
      (i) => i.id === process.env.REACT_APP_STRIPE_PROD_places
    )[0]

    const subscriptionDatas = {
      priceId: price.id,
      customerId: userDatas.stripeId,
      placeDatas: CreatePlaceState.placeInformations,
      type: 'place_subscription',
      coupon: couponDatas?.id,
      lang: currentLang,
    }

    const subCreated = await createSubscription(subscriptionDatas)
    const { status, datas: subDatas, error } = subCreated.data
    CreatePlaceState.setCreatedPlace(subDatas)
    console.log(subDatas)
    if (status === 'success') {
      if (subDatas.paid) {
        CreatePlaceState.setActiveStep(3)
        CreatePlaceState.setIsFree(true)
      } else {
        setShowPayment(true)
      }
    } else {
      enqueueSnackbar(error, { variant: 'error' })
    }

    setLoadingPaymentForm(false)
  }
  let history = useHistory()

  return (
    <Container style={{ textAlign: '-webkit-center' }}>
      <Modal onHide={cancelPayment} show={showPayment}>
        <Modal.Header closeButton>
          <Modal.Title>Paiement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!_.isEmpty(CreatePlaceState.createdPlace) ? (
            <PaymentForm
              additionnalPaymentDatas={additionnalPaymentDatas}
              redirection="/dashboard/place-creation-succeeded"
            />
          ) : (
            <Spinner variant="dark" size="lg" animation="border" />
          )}
        </Modal.Body>
      </Modal>

      <Row>
        <Col>
          {couponBlock ? (
            <>
              <CustomCard loading={checkingCoupon} bsPrefix="card white-card">
                <Title>{t('coupon')}</Title>
                <Coupon
                  notValid={t('coupon_not_valid')}
                  key="couponFields"
                  placeholder={t('enter_your_coupon')}
                  cancel={t('cancel_coupon')}
                  apply={t('apply_coupon')}
                  total={cart.reduce((acc, curr) => curr.price.unit_amount + acc, 0)}
                />

                {couponDatas?.valid === false && <p>{t('coupon_not_valid')}</p>}
              </CustomCard>
            </>
          ) : (
            <CouponToggle onClick={() => showCouponBlock(!couponBlock)}>
              {t('i_have_a_coupon')}
            </CouponToggle>
          )}
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(handlePaymentSubmit)}>
        <Row>
          <Col>
            <CustomCard loading={checkingCoupon} bsPrefix="card white-card">
              <Title>{t('order')}</Title>
              <SubscriptionCart
                reduction={couponDatas?.reductedPrice}
                formatedCart={formatedCart}
                cart={cart}
              />
            </CustomCard>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomCard bsPrefix="card white-card">
              <Title>{t('invoice info')}</Title>
              <Form.Group>
                <CustomFormControl
                  placeholder={t('compagny name')}
                  isInvalid={errors.compagny_name}
                  {...register('compagny_name', { required: true })}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {t('compagny name required')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <CustomFormControl
                  placeholder={t('invoice address')}
                  isInvalid={errors.invoice_address}
                  {...register('invoice_address', { required: true })}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {t('invoice address error')}
                </Form.Control.Feedback>
              </Form.Group>
            </CustomCard>
          </Col>
        </Row>
        <Navbar bsPrefix=" navbar navbar-expand navbar-expand-bottom-fixed">
          <Navbar.Collapse>
            <CustomOutlineButton
              onClick={() => history.push('/dashboard/create-place')}
            >
              {t('cancel')}
            </CustomOutlineButton>
          </Navbar.Collapse>
          <CustomButton2
            variant="default"
            onClick={() => {
              CreatePlaceState.setActiveStep(1)
            }}
          >
            <ArrowIcon />
            {t('return')}
          </CustomButton2>
          <CustomButton
            disabled={couponDatas?.valid === false}
            type="submit"
            variant="primary"
          >
            {loadingPaymentForm ? (
              <Spinner animation="border" size="sm" variant="light" />
              ) : t('next')
            }
          </CustomButton>
        </Navbar>
      </Form>
    </Container>
  )
}

export default withTranslation()(CheckoutStep)

const Title = styled.div`
  font-size: 16pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
const CustomCard = styled(Card)`
  width: 70%;
	@media screen and (max-width: 991px) {
	  width: 95%;
	} 
  opacity: ${(props) => props.loading && '0.4'};

  text-align: left;
  padding: 20px 40px 30px 40px !important;
`
const CustomFormControl = styled(Form.Control)`
  ::placeholder {
    opacity: 0.5;
  }
`
const ArrowIcon = styled(ArrowBack)`
  width: 20px !important;
  height: 30px !important;
  margin-top: -2px;
  margin-right: 7px;
  color: black;
`
const CustomButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
`
const CustomButton2 = styled(Button)`
  width: 150px;
  height: 40px;
`
const CustomOutlineButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
  background-color: rgba(111, 79, 179, 0.2) !important;
  color: ${(props) => props.theme.primary} !important;
  border-style: none;
`
const CouponToggle = styled.div`
  margin-top: 16px;
  color: ${(props) => props.theme.primary};
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`
