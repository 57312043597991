import React from 'react'
import {
	useStripe as stripeHook,
	useElements,
	CardElement,
} from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'
import { Spinner, Button, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import withStripe from '../../../../hoc/withStripe'
import useStripe from '../../../../hooks/Stripe'

const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,

	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

const ConfirmPayment = ({ t, setShow, pm, client_secret,invoice }) => {
	const stripe = stripeHook()
	const elements = useElements()
	const { getSetutIntent } = useStripe()
	const [intent, setIntent] = React.useState({})
	const [error, setError] = React.useState('')
	const { enqueueSnackbar } = useSnackbar()
	const [processing, setProcessing] = React.useState(false)

	const handleSubmit = async (event) => {
		setProcessing(true)
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}

		const result = await stripe.confirmCardPayment(client_secret, {
			payment_method: pm,
		})

		if (result.error) {
			setError(result.error.message)
		} else {
			enqueueSnackbar(t('add_new_payment_method_added_success'), {
				variant: 'success',
			})
			setShow(false)
		}
		setProcessing(false)
	}
	return (
		<form onSubmit={handleSubmit}>
			{error !== '' && <Alert variant="danger">{error}</Alert>}
			<>
				<div style={{textAlign:"center"}}>
				<Alert>{t('info_before_retry_payment')} <b>{(invoice.amount_due/100).toFixed(2)} €</b></Alert>
				<PayButton disabled={processing} type="submit">
					{processing ? (
						<Spinner animation="border" size="sm" variant="light" />
					) : (
						t('retry_paiement')
					)}
				</PayButton>
				</div>
			</>
		</form>
	)
}

export default withStripe(ConfirmPayment)

const PayButton = styled(Button)`
	display: block;
	margin: 10px auto;
`
