import styled from 'styled-components'
import Badge from './Badge/Badge'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'

export const RestaurantCard = ({ 
  product, 
  onClick, 
  color, 
  darkmode, 
  subProduct,
  formula
}) => {
  const { selected_lang } = useMenuClientContainer.useContainer()

  if (product) {
    const description = getMultilangContent(
      product,
      'product_description_ml',
      selected_lang
    )
    const name = getMultilangContent(product, 'product_name_ml', selected_lang)

    return (
      <RestaurantCardBox 
        darkmode={darkmode} 
        lineThrough={false} 
        subProduct={subProduct}
        formula={formula}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: product?.product_main_image_url ? 'calc(100% - 90px)' : '100%',
            }}
          >
            <RestaurantCardTitle
              noStock={null}
              lineThrough={false}
              style={{ 
                marginBottom: !name && '19px',
                color: darkmode && 'white',
             }}
             onClick={() => onClick && onClick(product)}
            >
              {name}
            </RestaurantCardTitle>
            {product?.product_labels && (
              <BadgeHandler 
                marginBottom="6px"
                onClick={() => onClick && onClick(product)}>
                {product?.product_labels.map((drink, i) => (
                  <Badge 
                    color={color} 
                    key={i} 
                    text={drink} 
                    isActive 
                    type="labels"
                    subProduct={subProduct}
                  />
                ))}
              </BadgeHandler>
            )}
            {description && (
              <RestaurantCardDesc 
                darkmode={darkmode} 
                noStock={null} 
                lineThrough={false}
                onClick={() => onClick && onClick(product)}>
                {description}
              </RestaurantCardDesc>
            )}
          {!formula &&
            <>
              {product?.product_prices && product.product_prices.length > 1 ? (
                <RestaurantCardSlideUp>
                  {product.product_prices.length > 1 && (
                    <RestaurantCardSlideUpContent
                      style={{ backgroundColor: darkmode ? 'black' : subProduct ? '#E7F2EF' : 'white', color: darkmode && 'white'}}
                    >
                      {product.product_prices.map((prices, i) => (
                            <RestaurantCardSlideUpContentItem key={i}>
                              <RestaurantCardSlideUpContentItemTitle>
                                {prices.description}
                              </RestaurantCardSlideUpContentItemTitle>
                              <div style={{ display: 'flex', marginRight: '10px'}} onClick={() => onClick && onClick(product)}>
                                <div
                                  style={{
                                    textDecoration: product.product_promo_type === 'percentage' && 'line-through',
                                    marginRight: '16px',
                                    color: darkmode && 'white',
                                    font: 'normal normal bold 16px Lato',
                                  }}
                                >
                                  {prices.price} €
                                </div>
                                {product.product_promo_type === 'percentage' && (
                                  <RestaurantCardNewPrice color={color}>
                                    {parseFloat(
                                      product.product_prices[i].price -
                                        product.product_prices[i].price *
                                          (product.product_promo_reduction / 100)
                                    ).toFixed(2)} €
                                  </RestaurantCardNewPrice>
                                )}
                              </div>
                            </RestaurantCardSlideUpContentItem>
                          ))}
                    </RestaurantCardSlideUpContent>
                  )}
                </RestaurantCardSlideUp>
              ) : (
                <RestaurantCardNoSlideUp 
                  onClick={() => onClick && onClick(product)}
                >
                  {product?.product_prices[0] && (
                    <RestaurantCardPrice
                      darkmode={darkmode}
                      noStock={product.product_notstock}
                    >
                      <RestaurantCardSlideUpContentItemTitle>
                        {product.product_prices[0].description}
                      </RestaurantCardSlideUpContentItemTitle>
                      <div 
                        style={{ display: 'flex', marginRight: '10px'}} 
                        onClick={() => onClick && onClick(product)}
                      >
                        <div
                          style={{
                            textDecoration: product.product_promo_type === 'percentage' && 'line-through',
                            marginRight: '16px',
                            color: darkmode && 'white',
                            font: 'normal normal bold 16px Lato',
                          }}
                        >
                        {product.product_prices[0].price} €
                        </div>
                        {product?.product_prices[0] &&
                          product.product_promo_type === 'percentage' && (
                            <RestaurantCardNewPrice color={color}>
                              {parseFloat(
                                product.product_prices[0].price -
                                  product.product_prices[0].price *
                                    (product.product_promo_reduction / 100)
                              ).toFixed(2)} €
                            </RestaurantCardNewPrice>
                          )}
                      </div>
                    </RestaurantCardPrice>
                  )}
                </RestaurantCardNoSlideUp>
              )}
              {product?.product_alergens && (
                <BadgeHandler onClick={() => onClick && onClick(product)}>
                  {product.product_alergens.map((filterItem, i) => (
                    <Badge
                      color={color}
                      noStock={null}
                      key={i}
                      text={filterItem}
                      isActive
                      isBackgroundWhite
                      type="alergens"
                      subProduct={subProduct}
                    />
                  ))}
                </BadgeHandler>
              )}
            </>
          }
          </div>
          {product.product_main_image_url && (
            <RestaurantCardImageHandler 
              offerTitle={false}
              onClick={() => onClick && onClick(product)}>
              <RestaurantCardImage src={product.product_main_image_url} />
              {false && (
                <RestaurantCardOfferTitle color={color} noStock={null}>
                  {false}
                </RestaurantCardOfferTitle>
              )}
            </RestaurantCardImageHandler>
          )}
        </div>
      </RestaurantCardBox>
    )
  } else return null
}

export default RestaurantCard

const RestaurantCardSlideUpContentItemTitle = styled.div`
  margin-left: 15px;
  overflow: hidden;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const RestaurantCardSlideUpContentItem = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
`
const RestaurantCardSlideUpContent = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 20px;
`
const RestaurantCardSlideUp = styled.div`
  margin-bottom: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
  width: 100%;
  color: #1d1d1d;
  font: normal normal normal 15px Lato;
`
const RestaurantCardOfferTitle = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 3px 9px 5px 9px;
  background-color: ${(props) => (props.noStock ? '#BA400A' : props.color)};
  text-align: center;
  font: normal normal bold 15px Lato;
  color: white;
  border-radius: 2px;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
`
const BadgeHandler = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`
const RestaurantCardImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`
const RestaurantCardImageHandler = styled.div`
  border-radius: 5px;
  height: 90px;
  width: 90px;
  position: relative;
  margin: 15px 0px 0px 10px;
`

const RestaurantCardBox = styled.div`
  padding: 9px 15px 11px 20px;
  border-radius: 5px;
  background-color: ${(props) => (props.darkmode ? props.subProduct ? '#292C2B': 'black' : props.subProduct ? props.formula ? 'white' : '#E7F2EF' : props.formula ? '#E7F2EF' : 'white')};
  width: 100%;
  color: #969696 !important;
  &:hover {
    cursor: pointer;
  }
`
const RestaurantCardTitle = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal bold 16px Lato;
  color: ${(props) => (props.noStock ? '#969696' : '#1D1D1D')};
`
const RestaurantCardDesc = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: normal normal normal 14px Lato;
  color: ${(props) => (props.noStock ? '#969696' : props.darkmode ? '#969696' : '#606060')};
  margin-bottom: 11px;
`
const RestaurantCardNoSlideUp = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`
const RestaurantCardPrice = styled.div`
  display: contents;
  font: normal normal bold 16px Lato;
  margin-bottom: 11px;
  color: ${(props) => (props.noStock ? '#969696' : props.darkmode ? '#969696' : '#1D1D1D')};
`
const RestaurantCardNewPrice = styled.div`
  margin-bottom: 11px;
  color: ${(props) => (props.color)};
  font: normal normal bold 16px Lato;
`
