import styled from 'styled-components'
import useMenuClientContainer from '../../../stores/MenuClientContainer'

const Banner = ({ text, fontSize }) => {
  const {
    place: { theme_color },
  } = useMenuClientContainer.useContainer()
  return (
    <BannerStyle theme_color={theme_color} fontSize={fontSize}>
      {text}
    </BannerStyle>
  )
}

const BannerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${(props) =>
    props.theme_color ? props.theme_color : '#276347'};
  padding-top: 4px;
  padding-bottom: 8px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  font-style: italic;
`

export default Banner
