import React, { useEffect } from 'react'
import { Card, Col, Row, Container, Button, Spinner } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { withTheme } from 'styled-components'
import DescriptionIcon from '@material-ui/icons/Description'
import moment from 'moment'
import AuthContainer from '../../../../../stores/AuthContainer'
import UseCreatePlaceContainer from '../../CreateAPlaceStore'
import { FirestoreDb } from '../../../../../utils/firebase/FirebaseModules'

const PaymentSucceeded = ({ t }) => {
  const { paymentIntent, createdPlace, isFree } =
    UseCreatePlaceContainer.useContainer()
  const [invoice, setInvoice] = React.useState(null)
  const { user } = AuthContainer.useContainer()

  useEffect(() => {
    ;(async () => {
      await FirestoreDb()
        .collection('users')
        .doc(user.uid)
        .collection('invoices')
        .where('payment_intent', '==', paymentIntent)
        .onSnapshot((invoices) => {
          try {
            const _invoice = invoices.docs[0].data()
            setInvoice(_invoice)
          } catch (e) {
            return
          }
        })
    })()
  }, [paymentIntent, user.uid])

  return (
    <PaymentSucceededStyled>
      <Card bsPrefix="card white-card">
        <Card.Title style={{ marginTop: '30px' }}>{t('order confirmed')}</Card.Title>
        <Card.Body style={{ textAlign: '-webkit-center' }}>
          <div style={{ width: '70%' }}>{t('place create succeed')}</div>
          <Link to={`/dashboard/edit/infos/?place_id=${createdPlace.place_id}`}>
            <CustomButton variant="primary">
              {t('edit your place')}
            </CustomButton>
          </Link>
          <br />
          <Link className="backLink" to="/dashboard">
            {t('back to dashboard')}
          </Link>
        </Card.Body>
      </Card>
      {!isFree && (
        <Card bsPrefix="card white-card">
          <Card.Body>
            <Row>
              <Col className="invoiceText">
                <div>
                  <DescriptionIcon style={{ fontSize: '63px', color: '#bbb' }} />
                </div>
                <div>
                  <div
                    style={{
                      fontSize: '15px',
                      width: '200px',
                      marginBottom: '.5rem',
                      fontWeight: '500',
                      lineHeight: '1.2',
                    }}
                  >
                    {t('dowload your invoice')}
                  </div>
                  <div style={{ fontSize: '13px' }}>
                    {invoice ? (
                      moment(invoice).format('DD/MM/YYYY')
                    ) : (
                      <Spinner variant="dark" size="sm" animation="border" />
                    )}
                  </div>
                </div>
              </Col>
              <Col style={{ alignSelf: 'center', textAlign: 'right' }}>
                <CustomDownloadButton
                  onClick={() => window.open(invoice.invoice_pdf)}
                  disabled={invoice === null}
                  variant="secondary"
                >
                  {invoice !== null ? (
                    t('dowload')
                  ) : (
                    <Spinner variant="light" size="sm" animation="border" />
                  )}
                </CustomDownloadButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </PaymentSucceededStyled>
  )
}

export default withTranslation()(withTheme(PaymentSucceeded))

const PaymentSucceededStyled = styled(Container)`
  text-align: center;
  width: 50%;
	@media screen and (max-width: 991px) {
	  width: 95%;
	} 
  .backLink {
    color: ${(props) => props.theme.secondary};
    display: block;
    margin-top: 20px;
  }
  .invoiceText {
    svg {
      display: inline-block;
      margin-right: 10px;
    }
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: start;
    h5 {
      font-size: 1rem;
    }
  }
`
const CustomButton = styled(Button)`
  margin-top: 30px;
  width: 40%;
	@media screen and (max-width: 551px) {
    width: 80%;
	}
`
const CustomDownloadButton = styled(Button)`
  fontSize: '18px'
	@media screen and (max-width: 551px) {
    fontSize: '10px'
	}
`
