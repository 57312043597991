import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap'

import { PermMedia } from '@material-ui/icons'

import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import { Controller } from 'react-hook-form'
import { SetLogoModal, PromoBox, OutOfStockBox, PriceBox, TagsBox } from '../common'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'

const Others = ({
  t,
  control,
  register,
  setValue,
  product,
  onChangeAlergens,
  unregister,
  onChangeLabels,
  deleteImageButton,
  pricesList,
}) => {
  const [promoPercentage, setPromoPercentage] = useState(20)
  const [modalOpen, setModalOpen] = useState(false)
  const [outOfStock, setOutOfStock] = useState(false)
  const [promo, setPromo] = useState(false)
  const [PicUrl, setPicUrl] = useState(null)
  const [prices, setPrices] = useState([])
  const handleAddImage = (img) => {
    if (typeof img === 'object' && img.blob.size > 0) {
      setValue('product_main_image', getFileFromBlob(img.blob, 'product_main_image'))
    }
  }
  useEffect(() => {
    unregister('product_prices')
    setValue('product_prices', prices)
  }, [prices])
  useEffect(() => {
    if (product.product_prices && product.product_prices.length > 0) {
      setPrices(product.product_prices)
    }
  }, [product.product_prices])
  return (
    <>
      <SetLogoModal
        setPicUrl={setPicUrl}
        modalOpen={modalOpen}
        toggleModal={setModalOpen}
        handleAddImage={handleAddImage}
      />
      <Container fluid>
        <br />
        <Row style={{ flexWrap: 'wrap-reverse', justifyContent: 'center' }}>
          <Col lg="8">
            <CustomCard bsPrefix="card white-card">
              <Title>
                {t('information')}
              </Title>
              <Background>
                <div style={{ display: 'inline', width: '100%' }}>
                  <NameInput
                    placeholder={t('product name')}
                    {...register('product_name', { required: true })}
                  />
                  <AreaInput
                    placeholder={t('product description')}
                    {...register('product_description')}
                  />
                </div>
              </Background>
            </CustomCard>
            <CustomCard bsPrefix="card white-card">
              <Title>
                {t('extra infos')}
              </Title>
              <Background>
                <div style={{ display: 'inline', width: '100%' }}>
                  <SupInfoArea
                    placeholder={t('extra infos')}
                    {...register('product_additional_infos')}
                  />
                </div>
              </Background>
            </CustomCard>
            <CustomCard bsPrefix="card white-card">
              <PriceBox
                prices={prices}
                setPrices={setPrices}
                pricesList={pricesList}
                pricesGroup={[]}
              />{' '}
            </CustomCard>
            <CustomCard bsPrefix="card white-card">
              <TagsBox setValue={setValue} type={1} />
            </CustomCard>
            <div style={{ marginBottom: '100px' }} />
          </Col>
          <Col lg="4" style={{ maxWidth: '380px' }}>
            <CustomCard bsPrefix="card white-card">
              <Title>
                {t('product pic')}
              </Title>
              <Background>
                {!PicUrl && product.product_main_image_url == null ? (
                  <Logo
                    onClick={() => {
                      setModalOpen(!modalOpen)
                    }}
                  >
                    <InsertLogo>
                      <PermMedia
                        style={{
                          width: '50px',
                          height: '50px',
                          marginBottom: '20px',
                          opacity: 0.5,
                          color: 'lightgrey',
                        }}
                      />
                      {t('add pic')}
                      <SizeText>{t('pic size')}</SizeText>
                    </InsertLogo>
                  </Logo>
                ) : (
                  <div style={{ position: 'relative' }}>
                    {deleteImageButton()}{' '}
                    <Img
                      src={
                        !PicUrl && product.product_main_image_url !== null
                          ? product.product_main_image_url
                          : PicUrl.url
                      }
                      alt="logo"
                      onClick={() => {
                        setModalOpen(!modalOpen)
                      }}
                    />
                  </div>
                )}
              </Background>
            </CustomCard>
            <CustomCard bsPrefix="card white-card">
              <PromoBox
                prices={prices}
                promo={promo}
                setPromo={setPromo}
                promoPercentage={promoPercentage}
                setPromoPercentage={setPromoPercentage}
                control={control}
                register={register}
              />
            </CustomCard>
            <CustomCard bsPrefix="card white-card">
              <OutOfStockBox
                prices={prices}
                outOfStock={outOfStock}
                setOutOfStock={setOutOfStock}
                control={control}
              />
            </CustomCard>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(Others))

const Background = styled.div`
  background-color: #e6efff;
  padding: 20px;
  border-radius: 10px;
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const CustomCard = styled(Card)`
  padding: 15px 20px !important;
`
const Title = styled.div`
  font-size: 15pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
const Logo = styled.div`
  width: 100%;
  height: 210px;
  border-radius: 7px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  padding-top: 55px;
  cursor: pointer;
`
const Img = styled.img`
  width: 100%;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 110px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const SupInfoArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 110px;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const InsertLogo = styled.div`
  width: 200px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 10px;
  display: grid;
  justify-items: center;
`
