import styled from 'styled-components'
import { useState } from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Badge from './Badge'

export const RestaurantThemeCard = ({
  cardId,
  onClick,
  multiplePrice,
  title,
  description,
  drink,
  filter,
  offerTitle,
  image,
  price,
  newPrice,
  noStock,
  isBackgroundWhite,
  lineThrough,
  datas,
}) => {
  const [slideUpOpen, setSlideUpOpen] = useState(false)
  return (
    <RestaurantCardBox
      datas={datas}
      isBackgroundWhite={isBackgroundWhite}
      lineThrough={lineThrough}
      onClick={() => onClick && onClick(cardId)}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: image ? 'calc(100% - 90px)' : '100%' }}>
          <RestaurantCardTitle
            datas={datas}
            noStock={noStock}
            lineThrough={lineThrough}
            style={{ marginBottom: !description && '19px' }}
          >
            {title}
          </RestaurantCardTitle>
          {drink && (
            <BadgeHandler marginBottom="6px">
              {drink.map((drink, i) => (
                <Badge
                  key={i}
                  text={drink}
                  isActive
                  noStock={noStock}
                  color={datas.color}
                  isBackgroundWhite={isBackgroundWhite}
                />
              ))}
            </BadgeHandler>
          )}
          {description && (
            <RestaurantCardDesc
              datas={datas}
              noStock={noStock}
              lineThrough={lineThrough}
            >
              {description}
            </RestaurantCardDesc>
          )}
          {multiplePrice && (
            <RestaurantCardSlideUp
              datas={datas}
              onClick={() => setSlideUpOpen(!slideUpOpen)}
            >
              <div style={{ marginLeft: '8px' }}>{multiplePrice[0].name}</div>
              <RestaurantCardSlideUpRight datas={datas}>
                {multiplePrice[0].price}
                <ArrowBackIosIcon
                  style={{
                    transform: slideUpOpen ? 'rotate(-90deg)' : '',
                    marginLeft: '4px',
                    color: '#969696',
                    fontSize: '12px',
                  }}
                />
              </RestaurantCardSlideUpRight>
              <RestaurantCardSlideUpContent
                style={{ transform: slideUpOpen ? 'scale(1, 1)' : '' }}
              >
                {multiplePrice.map((prices, i) => {
                  if (i !== 0) {
                    return (
                      <RestaurantCardSlideUpContentItem key={i}>
                        <RestaurantCardSlideUpContentItemTitle>
                          {prices.name}
                        </RestaurantCardSlideUpContentItemTitle>
                        <div
                          style={{
                            marginRight: '16px',
                            font: 'normal normal bold 16px Lato',
                          }}
                        >
                          {prices.price}
                        </div>
                      </RestaurantCardSlideUpContentItem>
                    )
                  }
                  return null
                })}
              </RestaurantCardSlideUpContent>
            </RestaurantCardSlideUp>
          )}
          <div style={{ display: 'flex' }}>
            {price && (
              <RestaurantCardPrice
                datas={datas}
                newPrice={newPrice}
                noStock={noStock}
                lineThrough={lineThrough}
              >
                {price}
              </RestaurantCardPrice>
            )}
            {newPrice && (
              <RestaurantCardNewPrice datas={datas} lineThrough={lineThrough}>
                {newPrice}
              </RestaurantCardNewPrice>
            )}
          </div>
        </div>
        {image && (
          <RestaurantCardImageHandler offerTitle={offerTitle}>
            <RestaurantCardImage src={image} />
            {offerTitle && (
              <RestaurantCardOfferTitle datas={datas} noStock={noStock}>
                {offerTitle}
              </RestaurantCardOfferTitle>
            )}
          </RestaurantCardImageHandler>
        )}
      </div>
      {filter && (
        <BadgeHandler>
          {filter.map((filterItem, i) => (
            <Badge
              datas={datas}
              noStock={noStock}
              color={datas.color}
              key={i}
              text={filterItem}
              isActive
              isBackgroundWhite={isBackgroundWhite}
            />
          ))}
        </BadgeHandler>
      )}
    </RestaurantCardBox>
  )
}

export default RestaurantThemeCard

const RestaurantCardSlideUpContentItemTitle = styled.div`
  margin-left: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const RestaurantCardSlideUpContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
`

const RestaurantCardSlideUpContent = styled.div`
  background-color: white;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 33px;
  transition: transform 0.2s ease-in-out;
  transform: scale(1, 0);
`

const RestaurantCardSlideUpRight = styled.div`
  display: flex;
  align-items: center;
  font: ${(props) => `normal normal bold 16px ${props.datas.font}`};
  color: #1d1d1d;
  > svg {
    transition: transform 0.2s ease-in-out;
  }
`

const RestaurantCardSlideUp = styled.div`
  margin-bottom: 17px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: 1px solid #1d1d1d;
  width: 100%;
  color: #1d1d1d;
  font: ${(props) => `normal normal normal 15px ${props.datas.font}`};
`
const RestaurantCardOfferTitle = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 3px 9px 5px 9px;
  background-color: ${(props) => (props.noStock ? '#BA400A' : props.datas.color)};
  text-align: center;
  font: ${(props) => `normal normal bold 15px ${props.datas.font}`};
  color: white;
  border-radius: 2px;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
`

const BadgeHandler = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`

const RestaurantCardImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`

const RestaurantCardImageHandler = styled.div`
  border-radius: 5px;
  height: 90px;
  width: 90px;
  position: relative;
  margin-bottom: ${(props) => (props.offerTitle ? '26px' : '10px')};
`

const RestaurantCardBox = styled.div`
  padding: 9px 15px 11px 20px;
  border-radius: 5px;
  background-color: ${(props) => (props.datas.darkmode ? 'black' : 'white')};
  width: 100%;
  color: #969696 !important;
`

const RestaurantCardTitle = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: ${(props) => `normal normal bold 16px ${props.datas.font}`};
  color: ${(props) =>
    props.datas.darkmode ? 'white' : props.noStock ? '#969696' : '#1D1D1D'};
`
const RestaurantCardDesc = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  font: ${(props) => `normal normal normal 14px ${props.datas.font}`};
  color: ${(props) =>
    props.datas.darkmode ? 'e8e8e8' : props.noStock ? '#969696' : '#1D1D1D'};
  margin-bottom: 11px;
`

const RestaurantCardPrice = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  font: ${(props) => `normal normal bold 16px ${props.datas.font}`};
  margin-bottom: 11px;
  ${(props) =>
    props.newPrice
      ? 'text-decoration: line-through;font: normal normal normal 16px Lato;margin-right: 15px;'
      : ''}
  color: ${(props) => (props.noStock ? '#969696' : '#1D1D1D')};
`

const RestaurantCardNewPrice = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 11px;
  color: ${(props) => props.datas.color};
  font: ${(props) => `normal normal bold 16px ${props.datas.font}`};
`
