// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Auth, FirestoreDb } from '../utils/firebase/FirebaseModules'

const AuthContainer = (props) => {
	const [user] = useAuthState(Auth())
	const [userClaims, setClaims] = useState({})
	const [userDatas, setUserDatas] = useState(null)

	useEffect(() => {
		;(async () => {
			if (user !== null) {
				const { claims } = await user.getIdTokenResult()
				setClaims(claims)
				FirestoreDb()
					.collection('users')
					.doc(user.uid)
					.onSnapshot((d) => {
						// fetch private datas
						FirestoreDb()
							.collection('users')
							.doc(user.uid)
							.collection('restrict')
							.doc('subscription')
							.onSnapshot((p) => {
								setUserDatas({
									...user,
									...d.data(),
									restrict: { ...p.data() },
									id: d.id,
								})
							})
					})
			} else {
				setUserDatas(null)
			}
		})()
	}, [user])

	return {
		userDatas,
		userClaims,
		user,
	}
}

export default createContainer(AuthContainer)
