import React from 'react'
import { Button, Modal, Card, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useCollection } from '@nandorojo/swr-firestore'
import AuthContainer from '../../../../stores/AuthContainer'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import { useTranslation } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useFirestoreApi } from '../../../../hooks/FireTools'
import { Functions } from '../../../../utils/firebase/FirebaseModules'
import firebase from 'firebase/app'

const importFunction = Functions().httpsCallable('formulas-importAFormula')

const useImportFormula = () => {
	const { t } = useTranslation()
	const [modalOpen, toggleModal] = React.useState(false)
	const [sectionId, setSectionId] = React.useState(null)
	const { updateDatas, loading } = useFirestoreApi()
	const [menuId, setMenuId] = React.useState(null)
	const [formulas, setFormulas] = React.useState([])
	const [placeId, setPlaceId] = React.useState([])
	const [menuOrder, setMenuOrder] = React.useState([])
	const [currentLang, setCurrentLang] = React.useState([])
	const [importing, setImporting] = React.useState(false)
	const { user } = AuthContainer.useContainer()
	const { data: fetchedFormulas } = useCollection(
		placeId !== null && user?.uid ? `places/${placeId}/formulas` : null,
		{
			listen: true,
			where: ['place_id', '==', placeId],
		}
	)

	const config = ({
		sectionId,
		menuId,
		formulas,
		placeId,
		currentLang,
		menuOrder,
	}) => {
		setSectionId(sectionId)
		setMenuId(menuId)
		setFormulas(formulas)
		setPlaceId(placeId)
		setCurrentLang(currentLang)
		setMenuOrder(menuOrder)

		toggleModal(true)
	}

	const importAction = async (id) => {
		setImporting(true)

		const importation = await importFunction({
			data: {
				formulaId: id,
				sectionId,
				menuId,
				placeId,
				menuOrder,
			},
		})

		const imported_formula = JSON.parse(importation.data.datas)
		if (!formulas.find((f) => f.id === imported_formula.id)) {
			formulas.push(imported_formula)
			await updateDatas({
				query: `places/${placeId}/menus/${menuId}/sections/${sectionId}`,

				datas: {
					formulas,
				},
				action: 'set',
			})
		} else {
			alert('Cette formule est déjà présente dans la catégorie')
		}

		setImporting(false)
		toggleModal(false)
	}

	const deleteFormula = async (id) => {
		setImporting(true)

		await updateDatas({
			query: `places/${placeId}/menus/${menuId}/sections/${sectionId}`,

			datas: {
				formulas: formulas.filter((f) => f.id !== id),
			},
			action: 'set',
		})

		const linkRef = `places/${placeId}/menus/${menuId}/sections/${sectionId}`

		updateDatas({
			query: `places/${placeId}/formulas/${id}`,
			datas: {
				formula_location: firebase.firestore.FieldValue.arrayRemove(linkRef),
			},
			action: 'set',
		})

		setImporting(false)
		toggleModal(false)
	}

	const deleteFormulaFunction = async (
		_formulaId,
		_placeId,
		_menuId,
		_sectionId
	) => {
		setImporting(true)

		await updateDatas({
			query: `places/${_placeId}/menus/${_menuId}/sections/${_sectionId}`,

			datas: {
				formulas: formulas.filter((f) => f.id !== _formulaId),
			},
			action: 'set',
		})

		const linkRef = `places/${_placeId}/menus/${_menuId}/sections/${_sectionId}`

		updateDatas({
			query: `places/${_placeId}/formulas/${_formulaId}`,
			datas: {
				formula_location: firebase.firestore.FieldValue.arrayRemove(linkRef),
			},
			action: 'set',
		})

		setImporting(false)
	}

	const importFormulaModal = (
		<CustomModal show={modalOpen} onHide={() => toggleModal(false)}>
			<ContainerFormulas>
				{fetchedFormulas &&
					fetchedFormulas.map((formula) => (
						<FormulaItemStyled
							added={formulas.find((f) => f.id === formula.id)}
							key={formula.id}
							formula={formula}
							currentLang={currentLang}
							t={t}
							importAction={importAction}
						>
							<Card.Header>
								<Card.Title>
									{getMultilangContent(formula, 'name_ml', currentLang)}
								</Card.Title>
								<Card.Subtitle>
									{getMultilangContent(formula, 'description_ml', currentLang)}
								</Card.Subtitle>
							</Card.Header>
							<Card.Footer>
								<Button
									disabled={importing}
									onClick={() => {
										if (formulas.find((f) => f.id === formula.id)) {
											deleteFormula(formula.id)
										} else {
											importAction(formula.id)
										}
									}}
								>
									{' '}
									<GetAppIcon />{' '}
									{formulas.find((f) => f.id === formula.id)
										? t('remove_formula')
										: t('import_formula')}
								</Button>
							</Card.Footer>
						</FormulaItemStyled>
					))}
			</ContainerFormulas>
		</CustomModal>
	)

	return {
		importFormulaModal,
		toggleModal,
		config,
		deleteFormulaFunction,
		importing,
	}
}

export default useImportFormula

const FormulaItemStyled = styled(Card)`
	width: 33.33%;
	margin: 5px !important;
	border: ${(props) => props.added && `3px solid  #6F4FB3`};
	background-color: white;
	margin: 20 px 20 px 0 px 20 px;
	padding: 20 px 40 px 20 px 40 px;
	text-align: center;
	border-radius: 25 px;
`
const ContainerFormulas = styled(Container)`
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
`
const CustomModal = styled(Modal)`
	width: 100%;
	.modal-dialog {
		max-width: 80%;
	}
	.modal-content {
		background-color: #eceaff;
		border-radius: 20px;
	}
`
