import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import _ from 'underscore'
import RestaurantCard from '../RestaurantCard/RestaurantCard'
import RestaurantCardModal from '../RestaurantCard/RestaurantCardModal/RestaurantCardModal'
import RestaurantFormulaCardModal from '../RestaurantCard/RestaurantCardModal/RestaurantFormulaCardModal'
import Categorie from './Categorie/Categorie'
import SubCategorie from './Categorie/SubCategorie/SubCategorie'
import Filter from './Filter/Filter'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'
import { formatCategoryElements } from '../../../../utils/MenuHelpers'

const week_day = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
]

const RestaurantInformation = ({ t, sections, datas }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFormulaOpen, setIsFormulaOpen] = useState(false)
  const [currentCard, setCurrentCard] = useState(false)
  const { selected_lang } = useMenuClientContainer.useContainer()
  const [alergenesFilter, setAlergenesFilter] = useState([])
  const [labelsFilter, setLabelsFilter] = useState([])
  let today = new Date();

  const inTime = (datas) => {
    var start = parseInt(datas.displayHours.split(':')[0], 10) * 60 + parseInt(datas.displayHours.split(':')[1], 10)
    var end = parseInt(datas.undisplayHours.split(':')[0], 10) * 60 + parseInt(datas.undisplayHours.split(':')[1], 10)
    var time = today.getHours() * 60 + today.getMinutes();
    if (start > end)
      return time <= start && time < end;
    else
      return time >= start && time < end;
  }

  const checkHours = (datas) => {
    if ((typeof datas.displayHours === 'string' || datas.displayHours instanceof String)
    && (typeof datas.undisplayHours === 'string' || datas.undisplayHours instanceof String)) {
      if (inTime(datas))
        return true
    }
    else 
      return false
  } 

  const checkDayHours = (section) => {
    let res = false
    let tmp_res = false
    
    if (section?.group_hours) { 
      if (Object.keys(section?.group_hours).length === 0)
        return true
      section.group_hours.map((datas, i) => {
        tmp_res =  checkHours(datas)
        if (!datas.openDays[week_day[today.getDay()]])
            tmp_res = false
        if (tmp_res)
          res = true
      })
    }
    else
      return true
    return res
  }

  const handleModalCard = (propsCard) => {
    setCurrentCard({
      ...propsCard,
      product_name: getMultilangContent(propsCard, 'product_name_ml', selected_lang),
      product_description: getMultilangContent(
        propsCard,
        'product_description_ml',
        selected_lang
      ),
      product_add_infos: getMultilangContent(
        propsCard,
        'product_additional_infos_ml',
        selected_lang
      ),
    })
    setIsOpen(true)
  }

  const handleModalFormulaCard = (propsCard) => {
    setCurrentCard({
      ...propsCard,
      name: getMultilangContent(propsCard, 'name_ml', selected_lang),
      description: getMultilangContent(propsCard, 'description_ml', selected_lang),
      add_infos: getMultilangContent(
        propsCard,
        'additional_infos_ml',
        selected_lang
      ),
    })
    setIsFormulaOpen(true)
  }

  const cardFilter = (product) => {
    var tmp = true
    var tmp1 = false

    product.product_alergens?.map((alergen, i) => {
      if (alergenesFilter.includes(alergen)) tmp = false
    })
    if (labelsFilter.length > 0) {
      product.product_labels?.map((label, i) => {
        if (labelsFilter.includes(label)) tmp1 = true
      })
      if (tmp) return tmp1
    }
    return tmp
  }

  return (
    <>
      <RestaurantCardModal
        datas={datas}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentCard={currentCard}
      />
      <RestaurantFormulaCardModal
        datas={datas}
        isOpen={isFormulaOpen}
        setIsOpen={setIsFormulaOpen}
        currentCard={currentCard}
      />
      <FilterBox>
        <Filter
          alergenesFilter={alergenesFilter}
          labelsFilter={labelsFilter}
          setAlergenesFilter={setAlergenesFilter}
          setLabelsFilter={setLabelsFilter}
          datas={datas}
        />
      </FilterBox>
      {sections &&
        sections
          .filter((cat) => !cat?.group_parent || cat?.group_parent === null)
          .map((section) => {
            if (
                section.group_type === 2 && 
                !section.group_visible && 
                checkDayHours(section)
              ) {
              return (
                <SpecialOfferBox
                  color={datas.color}
                  id={
                    getMultilangContent(section, 'group_name_ml', selected_lang) ||
                    ''
                  }
                >
                  <SpecialOfferTitle>
                    {
                      section.group_name_ml?.find((x) => x.lang === selected_lang)
                        ?.content
                    }
                  </SpecialOfferTitle>
                  <SpecialOfferDesc>
                    {
                      section.group_description_ml?.find(
                        (x) => x.lang === selected_lang
                      )?.content
                    }
                  </SpecialOfferDesc>
                  <Row>
                    {section.products.map(
                      (product, y) =>
                        cardFilter(product) && (
                          <Col
                            key={y}
                            sm="6"
                            md="6"
                            style={{ paddingBottom: '15px' }}
                          >
                            <RestaurantCard
                              darkmode={datas.darkmode}
                              color={datas.color}
                              product={product}
                              onClick={handleModalCard}
                            />
                          </Col>
                        )
                    )}
                  </Row>
                </SpecialOfferBox>
              )
            } else if (
                section.group_type === 0 && 
                !section.group_visible && 
                checkDayHours(section)
              ) {
              return (
                <Categorie
                  color={datas.color}
                  darkmode={datas.darkmode}
                  cardClicked={handleModalCard}
                  cardFormulaClicked={handleModalFormulaCard}
                  sectionInfo={section}
                  cardFilter={cardFilter}
                  section={formatCategoryElements(section, sections) || []}
                  title={
                    getMultilangContent(section, 'group_name_ml', selected_lang) ||
                    ''
                  }
                  description={
                    getMultilangContent(
                      section,
                      'group_description_ml',
                      selected_lang
                    ) || ''
                  }
                  image={section.group_image_url && section.group_image_url}
                />
              )
            }
          })}

      {/* Categorie de base d'Erwin */}
      {/* <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          <Categorie
            cardClicked={handleModalCard}
            idSCroll="formules"
            cardInformation={cards}
            title="Les Formules"
            description="Description de la section sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
            image="https://img.cuisineaz.com/680x357/2015/01/07/i64785-plats-familliaux.jpg"
          />
          <Categorie
            cardClicked={handleModalCard}
            subCategorie={(
                <SubCategorie
                    cardClicked={handleModalCard}
                    image="https://img.cuisineaz.com/680x357/2015/01/07/i64785-plats-familliaux.jpg"
                    cards={cards3}
                    title="Sous catégorie"
                    description="Description à partager sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
                />
                )}
            cardInformation={cards2}
            title="Catégorie prix multiples"
            description="Description de la section sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
            image="https://img.cuisineaz.com/680x357/2015/01/07/i64785-plats-familliaux.jpg"
            subCategorie={(
                <SubCategorie
                    cardClicked={handleModalCard}
                    cards={cards3}
                    title="Sous catégorie sans photo"
                    description="Description à partager sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
                />
                )}
            cardInformation={cards5}
            title="Catégorie sans photos"
            description="Description de la section sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
          />
        </div> */}
    </>
  )
}

export default RestaurantInformation

const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 17px;
`
const SpecialOfferBox = styled.div`
  padding: 22px 15px 9px 15px;
  background-color: ${(props) => props.color};
`

const SpecialOfferTitle = styled.div`
  margin-left: 18px;
  margin-bottom: 8px;
  color: white;
  font: normal normal bold 25px Lato;
`

const SpecialOfferDesc = styled.div`
  margin-bottom: 21px;
  margin-left: 18px;
  font: normal normal normal 15px Lato;
  color: white;
  width: 90%
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-line;
`
