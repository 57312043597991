import React from 'react'
import { Auth, Functions } from '../utils/firebase/FirebaseModules'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export const useUserSwitch = () => {
	const [loading, setLoading] = React.useState(false)
	const history = useHistory()
	const backToAdmin = async () => {
		if (localStorage.getItem('original_user') !== null) {
			await Auth().signInWithCustomToken(localStorage.getItem('original_user'))
			history.push('/admin')
			localStorage.removeItem('original_user')
		}
	}

	const connectAsUser = async (userId) => {
		setLoading(true)
		const logAsUserFunction = Functions().httpsCallable('adminFunctions-logAsUser')
		const res = await logAsUserFunction({
			userId,
		})

		const { status, token, originalUserToken } = res.data

		if (status === 'success') {
			const original_user = localStorage.getItem('original_user')
			if (!original_user) {
				localStorage.setItem('original_user', originalUserToken)
			} else {
				localStorage.removeItem('original_user')
			}

			await Auth().signInWithCustomToken(token)

			history.push('/dashboard/')
		}
		setLoading(false)
	}

	const BannerSwichUser = () => (
		<BannerSwichUserStyled
			visible={localStorage.getItem('original_user') !== null}
			onClick={() => backToAdmin()}
		>
			Back to admin account
		</BannerSwichUserStyled>
	)

	return {
		BannerSwichUser,
		loading,
		connectAsUser,
	}
}

const BannerSwichUserStyled = styled.div`
	display: ${(props) => (props.visible ? 'block' : 'none')};
	background: black;
	color: white;
	cursor: pointer;

	padding: 20px;
	position: fixed;
	z-index: 300000;
	bottom: 0;
	right: 0;
	text-align: center;
`
