import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import CreatePlacePic from '../../../assets/dashboard/create-place.jpg';
import CollaboratorsPic from '../../../assets/dashboard/collaborators.jpg';

const DashboardIndex = ({ t, setTitle }) => {
useEffect(() => {
	setTitle(`<b>${t('dashboard')}</b>`);
}, [t, setTitle]);

  return (
    <CustomContainer fluid>
      <Row>
        <Col lg="8">
          <StyledCard bsPrefix="card white-card">
            <CustomImg src={CreatePlacePic} alt="logo" />
            <div style={{ zIndex: 2, width: '90%' }}>
              <PopupContent>
                <PopupTitle>
                  {t('first place creation')}
                </PopupTitle>
                <PopupInfos>
                  <PriceContainer>
                    <div style={{
                        fontSize: '17pt', color: '#6F4FB3', marginTop: '15px', marginLeft: '5px',
                      }}
                    >
                      €
                    </div>
                    <FlexRow>
                      <div style={{ fontSize: '45pt', color: '#6F4FB3', fontWeight: 'bold' }}>
                        29
                      </div>
                      <div style={{ marginTop: '-10px', fontWeight: 300 }}>
                        {t('without tax / months')}
                      </div>
                    </FlexRow>
                  </PriceContainer>
                  <OfferContainer>
                    <PopupListHalf>
                      <MBottom>
                        <CustomCheck />
                        {t('create your menu')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('load pdf')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('customize')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('hotline')}
                      </MBottom>
                    </PopupListHalf>
                    <PopupListHalf>
                      <MBottom>
                        <CustomCheck />
                        {t('1 qr code')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('fast update')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('multi languages')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('menus hours')}
                      </MBottom>
                    </PopupListHalf>
                  </OfferContainer>
                </PopupInfos>
              </PopupContent>
            </div>
            <div style={{ textAlign: 'center', zIndex: 3 }}>
              <Link to="/dashboard/create-place">
                <CustomButton>
                  {t('create-place')}
                </CustomButton>
              </Link>
            </div>
          </StyledCard>
          <StyledCard bsPrefix="card white-card">
            <CustomImg src={CollaboratorsPic} alt="logo" />
            <div style={{ zIndex: 2 }}>
              <PopupShareContent>
                <PopupTitle>
                  {t('invite mates')}
                  <br />
                  {t('invite mates2')}
                </PopupTitle>
              </PopupShareContent>
            </div>
            <div style={{ textAlign: 'center', zIndex: 3 }}>
              <CustomButton2 variant="disabled">
                <AvalaibleSoon>
                  {t('avalaible soon')}
                </AvalaibleSoon>
                {t('add mate')}
              </CustomButton2>
            </div>
          </StyledCard>
        </Col>
        <Col lg="4">
          <CardDeskOnly>
            <PromoTitle>
              {t('15 free days')}
            </PromoTitle>
            <PromoSubTitle>
              {t('for first place')}
              <br />
              {t('for first place2')}
            </PromoSubTitle>
            <FadeDiv>
              <PopupTitleBis>
              {t('test all features')}
              </PopupTitleBis>
              <PopupList style={{ color: 'white' }}>
                <MBottom>
                  <CustomPopupCheck />
                  {t('without commitment')}
                </MBottom>
                <MBottom>
                  <CustomPopupCheck />
                  {t('1 click')}
                </MBottom>
              </PopupList>
              <PopupBottomText>
                {t('conditions')}
              </PopupBottomText>
            </FadeDiv>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Link to="/dashboard/create-place">
                <CustomPopupButton variant="outline">
                  {t('let do it')}
                </CustomPopupButton>
              </Link>
            </div>
          </CardDeskOnly>
        </Col>
      </Row>
      <br/>
    </CustomContainer>
  );
};

export default withTheme(withTranslation()(DashboardIndex));

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media(min-width  : 992px) {
		padding-left: 250px;
    }
`;
const AvalaibleSoon = styled.div`
  width: 160px;
  height: 25px;
  padding-top: 2px;
  margin: 12px 0px 0px 28px;
  position: absolute;
  background-color: ${(props) => props.theme.primary};
  border-radius: 5px;
  font-size: 14px;
  color: white;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
`;
const MBottom = styled.div`
  margin-bottom: 5px;
  display: flex;
`;
const CardDeskOnly = styled(Card)`
	@media screen and (max-width: 991px) {
	display: none;
	} 
	background: linear-gradient(290deg, #6F4FB3 -70%, #879AE6 120%, #879AE6 80%);
	margin: 17px 0; 
	padding: 20px;
	border-radius: 20px;
	align-items: center;
`;
const StyledCard = styled(Card)`
	margin: 17px 0;
	padding: 20px;
	align-items: center;
	overflow: hidden;
`;
const CustomButton = styled(Button)`
	width: 240px;
	margin-top: -25px;
	font-size: 11pt;
	background-color: ${(props) => props.theme.primary};
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const CustomButton2 = styled(Button)`
	width: 240px;
	margin-top: -25px;
	font-size: 11pt;
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const CustomPopupButton = styled(Button)`
	width: 80%;
	color: ${(props) => props.theme.primary};
	border-style: none;
	background-color: white;
	font-size: 11pt;
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const PromoTitle = styled.div`
	color: white;
	font-size: 18pt;
    font-weight: bold;
`;
const PromoSubTitle = styled.div`
	text-align: center;
	color: white;
	font-size: 11pt;
`;
const PopupBottomText = styled.div`
	margin-top: 30px;
	margin-bottom: 20px;
	color: white;
	font-size: 10pt;
	font-weight: 300;
	width: 85%;
`;
const PriceContainer = styled.div`
	flex: 0.25;
	display: flex;
	flex-direction: row-reverse;
	@media screen and (min-width: 550px) {
		border-right-style: solid;
		border-right-width: 1px;
		border-color: lightgrey;
		padding-right: 7px;
		margin-top: 5px;
		padding-right: 5%;
		margin-left: 10px;
		height: 125px;
	} 
	@media screen and (max-width: 550px) {
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-color: lightgrey;
		align-self: center;
		padding-left: 20px;
		margin-right: 10px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	} 
`;
const FadeDiv = styled.div`
	width: 90%;
    margin-top: 15px;
    margin-bottom: 20px;
	border-radius: 7px;
    padding-left: 20px;
	background: linear-gradient(320deg, white -200%, transparent 80%, transparent 20%);						
`;
const PopupTitle = styled.div`
	text-align: center;
	font-weight: bold;
	font-size: 15pt;
	margin: 0 25px;
	margin-bottom: 15px;
`;
const PopupTitleBis = styled.div`
	margin-top: 30px;
	margin-bottom: 20px;
	color: white;
	font-size: 12pt;
	font-weight: 300;
	width: 85%;
`;
const PopupContent = styled.div`
	width: 100%;
	border-radius: 7px;
    padding: 15px;
	background-color: rgba(255, 255, 255, 0.7);
`;
const PopupShareContent = styled.div`
	width: 100%;
	border-radius: 7px;
	margin-top: 10px;
    padding: 20px;
    background: linear-gradient(320deg,white 30%,transparent 80%,transparent 60%);
`;
const PopupList = styled.div`
    font-weight: 600;
	@media screen and (min-width: 550px) {
		padding: 5px;
	} 
`;

const PopupListHalf = styled(PopupList)`
	@media screen and (min-width: 550px) {
		margin-left: 15px;
	} 
`;

const CustomCheck = styled(Check)`
	width: 20px;
	height: 17px; 
	color: #E76B56;
	margin-right: 2px;
`;
const CustomPopupCheck = styled(Check)`
	width: 20px;
	height: 17px; 
	color: white; 
	margin-right: 2px;
`;
const CustomImg = styled.img`
	position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  @media screen and (max-width: 550px) {
    width: 200%;
  } 
	border-radius: 20px;
  opacity: 0.2;
`;
const PopupInfos = styled.div`
	display: flex;
	@media screen and (max-width: 550px) {
		flex-direction: column;
	} 
	justify-content: center;
`;
const OfferContainer = styled.div`
	display: flex;
	flex: 1;
    margin-left: 20px;
    margin-bottom: 20px;
	@media screen and (max-width: 550px) {
		flex-direction: column;
	} 
`;
