import React from 'react'
import { createContainer } from 'unstated-next'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const useProductBaseStore = createContainer((props) => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [cart, setCart] = React.useState([])
	const [openCart, setOpenCart] = React.useState(false)
	const [error, setError] = React.useState()

	const addToCart = (element) => {
		if (!cart.find((el) => el.id === element.id)) {
			const add = [...cart, element]

			setCart([...cart, element])
		} else {
			setError({
				message: 'item_already_added',
			})
			showError()
		}
	}
	const showCart = () => setOpenCart(true)
	const closeCart = () => setOpenCart(false)
	const cleanCart = () => setCart([])

	const removeFromCart = (element) => {
		const afterRemove = [...cart].filter((e) => e.id !== element.id)
		if (afterRemove.length === 0) {
			closeCart()
		}
		setCart(afterRemove)
	}

	const showError = React.useCallback(() => {
		if (error) {
			enqueueSnackbar(t(error.message), { variant: 'error' })
		}
	}, [error])

	return {
		cart,
		addToCart,
		removeFromCart,
		openCart,
		showCart,
		closeCart,
		cleanCart,
	}
})

export default useProductBaseStore

export const withProductBaseStore =
	(Component) =>
	({ ...props }) => {
		return (
			<useProductBaseStore.Provider>
				<Component {...props} />
			</useProductBaseStore.Provider>
		)
	}
