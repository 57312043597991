import styled from 'styled-components'
import EventIcon from '@material-ui/icons/Event'
import { useState } from 'react'
import ContactLogo from '../../Contact/ContactLogo'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'

const HeaderCard = ({ t, menuInfo, place, datas }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { selected_lang } = useMenuClientContainer.useContainer()

  return (
    <CardStyled color={datas.color}>
      <HeadingReservation 
        style={{display: !datas.topbanner && 'none'}}
        onClick={() => setIsOpen(!isOpen)}>
        <HeadingReservationAnimationContainer
          style={{ transform: isOpen && 'translate3d(0, 0, 0)' }}
        >
          <HeadingReservationInformation>
            <HeadingReservationInformationTitle>
              Réservation
            </HeadingReservationInformationTitle>
            <HeadingReservationInformationDesc>
              Réservation uniquement par téléphone au
            </HeadingReservationInformationDesc>
            <HeadingReservationInformationNumber>
              {place.place_phone && place.place_phone.match(/.{1,2}/g).join(' ')}
            </HeadingReservationInformationNumber>
          </HeadingReservationInformation>
          <HeadingReservationIconButton>
            <EventIcon
              style={{ color: 'white', marginRight: '9px', fontSize: '20px' }}
            />
            Réserver
          </HeadingReservationIconButton>
        </HeadingReservationAnimationContainer>
      </HeadingReservation>
        {menuInfo.background_image_url ? 
          <ImgCardStyled 
            src={menuInfo.background_image_url || ''} 
            lt="restaurant" 
          /> :
          <NoImgCardStyled 
            color={datas.color} 
            alt="restaurant"
          />
        }
      <InformationHandler>
        <InformationTitle>
          {getMultilangContent(menuInfo, 'name_ml', selected_lang)}
        </InformationTitle>
        <InformationDescription>
          {getMultilangContent(menuInfo, 'description_ml', selected_lang)}
        </InformationDescription>
        <ContactLogo place={place} fontColor={datas.color} isWhite={!!datas.darkmode}/>
      </InformationHandler>
    </CardStyled>
  )
}

export default HeaderCard
const HeadingReservationAnimationContainer = styled.div`
  display: flex;
  transform: translate3d(0, calc(32px - 100%), 0);
  transition: transform 0.25s ease-in-out;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
`

const HeadingReservationIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 13px;
  padding-right: 13px;
  font: normal normal normal 16px Lato;
  width: 113px;
  background-color: #276347;
  border-radius: 0px 0px 15px 15px;
`

const HeadingReservationInformationTitle = styled.div`
  font: normal normal bold 25px Lato;
  margin-top: 20px;
  margin-bottom: 10px;
`
const HeadingReservationInformationDesc = styled.div`
  font: normal normal normal 15px Lato;
  margin-bottom: 13px;
`
const HeadingReservationInformationNumber = styled.div`
  font: normal normal bold 20px Lato;
  margin-bottom: 30px;
`

const HeadingReservationInformation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #276347;
`

const HeadingReservation = styled.div`
  position: absolute;
  top: 0;
  z-index: 10;
  text-align: center;
  overflow: hidden;
  width: 100%;
`

const ImgCardStyled = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const NoImgCardStyled = styled.div`
position: absolute;
width: 100%;
height: 100%;
background: ${(props) => props.color && props.color};
`

const InformationTitle = styled.div`
  font: bold 25px;
  margin-bottom: 25px;
`

const InformationHandler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InformationDescription = styled.p`
  text-align: center;
  font-size: 15px;
  width: 412px;
  font-weight: normal;
  margin-bottom: 40px;
  line-height: 1.2em;
  white-space: pre-line;
`
const CardStyled = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
  ${HeadingReservationInformation} {
    background: ${(props) => props.color && props.color};
  }
  ${HeadingReservationIconButton} {
    background: ${(props) => props.color && props.color};
  }
`
