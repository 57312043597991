import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import CreatePlacePic from '../../../assets/dashboard/create-place.jpg';
import CreatePlaceShowPic from '../../../assets/dashboard/create-place-showcase.jpg';

const CreateAPlaceView = ({ t, setTitle }) => {
  const history = useHistory();
  
  useEffect(() => {
    setTitle(`${t('place')} > <b>${t('create-place')}</b>`);
  }, [t, setTitle]);

  return (
    <CustomContainer fluid>
      <Row>
        <Col lg="6">
          <StyledCard bsPrefix="card white-card">
            <CustomImg src={CreatePlacePic} alt="logo" />
            <div style={{ zIndex: 2, width: '90%' }}>
              <PopupContent>
                <PopupTitle>
                  {t('digital menu')}
                </PopupTitle>
                <PopupSubTitle>
                  {t('create and share')}
                </PopupSubTitle>
                <PopupInfos>
                  <PriceContainer>
                    <div style={{
                      fontSize: '17pt', color: '#6F4FB3', marginTop: '15px', marginLeft: '5px',
                      }}
                    >
                      €
                    </div>
                    <FlexRow>
                      <div style={{ fontSize: '45pt', color: '#6F4FB3', fontWeight: 'bold' }}>
                        29
                      </div>
                      <div style={{ marginTop: '-10px', fontWeight: 300 }}>
                        {t('without tax / months')}
                      </div>
                    </FlexRow>
                  </PriceContainer>
                  <OfferContainer>
                    <PopupListHalf>
                      <MBottom>
                        <CustomCheck />
                        {t('create your menu')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('load pdf')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('customize')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('hotline')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('1 qr code')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('fast update')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('multi languages')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('menus hours')}
                      </MBottom>
                    </PopupListHalf>
                  </OfferContainer>
                </PopupInfos>
              </PopupContent>
            </div>
            <div style={{ textAlign: 'center', zIndex: 3 }}>
              <CustomButton
                onClick={() => {
                  history.push('/dashboard/create-form')
                  history.go(0) 
                  // GoolgeAutocomptete marche pas sans reload pour l'instant
                }}>
                {t('create-place')}
              </CustomButton>
            </div>
          </StyledCard>
        </Col>
        <Col lg="6">
          <StyledCard bsPrefix="card white-card">
            <CustomImg src={CreatePlaceShowPic} alt="logo" />
            <div style={{ zIndex: 2, width: '90%' }}>
              <PopupContent>
                <Flex>
                  <PopupHalfTitle>
                    {t('digital menu')}&nbsp;
                  </PopupHalfTitle>
                  <PopupHalfTitle style={{ color: '#E76B56' }}>
                    &nbsp;{t('showcase')}
                  </PopupHalfTitle>
                </Flex>
                <div style={{ textAlign: 'center' }}>
                  <PopupSubTitle>
                    {t('create and share2')}
                  </PopupSubTitle>
                </div>
                <PopupInfos>
                  <PriceContainer>
                    <div style={{
                      fontSize: '17pt', color: '#E76B56', marginTop: '15px', marginLeft: '5px',
                      }}
                    >
                      €
                    </div>
                    <FlexRow>
                      <div style={{ fontSize: '45pt', color: '#E76B56', fontWeight: 'bold' }}>
                        49
                      </div>
                      <div style={{ marginTop: '-10px', fontWeight: 300 }}>
                        {t('without tax / months')}
                      </div>
                    </FlexRow>
                  </PriceContainer>
                  <OfferContainer>
                    <PopupListHalf>
                      <MBottom>
                        <CustomCheck />
                        {t('create your menu')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('load pdf')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('customize')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('hotline')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('1 qr code')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('fast update')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('multi languages')}
                      </MBottom>
                      <MBottom>
                        <CustomCheck />
                        {t('menus hours')}
                      </MBottom>
                    </PopupListHalf>
                  </OfferContainer>
                </PopupInfos>
              </PopupContent>
            </div>
            <div style={{ textAlign: 'center', zIndex: 3 }}>
              <CustomButton 
                variant="disabled" // secondary
                onClick={() => {
                  // history.push('/dashboard/create-form')
                  // history.go(0)
                }}>
                <AvalaibleSoon>
                  {t('avalaible soon')}
                </AvalaibleSoon>
                {t('create-place')}
              </CustomButton>
            </div>
          </StyledCard>
        </Col>
      </Row>
      <br/>
    </CustomContainer>
  );
};

export default withTheme(withTranslation()(CreateAPlaceView));

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media(min-width  : 992px) {
		padding-left: 250px;
    }
`;
const AvalaibleSoon = styled.div`
  width: 160px;
  height: 25px;
  padding-top: 2px;
  margin: 13px 0px 0px 27px;
  position: absolute;
  background-color: #E76B56;
  border-radius: 5px;
  font-size: 14px;
  color: white;
`
const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
`;
const MBottom = styled.div`
  margin-bottom: 5px;
  display: flex;
`;
const StyledCard = styled(Card)`
	margin: 17px 0;
	padding: 20px;
	align-items: center;
	overflow: hidden;
`;
const CustomButton = styled(Button)`
	width: 240px;
	margin-top: -25px;
	font-size: 11pt;
	box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`;
const PriceContainer = styled.div`
	flex: 0.25;
	display: flex;
	flex-direction: row-reverse;
	@media screen and (min-width: 550px) {
		border-right-style: solid;
		border-right-width: 1px;
		border-color: lightgrey;
		padding-right: 7px;
		margin-top: 5px;
		padding-right: 5%;
		margin-left: 10px;
		height: 220px;
	} 
	@media screen and (max-width: 550px) {
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-color: lightgrey;
		align-self: center;
		padding-left: 20px;
		margin-right: 10px;
		margin-bottom: 20px;
		padding-bottom: 10px;
	} 
`;
const PopupTitle = styled.div`
	text-align: center;
	font-weight: bold;
	font-size: 15pt;
	margin: 0 25px;
	margin-bottom: 15px;
`;
const PopupSubTitle = styled.div`
	text-align: center;
	font-size: 12pt;
	margin: 10px 30px 20px 30px;
`;
const PopupHalfTitle = styled.div`
	text-align: center;
	font-weight: bold;
	font-size: 15pt;
	margin-bottom: 15px;
`;
const PopupContent = styled.div`
	width: 100%;
	border-radius: 7px;
    padding: 15px;
	background-color: rgba(255, 255, 255, 0.7);
`;
const PopupList = styled.div`
    font-weight: 600;
	@media screen and (min-width: 550px) {
		padding: 5px;
	} 
`;

const PopupListHalf = styled(PopupList)`
	@media screen and (min-width: 550px) {
		margin-left: 15px;
	} 
`;

const CustomCheck = styled(Check)`
	width: 20px;
	height: 17px; 
	color: #E76B56;
	margin-right: 2px;
`;
const CustomImg = styled.img`
	position: absolute;
  left: 0;
  top: 0;
  width: 100%;
	@media screen and (max-width: 550px) {
    width: 200%;
	} 
	border-radius: 20px;
  opacity: 0.2;
`;
const PopupInfos = styled.div`
	display: flex;
	@media screen and (max-width: 550px) {
		flex-direction: column;
	} 
	@media screen and (min-width: 550px) {
    width: fit-content;
    margin: auto;
	} 
	justify-content: center;
  padding-bottom: 20px;
`;
const OfferContainer = styled.div`
	display: flex;
	flex: 1;
    margin-left: 20px;
    margin-bottom: 20px;
	@media screen and (max-width: 550px) {
		flex-direction: column;
	} 
`;
