import React from 'react'
import ReactDOM from 'react-dom'
// STYLE
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// lang
import './i18n'

import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { FuegoProvider } from '@nandorojo/swr-firestore'
import { Fuego } from './utils/firebase/Fuego'
import App from './App'
import { FirestoreDb, Analytics } from './utils/firebase/FirebaseModules'
import firebaseConfig from './utils/firebase/MyFirebase'
import { theme } from './theme'

Analytics()
const fuego = new Fuego(firebaseConfig)
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <FuegoProvider fuego={fuego}>
          <App />
        </FuegoProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
