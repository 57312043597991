import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

const menus = [
	'Menu - Restaurant',
	'Menu - Bar',
	'Menu - Boissons',
];

const code = '<iframe id=« mmi » src=« https://monmenu360.fr » style=« width:100%;border:none;height:500px; »></iframe><script>window.onmessage=(e)=>{if(e.data.hasOwnProperty(« frame Height »)){document.getElementById(« mmi »).style.height = `${e.data.frameHeight}px`;}};</script>';

const ExIntegration = ({ t }) => {
  const [menuSelected, setMenuSelected] = useState('Menu - Restaurant');

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="8">
            <Card bsPrefix="card white-card">
              <Title>Intégrer une page</Title>
              <Background>
                <SubBackgroundTitle>
                  Page à intégrer
                </SubBackgroundTitle>
                <MenuSelect
									value={menuSelected}
									onChange={(e) => setMenuSelected(e.target.value)}
                >
                  {menus.map((menu, i) => (
                    <option
												key={`menu${i}`}
												value={menu}
                    >
                      {menu}
                    </option>
										))}
                </MenuSelect>
                <CodeContainer>
                  {code}
                </CodeContainer>
                <Button
									onClick={() => { navigator.clipboard.writeText(code); }}
									style={{
										width: '95%',
										marginLeft: '5px',
										marginBottom: '20px',
										backgroundColor: '#6F4FB3',
										boxShadow: '4px -4px 20px 3px rgba(0, 0, 0, 0.08)',
									}}
                >
                  Copier le code
                </Button>
              </Background>
            </Card>
          </Col>
          <Col>
            <Card bsPrefix="card white-card">
              <Title>Aperçu de la page</Title>
              <Background />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withTheme(withTranslation()(ExIntegration));

const MenuSelect = styled.select`
	background-color: white;
	cursor: pointer;
	width: 95%;
	padding: 5px;
    font-size: 11pt;
	border-style: none;
	border-radius: 5px;
    margin-left: 6px;
    margin-right: 60px;
    margin-bottom: 15px;
	::placeholder {
		opacity: 0.5;
	}
`;
const Background = styled.div`
  background-color: #F8F7FF;
  padding-top: 12px;
  padding-left: 20px;
  border-radius: 10px;
`;
const CodeContainer = styled.div`
	background-color: white;
	width: 95%;
	padding: 10px;
	font-size: 11pt;
	border-style: none;
	border-radius: 5px;
	margin-left: 6px;
	margin-right: 60px;
    margin-bottom: 15px;
`;
const SubBackgroundTitle = styled.div`
	font-size: 13pt;
	font-weight: bold;
	margin-bottom: 10px;
`;
const Title = styled.p`
	font-size: 15pt;
	font-weight: bold;
	margin-top: 6px;
	margin-left: 7px;
    margin-bottom: 10px;
`;
