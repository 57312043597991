import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import {
  CloseOutlined,
  KeyboardArrowDown,
  SettingsOutlined,
  PowerSettingsNewOutlined,
  HelpOutline,
  Security,
  Person,
} from '@material-ui/icons'

import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import DisplayContainer from '../../../stores/DisplayContainer'
import AuthContainer from '../../../stores/AuthContainer'
import { Auth } from '../../../utils/firebase/FirebaseModules'
import LogoLongLight from '../../../assets/logo/logo_long_light.svg'

const LeftMenu = ({ setMenuItems, items, t, isAdmin }) => {
  const { dashboardMenuShow, setDashboardMenuShow } = DisplayContainer.useContainer()
  const { userClaims } = AuthContainer.useContainer()
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()

  const showChild = (item) => {
    const _items = [...items]
    let index
    items.forEach((curr, i) => {
      if (curr.id === item.id) {
        index = i
      }
    })
    _items[index].showChild = !_items[index].showChild
    setMenuItems(_items)
  }

  const handleClick = (item) => {
    const _items = [...items]
    items.forEach((curr, i) => {
      if (item.id === curr.id && item.childs) {
        _items[i].showChild = true
      } else {
        _items[i].showChild = false
      }
    })
    setMenuItems(_items)
  }

  return (
    <>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>
            {t('disconnect')}
          </ModalTitle>
          <ModalSubTitle>
            {t('confirm_disconnect')}
          </ModalSubTitle>
          <Flex>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => {
                await Auth().signOut()
                history.push('/login')
              }}
            >
              {t('confirm')}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
      <LeftMenuStyled show={dashboardMenuShow}>
        <LeftMenuStyledNav admin={isAdmin} show={dashboardMenuShow}>
          <HeaderStyled>
            <CloseMenu onClick={() => setDashboardMenuShow(false)} />
            <img src={LogoLongLight} alt="logo" style={{ clear: 'both' }} />
          </HeaderStyled>
          <MenuItems>
            {items.map((item, i) =>
              item.childs.length > 0 ? (
                <MenuItem key={`menu_item${i}`} show={item.showChild}>
                  <NavLink
                    style={{flexBasis: 'none'}}
                    onClick={() => {
                      handleClick(item)
                      setDashboardMenuShow(false)
                    }}
                    exact={!item.childs}
                    to={item.path}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </NavLink>
                  {item.childs && (
                    <>
                      <ToggleChild onClick={() => showChild(item)} />
                      <ChildList show={item.showChild}>
                        {item.childs.map((itemChild, i) => (
                          <li key={`itemChild${i}`}>
                            <NavLink
                              to={itemChild.path}
                              onClick={() => setDashboardMenuShow(false)}
                            >
                              {itemChild.label}
                            </NavLink>
                          </li>
                        ))}
                      </ChildList>
                    </>
                  )}
                </MenuItem>
              ) : (
                <MenuItem key={`menu_item${i}`} show={item.showChild}>
                  <NavLink
                    onClick={() => {
                      handleClick(item)
                      setDashboardMenuShow(false)
                    }}
                    exact={!item.childs}
                    to={item.path}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </NavLink>
                </MenuItem>
              )
            )}
          </MenuItems>
          <BottomMenu>
            {userClaims?.admin && (
              <BottomItem>
                <NavLink
                  to={isAdmin ? '/dashboard' : '/admin'}
                  onClick={() => setDashboardMenuShow(false)}
                >
                  {isAdmin ? <Person /> : <Security />}
                  {isAdmin ? t('dashboard_user') : t('dashboard_admin')}
                </NavLink>
              </BottomItem>
            )}
            {!isAdmin && 
              <BottomItem>
                <NavLink
                  to="/dashboard/help"
                  onClick={() => setDashboardMenuShow(false)}
                >
                  <HelpOutline />
                  {t('help_')}
                </NavLink>
              </BottomItem>
            }
            {!isAdmin && 
              <BottomItem>
                <NavLink
                  to="/dashboard/profile"
                  onClick={() => setDashboardMenuShow(false)}
                >
                  <SettingsOutlined />
                  {t('settings')}
                </NavLink>
              </BottomItem>
            }
            <DisconnectItem onClick={() => setIsOpen(true)}>
              <PowerSettingsNewOutlined />
              {t('disconnect')}
            </DisconnectItem>
          </BottomMenu>
        </LeftMenuStyledNav>
      </LeftMenuStyled>
    </>
  )
}

export default withTranslation()(withTheme(LeftMenu))

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const CloseMenu = styled(CloseOutlined)`
  @media (min-width: 991px) {
    display: none !important;
  }
  float: right;
  position: relative;
  right: 15px;
  font-size: 36px;
  color: white;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.secondary};
  }
`
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`
const ChildList = styled.ul`
  display: ${(props) => (props.show ? 'block' : 'none')};
  transform-origin: top;
  background: white;
  border-radius: 0px 0px 0px 12px;
  padding: 15px 0px 15px 30px;
  color: ${(props) => props.theme.primary};
  top: -9px;
  position: relative;
  li {
    list-style: none;
    a {
      &.active {
        font-weight: bold;
      }
      line-height: initial;
      height: initial;
      padding: 5px;
    }
  }
`
const ToggleChild = styled(KeyboardArrowDown)`
  position: absolute;
  top: 10px;
  right: 12px;
  color: ${(props) => props.theme.grey_3};
  transition: ease 300ms;
  &:hover {
    color: ${(props) => props.theme.secondary};
    cursor: pointer;
    top: 12px;
  }
`
const MenuItem = styled.li`
  width: 100%;
  .accordion {
    button {
      border: none;
      background: none;
    }
  }
  position: relative;
  margin-bottom: 8px;
  list-style: none;
  a {
    transition: ease 400ms;
    color: ${(props) => (props.show ? (props) => props.theme.primary : 'white')};
    background: ${(props) => (props.show ? 'white' : 'transparent')};
    text-decoration: none;
    display: block;
    height: 41px;
    border-radius: 12px 0px 0px 12px;
    line-height: 40px;
    padding: 0px 50px 0px 20px;
    svg {
      margin-right: 9px;
      color: ${(props) => (props.show ? (props) => props.theme.secondary : 'white')};
    }
    &:hover {
      background: white;
      color: ${(props) => props.theme.primary};
      svg {
        color: ${(props) => props.theme.secondary};
      }
    }
    &.active {
      background: white;
      display: block;
      color: ${(props) => props.theme.primary};
      svg {
        color: ${(props) => props.theme.secondary};
      }
    }
  }
`
const BottomItem = styled.li`
  .accordion {
    button {
      border: none;
      background: none;
    }
  }
  position: relative;
  margin-bottom: 8px;
  list-style: none;
  a {
    transition: ease 400ms;
    color: ${(props) => (props.show ? (props) => props.theme.primary : 'white')};
    background: ${(props) => (props.show ? 'white' : 'transparent')};
    text-decoration: none;
    display: block;
    @media (min-width: 991px) {
      width: 210px;
    }
    height: 41px;
    border-radius: 12px 0px 0px 12px;
    line-height: 40px;
    padding: 0px 0px 0px 20px;
    svg {
      margin-right: 9px;
      color: ${(props) => (props.show ? (props) => props.theme.secondary : 'white')};
    }
    &:hover {
      background: white;
      color: ${(props) => props.theme.primary};
      svg {
        color: ${(props) => props.theme.secondary};
      }
    }
    &.active {
      background: white;
      display: block;
      color: ${(props) => props.theme.primary};
      svg {
        color: ${(props) => props.theme.secondary};
      }
    }
  }
`

const DisconnectItem = styled.li`
  cursor: pointer;
  position: relative;
  margin-bottom: 8px;
  list-style: none;
  transition: ease 400ms;
  color: ${(props) => (props.show ? (props) => props.theme.primary : 'white')};
  background: ${(props) => (props.show ? 'white' : 'transparent')};
  text-decoration: none;
  display: block;
  height: 41px;
  border-radius: 12px 0px 0px 12px;
  line-height: 40px;
  padding: 0px 0px 0px 20px;
  svg {
    margin-right: 9px;
    color: ${(props) => (props.show ? (props) => props.theme.secondary : 'white')};
  }
  &:hover {
    background: ${(props) => props.theme.secondary};
    color: white;
    svg {
      color: white;
    }
  }
  &.active {
    background: white;
    display: block;
    color: ${(props) => props.theme.primary};
    svg {
      color: ${(props) => props.theme.secondary};
    }
  }
`
const MenuItems = styled.ul`
  margin: 0;
  padding: 0px;
  margin-left: 10px;
  overflow: auto;
  min-width: 210px;
  max-width: 210px;
`

const BottomMenu = styled.ul`
  @media (max-width: 991px) {
    width: 100%;
  }
  bottom: 20px;
  position: fixed;
  margin: 0;
  padding: 0px;
  margin-left: 10px;
  overflow: auto;
`

const LeftMenuStyled = styled.div`
  z-index: 20;
  position: relative;
  @media (max-width: 991px) {
    position: fixed;
    height: 100vh;
    overflow: auto;
    width: 100vw;
    transition: ease 400ms;
    transform: translateX(${(props) => (props.show ? '0' : '-100%')});
  }
`
const HeaderStyled = styled.header`
  text-align: center;
  img {
    width: 113px;
  }
  padding: 19px 0 19px 0;
`
const LeftMenuStyledNav = styled.nav`
  padding: 0px 0px 20px 8px;
  min-height: 100vh;
  background: ${(props) =>
    props.admin
      ? 'linear-gradient(252deg, #BA00FE 23%, #223FF6 100%)'
      : 'linear-gradient(0deg, #6f4fb3 10%, #879ae6 100%, #879ae6 81%)'};
  box-shadow: 4px -4px 20px 3px #00000045;
  @media (max-width: 991px) {
    height: 100vh;
    overflow: auto;
    width: 100vw;
  }
  @media (min-width: 991px) {
    border-radius: 0px 20px 20px 0;
  }
`
