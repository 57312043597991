import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Logo from '../../../assets/logo/logo.svg';

const LoginHeader = ({ title, subtitle }) => (
  <LoginHeaderStyled>

    <LogoStyled src={Logo} />

    <h1>{title}</h1>
    <h3>{subtitle}</h3>

  </LoginHeaderStyled>
  );

export default withTheme(LoginHeader);

LoginHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

LoginHeader.defaultProps = {
  title: 'Title',
  subtitle: 'Subtitle',
};

const LoginHeaderStyled = styled.div`
	margin-bottom : 45px ;  
  text-align : center ; 
	h1{
    font-weight  : bold ;

		color : ${(props) => props.theme.grey_1};
		font-size : ${(props) => props.theme.title}; 
	}
	h3{
      color : ${(props) => props.theme.grey_3};
      font-size : ${(props) => props.theme.text}; 
	}

`;

const LogoStyled = styled.img`

  width : 120px ; 
  margin: 25px 0px 64px 10px ;

`;
