import React from 'react';
import { createContainer } from 'unstated-next';
// eslint-disable-next-line
import  _,{findIndex} from 'underscore' ; 

const UseCreateAQrCodeStore = (props) => {
const [activeStep, setActiveStep] = React.useState(0);
const [placeInformations, setPlaceInformations] = React.useState({});
const [cart, setCart] = React.useState([]);
const [paymentIntent, setPaymentIntent] = React.useState(null);
const [createdPlace, setCreatedPlace] = React.useState({});

const cleanCart = () => setCart([]);
const addProduct = (product, type = 'unique') => {
	if (type === 'multiple') {
 	 setCart([...cart, product]);
	} else if (type === 'unique') {
 	 const _cart = [...cart];
 	 const _i = _.findIndex(_cart, { id: product.id });
 	 if (_i > -1) {
 	 _cart[_i] = { ...product };
 	 setCart(_cart);
 	} else {
 		setCart([...cart, product]);
 	}
	} else {
		console.log('Unknow product type');
	}
};
const removeProduct = (product, all = false) => {
const _cart = [...cart];
let productEntries = [];

if (all === false) {
  productEntries = _cart.filter((p) => p.id === product.id);
  productEntries.shift();

  productEntries = [...productEntries, ..._cart.filter((p) => p.id !== product.id)];
} else {
   productEntries = _cart.filter((p) => p.id !== product.id);
}

 setCart(productEntries);
};

  const formatedCart = () => {
		const FormatedCart = [];
		const prevcart = [...cart];

		 prevcart.reduce((acc, curr) => {
		if (!acc[curr.id]) {
   	 	acc[curr.id] = { ...curr, qty: 1 };

   			 FormatedCart.push(acc[curr.id]);
  			} else {
   			 acc[curr.id].qty++;
 			 }

 			 return acc;
			}, {});

		return FormatedCart;
  };

  return {
  	activeStep,
  	setActiveStep,
    cleanCart,
  	placeInformations,
    setPlaceInformations,
    addProduct,
    cart,
    formatedCart,
    removeProduct,
    paymentIntent,
    setPaymentIntent,
    createdPlace,
    setCreatedPlace,
  };
};

export default createContainer(UseCreateAQrCodeStore);
