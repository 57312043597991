// Container to handle elements states like globally , like menus , siders ...etcs
import React from 'react';
import { createContainer } from 'unstated-next';

const DisplayContainer = (props) => {
const [dashboardMenuShow, setDashboardMenuShow] = React.useState(false);

  return {

  	dashboardMenuShow,
  	setDashboardMenuShow,

  };
};

export default createContainer(DisplayContainer);
