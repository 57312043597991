import React, { useState } from 'react'
import { createContainer } from 'unstated-next'
import { withTranslation } from 'react-i18next'

const queryString = require('query-string')

const UseDashboardClientStore = () => {
  const [itemsPlace, setItemPlace] = useState([
    {
      id: 'place',
      path: '/dashboard/edit/infos',
      icon: 'info',
      label: 'place',
    },
    {
      id: 'menu',
      path: '/dashboard/edit/menus',
      icon: 'menu',
      label: 'menu',
    },
    /* {
    id : "reminder-book",
    path:"/dashboard/edit/reminder_book",
    icon  : 'reminder_book',
    label :'reminder_book' ,
  },{
    id : "news",
    path:"/dashboard/edit/news",
    icon  : 'news',
    label :'news' ,
  }, */ {
      id: 'help',
      path: '/dashboard/edit/help',
      icon: 'help',
      label: 'help',
    },
  ])

  const setPlaceItemsId = (id) => {
    setItemPlace(itemsPlace.map((item) => ({ ...item, place_id: `${id}` })))
  }

  return {
    itemsPlace,
    setPlaceItemsId,
    setItemPlace,
  }
}

export default createContainer(UseDashboardClientStore)
