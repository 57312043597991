import React from 'react'
import DropContext from './DropContext'
import GroupDraggable from './GroupDraggable'
import { Droppable } from 'react-beautiful-dnd'
import { formatProductDatabaseOrder } from '../../../../../utils/MenuHelpers'
import styled from 'styled-components'

const ProductsDnD = ({
	onDragEnd,
	category,
	category_index,
	searchInput,
	currentLang,
	functions,
	register,
}) => (
		<DropContext onDragEnd={onDragEnd}>
			<Droppable
				droppableId={`ITEM-${category_index}`}
				type={`ITEM-${category_index}`}
			>
				{(provided, snapshot) => (
					<div ref={provided.innerRef}>
						{formatProductDatabaseOrder(category).map((item, index) => (
							<>
								<GroupDraggable
									group={item}
									key={item.id}
									category_index={category_index}
									unclassed={item.id === 'UNCLASSED'}
									index={index}
									currentLang={currentLang}
									functions={functions}
									searchInput={searchInput}
									register={register}
								/>
								<br/>
							</>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DropContext>
	)

export default ProductsDnD
