import React, { useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import withAccountVerification from '../../hoc/withAccountVerification'
import { useUserSwitch } from '../../hooks/Admin'

import AuthContainer from '../../stores/AuthContainer'
import DashboardLayout from '../../layouts/DashboardLayout'
import DashboardMenuItems from './DashboardMenuItems'
import LeftMenu from '../../components/Dashboard/LeftMenu/LeftMenu'
import EditLeftMenu from '../../components/Dashboard/LeftMenu/EditLeftMenu'
import TopMenu from '../../components/Dashboard/TopMenu/TopMenu'
import DashboardIndex from './DashboardIndex/DashboardIndex'
import DashboardProfile from './DashboardProfile/DashboardProfile'
import DashboardHelp from './DashboardHelp/DashboardHelp'
import DashboardSettings from './DashboardSettings/DashboardSettings'
import CreateAPlaceView from './CreateAPlace/CreateAPlaceView'
import CreateAPlaceForm from './CreateAPlace/CreateAPlaceContainer'
import CreateAQrCodeContainer from './CreateAQrCode/CreateAQrCodeContainer'
import EditHelp from './EditHelp/EditHelp'
import EditMenus from './EditMenus/EditMenus'
import CreateMenu from './CreateMenu/CreateMenu'
import CreateFormula from './CreateFormula/CreateFormula'
import EditMenu from './EditMenu/EditMenu'
import EditFormula from './EditFormula/EditFormula'
import CreateProduct from './CreateProduct/CreateProduct'
import EditAPlace from './EditAPlace/EditAPlace'
import EditQrcode from './EditQrcode/EditQrcode'
import Places from './Places/Places'
import Subscription from './Subscription/Subscription'
import Subscriptions from './Subscriptions/Subscriptions'
import Invoices from './Invoices/Invoices'
import UseDashboardClientStore from './DashboardClientStore'
import PlaceContainer from '../../stores/PlaceContainer'
import Page404 from '../Page404/Page404'

const queryString = require('query-string')

const DashboardClient = ({ t, i18n }) => {
  const [title, setTitle] = React.useState('title')
  const AuthState = AuthContainer.useContainer()
  const { BannerSwichUser } = useUserSwitch()
  const { items } = DashboardMenuItems()
  const { itemsPlace, setItemPlace, setPlaceItemsId } =
    UseDashboardClientStore.useContainer()
  const [menuItems, setMenuItems] = React.useState(items)
  const history = useHistory()
  const location = useLocation()
  const { place_id } = queryString.parse(location.search)

  useEffect(() => {
    if (place_id) setPlaceItemsId(place_id)
  }, [place_id])

  useEffect(() => {
    if (i18n.language) setMenuItems(items)
  }, [i18n.language])

  return (
    <>
      {!AuthState.user ? (
        <>
          <Alert>
            {t('Need to be connected')}
            <br />
            <Button onClick={() => history.push('/login')} variant="primary">
              {t('login')}
            </Button>
          </Alert>
        </>
      ) : (
        <DashboardLayout>
          {history.location.pathname.split('/')[2] !== 'place' &&
          history.location.pathname.split('/')[4] !== 'product' ? (
            <TopMenu title={title} />
          ) : null}
          <Row style={{ placeContent: 'center' }}>
            <ColStyledLeft lg="auto">
              {history.location.pathname.split('/')[2] !== 'place' &&
              history.location.pathname.split('/')[4] !== 'product' ? (
                history.location.pathname.split('/')[2] === 'edit' ? (
                  <EditLeftMenu items={itemsPlace} setMenuItems={setItemPlace} />
                ) : (
                  <LeftMenu isAdmin={false} items={menuItems} setMenuItems={setMenuItems} />
                )
              ) : null}
            </ColStyledLeft>
            <ColStyledRight path={history.location.pathname}>
              <Switch>
                <Route exact path="/dashboard">
                  <DashboardIndex setTitle={setTitle} />
                </Route>
                <Route exact path="/dashboard/profile">
                  <DashboardProfile setTitle={setTitle} />
                </Route>
                <Route exact path="/dashboard/help">
                  <DashboardHelp setTitle={setTitle} />
                </Route>
                <Route exact path="/dashboard/settings">
                  <DashboardSettings setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/create-place">
                  <CreateAPlaceView setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/create-form">
                  <CreateAPlaceForm setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/create-qrcode/:place_id">
                  <CreateAQrCodeContainer setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/places">
                  <Places setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/edit/infos">
                  <PlaceContainer.Provider>
                    <EditAPlace setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/menus">
                  <PlaceContainer.Provider>
                    <EditMenus setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route exact path="/dashboard/edit/menu" modePDF={false}>
                  <PlaceContainer.Provider>
                    <EditMenu setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/menu/pdf">
                  <PlaceContainer.Provider>
                    <EditMenu setTitle={setTitle} modePDF />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/help">
                  <EditHelp setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/edit/new/menu/">
                  <PlaceContainer.Provider>
                    <CreateMenu setTitle={setTitle} modePDF={false} />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/new/pdf">
                  <PlaceContainer.Provider>
                    <CreateMenu setTitle={setTitle} modePDF />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/new/product">
                  <PlaceContainer.Provider>
                    <CreateProduct setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/edit/new/formula/">
                  <PlaceContainer.Provider>
                    <CreateFormula setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route exact path="/dashboard/edit/formula" modePDF={false}>
                  <PlaceContainer.Provider>
                    <EditFormula setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                <Route path="/dashboard/invoices">
                  <Invoices setTitle={setTitle} />
                </Route>
                <Route exact path="/dashboard/subscriptions">
                  <Subscriptions setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/subscriptions/:placeId">
                  <Subscription setTitle={setTitle} />
                </Route>
                <Route path="/dashboard/edit/qrcode/:place_id?">
                  <PlaceContainer.Provider>
                    <EditQrcode setTitle={setTitle} />
                  </PlaceContainer.Provider>
                </Route>
                {/* <Route path="/dashboard/place-creation-succeeded"   >
                <CreationSucceeded/>
              </Route> */}
                <Route>
                  <Page404 mode />
                </Route>
              </Switch>
            </ColStyledRight>
          </Row>
          {BannerSwichUser()}
        </DashboardLayout>
      )}
    </>
  )
}

export default withTranslation()(withAccountVerification(DashboardClient))

const ColStyledLeft = styled(Col)`
  z-index: 999;
  position: fixed;
  left: 0;
  margin-left: -20px;
`

const ColStyledRight = styled(Col)`
  margin: 0 auto;
`
