import React from 'react';
import styled from 'styled-components';

export const DefaultSeparator = (props) => (<DefaultSeparatorStyled><div>{props.children}</div></DefaultSeparatorStyled>);

const DefaultSeparatorStyled = styled.div`


    text-align : center; 
    div{
    	background : white ; 
    	display : inline-block ; 
    	position: relative  ;  
    	padding : 0px 20px ; 
    	top : 14px ; 
    }
    border-bottom : 1px solid #eee; 
     
    margin-bottom : 40px ; 


`;
