import styled from 'styled-components';
import { Tune, Block } from '@material-ui/icons'
import RestoreIcon from '@material-ui/icons/Restore';
import { useState } from 'react';

let Allergene = [
  { icon: <Block />, name: 'Arachide' },
  { icon: <Block />, name: 'Céleri' },
  { icon: <Block />, name: 'Crustacé' },
  { icon: <Block />, name: 'Fruit à coque' },
  { icon: <Block />, name: 'Gluten' },
  { icon: <Block />, name: 'Lactose' },
  { icon: <Block />, name: 'Lupin' },
  { icon: <Block />, name: 'Molusque' },
  { icon: <Block />, name: 'Moutarde' },
  { icon: <Block />, name: 'Oeuf' },
  { icon: <Block />, name: 'Poisson' },
  { icon: <Block />, name: 'Sésame' },
  { icon: <Block />, name: 'Soja' },
  { icon: <Block />, name: 'Sulfite' },
]

let Labels = [
  { name: 'Bio' },
  { name: 'Végan' },
  { name: 'Fait maison' },
  { name: 'Halal' },
  { name: 'Casher' },
  { name: 'Produit frais' },
  { name: 'Circuit court' },
  { name: 'Végétarien' },
  { name: 'Végétalien' },
  { name: 'Bio-dynamie' },
]

const hexToRGB = (hex, alpha) => {
  if (!hex) {
    if (alpha) {
      return `rgba(111, 79, 179, ${alpha})`
    } else {
      return 'rgba(111, 79, 179)'
    }
  }
  var r = parseInt(hex.slice(1, 3), 16);
  var g = parseInt(hex.slice(3, 5), 16);
  var b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
      return `rgb(${r}, ${g}, ${b})`;
  }
}

const Filter = ({ 
  alergenesFilter,
  labelsFilter, 
  setAlergenesFilter, 
  setLabelsFilter,
  datas 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleAFilter = (array) => {
        if (alergenesFilter.includes(array.name)) {
          setAlergenesFilter(alergenesFilter.filter((item) => item !== array.name));
        } else {
          setAlergenesFilter((oldArray) => [...oldArray, array.name]);
        }
    };
    const handleLFilter = (array) => {
        if (labelsFilter.includes(array.name)) {
          setLabelsFilter(labelsFilter.filter((item) => item !== array.name));
        } else {
          setLabelsFilter((oldArray) => [...oldArray, array.name]);
        }
    };
    return (
      <div>
        <FilterModalBackground onClick={() => setIsOpen(false)} style={{ transform: isOpen ? 'scale(1, 1)' : 'scale(0,0)' }} />
        <div style={{ position: 'relative' }}>

          <FilterHandler onClick={() => setIsOpen(true)}>
            <div style={{ color: datas.color }}>
              <Tune style={{ marginRight: '11px' }} />
              Filtrer
            </div>
            <FilterCount color={datas.color}>
              {alergenesFilter.length + labelsFilter.length}
            </FilterCount>
          </FilterHandler>
          <FilterModal style={{ transform: isOpen ? 'scale(1, 1) translate(-50%, 0px)' : '' }}>
            <FilterModalHeadLine onClick={() => { }}>
              <FilterModalHeadLineClose 
                color={datas.color}
                onClick={() => setIsOpen(false)}>
                <div style={{ marginRight: '14px' }}>X</div>
                {' '}
                Fermer
              </FilterModalHeadLineClose>
              <FilterModalHeadLineReset 
                onClick={() => {
                    setAlergenesFilter([])
                    setLabelsFilter([])
                  }}>
                Rétablir
                {' '}
                <RestoreIcon style={{ color: '#E76B56', fontSize: '14px', marginLeft: '6px' }} />
              </FilterModalHeadLineReset>
            </FilterModalHeadLine>
            <FilterModalSection color={datas.color}>
              <FilterModalSectionTitle>
                Allergènes
              </FilterModalSectionTitle>
              <FilterModalSectionSubtitle>
                Sélectionnez des aliments sans...
              </FilterModalSectionSubtitle>
              <FilterModaleBadgeHandler color={datas.color}>
                {Allergene.map((allergene, i) => (
                  <FilterModaleBadgeItem 
                    color={datas.color}
                    key={i} 
                    onClick={() => handleAFilter(allergene)} 
                    isActive={alergenesFilter.includes(allergene.name)}
                  >
                    {allergene.icon}
                    {' '}
                    <div>{allergene.name}</div>
                  </FilterModaleBadgeItem>
                ))}
              </FilterModaleBadgeHandler>
            </FilterModalSection>
            <FilterModalSection color={datas.color}>
              <FilterModalSectionTitle>
                Alimentation
              </FilterModalSectionTitle>
              <FilterModalSectionSubtitle>
                Ne sélectionnez que des aliments...
              </FilterModalSectionSubtitle>
              <FilterModaleBadgeHandler color={datas.color}>
                {Labels.map((label, i) => 
                  <FilterModaleBadgeItem 
                    color={datas.color}
                    key={i} 
                    onClick={() => handleLFilter(label)} 
                    isActive={labelsFilter.includes(label.name)}
                  >
                    {label.name}
                  </FilterModaleBadgeItem>
                )}
              </FilterModaleBadgeHandler>
            </FilterModalSection>
          </FilterModal>
        </div>
      </div>
    );
};

export default Filter;
const FilterModalBackground = styled.button`
transition: transform .2s ease-in-out;
left: -10px;
top: -10px;
position: fixed;
width: 120vw;
background-color: #1D1D1D80;
opacity: .5s;
height: 120vh;
z-index: 2000;
`;
const FilterModaleBadgeItem = styled.button`
> svg {
    font-size: 12px;
    margin-right: 7px;
}
display: flex;
align-items: center;
border: none;
background-color: ${(props) => (props.isActive ? props.color : 'white')};
color: ${(props) => (props.isActive ? 'white' : '#969696')};
font: normal normal normal 15px Lato;
padding: 7px 15px 9px 14px;
border-radius: 50px;
margin-right: 5px;
margin-bottom: 12px;
&:hover {
    cursor: pointer;
}
&:last-child {
    margin-right: 0px;
}
`;

const FilterModaleBadgeHandler = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

const FilterModalSectionTitle = styled.div`
font: normal normal bold 20px Lato;
color: #1D1D1D;
margin-bottom: 5px;
text-align: center;
`;
const FilterModalSectionSubtitle = styled.div`
font: normal normal 13px Lato;
color: rgba(0, 0, 0, 0.5);
margin-bottom: 20px;
text-align: center;
`;

const FilterModalSection = styled.div`
&:last-child {
    margin-bottom: 0px;
}
padding: 9px 30px 7px 30px;
@media (max-width: 390px) {
    padding: 9px 15px 7px 15px;
}
background-color: ${(props) => hexToRGB(props.color, 0.1)};
margin-bottom: 19px;
border-radius: 5px;
`;

const FilterModalHeadLineClose = styled.div`
color: ${(props) => props.color};
display:flex;
justify-content: center;
align-items: center;
&:hover {
    cursor: pointer;
}
`;

const FilterModalHeadLine = styled.div`
position: relative;
display:flex;
justify-content: center;
align-items: center;
margin-bottom: 19px;
`;

const FilterModalHeadLineReset = styled.div`
&:hover {
    cursor: pointer;
}
position: absolute;
right: 0px;
display: flex;
align-items: center;
color: #E76B56;
font: normal normal normal 12px Lato;
`;

const FilterModal = styled.div`
transform: scale(0, 0) translate3d(-50%, 0px, 0px);
transform-origin: left;
transition: transform .2s ease-in-out;
z-index: 2001;
overflow: hidden;
top: 0;
position: absolute;
width: 768px;
@media (max-width: 768px) {
    width: 100vw;
}
left: 50%;
background-color: white;
padding: 6px 15px 23px 15px;
`;

const FilterCount = styled.div`
color: white;
background-color: ${(props) => props.color};
border-radius: 100%;
width: 23px;
height: 23px;
margin-left: 12px;
display: flex;
justify-content: center;
align-items: center;
font-size: 13px;
`;

const FilterHandler = styled.div`
position: relative;
&:hover {
    cursor: pointer;
}
font-size: 16px;
display: flex;
align-items: center;
justify-content: space-between;
background-color: white;
border-radius: 20px;
padding: 5px 5px 5px 20px;
`;
