import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useForm } from 'react-hook-form'
import { useDocument } from '@nandorojo/swr-firestore'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../stores/AuthContainer'
import Navbar from '../../../components/UI/navbar/Navbar'
import { Menu, Display, Theme } from './view'
import { useStorageTools } from '../../../hooks/FireTools'
import { formatFromDb } from '../../../utils/DateHelpers'
import PlaceContainer from '../../../stores/PlaceContainer'

import {
  updateMultilangArray,
  createMultilangArray,
} from '../../../utils/MultilangHelpers'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const navItems = ['menu', 'display', 'theme']

const CreateMenu = ({ t, setTitle, modePDF, newMenu }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState('menu')
  const location = useLocation()
  const { place: placeDatas } = PlaceContainer.useContainer()
  const { place_id } = queryString.parse(location.search)
  const history = useHistory()
  var menuRef = []
  
  if (placeDatas?.id) {
  menuRef = FirestoreDb()
    .collection(placeDatas?.id ? `places/${place_id}/menus` : null)
    .doc() 
  } else {
    history.push('/404')
  }

  const {
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
    unregister,
    watch,
    formState: { errors },
    control,
  } = useForm()
  const { user } = AuthContainer.useContainer()
  const { firebaseUploader } = useStorageTools()
  const [saving, setSaving] = useState(false)
  let currentLang = placeDatas?.main_language || 'FR'

  useEffect(() => {
    if (placeDatas) {
      setTitle(`${placeDatas.place_name} > <b>${t('menus')}</b>`)
    }
  }, [t, setTitle, placeDatas])

  const formatHoursFromDb = (hours = []) => {
    const _hours = [...hours]
    hours.forEach((_h, i) => {
      _hours[i].displayHours = formatFromDb(_h.displayHours)
      _hours[i].undisplayHours = formatFromDb(_h.undisplayHours)
    })
    return _hours
  }

  const handleSubmitForm = async (datas) => {
    setSaving(true)
    const commonDatas = {
      uid: user.uid,
      created_at: new Date(),
      place_id: placeDatas.id,
    }

    let pdf = {}

    if (datas.pdf_file && datas.pdf_file.size > 0) {
      try {
        let pdfUploaded = await firebaseUploader(
          [datas.pdf_file],
          `users/${user.uid}/menus/${menuRef.id}`,
          [`menu_pdf_${currentLang}`]
        )
        pdf.pdf_url_ml = updateMultilangArray(pdfUploaded[0].url, [], currentLang)
        pdf.pdf_file_size_ml = updateMultilangArray(
          datas.pdf_file.size,
          [],
          currentLang
        )
        pdf.pdf_file_name_ml = updateMultilangArray(
          datas.pdf_file.name,
          [],
          currentLang
        )
      } catch (e) {
        enqueueSnackbar(t('error_saving_pdf'), { variant: 'error' })
      }
    }
    let background_image_url = null
    if (datas.background_image_file && datas.background_image_file.size > 0) {
      try {
        let bgImageUploaded = await firebaseUploader(
          [datas.background_image_file],
          `users/${user.uid}/menus/${menuRef.id}`,
          ['background_image']
        )
        background_image_url = bgImageUploaded[0].url
      } catch (e) {
        enqueueSnackbar(t('error_saving_image'), { variant: 'error' })
      }
    }

    const simpleDatas = {
      ...commonDatas,
      ...pdf,
      menu_display_hours: formatHoursFromDb(datas.menu_display_hours),
      background_image_url,
      name_ml: createMultilangArray(
        [placeDatas.main_language, ...placeDatas.languages_array],
        currentLang,
        datas.name
      ),
      description_ml: createMultilangArray(
        [placeDatas.main_language, ...placeDatas.languages_array],
        currentLang,
        datas.description
      ),
      footer_ml: createMultilangArray(
        [placeDatas.main_language, ...placeDatas.languages_array],
        currentLang,
        datas.footer
      ),
      order: 1000000,
      type: modePDF ? 1 : 0,
      menu_theme: datas.menu_theme || 'light',
      menu_specific_theme: datas.menu_specific_theme || false,
      theme_color: datas.theme_color || '#0F0F0F',
      theme_darkmode: datas.theme_darkmode || false,
      theme_font: datas.theme_font || 'Arial',
    }
    try {
      await menuRef.set(simpleDatas, { merge: true })
    } catch (e) {
      enqueueSnackbar(t('error_saving_data_menu'), { variant: 'error' })
      return
    }

    enqueueSnackbar(t('menu_created_success'), { variant: 'success' })
    setSaving(false)
    history.push(
      `/dashboard/edit/menu${modePDF ? '/pdf' : ''}?place_id=${
        placeDatas.id
      }&menu_id=${menuRef.id}`
    )
  }

  const displayView = () => {
    const _props = {
      register,
      modePDF,
      setValue,
      unregister,
      formatHoursFromDb,
    }
    const _propsMenu = {
      errors,
    }
    switch (tab) {
      case 'menu':
        return <Menu {..._props} {..._propsMenu} />
      case 'display':
        return <Display {..._props} />
      case 'theme':
        return (
          <Theme
            placeDatas={placeDatas}
            register={register}
            control={control}
            watch={watch}
          />
        )
      default:
        return <Menu />
    }
  }

  return (
    <CustomContainer fluid>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div style={{ marginTop: '70px' }} />
        <Row>
          <Col lg="10">
            <Navbar
              items={!modePDF ? navItems : navItems.filter((i) => i !== 'theme')}
              setTab={setTab}
              tab={tab}
              t={t}
            />
          </Col>
          <Col lg="2" style={{ marginTop: '20px' }}>
            <SaveButton disabled={saving} type="submit" variant="secondary">
              {saving ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                t('save')
              )}
            </SaveButton>
          </Col>
        </Row>
        <ThemeProvider theme={theme}>{displayView()}</ThemeProvider>
      </form>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(CreateMenu))

const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const SaveButton = styled(Button)`
  margin-left: 5%;
  width: 85%;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
