import React from 'react';
import styled from 'styled-components';

const FullscreenNavbar = (props) => (
  <NavbarStyled>
    <NavbarContainer>
      {props.items.map((item, i) => (
        <NavbarItem key={`tab${i}`}>
          {props.tab === item
                                ? (
                                  <SelectedElement
                                    onClick={() => props.setTab(item)}
                                    className="box"
                                    style={{ color: 'black' }}
                                  >
                                    {props.t(item)}
                                    <hr />
                                  </SelectedElement>
)
                                : (
                                  <div
                                    onClick={() => props.setTab(item)}
                                    style={{ color: '#969696' }}
                                  >
                                    {props.t(item)}
                                  </div>
)}
        </NavbarItem>
                    ))}
    </NavbarContainer>
  </NavbarStyled>
    );

export default FullscreenNavbar;

const NavbarStyled = styled.div`
    background: #E6EFFF;
    padding: 9px 20px;
    height: 40px;
    color: white;
    text-align: center;
`;
const NavbarContainer = styled.div`
    display: flex;
    text-align: initial;
    justify-content: center;
`;
const NavbarItem = styled.div`
    cursor: pointer;
    margin-right: 3%;
`;
const SelectedElement = styled.div`
    hr {
        width: 40px;
        margin-top: 5px;
        margin-left: 0px;
        border-color: #E76B56;
        border-bottom: 1px solid red;
    }
    cursor: pointer;
    font-weight: bold;
    margin-right: 3%;
    padding-bottom: 4px;
    display: contents;
`;
