import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import RestaurantThemeCard from './RestaurantThemeCard';

const CategorieTheme = ({
 idSCroll, title, image, description, cardInformation, cardClicked, datas,
}) => (
  <CategorieHandler id={idSCroll}>
    <CategorieHeader style={{ height: image && '215px' }}>
      {image && <CategorieHeaderImage src={image} />}
      <CategorieHeaderTitle datas={datas} image={image}>
        {title}
      </CategorieHeaderTitle>
    </CategorieHeader>
    <CategorieDescription image={image} datas={datas}>
      {description}
    </CategorieDescription>
    <Row>
      {cardInformation.map((card, i) => (
        <Col key={i} sm="6" md="4" lg="12" style={{ paddingBottom: '15px' }}>
          <RestaurantThemeCard datas={datas} {...card} onClick={cardClicked} />
        </Col>
))}
    </Row>
  </CategorieHandler>
    );

export default CategorieTheme;

const CategorieDescription = styled.div`
margin-top: ${(props) => (props.image ? '19px' : '0px')};
color: ${(props) => (props.datas.darkmode ? 'white' : props.datas.color)};
margin-bottom: 19px;
font: ${(props) => `normal normal normal 15px ${props.datas.font}`};
width: 80%;
@media(max-width: 768px){
    width: 526px
}
@media(max-width: 526px){
    width: 100%
}
margin-left: 20px;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
`;

const CategorieHeaderTitle = styled.div`
border-radius: 5px;
background-color: ${(props) => (props.image ? 'rgba(0, 0, 0, 0.5)' : '')};
height: 100%;
display: flex;
align-items: flex-end;
padding-bottom: 20px;
position: relative;
padding-left: 19px;
padding-right: 19px;
font: ${(props) => `normal normal bold 25px ${props.datas.font}`};
color: ${(props) => (props.image ? 'white' : '#276347')};
&:after {
    content: '';
    position: absolute;
    bottom: 12px;
    left: 19px;
    width: calc(100% - 38px);
    height: 1px;
    background-color: ${(props) => (props.image ? 'white' : '#276347')};
}
`;

const CategorieHeader = styled.div`
position: relative;
`;

const CategorieHeaderImage = styled.img`
border-radius: 5px;
width: 100%;
height: 100%;
position: absolute;
object-fit: cover;
`;

const CategorieHandler = styled.div`
background-color: transparent;
width: 100%;
padding-top: 50px;
`;
