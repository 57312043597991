import React from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import Helmet from 'react-helmet'
import Banner from '../../components/Menus/Banner/Banner'
import Header from '../../components/Menus/Header/Header/Header'
import Card from '../../components/Menus/Menus/Card/Card'
import Footer from '../../components/Menus/Footer/Footer'

import { getMultilangContent } from '../../utils/MultilangHelpers'
import useMenuClientContainer from '../../stores/MenuClientContainer'

const week_day = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
]

const PlaceView = ({ t }) => {
  const { place, menus, selected_lang } = useMenuClientContainer.useContainer()
  var today = new Date();

  const inTime = (datas) => {
    var start = parseInt(datas.displayHours.split(':')[0], 10) * 60 + parseInt(datas.displayHours.split(':')[1], 10)
    var end = parseInt(datas.undisplayHours.split(':')[0], 10) * 60 + parseInt(datas.undisplayHours.split(':')[1], 10)
    var time = today.getHours() * 60 + today.getMinutes();
    if (start > end)
      return time <= start && time < end;
    else
      return time >= start && time < end;
  }

  const checkHours = (datas) => {
    if ((typeof datas.displayHours === 'string' || datas.displayHours instanceof String)
    && (typeof datas.undisplayHours === 'string' || datas.undisplayHours instanceof String)) {
      if (inTime(datas))
        return true
    }
    else 
      return false
  } 

  const checkDayHours = (menu) => {
    let res = false
    let tmp_res = false
    
    if (menu?.menu_display_hours) {
      if (Object.keys(menu?.menu_display_hours).length === 0)
        return true
      menu.menu_display_hours.map((datas, i) => {
        tmp_res =  checkHours(datas)
        if (!datas.openDays[week_day[today.getDay()]])
            tmp_res = false
        if (tmp_res)
          res = true
      })
    }
    else
      return true
    return res
  }

  const orderMenus = (datas) =>
    menus
      .sort((a, b) => a.order - b.order)
      .map(
        (menu) =>
          menu.visible && checkDayHours(menu) && (
            <Col sm="6">
              <Card
                color={datas.color || '#276347'}
                img={menu.background_image_url}
                title={getMultilangContent(menu, 'name_ml', selected_lang)}
                url={`/place/${place.place_slug}/menu/${menu.id}?lang=${selected_lang}`}
              />
            </Col>
          )
      )

  if (!place) {
    return <Spinner animation="border" size="sm" variant="dark" />
  } else {
    const datas = {
      color: place.theme_color,
      font: place.theme_font,
      darkmode: place.theme_darkmode,
      topbanner: place.theme_topbanner,
    }

    return (
      <MaxBox>
        <Helmet
          bodyAttributes={{
            style: `background-color : ${datas.darkmode ? `#292C2B` : '#E7F2EF'}`,
          }}
        />
        <Header menus={menus} place={place} place_id={place.id} datas={datas} mode />
        <Banner text="Menus" />
        <DescContainer>
          {place.place_description_ml &&
            getMultilangContent(place, 'place_description_ml', selected_lang)}
        </DescContainer>
        <Container fluid>
          <CardContainer>
            <Row>{menus && orderMenus(datas)}</Row>
          </CardContainer>
        </Container>
        <Footer place={place} datas={datas} mode={false} />
      </MaxBox>
    )
  }
}

export default withTranslation()(PlaceView)

const CardContainer = styled.div`
  padding-top: 29px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 38px;
  @media (max-width: 1139px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`
const DescContainer = styled.div`
  text-align: center;
  margin: 30px 0px 0px 0px;
  font-size: 13pt;
  font-style: italic;
  font-weight: 500;
  white-space: pre-line;
`
const MaxBox = styled.div`
  max-width: 900px;
  margin: 0 auto;
`
