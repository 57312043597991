import styled from 'styled-components';

const RestaurantFooter = ({ datas, text }) => {
  if (text)
    return (
      <div style={{ padding: '50px 15px', color: datas.darkmode ? 'white' : datas.color, backgroundColor: datas.darkmode && '#292C2B' }}>
        <Line datas={datas} style={{ marginBottom: '5px' }} />
        <Line datas={datas} />
        <p style={{
            textAlign: 'center',
            font: `normal normal normal 16px ${datas.font}`,
            marginBottom: '0px',
            padding: '17px 0',
          }}
        >
          {text}
        </p>
        <Line datas={datas} style={{ marginBottom: '5px' }} />
        <Line datas={datas} />
      </div>
    )
  else return null
}

export default RestaurantFooter;

const Line = styled.div`
width:100%;
height:1px;
background-color: ${(props) => (props.datas.darkmode ? 'white' : props.datas.color)}
`;
