import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dashboard, Group, Restaurant, Storage } from '@material-ui/icons'

const DashboardMenuItems = () => {
  const { t } = useTranslation()
  const items = [
    {
      id: 'dashboard',
      path: '/admin',
      label: t('dashboard'),
      icon: <Dashboard />,
      childs: false,
      displayChilds: false,
    },
    {
      id: 'users',
      path: '/admin/users',
      label: t('admin users'),
      icon: <Group />,
      childs: false,
      displayChilds: false,
    },
    {
      id: 'restaurants',
      path: '/admin/places',
      label: t('admin places'),
      icon: <Restaurant />,
      childs: false,
      displayChilds: false,
    },
    {
      id: 'product_base',
      path: '/admin/product_base',
      label: t('/admin/product_base'),
      icon: <Storage />,
      childs: false,
      displayChilds: false,
    },
  ]

  return { items }
}

export default DashboardMenuItems
