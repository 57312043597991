import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  OverlayTrigger,
  Container,
  Button,
  Spinner,
  Row,
  Col,
} from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Switch from '@material-ui/core/Switch'

import {
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import styled, { withTheme } from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Modal from 'react-bootstrap/Modal'
import { FirestoreDb, Functions } from '../../../../utils/firebase/FirebaseModules'
import Digitalicon from '../../../../assets/menu/digital_menu.svg'
import PDFicon from '../../../../assets/menu/pdf_menu.svg'
import Moveicon from '../../../../assets/menu/move.svg'
import { getMultilangContent } from '../../../../utils/MultilangHelpers'
import { useFirestoreApi } from '../../../../hooks/FireTools'
import LangSelector from '../../../../components/UI/form/LangSelector/LangSelector'
import PlaceContainer from '../../../../stores/PlaceContainer'

const queryString = require('query-string')

const Formulas = ({
  t,
  items,
  setItems,
  savingDatas,
  setSavingDatas,
  placeDatas,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deletedId, setDeletedId] = useState(0)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { updateDatas } = useFirestoreApi()
  const location = useLocation()
  const { place_id, lang } = queryString.parse(location.search)
  const { place } = PlaceContainer.useContainer()
  let currentLang = lang || place?.main_language || 'FR'

  const Reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const updateOrder = async (newOrder) => {
    setSavingDatas(true)

    await Promise.all(
      newOrder.map(async (item, index) => {
        await updateDatas({
          query: `places/${placeDatas.id}/formulas/${item.id}`,
          datas: { order: index },
          action: 'set',
        })
      })
    )

    setItems(newOrder)
    setSavingDatas(false)
  }

  const setDisplay = async (id, visible) => {
    setSavingDatas(true)
    await updateDatas({
      query: `places/${placeDatas.id}/formulas/${id}`,
      datas: { visible: !visible },
      message: {
        success: visible
          ? t('formula_visibility_modified_succeed2')
          : t('formula_visibility_modified_succeed'),
        error: t('formula_visibility_modified_failed'),
      },
      action: 'set',
    })

    setSavingDatas(false)
  }

  const copyMenus = async (id) => {
    setSavingDatas(true)

    const formulasToCopy = items.filter((i) => i.id === id)[0]

    const duplicate = Functions().httpsCallable('formulas-duplicateFormula')
    const maxValueOforder = Math.max(...items.map((o) => o.order), 1)
    let newOrder
    if (maxValueOforder > Object.keys(items).length) {
      newOrder = maxValueOforder + 1
    } else {
      newOrder = Object.keys(items).length + 1
    }

    duplicate({
      data: {
        formula_id: formulasToCopy.id,
        place_id: placeDatas.id,
        order: newOrder,
      },
    }).then((result) => {
      enqueueSnackbar(t('formula_copy_succeed'), { variant: 'success' })
      setSavingDatas(false)
    })
  }

  const deleteMenus = (id) => {
    setDeletedId(id)
    setIsOpen(true)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    setSavingDatas(true)
    let newOrder = items
    newOrder = Reorder(items, result.source.index, result.destination.index)
    updateOrder(newOrder)
    setItems(newOrder)
    setSavingDatas(false)
  }

  const itemRenderer = (item, provided) => (
    <Flex {...provided.dragHandleProps}>
      <img
        src={Moveicon}
        alt="logo"
        style={{
          width: '15px',
          marginRight: '20px',
          marginTop: '-3px',
          cursor: 'move',
        }}
      />
      <Content>
        <TypeBox>{t('formula')}</TypeBox>

        <div style={{ width: '100%', textAlign: 'center' }}>
          <ContentTitle
            style={{ cursor: 'pointer', width: 'fit-content' }}
            onClick={() =>
              history.push(
                `/dashboard/edit/formula${item.type === 1 ? '/pdf' : ''}?place_id=${
                  placeDatas.id
                }&formula_id=${item.formula_id}`
              )
            }
          >
            {getMultilangContent(item, 'name_ml', currentLang)}
          </ContentTitle>
          <ContentAction>
            <Flex>
              <Flex style={{ alignItems: 'center', marginRight: '40px' }}>
                <OverlayTrigger
                  key="copyToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      Visible
                    </Toolbox>
                  }
                >
                  <Switch
                    checked={item.visible}
                    onChange={() => setDisplay(item.formula_id, item.visible)}
                    name={`switch-${item.formula_id}`}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </OverlayTrigger>
              </Flex>
              <Flex style={{ marginRight: '20px' }}>
                <OverlayTrigger
                  key="copyToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      {t('duplicate')}
                    </Toolbox>
                  }
                >
                  <IconContainer onClick={() => copyMenus(item.formula_id)}>
                    <CopyIcon />
                  </IconContainer>
                </OverlayTrigger>
                <OverlayTrigger
                  key="editToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      {t('edit')}
                    </Toolbox>
                  }
                >
                  <IconContainer
                    onClick={() =>
                      history.push(
                        `/dashboard/edit/formula${
                          item.type === 1 ? '/pdf' : ''
                        }?place_id=${placeDatas.id}&formula_id=${item.formula_id}`
                      )
                    }
                  >
                    <EditIcon />
                  </IconContainer>
                </OverlayTrigger>
                <OverlayTrigger
                  key="deleteToolbox"
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Toolbox>
                      <Spike />
                      {t('remove')}
                    </Toolbox>
                  }
                >
                  <IconContainer onClick={() => deleteMenus(item.formula_id)}>
                    <DeleteIcon />
                  </IconContainer>
                </OverlayTrigger>
              </Flex>
            </Flex>
          </ContentAction>
        </div>
      </Content>
    </Flex>
  )

  const formatItems = (items) => {
    const fItems = items.map((_i, index) => ({
      ..._i,
      id: `formula-${_i.id}`,
      formula_id: _i.id,
    }))
    return fItems
  }

  const DragAndDropRender = () => {
    const formatedItems = formatItems(items)

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="MENU">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {formatedItems.map((formula, index) => (
                <Draggable key={formula.id} draggableId={formula.id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {itemRenderer(formula, provided)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>{t('remove formula from menu')}</ModalTitle>
          <ModalSubTitle>{t('cannot go back')}</ModalSubTitle>
          <FlexModal>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => {
                setSavingDatas(true)
                await updateDatas({
                  query: `places/${placeDatas.id}/formulas/${deletedId}`,
                  message: {
                    success: t('formula_deleted_succeed'),
                    error: t('formula_deleted_failed'),
                  },
                  action: 'delete',
                })
                setIsOpen(false)
                setSavingDatas(false)
              }}
            >
              {t('validate')}
            </Button>
          </FlexModal>
        </Modal.Body>
      </Modal>
      <Container fluid>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <NewMenuButton
            variant="secondary"
            onClick={() => {
              history.push(`/dashboard/edit/new/formula?place_id=${placeDatas.id}`)
            }}
          >
            <img
              src={Digitalicon}
              alt="logo"
              style={{
                width: '23px',
                marginRight: '20px',
                marginTop: '-3px',
              }}
            />
            {t('create_formula')}
          </NewMenuButton>

          <br />
          <br />
          <LangSelector placeDatas={place} lang={lang} id={place_id} />
        </div>
        <Background>
          <Title>
            {t('all_formulas')}
            {savingDatas && <Spinner animation="border" size="sm" variant="dark" />}
          </Title>
          <SubTitle>
            {t('setup formula')}
          </SubTitle>
          {Object.keys(items).length !== 0 && DragAndDropRender()}
        </Background>
      </Container>
    </>
  )
}

export default withTheme(withTranslation()(Formulas))

const Flex = styled.div`
  display: flex;
`
const FlexModal = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const NewMenuButton = styled(Button)`
  width: 350px;
  margin: 0px 20px;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 400px) {
    height: 50px;
  }
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const NewPDFButton = styled(Button)`
  width: 350px;
  margin: 0px 20px;
  @media screen and (max-width: 400px) {
    width: 90%;
  }
  @media screen and (min-width: 400px) {
    height: 50px;
  }
  @media screen and (max-width: 839px) {
    margin-top: 20px;
  }
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const AvalaibleSoon = styled.div`
  width: 160px;
  height: 25px;
  padding-top: 2px;
  margin: 28px 0px 0px 80px;
  position: absolute;
  background-color: ${(props) => props.theme.primary};
  border-radius: 5px;
  font-size: 14px;
  color: white;
`
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`
const TypeBox = styled.div`
  background-color: #e6efff;
  margin: 10px 20px 10px 30px;
  padding: 5px 5px 5px 5px;
  border-radius: 14px;
  width: 130px;
  font-size: 11pt;
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const Content = styled.div`
  display: flex;
  background-color: white;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #d1d1d1;
  width: 100%;
  margin-bottom: 10px;
`
const ContentTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  margin-top: 12px;
  float: left;
`
const ContentAction = styled.div`
  float: right;
`
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
  margin-bottom: 10px;
`
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`
const Background = styled.div`
  margin-top: 40px;
  background-color: white;
  padding: 20px 40px 20px 40px;
  text-align: center;
  border-radius: 7px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
