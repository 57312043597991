import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useCollection } from '@nandorojo/swr-firestore'

import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import Navbar from '../../../components/UI/navbar/Navbar'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'
import AuthContainer from '../../../stores/AuthContainer'
import PlaceContainer from '../../../stores/PlaceContainer'

import { Pages, ExternalIntegration } from './view'

const navItems = [
  'pages',
  // 'external-inte',
]

const queryString = require('query-string')

const EditAPlace = ({ t, setTitle }) => {
  const location = useLocation()
  const { user } = AuthContainer.useContainer()
  const { place, updatePlace } = PlaceContainer.useContainer()
  const { place_id } = queryString.parse(location.search)
  const [tab, setTab] = useState('pages')
  const [items, setItems] = useState([])

  const {
    data: menus,
    error,
    isValidating,
  } = useCollection(place?.id ? `places/${place.id}/menus` : null, {
    where: ['uid', '==', user.uid],
    orderBy: ['order', 'asc'],
    listen: true,
  })
  useEffect(() => {
    if (menus) setItems(menus)
  }, [menus])

  useEffect(() => {
    FirestoreDb()
      .collection('places')
      .doc(place_id)
      .onSnapshot((docPlace) => {
        if (docPlace.exists) {
          setTitle(`${docPlace.data().place_name} > <b>${t('share')}</b>`)
        }
      })
  }, [t, setTitle, place_id])

  const displayView = () => {
    switch (tab) {
      case 'pages':
        return (
          <Pages place={place} menus={items} updatePlace={updatePlace} user={user} />
        )
      case 'external-inte':
        return <ExternalIntegration />
      default:
        return <Pages />
    }
  }

  return (
    <CustomContainer fluid>
      <div style={{ marginTop: '90px' }} />
      <Navbar items={navItems} setTab={setTab} tab={tab} t={t} />
      {displayView()}
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(EditAPlace))

const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
