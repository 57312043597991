import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useForm } from 'react-hook-form'
import { useDocument, useCollection } from '@nandorojo/swr-firestore'
import AuthContainer from '../../../stores/AuthContainer'
import Navbar from '../../../components/UI/navbar/Navbar'
import { Formula } from './view'
import { useStorageTools } from '../../../hooks/FireTools'
import LangSelector from '../../../components/UI/form/LangSelector/LangSelector'
import { Synchronizing } from '../../../components/UI/loaders'
import { formatFromDb } from '../../../utils/DateHelpers'
import { Functions } from '../../../utils/firebase/FirebaseModules'
import PlaceContainer from '../../../stores/PlaceContainer'
import {
  updateMultilangArray,
  getMultilangContent,
} from '../../../utils/MultilangHelpers'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const navItems = ['formula']

const EditFormula = ({ t, setTitle, modePDF }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState('formula')
  const location = useLocation()
  const { formula_id, lang, sectionId, productId } = queryString.parse(
    location.search
  )
  const { place: placeDatas } = PlaceContainer.useContainer()
  let currentLang = lang || placeDatas?.main_language || 'FR'

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    unregister,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm()
  const { user } = AuthContainer.useContainer()
  const { firebaseUploader } = useStorageTools()
  const [saving, setSaving] = useState(false)
  const [synchronising, setSynchronising] = useState('hidden')
  const history = useHistory()
  const { data: formulaDatas, set: updateFormula } = useDocument(
    placeDatas?.id && formula_id
      ? `places/${placeDatas.id}/formulas/${formula_id}`
      : null,
    { listen: true }
  )
  const { data: sections, add: addSection } = useCollection(
    formulaDatas?.id && user?.uid
      ? `places/${placeDatas.id}/formulas/${formula_id}/sections`
      : null,

    { where: ['uid', '==', user.uid], orderBy: ['order', 'asc'], listen: true }
  )

  const formatHoursFromDb = (hours) => {
    const _hours = [...hours]
    hours.forEach((_h, i) => {
      _hours[i].displayHours = formatFromDb(_h.displayHours)
      _hours[i].undisplayHours = formatFromDb(_h.undisplayHours)
    })
    return _hours
  }

  const syncFormulasWithMenus = async (formulaId) => {
    setSynchronising('active')
    const _syncFunction = Functions().httpsCallable(
      'formulas-updateFormulasInMenusOnCall'
    )

    try {
      const sync = await _syncFunction({
        data: {
          formulaId,
          placeId: placeDatas.id,
          action: 'updated',
        },
      })
      if (sync.data.status === 'success') {
        setSynchronising('done')
      } else {
        setSynchronising('failed')
      }
    } catch (e) {
      setSynchronising('failed')
    }

    setTimeout(() => setSynchronising('hidden'), 2000)
  }

  useEffect(() => {
    if (placeDatas) {
      setTitle(`${placeDatas.place_name} > <b>${t('formulas')}</b>`)
    }
  }, [t, setTitle, placeDatas])

  useEffect(() => {
    if (formulaDatas) {
      reset({
        ...formulaDatas,
        description: getMultilangContent(
          formulaDatas,
          'description_ml',
          currentLang
        ),
        name: getMultilangContent(formulaDatas, 'name_ml', currentLang),
        menu_footer: getMultilangContent(formulaDatas, 'footer_ml', currentLang),
      })
    }
  }, [currentLang, formulaDatas])

  const handleSubmitForm = async (datas) => {
    setSaving(true)
    const commonDatas = {
      uid: user.uid,
      modified_at: new Date(),
      place_id: placeDatas.id,
    }

    let background_image_url = formulaDatas.background_image_url
      ? formulaDatas.background_image_url
      : null
    if (
      datas.background_image_file !== undefined &&
      datas.background_image_file.size > 0
    ) {
      let bgImageUploaded = await firebaseUploader(
        [datas.background_image_file],
        `users/${user.uid}/formulas/${formulaDatas.id}`,
        ['background_image']
      )
      background_image_url = bgImageUploaded[0].url
    }

    const simpleDatas = {
      ...commonDatas,
      background_image_url,
      description_ml: updateMultilangArray(
        datas.description,
        formulaDatas?.description_ml,
        currentLang
      ),
      name_ml: updateMultilangArray(datas.name, formulaDatas?.name_ml, currentLang),
      price: datas?.price || 0,
    }

    try {
      await updateFormula(simpleDatas, { merge: true })
      enqueueSnackbar(t('menu_saved_succeed'), { variant: 'success' })
      syncFormulasWithMenus(formula_id)
    } catch (e) {
      enqueueSnackbar(t('error_updating_data_menu'), {
        variant: 'error',
      })
    }

    setSaving(false)
  }

  const displayView = () => {
    const _props_menu = {
      register,
      modePDF,
      setValue,
      formulaDatas,
      background_image_url: formulaDatas.background_image_url,
      placeDatas,
      sections,
      errors,
      currentLang,
      addSection,
      sectionId,
      productId,
      sync: () => syncFormulasWithMenus(formula_id),
    }
    const props_display = {
      unregister,
      formatHoursFromDb,
      menu_display_hours: formulaDatas.menu_display_hours || [],
      setValue,
    }
    switch (tab) {
      case 'formula':
        return <Formula {..._props_menu} />

      default:
        return <Formula {..._props_menu} />
    }
  }

  if (!placeDatas || !formulaDatas) {
    return null
  } else {
    return (
      <CustomContainer fluid>
        <form>
          <div style={{ marginTop: '70px' }} />
          <Row>
            <Col lg="8">
              <Navbar items={navItems} setTab={setTab} tab={tab} t={t} />
            </Col>
            <Col lg="4" style={{ marginTop: '20px' }}>
              <ShowButton
                variant="outline"
                href={`/place/${placeDatas?.place_slug}/menu/${formula_id}`}
                target="_blank"
              >
                {t('visualize')}
              </ShowButton>
              <SaveButton
                disabled={saving}
                onClick={handleSubmit(handleSubmitForm)}
                type="submit"
                variant="secondary"
              >
                {saving ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  t('save')
                )}
              </SaveButton>
            </Col>
          </Row>
          <ThemeProvider theme={theme}>
            {tab === 'formula' && (
              <LangSelector
                placeDatas={placeDatas}
                lang={lang}
                formula_id={formula_id}
                id={placeDatas.id}
                action={handleSubmit(handleSubmitForm)}
              />
            )}
            {formulaDatas !== null ? (
              displayView()
            ) : (
              <Spinner animation="border" size="lg" variant="dark" />
            )}
          </ThemeProvider>
        </form>
        <Synchronizing status={synchronising} />
      </CustomContainer>
    )
  }
}

export default withTheme(withTranslation()(EditFormula))
const CustomContainer = styled(Container)`
  @media (min-width: 992px) {
    padding-left: 120px;
  }
`
const SaveButton = styled(Button)`
  margin-left: 5%;
  width: 45%;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const ShowButton = styled(Button)`
  margin-right: 5%;
  padding-left: 18px;
  padding-right: 31px;
  width: 45%;
  border-style: solid;
  border: 1px;
  background-color: white;
  color: ${(props) => props.theme.primary};
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
