import React, { useState } from 'react'
import styled from 'styled-components'
import {
  FileCopyOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import { OverlayTrigger } from 'react-bootstrap'
import Moveicon from '../../../../assets/menu/move.svg'
import RestaurantCardModal from '../../../Menus/Restaurant/RestaurantCard/RestaurantCardModal/RestaurantCardModal'
import Badge from './badge'

import { withTranslation } from 'react-i18next'

const ProductCard = ({
  t,
  product_name,
  product_description,
  prices,
  imgUrl,
  promoPercentage,
  product_promo_type,
  actions,
  product_labels,
  product_notstock,
  props,
  currentLang,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <RestaurantCardModal
        datas={{
          color: '#6F4FB3',
          darkmode: false,
          topbanner: true,
        }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentLang={currentLang}
        currentCard={{
          ...props,
          product_prices: prices,
          product_name,
          product_description,
          product_main_image_url: imgUrl,
        }}
      />
      <Flex>
        <DragImg>
          <CustomImg src={Moveicon} alt="logo" onClick={() => setIsOpen(true)} />
          {/* <div style={{margin: '5px 10px 0px 0px', color: 'black'}}>
            {index + 1}
          </div > */}
        </DragImg>
        <RestaurantCardBox lineThrough={false}>
          <div style={{ display: 'flex', width: '70%' }}>
            <RestaurantCardTitle lineThrough={false} onClick={() => setIsOpen(true)}>
              {product_name}
            </RestaurantCardTitle>
            {product_description && (
              <RestaurantCardDesc
                noStock={null}
                lineThrough={false}
                onClick={() => setIsOpen(true)}
              >
                {product_description}
              </RestaurantCardDesc>
            )}
          </div>
          <RestaurantCardNoSlideUp>
            {prices && prices[0] && (
              <RestaurantCardPrice>
                <RestaurantCardSlideUpContentItemTitle>
                  {prices[0].description}
                </RestaurantCardSlideUpContentItemTitle>
                <div style={{ display: 'flex', marginRight: '10px' }}>
                  <div
                    style={{
                      textDecoration:
                        promoPercentage === 'percentage' && 'line-through',
                      marginRight: '16px',
                      font: 'normal normal bold 16px Lato',
                    }}
                  >
                    {prices[0].price} €
                  </div>
                  {prices[0] && product_promo_type === 'percentage' && (
                    <RestaurantCardNewPrice color="#6F4FB3">
                      {parseFloat(
                        prices[0].price - prices[0].price * (promoPercentage / 100)
                      ).toFixed(2)}{' '}
                      €
                    </RestaurantCardNewPrice>
                  )}
                </div>
              </RestaurantCardPrice>
            )}
          </RestaurantCardNoSlideUp>
          <ContentAction>
            <Flex>
              <OverlayTrigger
                key="editToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    Éditer
                  </Toolbox>
                }
              >
                <IconContainer onClick={actions[1].function}>
                  <EditIcon />
                </IconContainer>
              </OverlayTrigger>
              <OverlayTrigger
                key="deleteToolbox"
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Toolbox>
                    <Spike />
                    {t('remove')}
                  </Toolbox>
                }
              >
                <IconContainer onClick={actions[2].function}>
                  <DeleteIcon />
                </IconContainer>
              </OverlayTrigger>
            </Flex>
          </ContentAction>
        </RestaurantCardBox>
      </Flex>
    </>
  )
}

export default withTranslation()(ProductCard)

const Flex = styled.div`
  display: flex;
`
const RestaurantCardBox = styled.div`
  display: -webkit-inline-box;
  justify-content: space-between;
  padding: 9px 15px 11px 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #d1d1d1;
  background-color: white;
  width: 90%;
  color: #969696 !important;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 15px;
`
const RestaurantCardTitle = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  align-self: center;
  overflow: hidden;
  width: 30%;
  display: -webkit-box;
  text-align: left;
  font: normal normal bold 16px Lato;
  color: black;
`
const RestaurantCardDesc = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  overflow: hidden;
  width: 70%;
  align-self: center;
  display: -webkit-box;
  text-align: left;
  font: normal normal normal 14px Lato;
  color: ${(props) =>
    props.noStock ? '#969696' : props.darkmode ? '#969696' : '#606060'};
  overflow: hidden;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const BadgeHandler = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`
const RestaurantCardSlideUpContentItemTitle = styled.div`
  margin-left: 15px;
  overflow: hidden;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const RestaurantCardPrice = styled.div`
  display: contents;
  font: normal normal bold 16px Lato;
  margin-bottom: 11px;
  color: ${(props) =>
    props.noStock ? '#969696' : props.darkmode ? '#969696' : '#1D1D1D'};
  @media screen and (max-width: 700px) {
    display: none;
  }
`
const RestaurantCardNewPrice = styled.div`
  margin-bottom: 11px;
  color: ${(props) => props.color};
  font: normal normal bold 16px Lato;
`
const RestaurantCardNoSlideUp = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`
const RestaurantCardImageHandler = styled.div`
  border-radius: 5px;
  height: 90px;
  width: 90px;
  position: relative;
  margin: 40px 0px 0px 10px;
`
const RestaurantCardImage = styled.img`
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`
const RestaurantCardOfferTitle = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 3px 9px 5px 9px;
  background-color: ${(props) => (props.noStock ? '#BA400A' : props.color)};
  text-align: center;
  font: normal normal bold 15px Lato;
  color: white;
  border-radius: 2px;
  left: 50%;
  transform: translate3d(-50%, 50%, 0);
`
const DragImg = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 30px;
  height: 30px;
  border-radius: 10px;
  font-size: 11pt;
`
const CustomImg = styled.img`
  width: 15px;
  margin: 2px 5px 0px 5px;
  cursor: move;
`
const ContentAction = styled.div`
  display: flex;
`
const Toolbox = styled.div`
  color: ${(props) => props.theme.primary};
  cursor: move;
  width: 100px;
  text-align: center;
  background: white;
  padding: 5px 15px 5px 15px;
  margin-bottom: 15px;
  z-index: 900;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`
const Spike = styled.div`
  position: absolute;
  margin-top: 23px;
  z-index: 999;
  margin-left: 30px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  transform: rotate(90deg);
  border-color: transparent transparent transparent white;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  cursor: pointer;
  margin: 10px 5px 10px 5px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const CopyIcon = styled(FileCopyOutlined)`
  color: ${(props) => props.theme.primary};
`
const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primary};
  margin-bottom: 2px;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
