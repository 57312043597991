import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Card,
  Container,
  Form,
  Button,
  Navbar,
  Spinner,
  InputGroup,
} from 'react-bootstrap'
import { Room } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { slugify } from 'underscore.string'
import { useCollection } from '@nandorojo/swr-firestore'
import UseCreatePlaceContainer from '../../CreateAPlaceStore'
import withAccountVerification from '../../../../../hoc/withAccountVerification'
import useStripe from '../../../../../hooks/Stripe'
import GradientTextBanner from '../../../../../components/UI/banners/GradientTextBanner'
import AuthContainer from '../../../../../stores/AuthContainer'
import LocationSearchInput from '../../../../../components/UI/form/GoogleMap/LocationSearchInput'

const queryString = require('query-string')

const place_types = [
  {
    id: 'restaurant',
    label: 'Restaurant',
  },
  {
    id: 'hotel',
    label: 'Hôtel',
  },
  {
    id: 'spa',
    label: 'Spa',
  },
  {
    id: 'shop',
    label: 'Boutique',
  },
  {
    id: 'bar',
    label: 'Bar',
  },
  {
    id: 'event_location',
    label: 'Lieu événementiel',
  },
  {
    id: 'other',
    label: 'autres',
  },
]

const InformationStep = ({ t, trialStarted, trialExpired }) => {
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm()
  const { userDatas } = AuthContainer.useContainer()
  const { createSubscription } = useStripe()
  const [creatingPlace, setCreatingPlace] = useState(false)
  const [address, setAddress] = useState('')
  const [type, setType] = useState('')
  const [alertAdress, setAlertAddress] = useState(false)
  let history = useHistory()
  const [place_name_field, place_slug_field] = watch(['place_name', 'place_slug'])
  const [slugAlert, setSlugAlert] = useState(false)
  const { ref, ...rest } = register('place_name')
  const place_name_ref = React.useRef(null)
  const { data: s_place, error } = useCollection(
    place_slug_field !== '' ? 'places' : null,
    { where: ['place_slug', '==', place_slug_field] }
  )
  const location = useLocation()
  const { lang } = queryString.parse(location.search)
  const currentLang = lang || 'FR'

  useEffect(() => {
    place_name_ref.current.addEventListener('keyup', () => {
      setValue('place_slug', slugify(place_name_ref.current.value))
    })
  }, [])

  const handleForm = async (datas) => {
    if (address !== '' && (!s_place || s_place.length < 1)) {
      datas.place_address = address
      if (trialStarted || trialExpired) {
        CreatePlaceState.setPlaceInformations(datas)
        CreatePlaceState.setActiveStep(1)
      } else {
        setCreatingPlace(true)
        const placesPlanId = process.env.REACT_APP_STRIPE_PROD_default_price_plan
        const placeCreated = await createSubscription({
          priceId: placesPlanId,
          customerId: userDatas.stripeId,
          placeDatas: datas,
          type: 'place_subscription',
          lang: currentLang,
        })
        const { datas: placeCreatedDatas, status } = placeCreated.data
        if (status === 'success') {
          history.push('/dashboard/places')
        }

        setCreatingPlace(false)
      }
    } else {
      setAlertAddress(true)
    }
  }

  return (
    <Container fluid>
      {!trialStarted ? (
        <GradientTextBanner>
          {t('free access banner')}
        </GradientTextBanner>
      ) : (
        <br />
      )}
      <Form
        onSubmit={handleSubmit(handleForm)}
        style={{ textAlign: '-webkit-center' }}
      >
        <CustomCard bsPrefix="card white-card">
          <Title>{t('place informations')}</Title>
          <Form.Group>
            <CustomFormControl
              defaultValue={CreatePlaceState.placeInformations.place_name}
              placeholder={t('place name')}
              isInvalid={errors.place_name}
              {...rest}
              ref={(e) => {
                ref(e)
                place_name_ref.current = e
              }}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {t('place name error')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>app.monmenu360.fr/</InputGroup.Text>
              <CustomFormControl
                placeholder={t('place slug')}
                isInvalid={errors.place_slug}
                {...register('place_slug')}
                defaultValue={CreatePlaceState.placeInformations.place_slug}
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                {t('place slug error')}
              </Form.Control.Feedback>
            </InputGroup>
            <AlertBox>
              {s_place && s_place.length > 0 && t('name_already_exist')}
            </AlertBox>
          </Form.Group>
          <Form.Group>
            <Flex>
              <IconAddressBar>
                <AdresseIcon />
              </IconAddressBar>
              <NoFlex>
                <LocationSearchInput
                  style={{
                    width: '100%',
                    borderStyle: 'solid',
                    borderColor: alertAdress ? '#dc3545' : 'rgba(0, 0, 0, 0.2)',
                    borderWidth: '1px',
                    borderStyleLeft: 'none',
                    height: '40px',
                    paddingLeft: '10px',
                    fontSize: '15px',
                    color: '#495057',
                  }}
                  placeholder="Adresse"
                  address={address}
                  setAddress={setAddress}
                  setAlertAddress={setAlertAddress}
                />
              </NoFlex>
            </Flex>
            <AlertBox>{alertAdress && t('place address error')}</AlertBox>
          </Form.Group>
          <TypeSelect
            style={{ color: type === '' && 'rgba(0, 0, 0, 0.3)' }}
            {...register('place_type', { required: false })}
            defaultValue={CreatePlaceState.placeInformations.place_type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="" disabled selected>
              {t('place type')}
            </option>
            {place_types.map((type, i) => (
              <option key={`type${i}`} value={type.id}>
                {type.label}
              </option>
            ))}
          </TypeSelect>
          <Form.Group>
            <CustomFormControl
              defaultValue={CreatePlaceState.placeInformations.place_email}
              placeholder={t('place email')}
              isInvalid={errors.place_email}
              {...register('place_email', { required: false })}
              type="email"
            />
          </Form.Group>
          <Form.Group>
            <CustomFormControl
              defaultValue={CreatePlaceState.placeInformations.place_phone}
              placeholder={t('place phone')}
              isInvalid={errors.place_phone}
              {...register('place_phone', { required: false })}
              type="text"
            />
          </Form.Group>
        </CustomCard>
        <Navbar
          bsPrefix=" navbar navbar-expand navbar-expand-bottom-fixed"
          style={{ height: '60px' }}
        >
          <Navbar.Collapse>
            <CustomOutlineButton
              onClick={() => history.push('/dashboard/create-place')}
            >
              {t('cancel')}
            </CustomOutlineButton>
          </Navbar.Collapse>
          {!trialStarted ? (
            <CustomButton2 disabled={creatingPlace} type="submit" variant="primary">
              {creatingPlace ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : null}{' '}
                {window.innerWidth > 551 ? 
                  t('Start free trial') : 
                  t('next')}
            </CustomButton2>
          ) : (
            <CustomButton type="submit" variant="primary">
              {t('next')}
            </CustomButton>
          )}
        </Navbar>
      </Form>
    </Container>
  )
}

export default withAccountVerification(withTranslation()(InformationStep))

const Flex = styled.div`
  display: flex;
`
const TypeSelect = styled.select`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
`

const NoFlex = styled.div`
  display: initial;
  width: 100%;
`
const CustomFormControl = styled(Form.Control)`
  ::placeholder {
    opacity: 0.5;
  }
`
const AlertBox = styled.div`
  font-size: 10pt;
  color: #dc3545;
  margin-top: 5px;
`
const AdresseIcon = styled(Room)`
  width: 20px !important;
  height: 30px !important;
  margin-top: 2px;
  color: ${(props) => props.theme.primary};
`
const IconAddressBar = styled.div`
  background-color: #fbfbfb;
  width: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style-right: none;
  padding-left: 7px;
  padding-top: 2px;
  margin-right: -5px;
`
const CustomCard = styled(Card)`
  width: 50%;
	@media screen and (max-width: 991px) {
	  width: 95%;
	} 
  text-align: left;
  padding: 20px 40px 30px 40px !important;
`
const Title = styled.div`
  font-size: 16pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 7px;
  margin-bottom: 20px;
`
const CustomButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
`
const CustomButton2 = styled(Button)`
  width: 260px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
`
const CustomOutlineButton = styled(Button)`
  width: 200px;
	@media screen and (max-width: 551px) {
    width: 100px;
	} 
  height: 40px;
  background-color: rgba(111, 79, 179, 0.2) !important;
  color: ${(props) => props.theme.primary} !important;
  border-style: none;
`
