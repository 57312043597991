import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Button, Modal, Spinner, Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import { useForm, Controller } from 'react-hook-form'
import {
  PermMedia,
  DeleteForeverOutlined,
  Close,
  SubdirectoryArrowRight,
} from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'
import SetLogoModal from './setLogoModal'
import { getFileFromBlob } from '../../../../utils/ImageHelpers'
import AuthContainer from '../../../../stores/AuthContainer'
import { FirestoreDb } from '../../../../utils/firebase/FirebaseModules'
import { useStorageTools, useFirestoreApi } from '../../../../hooks/FireTools'
import {
  updateMultilangArray,
  getMultilangContent,
  createMultilangArray,
} from '../../../../utils/MultilangHelpers'
import LangSelector from '../../../../components/UI/form/LangSelector/LangSelector'

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

const queryString = require('query-string')

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
})

const openHours = [
  {
    openDays: {
      monday: false,
      tuesday: false,
      wednesday: true,
      thursday: false,
      friday: false,
      saturday: true,
      sunday: false,
    },
    displayHours: '12:00',
    undisplayHours: '15:00',
  },
  {
    openDays: {
      monday: false,
      tuesday: false,
      wednesday: true,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: true,
    },
    displayHours: '18:00',
    undisplayHours: '22:00',
  },
]

const EditCategory = ({
  t,
  create,
  modalOpen,
  toggleModal,
  type,
  placeDatas,
  menus,
  category,
  currentLang,
  menuId,
}) => {
  const [picModalOpen, setPicModalOpen] = useState(false)
  const [hours, setHours] = useState(openHours)
  const [PicUrl, setPicUrl] = useState(null)
  const [saving, setSaving] = useState(false)
  const [groupImagefile, setGroupImagefile] = useState(null)
  const [groupImgUrl, setGroupImgUrl] = useState(null)
  const { firebaseUploader, documentDeletionButton } = useStorageTools()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = AuthContainer.useContainer()
  const { menu_id } = queryString.parse(location.search)
  const { updateDatas } = useFirestoreApi()
  const mlFields = ['group_name', 'group_description']

  const {
    register,
    formState: { errors },
    watch,
    reset,
    handleSubmit,
    setValue,
    getValues,
    control,
  } = useForm()
  let [group_name_field, group_description_field] = watch([
    'group_name',
    'group_description',
  ])

  const query = `places/${placeDatas.id}/menus/${menuId}/sections/`
  const newDocId = FirestoreDb().collection(query).doc().id

  const classes = useStyles()

  useEffect(() => {
    if (!category) {
      reset({})
      setHours([])
      setGroupImgUrl(undefined)
    } else {
      reset({
        ...category,
        group_name: getMultilangContent(category, 'group_name_ml', currentLang),
        group_description: getMultilangContent(
          category,
          'group_description_ml',
          currentLang
        ),
      })
      setHours(category?.group_hours || [])
      setGroupImgUrl(category?.group_image_url)
    }
  }, [modalOpen, currentLang])

  const displayLetter = (e) => {
    switch (e) {
      case 'monday':
        return 'L'
      case 'tuesday':
        return 'M'
      case 'wednesday':
        return 'M'
      case 'thursday':
        return 'J'
      case 'friday':
        return 'V'
      case 'saturday':
        return 'S'
      case 'sunday':
        return 'D'
      default:
        return '?'
    }
  };

  const handleAddImage = (img) => {
    if (typeof img === 'object' && img.blob.size > 0) {
      setGroupImagefile(getFileFromBlob(img.blob, 'file'))
      setGroupImgUrl(img.url)
    }
  }

  const submitForm = async (datas) => {
    let {
      group_description,
      group_name,
      group_visible,
      group_image_url,
      group_image_file,
      group_hours,
    } = datas

    setSaving(true)
    group_image_url = groupImgUrl || null
    if (groupImagefile && groupImagefile.size > 0) {
      try {
        const mainImage = await firebaseUploader(
          [groupImagefile],
          `users/${user.uid}/menus/${menu_id}/groups`,
          [category?.id ? category.id : newDocId]
        )
        group_image_url = mainImage[0].url
      } catch (e) {
        enqueueSnackbar('Error saving main image file ====> ', { variant: 'error' })
      }
    }

    let preparedDatas = {
      group_type: type,
      group_visible: getValues('group_visible') || false,
      group_image_url,
      group_hours: hours,
      products: category?.products || [],
      uid: user.uid,
      order: category?.order || menus.length + 1,
    }
    mlFields.map((f) => {
      preparedDatas[`${f}_ml`] = category?.id
        ? updateMultilangArray(datas[f], category[`${f}_ml`], currentLang)
        : createMultilangArray(
            [placeDatas.main_language, ...placeDatas.languages_array],
            currentLang,
            datas[f]
          )
    })

    updateDatas({
      query: query + (category?.id ? category.id : newDocId),
      action: 'set',
      datas: { ...preparedDatas },
      message: {
        success: t('category_create_succeed'),
        error: t('category_create_failed'),
      },
    })

    setSaving(false)
    toggleModal(false)
  }

  const displayGroupTitle = (id) => {
    if (id === 2) {
      return t('featured category')
    } else if (id === 1) {
      return t('formule category')
    } else {
      return t('simple category')
    }
  }

  const updateTime = (value, index, t) => {
    const tmp = [...hours]
    if (t === 0) {
      tmp[index].displayHours = value
    } else {
      tmp[index].undisplayHours = value
    }
    setHours(tmp)
  }

  const updateHours = (item, index, day) => {
    const tmp = [...hours]
    tmp[index].openDays[day] = !item.openDays[day]
    setHours(tmp)
  }

  const HeaderRender = () => (
    <>
      <Flex style={{ padding: '10px 0px 0px 20px' }}>
        <ContentLeftContainer>
          <ContentTitle>{group_name_field || t('new_cat_name')}</ContentTitle>
        </ContentLeftContainer>
        <div style={{ float: 'right' }}>
          <IconContainer onClick={() => toggleModal(false)}>
            <CloseIcon />
          </IconContainer>
        </div>
      </Flex>
      <div style={{ margin: '-20px 0px 0px 40px' }}>
        <Flex style={{ marginBottom: '10px' }}>
          <ArrowRight />
          <GroupTitle>{displayGroupTitle(type)}</GroupTitle>
        </Flex>
        <GroupDescription style={{ width: '450px' }}>
          {group_description_field || ''}
        </GroupDescription>
        <LangSelector
          placeDatas={placeDatas}
          lang={currentLang}
          id={placeDatas.id}
          menu_id={menuId}
        />
      </div>
    </>
  )

  return (
    <CustomModal show={modalOpen} onHide={toggleModal}>
      <form onSubmit={handleSubmit(submitForm)}>
        <SetLogoModal
          setPicUrl={setPicUrl}
          modalOpen={picModalOpen}
          toggleModal={setPicModalOpen}
          handleAddImage={handleAddImage}
        />
        <ButtonContainer>{HeaderRender()}</ButtonContainer>
        <BackgroundTransparent>
          <Flex>
            {!groupImgUrl ? (
              <Logo
                onClick={() => {
                  setPicModalOpen(!picModalOpen)
                }}
              >
                <InsertLogo>
                  <PermMedia
                    style={{
                      width: '50px',
                      height: '50px',
                      marginBottom: '20px',
                      opacity: 0.5,
                      color: 'lightgrey',
                    }}
                  />
                  {t('add background pic')}
                  <SizeText>{t('pic size')}</SizeText>
                </InsertLogo>
              </Logo>
            ) : (
              <div style={{ position: 'relative' }}>
                {documentDeletionButton({
                  serve: true,
                  url: groupImgUrl,
                  collection: `places/${placeDatas.id}/menus/`,
                  doc: menuId,
                  resetFunction: () => {
                    setPicUrl(null)
                    setGroupImgUrl(null)
                    setGroupImagefile(false)
                  },
                })}
                <Img
                  src={PicUrl !== null ? PicUrl.url : groupImgUrl}
                  alt="logo"
                  onClick={() => {
                    setPicModalOpen(!picModalOpen)
                  }}
                />
              </div>
            )}
            <div style={{ display: 'inline', width: '100%' }}>
              <Form.Control
                as={NameInput}
                {...register('group_name', { required: true })}
                isInvalid={errors.group_name}
                placeholder={t('group_name')}
              />
              <Form.Control.Feedback type="invalid">
                {t('group_name_required')}
              </Form.Control.Feedback>

              <Form.Control
                as={AreaInput}
                {...register('group_description', { required: false })}
                isInvalid={errors.group_description}
                placeholder={t('group_description')}
              />
              <Form.Control.Feedback type="invalid">
                {t('group_description_required')}
              </Form.Control.Feedback>
            </div>
          </Flex>
        </BackgroundTransparent>
        <Background>
          <Title>Option d'affichage</Title>
          <SubTitle>Choisissez quand afficher la catégorie</SubTitle>
          <Controller
            control={control}
            name="group_visible"
            defaultValue={false}
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <Flex>
                <Switch
                  checked={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                />
                <DisplayTitle>Masquer la catégorie du menu</DisplayTitle>
              </Flex>
            )}
          />
          <div style={{ textAlign: 'center' }}>
            {hours.map((item, index) => (
              <SubBackground>
                <FlexDiv>
                  <DayPickerContainer>
                    {days.map((day, i) => (
                      <>
                        {item.openDays[day] ? (
                          <DayPicker onClick={() => updateHours(item, index, day)}>
                            {displayLetter(day)}
                          </DayPicker>
                        ) : (
                          <DayUnPicked onClick={() => updateHours(item, index, day)}>
                            {displayLetter(day)}
                          </DayUnPicked>
                        )}
                      </>
                    ))}
                  </DayPickerContainer>
                  <FlexTimeInput>
                    <TimeInput>
                      <TextField
                        id="time-1"
                        type="time"
                        InputProps={{ classes }}
                        value={item.displayHours}
                        onChange={(e) => updateTime(e.target.value, index, 0)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                      />
                    </TimeInput>
                    <div style={{ marginTop: '11px' }}>-</div>
                    <TimeInput>
                      <TextField
                        id="time-2"
                        type="time"
                        InputProps={{ classes }}
                        value={item.undisplayHours}
                        onChange={(e) => updateTime(e.target.value, index, 1)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                      />
                    </TimeInput>
                  </FlexTimeInput>
                  <div style={{ width: '65%', float: 'right', marginRight: '10px' }}>
                    <IconContainer
                      onClick={() => setHours(hours.filter((obj) => obj !== item))}
                    >
                      <DeleteIcon />
                    </IconContainer>
                  </div>
                </FlexDiv>
              </SubBackground>
            ))}
            <CustomButton
              type="button"
              onClick={() =>
                setHours(
                  hours.concat({
                    openDays: {
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false,
                    },
                    displayHours: '12:00',
                    undisplayHours: '15:00',
                  })
                )
              }
            >
              {t('add open hours')}
            </CustomButton>
          </div>
        </Background>
        <br />
        <BottomBar>
          <CancelButton
            variant="primaryoutline"
            href="#"
            onClick={() => toggleModal(false)}
          >
            {t('cancel')}
          </CancelButton>
          <SaveButton disabled={saving} variant="primary" type="submit">
            {saving ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : create ? (
              t('validate')
            ) : (
              t('save')
            )}
          </SaveButton>
        </BottomBar>
      </form>
    </CustomModal>
  )
}

export default withTheme(withTranslation()(EditCategory))

const Flex = styled.div`
  display: flex;
`
const Logo = styled.div`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  background-color: white;
  margin-right: 15px;
  text-align: -webkit-center;
  cursor: pointer;
`
const Img = styled.img`
  width: 400px;
  height: 260px;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-right: 15px;
  cursor: pointer;
`
const NameInput = styled.input`
  width: 100%;
  outline: none;
  padding: 8px;
  font-size: 11pt;
  border-style: none;
  padding-left: 10px;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const AreaInput = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  height: 204px !important;
  padding: 5px;
  padding-left: 10px;
  font-size: 11pt;
  margin-top: 20px;
  border-style: none;
  border-radius: 5px;
  ::placeholder {
    opacity: 0.5;
  }
`
const SizeText = styled.div`
  font-size: 9pt;
  font-weight: 300;
  margin-top: 6px;
  opacity: 0.6;
`
const InsertLogo = styled.div`
  width: 200px;
  height: 70px;
  font-size: 10pt;
  font-weight: bold;
  margin-top: 70px;
  display: grid;
  justify-items: center;
`
const ButtonContainer = styled.div`
  padding-bottom: 10px;
  background-color: #f8f7ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`
const CustomModal = styled(Modal)`
  width: 100%;
  .modal-dialog {
    max-width: 80%;
  }
  .modal-content {
    background-color: #eceaff;
    border-radius: 20px;
  }
`
const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0px 40px 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
const CancelButton = styled(Button)`
  width: 150px;
  height: 45px;
  padding-top: 10px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SaveButton = styled(Button)`
  width: 200px;
  height: 45px;
  padding-top: 10px;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const FlexDiv = styled.div`
  display: flex;
`
const DeleteIcon = styled(DeleteForeverOutlined)`
  color: ${(props) => props.theme.primary};
`
const CloseIcon = styled(Close)``
const ArrowRight = styled(SubdirectoryArrowRight)`
  color: ${(props) => props.theme.primary};
  font-size: 14pt !important;
  margin-top: 2px;
  margin-right: 5px;
`
const DayPicker = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`
const DayPickerContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 800px;
`
const IconContainer = styled.div`
  background-color: #f8f7ff;
  float: right;
  position: relative;
  cursor: pointer;
  margin: 15px;
  padding: 2px 4px 4px 4px;
  align-item: center;
  svg {
    width: 0.8em;
    height: 0.8em;
  }
`
const DayUnPicked = styled.div`
  cursor: pointer;
  background-color: white;
  padding-top: 6px;
  width: 35px;
  height: 35px;
  margin: 10px 0px 10px 10px;
  border-radius: 40px;
`
const Title = styled.div`
  text-align: initial;
  font-size: 15pt;
  font-weight: bold;
`
const SubTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  opacity: 0.5;
  margin-bottom: 20px;
`
const DisplayTitle = styled.div`
  text-align: initial;
  font-size: 12pt;
  font-weight: bold;
  margin-top: 6px;
  margin-left: 10px;
`
const FlexTimeInput = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
`
const TimeInput = styled.div`
  background-color: white;
  padding-left: 15px;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
  margin: 10px;
  width: 100px;
  height: 30px;
`
const CustomButton = styled.button`
  @media screen and (max-width: 991px) {
    width: 90%;
  }
  @media screen and (min-width: 991px) {
    width: 30%;
  }
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f8f7ff;
  border-style: none;
  font-weight: bold;
  font-size: 11pt;
  color: ${(props) => props.theme.primary};
  padding: 5px;
  border-radius: 4px;
`
const Background = styled.div`
  margin: 0px 20px 0px 20px;
  background-color: white;
  padding: 20px 40px 20px 40px;
  text-align: center;
  border-radius: 7px;
`
const BackgroundTransparent = styled.div`
  margin: 20px 20px 10px 20px;
  background-color: #e6efff;
  text-align: center;
  border-radius: 7px;
`
const SubBackground = styled.div`
  margin-top: 15px;
  background-color: #e6efff;
  padding: 5px 0px 5px 30px;
  text-align: center;
  border-radius: 7px;
`
const ContentLeftContainer = styled.div`
  display: flex;
  float: left;
  margin-top: 12px;
  width: 90%;
`
const ContentTitle = styled.div`
  font-size: 15pt;
  font-weight: bold;
  width: 250px;
  margin: 0px 40px 0px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
`
const GroupTitle = styled.div`
  font-size: 13pt;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
  color: ${(props) => props.theme.primary};
`
const GroupDescription = styled.div`
  font-size: 12pt;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
  width: 350px;
`
