import React, { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { useLocation, useHistory } from 'react-router-dom'
import { useDocument } from '@nandorojo/swr-firestore'

var queryString = require('query-string')

const PlaceContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { lang, place_id } = queryString.parse(location.search)
	const [loadingPlaceDatas, setLoadingPlaceDatas] = useState(false)
	const {
		data: place,
		update: updatePlace,
		error: placeError,
	} = useDocument(place_id ? `places/${place_id}` : null, { listen: true })

	useEffect(() => {
		setLoadingPlaceDatas(true)
		if (!place) {
			setLoadingPlaceDatas(true)
		} else {
			setLoadingPlaceDatas(false)
		}
	}, [place])

	return {
		place,
		updatePlace,
		loadingPlaceDatas,
	}
}

export default createContainer(PlaceContainer)
