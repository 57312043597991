import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import RestaurantThemeCard from './RestaurantThemeCard';
import CategorieTheme from './CategorieTheme';

const RestaurantThemeInformation = ({ datas }) => {
    const cards = [{
 cardId: 0, isBackgroundWhite: true, image: 'https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg', price: '24,90 €', title: 'Formule entrée + plat + dessert (pas plus de 2 lignes)', description: 'Description de la formules pas plus de deux lignes de texte…',
},
    {
 cardId: 1, isBackgroundWhite: true, image: 'https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg', price: '24,90 €', title: 'Formule entrée + plat + dessert (pas plus de 2 lignes)', description: 'Description de la formules pas plus de deux lignes de texte…',
}];
    const cards2 = [{
 cardId: 7, filter: ['Sulfites', 'Souple et velouté', '13,5% alc./vol.'], offerTitle: 'Rupture', noStock: true, isBackgroundWhite: true, image: 'https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg', price: '24,90 €', title: 'Formule entrée + plat + dessert (pas plus de 2 lignes)', description: 'Description de la formules pas plus de deux lignes de texte…',
},
    {
 cardId: 8, filter: ['Sulfites', 'Souple et velouté', '13,5% alc./vol.'], lineThrough: true, noStock: true, isBackgroundWhite: true, image: 'https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg', price: '24,90 €', title: 'Formule entrée + plat + dessert (pas plus de 2 lignes)', description: 'Description de la formules pas plus de deux lignes de texte…',
}];
    return (
      <div>
        <SpecialOfferBox datas={datas}>
          <SpecialOfferTitle datas={datas} >
            Offres spéciales
          </SpecialOfferTitle>
          <SpecialOfferDesc datas={datas} >
            Informations supplémentaire de l’offre spécial sur pas plus 
            de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse.
          </SpecialOfferDesc>
          <Row>
            <Col sm="6" md="4" lg="12" style={{ paddingBottom: '15px' }}>
              <RestaurantThemeCard datas={datas} cardId={100} offerTitle="-20%" price="12,90 €" newPrice="10,90 €" title="Nom du produit sur deux Lignes maximum" description="Description du produit pas plus de deux lignes de texte" isBackgroundWhite image="https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg" />
            </Col>
            <Col sm="6" md="4" lg="12" style={{ paddingBottom: '15px' }}>
              <RestaurantThemeCard datas={datas} cardId={101} description="Description du produit pas plus de deux lignes de texte" newPrice="*Pour l’achat d’une formule" title="Nom du produit sur deux Lignes maximum" offerTitle="Offert*" newPrice="*Pour l’achat d’une formule" image="https://cdn.chefclub.tools/uploads/recipes/cover-hd/9f29e1a1-a732-45f9-a17e-20415aec6843_4QvlyRn.jpg" isBackgroundWhite />
            </Col>
          </Row>
        </SpecialOfferBox>
        <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
          <CategorieTheme
            datas={datas}
            idSCroll="formules"
            cardInformation={cards}
            title="Les entrées"
            description="Description de la section sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
            image="https://img.cuisineaz.com/680x357/2015/01/07/i64785-plats-familliaux.jpg"
          />
          <CategorieTheme
            datas={datas}
            cardInformation={cards2}
            title="Les boissons"
            description="Description de la section sur pas plus de trois lignes, limiter le nombre de caractères du champ pour pas que ça dépasse."
            image="https://img.cuisineaz.com/680x357/2015/01/07/i64785-plats-familliaux.jpg"
          />
        </div>
      </div>
    );
};

export default RestaurantThemeInformation;

const SpecialOfferBox = styled.div`
padding: 22px 15px 9px 15px;
background-color: ${(props) => props.datas.color};
`;

const SpecialOfferTitle = styled.div`
margin-left: 18px;
margin-bottom: 8px;
color: white;
font: ${(props) => `normal normal bold 25px ${props.datas.font}`};
`;

const SpecialOfferDesc = styled.p`
width: 65%;
@media (max-width: 576px) {
    width: 90%;
}
margin-bottom: 21px;
text-align: left;
margin-left: 18px;
font: ${(props) => `normal normal bold 15px ${props.datas.font}`};
color: white;
`;
