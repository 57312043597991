import { FirestoreDb, Storage } from './firebase/FirebaseModules'

/*
Helpers to work with image files
*/

/*
 * Create File from  Blob , return an Object with File and Url
 * @param {Blob} file
 * @param {String} filename
 * return {Object} ex : {url,file}
 */

export const getFileFromBlob = (file, fileName = null) => {
	let name = fileName !== null ? `${fileName}.${file.type.split('/')[1]}` : file.name

	file.lastModifiedDate = new Date()
	file.name = name
	return file
}

/*
 * Delete an image from firestore
 * @param {string} path
 * @param {string} firestore ref
 * @param {string} firestore field to reset
 */

export const deleteImage = async ({ url, collection, doc, field }) => {
	try {
		let datas = {}
		if (field) {
			datas[field] = null
		}
		const fileRef = await Storage().refFromURL(url)

		await fileRef.delete()
		if (field) {
			await FirestoreDb().collection(collection).doc(doc).set(datas, { merge: true })
		}
		return {
			status: 'success',
			message: 'Image deleted',
		}
	} catch (e) {
		return {
			status: 'error',
			message: e.message,
		}
	}
}
