import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Button, Spinner } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import styled, { withTheme } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, useController } from 'react-hook-form'
import { useCollection } from '@nandorojo/swr-firestore'
import PlaceContainer from '../../../stores/PlaceContainer'
import LangSelector from '../../../components/UI/form/LangSelector/LangSelector'
import AuthContainer from '../../../stores/AuthContainer'
import { FirestoreDb, Functions } from '../../../utils/firebase/FirebaseModules'
import FullscreenNavbar from '../../../components/UI/navbar/FullscreenNavbar'
import { Foods, Drinks, Others } from './view'
import { useStorageTools, useFirestoreApi } from '../../../hooks/FireTools'
import {
  updateMultilangArray,
  getMultilangContent,
  createMultilangArray,
  getMultilangContentMap,
} from '../../../utils/MultilangHelpers'
import ProductFieldsInterface from './productFieldsInterface'

const queryString = require('query-string')

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: 'white',
      },
      colorSecondary: {
        '&$checked': {
          color: 'white',
        },
      },
      track: {
        opacity: 0.4,
        backgroundColor: '#6F4FB3',
        '$checked$checked + &': {
          opacity: 1,
          backgroundColor: '#6F4FB3',
        },
      },
    },
  },
})

const navItems = ['foods', 'drinks', 'others']

const CreateProduct = ({ t }) => {
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const { place_id, lang, product_id, from, toProductDb } = queryString.parse(
    location.search
  )
  const [isOpen, setIsOpen] = useState(false)
  const [tab, setTab] = useState('foods')
  const { register, handleSubmit, control, setValue, unregister, reset, getValues } =
    useForm()
  const { user, userClaims } = AuthContainer.useContainer()
  const { data: pricesList } = useCollection(
    user?.uid ? `users/${user.uid}/prices` : null,
    {
      listen: true,
    }
  )

  const forProductDb = parseInt(toProductDb) === 1
  const prodCol = `products${forProductDb ? '-database' : ''}`
  const productRef = product_id
    ? FirestoreDb().collection(prodCol).doc(product_id)
    : FirestoreDb().collection(prodCol).doc()
  const { firebaseUploader, documentDeletionButton } = useStorageTools()
  const [product, setProduct] = useState({})
  const [saving, setSaving] = useState(false)
  const { place } = forProductDb
    ? {
        place: {
          id: 'ADMIN',
          main_language: 'FR',
          languages_array: ['CH', 'ES', 'DE'],
        },
      }
    : PlaceContainer.useContainer()
  const history = useHistory()
  let currentLang = lang || place?.main_language || 'FR'
  const {
    field: { onChange: onChangeAlergens },
  } = useController({ name: 'product_alergens', control })
  const {
    field: { onChange: onChangeLabels },
  } = useController({ name: 'product_labels', control })

  const mlFields = [
    'product_description',
    'product_name',
    'product_promo_free_conditions',
    'product_additional_infos',
    'drinks_datas',
  ]
  const mlFieldsMap = {
    drinks_datas: ['composition', 'advice', 'cuvee_name'],
  }

  const deleteImageButton = () => documentDeletionButton({
      server: false,
      resetFunction: () => {
        setProduct({ ...product, product_main_image_url: null })
        setValue('product_main_image', undefined)
      },
    })

  const syncProdsWithMenus = async (product_id) => {
    if (!forProductDb) {
      const _syncFunction = Functions().httpsCallable(
        'products-updateProductsInMenusOnCall'
      )

      return _syncFunction({
        data: {
          productId: product_id,
          action: 'updated',
        },
      })
        .then((data) => {
          if (data.data.status !== 'success') {
            enqueueSnackbar(t('sync_failed'), { variant: 'error' })
          }
        })
        .catch((e) => e)
    }
  }

  const sanitizeDbDatas = (datas) => {
    const _datas = { ...datas }
    Object.keys(_datas).map((k) => {
      switch (k) {
        case 'product_nostock_reapro':
          _datas[k] = _datas[k].toDate()
          break
        case 'created_at':
          _datas[k] = _datas[k].toDate()
          break
        default:
          _datas[k] = datas[k]
          break
      }
    })
    return _datas
  }

  const populateForm = (datas) => {
        console.log("rendering"); 

    let mlFieldsDisplay = {}
    mlFields.map((f) => {
      if (typeof datas[f] === 'object') {
        mlFieldsDisplay[f] = getMultilangContentMap(datas[f], currentLang)
      } else {
        mlFieldsDisplay[f] = getMultilangContent(datas, `${f}_ml`, currentLang)
      }
    })
    reset({
      ...datas,
      ...mlFieldsDisplay,
    })
  }

  useEffect(() => {
    if (product_id && place) {
      console.log("rendering")
      ;(async () => {
        let productDatas = {}
        try {

          productDatas = await productRef.get()
          productDatas = productDatas.data()
          setTab(productDatas.tab)
        } catch (e) {
          enqueueSnackbar('Error retrieving your product', { variant: 'error' })
        }

        const _datas = {
          ...sanitizeDbDatas(productDatas),
        }
        populateForm(_datas)
        setProduct(_datas)
      })()
    }
  }, [currentLang, product_id])

  useEffect(() => {
    if (Object.keys(product).length > 0) {
      populateForm(product)
    }
  }, [currentLang])

  const handleSaveAndQuit = async (datas) => {
    handleSaveProduct(datas)
    if (from) {
      history.push(decodeURIComponent(from))
    } else if (forProductDb) {
      history.push(`/admin/product_base`)
    } else {
      history.push(`/dashboard/edit/menus?place_id=${place_id}&tabs=products`)
    }
  }

  const handleSaveProduct = async (datas) => {
    setSaving(true)

    let product_main_image_url = product.product_main_image_url
      ? product.product_main_image_url
      : null
    if (datas.product_main_image) {
      try {
        const mainImage = await firebaseUploader(
          [datas.product_main_image],
          `users/${user.uid}/products/${productRef.id}`,
          ['product_main_image']
        )
        product_main_image_url = mainImage[0].url
      } catch (e) {
        enqueueSnackbar(t('product_image_error_save'), { variant: 'error' })
      }
    }

    const fieldsFormat = new ProductFieldsInterface(
      datas,
      mlFields,
      mlFieldsMap,
      product_id ? 'edit' : 'create',
      product,
      [place.main_language, ...place.languages_array],
      currentLang,
      {
        place_id: place.id,
        uid: user.uid,
        tab,
        created_at: !product_id ? new Date() : product.created_at,
        modified_at: new Date(),
        order: !product_id ? new Date().getTime() : product.order,
        product_main_image_url,
      }
    )

    try {
      await productRef.set(fieldsFormat.getFields(), { merge: true })
      if (product_id) {
        syncProdsWithMenus(product_id)
      }
      enqueueSnackbar(t('succeed_saving_product'), { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(t('failed_saving_product'), { variant: 'error' })
    }

    setSaving(false)
  }

  const displayView = () => {
    const _props = {
      register,
      control,
      unregister,
      setValue,
      product,
      onChangeAlergens,
      onChangeLabels,
      pricesList,
      deleteImageButton,
    }
    switch (tab) {
      case 'foods':
        return <Foods {..._props} />
      case 'drinks':
        return <Drinks {..._props} />
      case 'others':
        return <Others {..._props} />
      default:
        return <Foods {..._props} />
    }
  }
  return (
    <>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>
            {t('sure_to')}
          </ModalTitle>
          <ModalSubTitle>
              {t('sure_to description')}
          </ModalSubTitle>
          <FlexModal>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => {
                setIsOpen(false)
                if (forProductDb) {
                  history.push('/admin/product_base')
                }
              }}
            >
             {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => {
                if (from) {
                  history.push(decodeURIComponent(from))
                } else {
                  history.push(
                    forProductDb
                      ? `/admin/product_base`
                      : `/dashboard/edit/menus?place_id=${place_id}&tabs=products`
                  )
                }

                setIsOpen(false)
              }}
            >
              {t('confirm')}
            </Button>
          </FlexModal>
        </Modal.Body>
      </Modal>

      <form onSubmit={handleSubmit(handleSaveAndQuit)}>
        <Title>{product_id ? product.product_name : t('new product')}</Title>
        <FullscreenNavbar items={navItems} setTab={setTab} tab={tab} t={t} />
        <ThemeProvider theme={theme}>
          <br />
          {product_id && (
            <LangSelector
              action={handleSubmit(handleSaveProduct)}
              placeDatas={place}
              lang={lang}
              product_id={product_id}
              id={place_id}
              productDatabase={forProductDb}
            />
          )}
          {displayView()}
        </ThemeProvider>
        <BottomBar>
          <div style={{ width: '100%', alignSelf: 'center' }}>
            <CancelButton
              variant="primaryoutline"
              href="#"
              onClick={() => setIsOpen(true)}
            >
              {t('cancel')}
            </CancelButton>

            <LeftContainer>
              <SaveButton variant="primary" type="submit" disabled={saving}>
                {saving ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : product_id ? (
                  t('edit product')
                ) : (
                  t('create product')
                )}
              </SaveButton>
            </LeftContainer>
          </div>
        </BottomBar>
      </form>
    </>
  )
}

export default withTheme(withTranslation()(CreateProduct))

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16pt;
  margin: 20px 0px;
`
const FlexModal = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`
const BottomBar = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 70px;
  padding: 0px 40px;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
`
const CancelButton = styled(Button)`
  width: 150px;
  height: 45px;
  padding-top: 10px;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const SaveButton = styled(Button)`
  width: 200px;
  height: 45px;
  padding-top: 10px;
  font-weight: bold;
  box-shadow: 4px -4px 20px 3px rgba(0, 0, 0, 0.08);
`
const LeftContainer = styled.div`
  float: right;
`
