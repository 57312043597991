import React from 'react';
import { withTranslation } from 'react-i18next';
import {
 Container, Col, Row, Card, Button,
} from 'react-bootstrap';
import { PowerSettingsNewOutlined } from '@material-ui/icons';
import styled, { withTheme } from 'styled-components';

import AuthContainer from '../../../stores/AuthContainer';

const DashboardSettings = ({ setTitle, t }) => {
const AuthState = AuthContainer.useContainer();

 React.useEffect(() => {
setTitle(`<b>${t('settings')}</b>`);
}, [setTitle, t]);

  return (
    <CustomContainer fluid>
      <Row>
        <Col lg="3" >
          <Card bsPrefix="card white-card">
            <Card.Title>
              Title
            </Card.Title>
            <Card.Subtitle>
              Subtitle
            </Card.Subtitle>
          </Card>

        </Col>
      </Row>
    </CustomContainer>
  );
};

export default withTheme(withTranslation()(DashboardSettings));

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media(min-width  : 992px) {
		padding-left: 250px;
    }
`;

const PurpleContainer = styled.div`

`;

const SelectContainer = styled.div``;

const SwitchContainer = styled.div``;

const HalfInputsContainer = styled.div`
    display: flex;
`;