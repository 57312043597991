import React from 'react'
import { Container, Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'

import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { DefaultSeparator } from '../../../components/UI/Separators/Separators'
import { useLogin } from '../../../hooks/Facebook'
import firebase from 'firebase/app'
import useGoogle from '../../../hooks/GoogleAuth'
import { FirestoreDb } from '../../../utils/firebase/FirebaseModules'

const openInNewTab = (url) => {
  const newWindow = window.open(
    url.split(':')[0] === 'https' ? url : `https://${url}`,
    '_blank',
    'noopener,noreferrer'
  )
  if (newWindow) newWindow.opener = null
}

const SignUpForm = ({ t, theme }) => {
  const { facebookButton } = useLogin()
  const { googleLoginButton, signUpWithEmail, signUpWithEmailError } = useGoogle()
  const recaptcha = React.useRef(null)
  const [showSubmit, setShowSubmit] = React.useState(true)
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [signinUp, setsigninUp] = React.useState(false)

  const recaptchaVerif = (datas) => {
    setsigninUp(true)
    setShowSubmit(false)

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      recaptcha.current,
      {
        size: 'normal',
        callback: async (response) => {
          await handleCreateAccount(datas)
          setShowSubmit(true)
        },
        'expired-callback': () => {
          setShowSubmit(true)
        },
      }
    )
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId
    })

    setsigninUp(false)
  }

  const handleCreateAccount = async (datas) => {
    setsigninUp(true)

    const { email, password } = datas
    const { compagny_name, firstname, lastname, phone } = datas

    // register additionnal account datas
    const signUp = await signUpWithEmail(email, password)
    if (signUp.success) {
      await FirestoreDb()
        .collection('users')
        .doc(signUp.userCredential.user.uid)
        .set(
          {
            compagny_name,
            firstname,
            lastname,
            phone,
          },
          { merge: true }
        )
        .then()
        .catch((e) => console.log(e))
    } else {
      recaptcha.current.innerHTML = ''
      setsigninUp(false)
    }
    setsigninUp(false)
  }
  return (
    <SignUpFormStyled>
      <div
        style={{ margin: '24px 0px 0px 0px', fontWeight: 'bold', fontSize: '17pt' }}
      >
        {t('Create your account2')}
      </div>
      <div
        style={{
          fontSize: '12pt',
          fontStyle: 'italic',
          fontFamily: 'LATO',
          marginBottom: '28px',
          color: '#8155c5',
        }}
      >
        {t('15 days free')}
      </div>
      {signUpWithEmailError !== null && <Alert>{signUpWithEmailError}</Alert>}
      <Form onSubmit={handleSubmit(recaptchaVerif)}>
        <Form.Group>
          <Form.Control
            isInvalid={errors.compagny_name}
            {...register('compagny_name', { required: true })}
            placeholder={t('compagny name')}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {t('compagny name required')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors.lastname}
            {...register('lastname', { required: true })}
            placeholder={t('lastname')}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {t('lastname required')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors.firstname}
            {...register('firstname', { required: true })}
            placeholder={t('firstname')}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {t('firstname required')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors.email}
            {...register('email', { required: true })}
            placeholder={t('Email address')}
            type="email"
            autoComplete="username"
          />
          <Form.Control.Feedback type="invalid">
            {t('Email address required')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors.phone}
            {...register('phone', { required: true })}
            placeholder={t('phone')}
            autoComplete="tel"
            type="tel"
          />
          <Form.Control.Feedback type="invalid">
            {t('phone required')}
          </Form.Control.Feedback>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Control
            isInvalid={errors.password}
            {...register('password', { required: true })}
            placeholder={t('Password')}
            type="password"
            autoComplete="new-password"
          />
          <Form.Text className="text-muted">{t('8 caracters min')}</Form.Text>
          <Form.Control.Feedback type="invalid">
            {t('Password required')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Control
            isInvalid={errors.password_confirm}
            {...register('password_confirm', {
              required: true,
              validate: (value) => value === getValues('password'),
            })}
            placeholder={t('confirm password')}
            type="password"
            autoComplete="new-password"
          />
          <Form.Control.Feedback type="invalid">
            {t('confirm password required')}
          </Form.Control.Feedback>
        </Form.Group>
        <div style={{ textAlign: 'center' }}>
          <div ref={recaptcha} />
          {showSubmit && (
            <SignUpButton disabled={signinUp} type="submit" size="lg">
              {signinUp ? (
                <>
                  <Spinner animation="border" variant="light" size="sm" />{' '}
                  {t('Create your account')}
                </>
              ) : (
                t('Create your account')
              )}
            </SignUpButton>
          )}
          <div style={{ textAlign: '-webkit-center' }}>
            <ThermsAndConditions>
              {` ${t('accept tcu')}`}
              <LinkK
                onClick={() => openInNewTab('https://monmenu360.fr/conditions/')}
              >
                {t('accept tcu2')}
              </LinkK>{' '}
              {t('accept tcu3')}
            </ThermsAndConditions>
          </div>
        </div>
      </Form>
      <DefaultSeparator>{t('or')}</DefaultSeparator>
      <Row>
        <Col>{facebookButton(t('Connect whith fb'))}</Col>
        <Col>{googleLoginButton(t('Connect whith google'))}</Col>
      </Row>
      <Row style={{ marginBottom: '20px' }}>
        <Col style={{ marginTop: '40px', fontSize: '12pt', textAlign: 'center' }}>
          {t('already register')} &nbsp;
          <Link style={{ color: '#6F4FB3', fontWeight: 'bold' }} to="/login">
            {t('login')}
          </Link>
        </Col>
      </Row>
    </SignUpFormStyled>
  )
}

export default withTheme(withTranslation()(SignUpForm))

const SignUpFormStyled = styled(Container)`
  background: white;
  padding: 10px 40px;
  border-radius: 20px;
  @media screen and (min-width: 1200px) {
    margin-left: 40px;
  }
  box-shadow: 0 0 7px #00000029;
`
const SignUpButton = styled(Button)`
  margin-top: 40px;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 20px;
`
const ThermsAndConditions = styled.div`
  margin-top: 20px;
  width: 70%;
  font-size: 9pt;
  color: grey;
  font-weight: 400;
`
const LinkK = styled.div`
  display: contents;
  color: #0645ad;
  text-decoration: underline;
  cursor: pointer;
`
