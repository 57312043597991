import React, { useEffect } from 'react'
import { Functions } from '../../../utils/firebase/FirebaseModules'
import { Container, Card } from '@material-ui/core'
import { useFirestoreApi } from '../../../hooks/FireTools'
import Products from '../../DashboardClient/EditMenus/view/products'
import { useTranslation } from 'react-i18next'

const DashboardAdminProductDatabase = (props) => {
	const { t } = useTranslation()
	return (
		<Container>
			<Card bsPrefix="card white-card">
				<Products t={t} productDatabase />
			</Card>
		</Container>
	)
}

export default DashboardAdminProductDatabase
