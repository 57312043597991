import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container, Row, Col, Card } from 'react-bootstrap'
import moment from 'moment'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import styled, { withTheme } from 'styled-components'
import useAlgolia from '../../../hooks/Algolia'
import AuthContainer from '../../../stores/AuthContainer'
import ClassicTableAlgolia from '../../../components/UI/tables/ClassicTableAlgolia'
import AlSearchBox from '../../../components/UI/form/algolia/AlSearchBox/AlSearchBox'
import AlSelect from '../../../components/UI/form/algolia/AlSelect/AlSelect'

const Subscriptions = ({ setTitle, t }) => {
  const { user } = AuthContainer.useContainer()
  const { AlgoliaClient } = useAlgolia()

  React.useEffect(() => {
    setTitle(`${t('subscriptions')} > <b>${t('my subscriptions')}</b>`)
  }, [setTitle, t])

  return (
    <CustomContainer fluid>
      <Row>
        <InstantSearch indexName="subscription_index" searchClient={AlgoliaClient}>
          <Configure filters={`uid:${user.uid}`} hitsPerPage={15} />
          <Col>
            <Card bsPrefix="card white-card">
              <Row style={{ marginBottom: '15px ' }}>
                <Col lg="7">
                  <AlSearchBox
                    translations={{
                      placeholder: `${t('place')}, ${t('status')}, ${t('type')}`,
                    }}
                    autoFocus
                  />
                </Col>
                <Col
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <AlSelect
                    translations={{ seeAllOption: t('Status') }}
                    attribute="status"
                  />
                  <AlSelect
                    translations={{ seeAllOption: t('subscriptions') }}
                    transformItems={(items) =>
                      items.map((item) => ({
                        ...item,
                        label: item.label === 'month' ? t('month_1') : t('year_1'),
                      }))
                    }
                    attribute="interval"
                  />
                  <AlSelect
                    translations={{ seeAllOption: t('Type') }}
                    transformItems={(items) =>
                      items.map((item) => ({
                        ...item,
                        label:
                          item.label === 'place_subscription'
                            ? t('place')
                            : 'QrCodes',
                      }))
                    }
                    attribute="subscription_type"
                  />
                </Col>
              </Row>
              <ClassicTableAlgolia
                type="algolia"
                headValues={[
                  { name: 'place_name', label: 'place name' },
                  { name: 'subscription_type', label: 'type' },
                  { name: 'status', label: 'status' },
                  // { name: 'quantity', label: 'quantity' },
                  { name: 'created', label: 'created at' },
                  { name: '', label: ' ' },
                ]}
                actions={[
                  {
                    link: '/dashboard/subscriptions',
                    link_id: 'place_id',
                    name: 'edit',
                  },
                ]}
                dataTypes={[
                  'place_name',
                  'subscription_type',
                  'status',
                  // 'quantity',
                  'created',
                ]}
                format={{
                  subscription_type: (type) =>
                    type === 'qr_code' ? 'Qr Codes' : 'Abonnement établissement',
                  created: (date) =>
                    moment(date * 1000).format('DD/MM/YYYY à HH:mm'),
                }}
              />
            </Card>
          </Col>
        </InstantSearch>
      </Row>
    </CustomContainer>
  )
}

export default withTheme(withTranslation()(Subscriptions))

const CustomContainer = styled(Container)`
  margin-top: 80px;
  @media (min-width: 992px) {
    padding-left: 250px;
  }
`
