import React from 'react'
import { useCollection } from '@nandorojo/swr-firestore'

import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import Footer from '../../components/Menus/Footer/Footer'
import Header from '../../components/Menus/Header/Header/Header'
import HeaderCard from '../../components/Menus/Restaurant/HeaderCard/HeaderCard'
import HeaderPDFCard from '../../components/Menus/Restaurant/HeaderPDFCard/HeaderPDFCard'
import SubMenu from '../../components/Menus/Restaurant/SubMenu/SubMenu'
import RestaurantFooter from '../../components/Menus/Restaurant/RestaurantFooter/RestaurantFooter'
import RestaurantInformation from '../../components/Menus/Restaurant/RestaurantInformation/RestaurantInformation'

import useMenuClientContainer from '../../stores/MenuClientContainer'
import { getMultilangContent } from '../../utils/MultilangHelpers'

const MenuView = ({ t }) => {
  const { place, menus, selected_lang, menu_id } =
    useMenuClientContainer.useContainer()

  const { data: sections } = useCollection(
    place?.id ? `places/${place.id}/menus/${menu_id}/sections` : null,
    {
      // where: ['group_visible', '==', false],
      orderBy: ['order', 'asc'],
      listen: true,
    }
  )

  if (!place || !menus || !sections) {
    return <Spinner animation="border" size="sm" variant="dark" />
  } else {
    const menu = menus.find((k) => k.id === menu_id)
    const datas = {
      color: menu.menu_specific_theme ? menu.theme_color : place.theme_color,
      font: menu.menu_specific_theme ? menu.theme_font : place.theme_font,
      darkmode: menu.menu_specific_theme
        ? menu.theme_darkmode
        : place.theme_darkmode,
      topbanner: menu.menu_specific_theme
        ? menu.theme_topbanner
        : place.theme_topbanner,
    }

    if (!menu) {
      return <div>This menu doesn't exists</div>
    } else
      return menu.type === 0 ? (
        <MaxBox>
          <Helmet
            bodyAttributes={{
              style: `background-color : ${datas.darkmode ? `#292C2B` : '#E7F2EF'}`,
            }}
          />
          <Header
            menus={menus}
            place={place}
            place_id={place.id}
            datas={datas}
            mode={false}
          />
          <HeaderCard menuInfo={menu} place={place} datas={datas} />
          <SubMenu sections={sections} lang={selected_lang} datas={datas} />
          <Box>
            <RestaurantInformation
              menus={menus}
              sections={sections}
              lang={selected_lang}
              datas={datas}
            />
            <RestaurantFooter
              datas={datas}
              text={
                menu.footer_ml
                  ? getMultilangContent(menu, 'footer_ml', selected_lang)
                  : null
              }
            />
          </Box>
          <Footer menu={menu} place={place} datas={datas} mode={false} />
        </MaxBox>
      ) : (
        <MaxBox>
          <Helmet
            bodyAttributes={{
              style: `background-color : ${datas.darkmode ? `#292C2B` : '#E7F2EF'}`,
            }}
          />
          <Header
            menus={menus}
            place={place}
            place_id={place.id}
            datas={datas}
            mode={false}
          />
          <HeaderPDFCard
            menuInfo={menu}
            menu={menu}
            place={place}
            datas={datas}
            lang={selected_lang}
          />
          <RestaurantFooter
            datas={datas}
            text={
              menu.footer_ml
                ? getMultilangContent(menu, 'footer_ml', selected_lang)
                : null
            }
          />
          <Footer menu={menu} place={place} datas={datas} mode={false} />
        </MaxBox>
      )
  }
}

export default withTranslation()(MenuView)

const Box = styled.div`
  background-color: #e7f2ef;
  scroll-behavior: smooth;
`
const MaxBox = styled.div`
  max-width: 900px;
  margin: 0 auto;
`