import { Container, Row, Col } from 'react-bootstrap';
import Banner from '../Banner/Banner';
import SlideUp from '../SlideUp/SlideUp';
import ContactInformation from '../Contact/ContactInformation';
import Horaire from './Horaire/Horaire';

const Footer = ({ t, place, datas, mode, menu }) => (
  <footer style={{
      backgroundColor: datas.darkmode && 'black',
      paddingTop: '20px',
      position: mode ? 'absolute' : 'inherit',
      bottom: mode && 0,
      width: '100%',
    }}>
    <Container fluid>
      <Row>
        <Col lg="6">
          <div style={{marginTop: '8px'}}/>
          <Row>
            {place.place_delivery_hours && 
              place.place_delivery_hours.length > 0 &&
                !place.place_delivery_hours_enable
            ? (
              <Col lg="12">
                <SlideUp 
                  darkmode={datas.darkmode} 
                  key={0} 
                  title="Horaire Livraison" 
                  subComponent={
                    <Horaire 
                      darkmode={datas.darkmode} 
                      hours={place.place_delivery_hours}
                    />
                  } 
                  TitlefontSize="15px" 
                  styleContainer={{ marginBottom: '13px' }} 
                  styleTitle={{ borderBottom: `solid ${datas.darkmode ? 'white': '#1D1D1D'} 1px`, fontSize: '15px !importante' }} 
                  />
              </Col>
                ) : null}
            {place.place_hours && 
              place.place_hours.length > 0 &&
                !place.place_hours_enable
            ? (
              <Col lg="12">
              {menu?.menu_hours_enable ? 
                <SlideUp 
                  darkmode={datas.darkmode} 
                  key={0} 
                  title="Horaire du menu" 
                  subComponent={
                    <Horaire 
                      darkmode={datas.darkmode} 
                      hours={menu.menu_hours}
                    />
                  } 
                  TitlefontSize="15px" 
                  styleContainer={{ marginBottom: '13px' }} 
                  styleTitle={{ borderBottom: `solid ${datas.darkmode ? 'white': '#1D1D1D'} 1px`, fontSize: '15px !importante' }} 
                  /> : 
                <SlideUp 
                  darkmode={datas.darkmode} 
                  key={1} 
                  title="Horaire de l'établissement" 
                  subComponent={
                    <Horaire 
                      darkmode={datas.darkmode} 
                      hours={place.place_hours} 
                    />
                  } 
                  TitlefontSize="15px" 
                  styleContainer={{ marginBottom: '25px' }} styleTitle={{ borderBottom: `solid ${datas.darkmode ? 'white': '#1D1D1D'} 1px`, fontSize: '15px !importante' }} 
                />
                }
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col lg={(place.place_delivery_hours && 
                    place.place_delivery_hours.length > 0 && 
                      !place.place_delivery_hours_enable) || 
                  (place.place_hours && 
                    place.place_hours.length > 0 && 
                      !place.place_hours_enable) ? "6" : "12"}>
          <div style={{
              marginBottom: '13px', 
              paddingBottom: '5px',
              borderBottom: `solid 1px ${datas.darkmode ? 'white': '#1D1D1D'}`, 
              fontSize: '18px', 
              fontWeight: 'bold',
              color: datas.darkmode && 'white',
            }}
          >
            Contact
          </div>
          <ContactInformation 
            darkmode={datas.darkmode}
            fontColor={datas.color}
            items={place}
            isCentered={false}
            marginBottom="10px"
          />
        </Col>
      </Row>
    </Container>
    <br/>
    <Banner text="© Mon Menu 360 - 2021" fontSize="14px" />
  </footer>
);

export default Footer;
