import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import { RadioGroup } from '@material-ui/core'
import { BigRadioCheckbox } from '../../../../../../components/UI/form/BigCheckbox/BigCheckbox'
import useStripe from '../../../../../../hooks/Stripe'
import UseCreatePlaceContainer from '../../../CreateAPlaceStore'

const theme = createTheme({
  palette: {
    primary: { main: '#6F4FB3' },
    secondary: { main: '#6F4FB3' },
  },
})

const config = require('../../../../../../config')

const PlacesPlan = ({ product, t }) => {
  const CreatePlaceState = UseCreatePlaceContainer.useContainer()
  const [prices, setPrices] = React.useState(null)
  const [selectedPrice, setSelectedPrice] = React.useState('')
  const { getPrices } = useStripe()
  const control = useForm()

  useEffect(() => {
    ;(async () => {
      try {
        const _prices = await getPrices(product.id)
        _prices.splice(1, 0, _prices.splice(2, 1)[0])
        setPrices(_prices)
      } catch (e) {
        console.error('can not retrieve prices', e.message)
        return
      }
    })()
  }, [product.id])

  const handleChange = (price) => {
    setSelectedPrice(price.id)
    CreatePlaceState.addProduct({ ...product, price }, 'unique')
  }

  return (
    <ThemeProvider theme={theme}>
      <RadioGroup value={selectedPrice}>
        {prices &&
          prices.map((price) => {
            const interval = t(
              `${price.recurring.interval}_${price.recurring.interval_count}`
            )
            const description = t(price.nickname)
            return (
              <BigRadioCheckbox
                key={price.id}
                selected={selectedPrice === price.id}
                title={interval}
                subtitle={description}
                price={parseFloat(
                  price.unit_amount / 100 / config.taxes.tax_20
                ).toFixed(0)}
                currency="€"
                priceMeta={`HT / ${interval.charAt(0).toLowerCase()}`}
                subPriceInfo={t(
                  `${price.recurring.interval}_${price.recurring.interval_count} free`
                )}
                name="placePlans"
                type="radio"
                change={() => handleChange(price)}
              />
            )
          })}
      </RadioGroup>
    </ThemeProvider>
  )
}

export default React.memo(withTranslation()(PlacesPlan))
