import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Navbar, Button } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';
import { MenuOutlined, Reply } from '@material-ui/icons';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import DisplayContainer from '../../../stores/DisplayContainer';
import AuthContainer from '../../../stores/AuthContainer';

// eslint-disable-next-line import/no-extraneous-dependencies
const queryString = require('query-string');

const TopMenu = ({ title, t }) => {
  const { setDashboardMenuShow } = DisplayContainer.useContainer();
  const AuthState = AuthContainer.useContainer();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { place_id } = queryString.parse(location.search);
  const history = useHistory();

  const displayMenu = () => (
    <>
      <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
        <Modal.Body style={{ padding: '5%' }}>
          <ModalTitle>
            {t('sure_to')}
          </ModalTitle>
          <ModalSubTitle>
            {t('sure_to description')}
          </ModalSubTitle>
          <Flex>
            <Button
              variant="primary"
              style={{ width: '40%' }}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="secondary"
              style={{ width: '40%' }}
              onClick={async () => {
                if (history.location.pathname.split('/')[4]?.split('?')[0] === 'formula'
                || history.location.pathname.split('/')[3]?.split('?')[0] === 'formula')
                  history.push(`/dashboard/edit/menus?place_id=${place_id}&tabs=formulas`);
                else
                  history.push(`/dashboard/edit/menus?place_id=${place_id}`);
                setIsOpen(false);
              }}
            >
              {t('confirm')}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
      <ShowMenuContainer className="justify-content-start">
        <ShowMenu onClick={() => setDashboardMenuShow(true)}>
          <MenuOutlined />
        </ShowMenu>
      </ShowMenuContainer>
      {(history.location.pathname.split('/')[3] === 'new'
        || history.location.pathname.split('/')[3] === 'menu' 
        || history.location.pathname.split('/')[3]?.split('?')[0] === 'formula') && (
        <GoBack onClick={() => setIsOpen(true)}>
          <Reply style={{ color: '#6F4FB3', width: '20px' }} />
          <GoBackTitle>
            {t('return')}
          </GoBackTitle>
        </GoBack>
      )}
      <Navbar.Brand dangerouslySetInnerHTML={{ __html: title }} />
      <Navbar.Collapse className="justify-content-end">
        <AvatarStyled>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AvatarLink to="/dashboard/profile">
              <Avatar src={AuthState.user && AuthState.user.photoURL}>
                {AuthState.user
                  && AuthState.user.displayName
                  && AuthState.user.displayName
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                    .toUpperCase()}
                {AuthState.user
                  && AuthState.userDatas
                  && !AuthState.user.displayName
                  && `${AuthState.userDatas.firstname} ${AuthState.userDatas.lastname}`
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                    .toUpperCase()}
              </Avatar>
            </AvatarLink>
            <div
              style={{ marginLeft: '1rem', marginRight: '1rem' }}
              onClick={() => history.push('/dashboard/profile')}
            >
              <div style={{ fontSize: '12pt', fontWeight: 'bold' }}>
                {AuthState.user && AuthState.user.displayName}
              </div>
              <div style={{ fontSize: '9pt', marginTop: '-3pt' }}>
                {t('administrator')}
              </div>
            </div>
          </div>
        </AvatarStyled>
      </Navbar.Collapse>
    </>
  );
  if (history.location.pathname.split('/')[2] === 'edit') {
    return <TopEditMenuStyled>{displayMenu()}</TopEditMenuStyled>;
  } else {
    return <TopMenuStyled>{displayMenu()}</TopMenuStyled>;
  }
};

export default withTheme(withTranslation()(TopMenu));

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const ShowMenuContainer = styled(Navbar.Collapse)`
  &.navbar-collapse {
    @media (min-width: 991px) {
      display: none !important;
    }
  }
`;
const GoBack = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 20px;
  background-color: #eceaff;
  border-radius: 7px;
  padding: 4px 8px 4px 5px;
`;
const GoBackTitle = styled.div`
  color: ${(props) => props.primary};
  margin-left: 5px;
  margin-top: 1px;
  font-weight: bold;
  font-size: 11pt;
`;
const ModalTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 17pt;
  margin-bottom: 10px;
`;
const ModalSubTitle = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 12pt;
  margin-bottom: 30px;
`;
const AvatarLink = styled(NavLink)`
  &.active {
    border: 4px solid ${(props) => props.theme.secondary};
  }
  border: 4px solid transparent;
  border-radius: 101px;
`;
const ShowMenu = styled.div`
  svg {
    color: ${(props) => props.theme.secondary};
    font-size: 35px;
    margin-left: 8px;
    transition: ease 400ms;
    &:hover {
      color: ${(props) => props.theme.primary};
      cursor: pointer;
    }
  }
`;
const AvatarStyled = styled.div`
  cursor: pointer;

  svg {
    transition: ease 300ms;
    position: relative;
  }

  &:hover {
    svg {
      top: 3px;
    }
  }
`;
const TopMenuStyled = styled(Navbar)`

background-color: rgba(237,234,255, 0.5);
position : absolute ; 
width  100% ; 
padding-left : 260px ; 
z-index : 10; 
 @media(max-width  : 991px){
  padding-left : 0 ; 
}
`;
const TopEditMenuStyled = styled(Navbar)`

background-color: rgba(237,234,255, 0.5);
position : absolute ; 
width  100% ; 
padding-left : 140px ; 
z-index : 10; 
 @media(max-width  : 991px){
  padding-left : 0 ; 

}
`;

TopMenu.propTypes = {
  title: PropTypes.string,
};
TopMenu.defaultProps = {
  title: 'Page Title',
};
