import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { KeyboardBackspace } from '@material-ui/icons'
import useMenuClientContainer from '../../../../stores/MenuClientContainer'

import Menu from '../Menu/Menu'
import './style.css'

const Header = ({ t, menus, place, place_id, mode, datas }) => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const { selected_lang, menu_id } = useMenuClientContainer.useContainer()
  return (
    <header className="header__handler">
      <div style={{ backgroundColor: datas.darkmode && 'black' }} className="header">
        {mode ? (
          <div className="header__menu" onClick={() => setIsOpen(!isOpen)}>
            <div
              style={{ backgroundColor: datas.darkmode && 'white' }}
              className={`header__menu__stick ${
                isOpen && 'header__menu__stick__open'
              }`}
            />
            <div
              style={{ backgroundColor: datas.darkmode && 'white' }}
              className={`header__menu__stick ${
                isOpen && 'header__menu__stick__open'
              }`}
            />
            <div
              style={{ backgroundColor: datas.darkmode && 'white' }}
              className={`header__menu__stick ${
                isOpen && 'header__menu__stick__open'
              }`}
            />
          </div>
        ) : (
          <div
            className="header__menu"
            onClick={() => history.push(`/place/${place.place_slug}?lang=${selected_lang}`)}
          >
            <KeyboardBackspace style={{ color: datas.darkmode && 'white' }} />
          </div>
        )}
        {place.place_logo && 
          <div
            className="header__menu__img__container"
            // url="/_"
            onClick={() => history.push(`/place/${place.place_slug}?lang=${selected_lang}`)}
          >
            <img
              style={{ cursor: 'pointer' }}
              className="header__menu__img"
              src={place.place_logo}
              alt="Logo"
            />
          </div>
        }
        <div className="header__select">
          <select
            style={{
              backgroundColor: datas.darkmode && 'black',
              color: datas.darkmode && 'white',
              borderStyle: 'none',
            }}
            onChange={(e) => {
              if (menu_id) {
                history.push(
                  `/place/${place.place_slug}/menu/${menu_id}?lang=${e.target.value}`
                )
              } else {
                history.push(`/place/${place.place_slug}?lang=${e.target.value}`)
              }
            }}
          >
            {[place.main_language, ...place.languages_array].map((lang) => (
              <option selected={lang === selected_lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Menu
        menus={menus}
        place={place}
        place_id={place_id}
        isOpen={isOpen}
        mode={mode}
        setIsOpen={setIsOpen}
      />
    </header>
  )
}

export default Header
