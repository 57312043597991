import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Dropdown, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const ClassicList = ({ sort, datas, t, dataTypes = [], actions = [], format }) => (
  <>
    {datas !== null &&
      sort(datas).map((data, i) => (
        <TableRow key={data.id}>
          {dataTypes.map((d, i) => (
            <TableCell key={`datatype-${i}`}>
              {format !== null && format[d] !== undefined
                ? format[d](data[d])
                : data[d]}
            </TableCell>
          ))}
          {actions.length > 0 && (
            <TableCell>
              <Dropdown>
                <Dropdown.Toggle as="div" id="dropdown-custom-components">
                  <IconButton>
                    <SettingsIcon />
                  </IconButton>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {actions.map((action, i) => (
                    <Dropdown.Item as="div" key={`action-item-${i}`}>
                      {action.func !== undefined ? (
                        <a
                          href="#"
                          onClick={() => action.func(data[action.params])}
                          variant="default"
                        >
                          {t(action.name)}
                        </a>
                      ) : (
                        <Link to={`${action.link}/${data[action.link_id]}`}>
                          {t(action.name)}
                        </Link>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </TableCell>
          )}
        </TableRow>
      ))}
  </>
)

export default React.memo(withTranslation()(ClassicList))
