import * as geofirestore from 'geofirestore'
import firebase from 'firebase/app'
import myFirebase from './MyFirebase'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/app-check'
import 'firebase/messaging'
import 'firebase/storage'
import 'firebase/analytics'

const emulation = false

export const FirestoreDb = () => {
	const db = myFirebase.firestore()
	/* if (emulation) {
		if (window.location.hostname === 'localhost') {
			if (typeof window === 'undefined' || !window._init) {
				db.useEmulator('localhost', 8080)

				if (typeof window !== 'undefined') {
					window._init = true
				}
			}
		}
	} */
	return db
}
export const Analytics = () => myFirebase.analytics()
export const Messaging = () => myFirebase.messaging()

export const AppCheck = (publicKey) => {
	const appCheck = myFirebase.appCheck()

	appCheck.activate(publicKey)
}

export const Auth = () => {
	let auth = myFirebase.auth()
	/* if (emulation) {
		if (window.location.hostname === 'localhost') {
			if (typeof window === 'undefined' || !window._init) {
				auth.useEmulator('localhost', 9099)

				if (typeof window !== 'undefined') {
					window._init = true
				}
			}
		}
	} */
	return auth
}

export const Functions = () => {
	let func = myFirebase.functions()

	if (emulation) {
		if (window.location.hostname === 'localhost') {
			if (typeof window === 'undefined' || !window._init) {
				func.useEmulator('localhost', 5002)

				if (typeof window !== 'undefined') {
					window._init = true
				}
			}
		}
	}
	return func
}

export const Storage = () => myFirebase.storage()
