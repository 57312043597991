import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import ProductCard from '../../../../../components/Dashboard/Menus/Cards/ProductCard'

const ProductDraggable = ({
	key,
	index,
	category_index,
	functions,
	prod,
	group_index,
	currentLang,
}) => (
	<Draggable draggableId={`${prod.id}-${group_index}`} index={index}>
		{(provided, snapshot) => (
			<CustomCol
				sm="12"
				md="12"
				ref={provided.innerRef}
				{...provided.dragHandleProps}
				{...provided.draggableProps}
			>
				<ProductCard
					{...prod}
					index={index}
					props={prod}
					currentLang={currentLang}
					actions={[
						{
							function: () => functions.duplicateProduct(prod.pid),
							icon: 'duplicate',
						},
						{ function: () => functions.editProd(prod.pid), icon: 'edit' },
						{ function: () => functions.delete(prod), icon: 'trash' },
					]}
				/>
				{provided.placeholder}
			</CustomCol>
		)}
	</Draggable>
)

export default ProductDraggable

const CustomCol = styled(Col)`
	margin-bottom: 20px !important;
`
