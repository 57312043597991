import styled from 'styled-components'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import './style.css'
import Modal from 'react-bootstrap/Modal'
import Badge from '../Badge/Badge'
import { getMultilangContent } from '../../../../../utils/MultilangHelpers'
import useMenuClientContainer from '../../../../../stores/MenuClientContainer'
import { withTranslation } from 'react-i18next';

export const RestaurantCardModal = ({ t, isOpen, setIsOpen, currentCard, datas, currentLang }) => {
  var { selected_lang } = currentLang || useMenuClientContainer.useContainer()

  return (
    <Modal show={isOpen} onHide={setIsOpen} style={{ width: '100%' }}>
      <Modal.Body scrollable style={{ padding: '0px' }}>
        <RestaurantCardModalHandler darkmode={datas.darkmode}>
          <div style={{ width: '100%', height: currentCard.product_main_image_url ? '301px' : '60px', position: 'relative' }}>
            <RestaurantCardModalCloseButton 
              darkmode={datas.darkmode}
              color={datas.color}
              onClick={() => setIsOpen(false)}>
              X
            </RestaurantCardModalCloseButton>
            {currentCard.product_main_image_url && 
              <AwesomeSlider fillParent mobileTouch buttons={false}>
                <div data-src={currentCard.product_main_image_url} />
              </AwesomeSlider>
            }
          </div>
          <div style={{ padding: '0 20px', paddingBottom: '1px', backgroundColor: datas.darkmode && '#292C2B' }}>
            <RestaurantCardModalHeading darkmode={datas.darkmode}>
              <RestaurantCardModalHeadingTitle darkmode={datas.darkmode}>
                {currentCard.product_name}
              </RestaurantCardModalHeadingTitle>
              <BadgeHandler>
                {currentCard?.product_labels &&
                  currentCard.product_labels.map((badgeItem, i) => (
                    <Badge
                      color={datas.color}
                      type="labels"
                      key={i}
                      style={{ marginBottom: '11px' }}
                      isActive={0}
                      text={badgeItem}
                      modal
                    />
                  ))}
              </BadgeHandler>
              <RestaurantCardModalHeadingDescription darkmode={datas.darkmode}>
                {currentCard.product_description}
              </RestaurantCardModalHeadingDescription>
              <RestaurantCardModalHeadingDescription darkmode={datas.darkmode}>
                {currentCard.product_add_infos}
              </RestaurantCardModalHeadingDescription>
              {currentCard?.product_prices && (
                <>
                  {currentCard.product_prices.length > 0 &&
                    currentCard.product_prices.map((price) => (
                      <Line darkmode={datas.darkmode}>
                        <div style={{ maxWidth: '80%' }}>
                          {price.description}
                        </div>
                        <div style={{ maxWidth: '80%' }}>
                          <div style={{ display: 'flex'}}>
                            <div style={{marginRight: '16px', textDecoration: currentCard.product_promo_type === 'percentage' && 'line-through' }}>
                              {price.price} €
                            </div>
                            {currentCard.product_promo_type === 'percentage' && (
                              <RestaurantCardNewPrice color={datas.color} style={{marginBottom: '0'}}>
                                {parseFloat(
                                  price.price -
                                    price.price *
                                      (currentCard.product_promo_reduction / 100)
                                ).toFixed(2)} €
                              </RestaurantCardNewPrice>
                            )}
                          </div>
                        </div>
                      </Line>
                    ))}
                </>
              )}
            </RestaurantCardModalHeading>
            <BadgeHandler center>
              {currentCard?.product_alergens &&
                currentCard.product_alergens.map((badgeItem, i) => (
                  <Badge
                    color={datas.color}
                    type="alergens"
                    key={i}
                    style={{ marginBottom: '11px' }}
                    isActive={0}
                    text={badgeItem}
                    modal
                  />
                ))}
            </BadgeHandler>
            {(currentCard?.cat !== 'drinks' && currentCard?.product_add_infos) && 
              <div>
                <RestaurantCardModalSectionTitle color={datas.color}>
                  {currentCard?.cat === 'others' ? t('extra infos') : t('composition')}
                </RestaurantCardModalSectionTitle>
                <RestaurantCardModalHeading>
                  {currentCard?.product_add_infos}
                </RestaurantCardModalHeading>
              </div>
            }
            {(currentCard?.cat === 'drinks' && getMultilangContent(
                currentCard.drinks_datas,
                'advice_ml',
                selected_lang
              )) && 
              <div>
                <RestaurantCardModalSectionTitle color={datas.color}>
                  Conseils de service
                </RestaurantCardModalSectionTitle>
                <RestaurantCardModalHeading>
                  {getMultilangContent(
                    currentCard.drinks_datas,
                    'advice_ml',
                    selected_lang
                  )}
                </RestaurantCardModalHeading>
              </div>
            }
            {/* <div>
              <RestaurantCardModalSectionTitle>
                En Options
              </RestaurantCardModalSectionTitle>
              <RestaurantCardModalSectionDescription>
                Choisissez vos options
              </RestaurantCardModalSectionDescription>
              <RestaurantCardModalHeading>
                <Line>
                  <div>Pain complet remplacé par du pain brioché fait maison</div>
                  <div>{}</div>
                </Line>
                <Line>
                  <div>Croque monsieur deux étages</div>
                  <div>+10 €</div>
                </Line>
              </RestaurantCardModalHeading>
            </div>
            {suggestions && (
              <div>
                <RestaurantCardModalSectionTitle>
                  Suggestions
                </RestaurantCardModalSectionTitle>
                <RestaurantCardModalSectionDescription>
                  Accompagner votre plat avec :
                </RestaurantCardModalSectionDescription>
                <RestaurantCardModalHeading>
                  {suggestions.map((suggestion, i) => (
                    <div key={i}>
                      <RestaurantCardDescription>
                        {suggestion.description}
                      </RestaurantCardDescription>
                      <RestaurantCard {...suggestion.card} />
                      {i !== suggestions.length - 1 && <Divider />}
                    </div>
                  ))}
                </RestaurantCardModalHeading>
              </div>
            )} */}
          </div>
        </RestaurantCardModalHandler>
      </Modal.Body>
    </Modal>
  )
}

export default withTranslation()(RestaurantCardModal)

const Divider = styled.div`
  background-color: #d1d1d1;
  margin: 30px 0px;
  height: 1px;
`
const RestaurantCardDescription = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font: normal normal normal 15px Lato;
  margin-bottom: 15px;
  color: #276347;
`
const RestaurantCardModalSectionDescription = styled.div`
  font: normal normal normal 15px Lato;
  color: #276347;
  margin-bottom: 13px;
  margin-left: 19px;
`
const RestaurantCardModalSectionTitle = styled.div`
  margin-left: 19px;
  color: ${(props) => props.color};
  font: normal normal bold 25px Lato;
  margin-bottom: 6px;
`
const BadgeHandler = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.center && 'center'};
`
const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: normal normal bold 15px Lato;
  letter-spacing: 0px;
  color: ${(props) => props.darkmode ? 'white' : '#1d1d1d'};
  margin-bottom: 11px;
  &:last-child {
    margin-bottom: 0px;
  }
`
const RestaurantCardModalHeading = styled.div`
  margin-top: 15px;
  background-color: ${(props) => props.darkmode ? 'black' : 'white'};
  padding: 16px 20px 25px 20px;
  border-radius: 5px;
  margin-bottom: 25px;
`
const RestaurantCardModalHeadingDescription = styled.div`
  font: normal normal normal 15px Lato;
  color: ${(props) => props.darkmode ? 'white' : '#606060'};
  margin-bottom: 16px;
`
const RestaurantCardModalHeadingTitle = styled.div`
  font: normal normal bold 20px Lato;
  color: ${(props) => props.darkmode ? 'white' : '#1d1d1d'};
  margin-bottom: 10px;
`
const RestaurantCardModalCloseButton = styled.button`
  z-index: 30;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: none;
  position: absolute;
  font-weight: bold;
  left: 20px;
  top: 18px;
  font-size: 13px;
  color: ${(props) => props.darkmode ? 'white' : props.color};
  background-color: ${(props) => props.darkmode ? 'black' : 'white'};
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`
const RestaurantCardModalHandler = styled.div`
  width: 524px;
  background-color: ${(props) => props.darkmode ? '#292C2B' : '#e7f2ef'};
  @media (max-width: 524px) {
    width: 100%;
  }
`
const RestaurantCardNewPrice = styled.div`
  ${(props) =>
    props.lineThrough
      ? 'text-decoration-color: #969696;text-decoration-line: line-through;'
      : ''}
  margin-bottom: 11px;
  color: ${(props) => props.color};
  font: normal normal bold 16px Lato;
`