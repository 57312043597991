import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import SyncIcon from '@material-ui/icons/Sync'
import { useTranslation } from 'react-i18next'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import CheckIcon from '@material-ui/icons/Check'

const Synchronizing = ({ status }) => {
	const { t } = useTranslation()
	let message = ''
	let icon = <SyncIcon />
	let color = 'primary'
	switch (status) {
		case 'active':
			message = ''
			icon = <SyncIcon />
			color = 'primary'
			break
		case 'done':
			message = ''
			icon = <CheckIcon />
			color = 'success'
			break
		case 'failed':
			message = `${t('synchronization_failed')}`
			icon = <SyncProblemIcon />
			color = 'failed'
			break
		default:
			message = ''
			icon = <SyncIcon />
			color = 'primary'
			break
	}

	return (
		<SynchronizingStyled color={color} status={status}>
			{icon} {message}
		</SynchronizingStyled>
	)
}

export default withTheme(Synchronizing)

const SynchronizingStyled = styled.div`
	display: ${(props) => (props.status === 'hidden' ? 'none' : 'block')};
	position: fixed;
	color: white;
	background: ${(props) => props.theme[props.color]};
	right: 0px;
	bottom: 0px;
	padding: 11px;
	z-index: 20000;
`

Synchronizing.defaultProps = {
	status: 'hidden',
}
Synchronizing.propTypes = {
	status: PropTypes.string,
}
