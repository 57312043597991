import React from 'react'
import Product from '../Product/Product'
import SubCategorie from '../SubCategorie/SubCategorie'
import styled, { withTheme } from 'styled-components'
import { Box, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const MainCategorie = ({ products, item }) => {
  const { t } = useTranslation()

  return (
    <Background>
      <Flex>
        <Title>{t(item.category_name)}</Title>
        <ProductNb>{`${
          products.filter((p) => p.tab === item.category_name).length
        } ${t('product(s)')}`}</ProductNb>
      </Flex>
      <br/>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} justifyContent="left" alignItems="center">
            {item.products.map((product) => (
              <Grid key={product.id} item xs={12} md={4} lg={4}>
                <Product product={product} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          {item.groups.map((group) => (
            <SubCategorie key={group.id} group={group} />
          ))}
        </Box>
    </Background>
  )
}

export default withTheme(MainCategorie)

const Flex = styled.div`
  display: flex;
`
const Title = styled.div`
  text-align: initial;
  font-size: 13pt;
  font-weight: bold;
  margin-left: 15px;
`
const Background = styled.div`
  padding: 20px 40px 20px 40px;
  @media screen and (max-width: 526px) {
    padding: 20px 10px 20px 10px;
  }
  box-shadow: 0px -4px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  margin-bottom: 40px;
`
const ProductNb = styled.div`
  font-size: 13pt;
  font-weight: 400;
  color: #6f4fb3;
  margin-top: ${(props) => props.top && '10px'};
  margin-left: 15px;
  margin-bottom: 10px;
`