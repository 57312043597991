import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import TopStepper from '../../../components/UI/TopStepper/TopStepper';
import UseCreateAQrCodeContainer from './CreateAQrCodeStore';
import withAccountVerification from '../../../hoc/withAccountVerification';

// Steps
import QrCodes from './steps/QrCodes/QrCodes';

const CreateAQrCode = ({ t, setTitle }) => {
const CreatePlaceState = UseCreateAQrCodeContainer.useContainer();

React.useEffect(() => {
setTitle(t('Create a place'));
}, [setTitle, t]);

  let step = {

  0: <QrCodes />,
  1: '',

  };

  return (

    <CreateAQrCodeStyled>
      <TopStepper
        steps={[
          t('subscriptions'),
          t('payment'),
        ]}
        step={CreatePlaceState.activeStep}
      />

      {step[CreatePlaceState.activeStep]}

    </CreateAQrCodeStyled>
  );
};

export default withAccountVerification(withTranslation()(CreateAQrCode));

const CreateAQrCodeStyled = styled.div`
overflow: auto;
    position: fixed;
    z-index: 5000;
    padding-bottom: 100px;
    top: 0;
    width: 100%;
    height: 103vh;
    left: 0;
    background: #eee;`;
