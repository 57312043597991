import React from 'react';
import styled from 'styled-components';

const Navbar = (props) => (
  <NavbarStyled>
    <NavbarContainer>
      {props.items.map((item, i) => (
        <NavbarItem key={`tab${i}`}>
          {props.tab === item
            ? (
                <SelectedElement
                    onClick={() => props.setTab && props.setTab(item)}
                    className="box"
                    setTab={props.setTab}
                >
                    {props.t(item)}
                    <hr />
                </SelectedElement>
            ) : (
                <div
                    onClick={() => props.setTab && props.setTab(item)}
                    style={{ color: '#969696', cursor: 'pointer' }}
                >
                    {props.t(item)}
                </div>
            )}
        </NavbarItem>
        ))}
    </NavbarContainer>
  </NavbarStyled>
);

export default Navbar;

const NavbarStyled = styled.div`
    background: #E6EFFF;
    padding: 9px 20px;	
    margin-right: 5%;
    height: 40px;
    color: white;
    text-align: center;
    border-radius: 12px;
    margin: 20px 4px 20px 16px;
`;
const NavbarContainer = styled.div`
    display: flex;
    text-align: initial;
`;
const NavbarItem = styled.div`
    margin-right: 3%;
`;
const SelectedElement = styled.div`
    hr {
        width: 40px;
        margin-top: 5px;
        margin-left: 0px;
        border-color: #E76B56;
        border-bottom: 1px solid red;
    }
    cursor: ${(props) => props.setTab ? 'pointer' : 'initial'};
    font-weight: bold;
    color: black;
    margin-right: 3%;
    padding-bottom: 4px;
    display: contents;
`;
