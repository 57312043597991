import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import styled, { withTheme } from 'styled-components'
import { Container, Row, Col, Card, Button, Spinner, Modal } from 'react-bootstrap'
import { useDocument } from '@nandorojo/swr-firestore'
import { useParams, useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'
import { confirmAlert } from 'react-confirm-alert'
import { SwapVert, Pause, DeleteForever } from '@material-ui/icons'

import Plans from './Plans/Plans'
import PaymentMethods from './PaymentMethods/PaymentMethods'
import CreatePaymentMethod from './CreatePaymentMethod/CreatePaymentMethod'
import Trial from './Trial/Trial'

import ErrorPaymentHandler from './ErrorPaymentHandler/ErrorPaymentHandler'
import UpcommingInvoice from './UpcommingInvoice/UpcommingInvoice'
import UpdateTrialSummary from './UpdateTrialSummary/UpdateTrialSummary'
import useStripe from '../../../hooks/Stripe'
import withAccountVerification from '../../../hoc/withAccountVerification'
import AuthContainer from '../../../stores/AuthContainer'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Subscription = ({ t, setTitle, remaintTrialDay, trialExpired }) => {
	const { placeId } = useParams()
	const { user } = AuthContainer.useContainer()
	const {
		updateSubscription,
		suspendSubscription,
		getPaymentMethods,
		updateTrialPlace,
		upcomingInvoice,
		Coupon,
		couponDatas,
		checkingCoupon,
		setCouponDatas,
	} = useStripe()
	const [paymenMethods, setPaymentMethods] = useState()
	const { enqueueSnackbar } = useSnackbar()
	const [updatingSubscription, setUpdatingSubscription] = useState(false)
	const [suspending, setSuspending] = useState(false)
	const [showCreatePaymentMethod, setShowCreatePaymentMethod] = useState(false)
	const [paymentMethodChoosed, setPaymentMethodChoosed] = useState(null)
	const [subscriptionMethodChoosed, setSubscriptionMethodChoosed] = useState(null)
	const [updatingTrial, setUpdatingTrial] = useState(false)
	const [upcomingInvoiceState, setUpcomingInvoice] = useState(null)
	const [changeModal, setChangeModal] = useState(false)
	const [pauseModal, setPauseModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const history = useHistory()
	const {
		data: placeData,
		deleteDocument: deletePlace,
		error: placeError,
	} = useDocument(placeId ? `places/${placeId}` : null)

	const { data: placeSubscriptionData, error: placeSubscriptionError } = useDocument(
		placeData ? `places/${placeData.id}/subscription/place_subscription` : null,
		{
			listen: true,
		}
	)

	useEffect(() => {
		if (!suspending) {
			setPauseModal(false)
		}
	}, [suspending])

	useEffect(() => {
		if (placeData) {
			setTitle(
				`${t('subscriptions')} > ${t('my subscriptions')} > <b>${
					placeData.place_name
				}</b>`
			)
			getUpcomingInvoice()
			getPaymentMethodsAsync()
		}
	}, [placeData])

	const getUpcomingInvoice = async (sub_id = null) => {
		const _updcomingInvoice = await upcomingInvoice({
			subscriptionId: sub_id !== null ? sub_id : placeData.subscriptionId,
		})
		const { status, code, error, datas } = _updcomingInvoice.data
		if (status === 'success') {
			setUpcomingInvoice(datas)
		}
	}

	const removeMethod = (id) => {
		setPaymentMethods(paymenMethods.filter((m) => m.id !== id))
	}

	const getPaymentMethodsAsync = async () => {
		const p_methods = await getPaymentMethods()
		const { status, code, error } = p_methods.data
		if (status === 'error') {
			enqueueSnackbar(t(code), { variant: 'error' })
		} else {
			setPaymentMethods(p_methods.data.datas.data)
		}
	}

	const switchPlan = async (priceId) => {
		setUpdatingSubscription(true)
		const _updcomingInvoice = await upcomingInvoice({
			subscriptionId: placeData.subscriptionId,
			priceId,
		})
		const { datas: prorationDatas } = _updcomingInvoice.data
		setChangeModal(false)

		confirmAlert({
			title: 'Confirmation',
			message: `${t('you_will_be_charge')} ${(
				prorationDatas.amount_due / 100
			).toFixed(2)} € TTC`,
			buttons: [
				{
					label: t('ok'),
					onClick: async () => {
						const up = await updateSubscription(placeId, priceId)
						const { status, code } = up.data

						if (status === 'error') {
							enqueueSnackbar(t(up.code), { variant: 'error' })
						} else {
							setChangeModal(false)
							enqueueSnackbar(t('subscription_update_success'), {
								variant: 'success',
							})
						}
						setUpdatingSubscription(false)

						getUpcomingInvoice()
					},
				},
				{
					label: t('cancel'),
					onClick: () => {
						setUpdatingSubscription(false)
					},
				},
			],
		})
	}
	const suspend = async (active = false) => {
		setSuspending(true)

		const up = await suspendSubscription(placeId, active)
		const { status, code } = up.data

		if (status === 'error') {
			enqueueSnackbar(t(up.code), { variant: 'error' })
		} else if (status === 'success') {
			enqueueSnackbar(
				!active
					? t('subscription_suspended_success')
					: t('subscription_reactivated_success'),
				{ variant: 'success' }
			)
		}
		setSuspending(false)
	}

	const createSubscriptionForTrialPlace = async () => {
		setUpdatingTrial(true)

		confirmAlert({
			title: 'Confirmation',
			message: !couponDatas?.reductedPrice
				? `${t('you_will_be_charge')} ${(
						subscriptionMethodChoosed.unit_amount / 100
				  ).toFixed(2)} € TTC`
				: `${t('you_will_be_charge')} ${couponDatas.reductedPrice} € TTC`,

			buttons: [
				{
					label: t('ok'),
					onClick: async () => {
						const up = await updateTrialPlace({
							placeId: placeData.id,
							pm: paymentMethodChoosed,
							priceId: subscriptionMethodChoosed?.id,
							coupon: couponDatas?.id,
						})

						const { status, error, code, datas } = up.data
						if (status === 'error') {
							enqueueSnackbar(t('error_payment'), { variant: 'error' })
							setUpdatingTrial(false)
						}

						if (status === 'success') {
							enqueueSnackbar(t('subscription_update_success'), {
								variant: 'success',
							})

							getUpcomingInvoice(datas.id)
							if (datas && datas.next_action) {
								window.open(datas.next_action.use_stripe_sdk.stripe_js, '_blank')
							}
							setUpdatingTrial(false)
						}
					},
				},
				{
					label: t('cancel'),
					onClick: () => {
						setUpdatingTrial(false)
					},
				},
			],
			onClickOutside: () => {
				setUpdatingTrial(false)
			},
		})
	}

	return (
		<>
			<Modal size="lg" show={changeModal} onHide={() => setChangeModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('change_subscription')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Plans
						currentPriceId={
							placeSubscriptionData?.plan ? placeSubscriptionData?.plan?.id : null
						}
						switchPlan={switchPlan}
						updatingSubscription={updatingSubscription}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setChangeModal(false)}>
						{t('cancel')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={pauseModal} onHide={setPauseModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{placeSubscriptionData?.pause_collection !== null
							? t('reactivate_subscription')
							: t('suspend_subscription')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>Vous êtes sûr ?</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline"
						disabled={suspending}
						onClick={() => {
							suspend(placeSubscriptionData?.pause_collection !== null)
						}}
					>
						{suspending ? (
							<Spinner animation="border" size="sm" variant="dark" />
						) : (
							t('validate')
						)}
					</Button>
					<Button variant="secondary" onClick={() => setPauseModal(false)}>
						{t('cancel')}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={deleteModal} onHide={setDeleteModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('remove_subscription')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>Il sera supprimé de façon définitive</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline"
						onClick={async () => {
							await deletePlace()
							enqueueSnackbar(t('place_delete_successfully'), { variant: 'success' })
							history.push('/dashboard/places')
						}}
					>
						{t('validate')}
					</Button>
					<Button variant="secondary" onClick={() => setDeleteModal(false)}>
						{t('cancel')}
					</Button>
				</Modal.Footer>
			</Modal>
			<CustomContainer fluid>
				{placeSubscriptionData && (
					<>
						<CreatePaymentMethod
							show={showCreatePaymentMethod}
							setShow={setShowCreatePaymentMethod}
							paymenMethodsReload={getPaymentMethodsAsync}
						/>
						<ErrorPaymentHandler
							invoice={placeSubscriptionData?.invoice}
							currentMethod={placeSubscriptionData?.default_payment_method}
							canceled={
								placeSubscriptionData.status === 'incomplete_expired' ||
								placeSubscriptionData.status === 'canceled'
							}
							placeId={placeData.id}
						/>

						{placeSubscriptionData.status !== 'incomplete_expired' &&
							placeSubscriptionData.status !== 'canceled' && (
								<>
									{!placeSubscriptionData?.trial ? (
										<>
											<Row>
												<Col lg="4">
													<SubscriptionAction bsPrefix="card white-card">
														<Card.Body style={{ padding: '10px 0px 0px 0px' }}>
															<ChangeIcon />
															<Card.Title>{t('change_subscription')}</Card.Title>
															<div
																style={{
																	textAlign: '-webkit-center',
																	marginTop: '20px',
																}}
															>
																<CardDescription>
																	<div>{t('change_subscription_detail')}</div>
																	<div>{t('change_subscription_detail2')}</div>
																</CardDescription>
															</div>
														</Card.Body>
														<div style={{ textAlign: '-webkit-center' }}>
															<CustomButton
																onClick={() => setChangeModal(true)}
																disabled={
																	placeSubscriptionData.pause_collection !== null
																}
															>
																{t('next')}
															</CustomButton>
														</div>
													</SubscriptionAction>
												</Col>
												<Col lg="4">
													<SubscriptionAction bsPrefix="card white-card">
														<Card.Body style={{ padding: '10px 0px 0px 0px' }}>
															<PauseIcon />
															<Card.Title>
																{placeSubscriptionData?.pause_collection !== null
																	? t('reactivate')
																	: t('suspend')}
															</Card.Title>
															<div
																style={{
																	textAlign: '-webkit-center',
																	marginTop: '20px',
																}}
															>
																<CardDescription>
																	{t('suspend_subscription_detail')}
																</CardDescription>
															</div>
														</Card.Body>
														<div style={{ textAlign: '-webkit-center' }}>
															<CustomButton onClick={() => setPauseModal(true)}>
																{t('next')}
															</CustomButton>
														</div>
													</SubscriptionAction>
												</Col>
												<Col lg="4">
													<SubscriptionAction bsPrefix="card white-card">
														<Card.Body style={{ padding: '10px 0px 0px 0px' }}>
															<DeleteIcon />
															<Card.Title>{t('remove')}</Card.Title>
															<div
																style={{
																	textAlign: '-webkit-center',
																	marginTop: '20px',
																}}
															>
																<CardDescription>
																	{t('remove_subscription_detail')}
																</CardDescription>
															</div>
														</Card.Body>
														<div style={{ textAlign: '-webkit-center' }}>
															<CustomButton onClick={() => setDeleteModal(true)}>
																{t('next')}
															</CustomButton>
														</div>
													</SubscriptionAction>
												</Col>
											</Row>
											<Row>
												<Col>
													<UpcommingInvoice t={t} invoice={upcomingInvoiceState} />
												</Col>
											</Row>
											<Row>
												<Col>
													<Card bsPrefix="card white-card">
														<Card.Header>
															<Card.Title>{t('payment_methods')}</Card.Title>
														</Card.Header>
														<Card.Body>
															<PaymentMethods
																placeId={placeData.id}
																currentMethod={
																	placeSubscriptionData?.default_payment_method
																}
																methods={paymenMethods}
																removeMethod={removeMethod}
															/>
															<div style={{ textAlign: 'center' }}>
																<hr />
																<Button
																	onClick={() => setShowCreatePaymentMethod(true)}
																>
																	{paymenMethods &&
																	Object.keys(paymenMethods).length < 1
																		? t('add_payment_method')
																		: t('add_new_payment_method')}
																</Button>
															</div>
														</Card.Body>
													</Card>
												</Col>
											</Row>
										</>
									) : (
										<Row>
											<Col>
												<Card bsPrefix="card white-card">
													{trialExpired ? (
														<Card.Header style={{ textAlign: 'center' }}>
															<Card.Title>{t('trial_period_ended')}</Card.Title>
															<Card.Subtitle>
																{t('update_trial_to_continue')}
															</Card.Subtitle>
														</Card.Header>
													) : (
														<Card.Header style={{ textAlign: 'center' }}>
															<Card.Title>
																<b>{remaintTrialDay} </b>
																{t('days_before_trial_expire')}
															</Card.Title>
															<Card.Subtitle>
																{t('places_in_trial_mode')}
															</Card.Subtitle>
														</Card.Header>
													)}
													<Card.Body>
														<Trial
															subscriptionMethodChoosed={
																subscriptionMethodChoosed?.id
															}
															setSubscriptionMethodChoosed={
																setSubscriptionMethodChoosed
															}
														/>
														<hr />
														<br />
														<PaymentMethods
															placeId={placeData.id}
															methods={paymenMethods}
															currentMethod={
																placeSubscriptionData?.default_payment_method
															}
															isTrial={placeSubscriptionData?.trial}
															paymentMethodChoosed={paymentMethodChoosed}
															setPaymentMethodChoosed={setPaymentMethodChoosed}
														/>
														{paymentMethodChoosed !== null &&
															subscriptionMethodChoosed !== null && (
																<Card.Footer>
																	<UpdateTrialSummary
																		selectedPlan={subscriptionMethodChoosed}
																		couponElements={{
																			element: Coupon,
																			datas: couponDatas,
																			loading: checkingCoupon,
																			reset: () => setCouponDatas({}),
																		}}
																	/>
																	<div style={{ textAlign: 'center' }}>
																		<Button
																			disabled={updatingTrial}
																			variant="secondary"
																			onClick={() =>
																				createSubscriptionForTrialPlace()
																			}
																		>
																			{updatingTrial ? (
																				<Spinner
																					animation="border"
																					size="sm"
																					variant="light"
																				/>
																			) : (
																				t('subscribe')
																			)}
																		</Button>
																	</div>
																</Card.Footer>
															)}
														<br />
														<div style={{ textAlign: 'center' }}>
															<Button
																onClick={() => setShowCreatePaymentMethod(true)}
															>
																{paymenMethods &&
																Object.keys(paymenMethods).length < 1
																	? t('add_payment_method')
																	: t('add_new_payment_method')}
															</Button>
														</div>
													</Card.Body>
												</Card>
											</Col>
										</Row>
									)}
								</>
							)}
					</>
				)}
			</CustomContainer>
		</>
	)
}

export default withAccountVerification(withTranslation()(Subscription))

const CustomContainer = styled(Container)`
	margin-top: 80px;
	@media (min-width: 992px) {
		padding-left: 250px;
	}
`
const SubscriptionAction = styled(Card)`
	text-align: center;
	height: 320px;
	.card-title {
		font-size: 20px !important;
	}
	p {
		font-size: 14px !important ;
	}
`
const CustomButton = styled(Button)`
	margin-top: -30px;
	width: 70%;
`
const CardDescription = styled.div`
	font-size: 13px;
	text-align: center;
	width: 80%;
`
const ChangeIcon = styled(SwapVert)`
	width: 65px !important;
	height: 65px !important;
	margin: 10px 0px 20px 0px;
	opacity: 0.3;
`
const PauseIcon = styled(Pause)`
	width: 65px !important;
	height: 65px !important;
	margin: 10px 0px 20px 0px;
	opacity: 0.3;
`
const DeleteIcon = styled(DeleteForever)`
	width: 65px !important;
	height: 65px !important;
	margin: 10px 0px 20px 0px;
	opacity: 0.3;
`
